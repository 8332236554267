import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { ToursCatalog } from 'package-types';

import { SearchResultStore } from '../dynamic-page-container/services/store/search-result.store';
import { HotelPageInfoStore } from './services/hotel-page-info.store';
import { HotelPageInfoState } from './services/hotel-page-info.state';
import { DynamicPage } from '@classes/decorators/dynamic-page.decorator';
import { SearchPageComponent } from '../dynamic-page-container/components/search-page/search-page.component';
import { ErrorModalService } from '@modules/tours/core/services/error-modal.service';
import { InformationStore } from '@services/store/information.store';
import { ToursSearchService } from '@services/tours-search/tours-search.service';

import { Hotel, HotelPageInfo, PageInfo } from '@interfaces/services/api/tours-catalog.endpoint';

@DynamicPage({ pageName: ToursCatalog.PageName.Main, disableH1SmallUpdates: true })
@Component({
  selector: 'app-hotel-overview-page',
  templateUrl: './hotel-overview-page.component.html',
  styleUrls: ['./hotel-overview-page.component.scss'],
  providers: [SearchResultStore, HotelPageInfoStore, ToursSearchService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotelOverviewPageComponent extends SearchPageComponent implements OnInit {
  hotel$: Observable<Hotel> = this.onHotelInfoChange();
  headImage$: Observable<ToursCatalog.PageHeadImage | undefined> = this.information.onChanges('pageInfo')
    .pipe(
      distinctUntilChanged(),
      filter((i): i is HotelPageInfo => i?.type === ToursCatalog.PageType.Hotel),
      map(hotelInfo => hotelInfo.headImage)
    );

  hotelPageInfo$: Observable<HotelPageInfoState['hotelInfo']> = this.hotelPageInfo.onChanges('hotelInfo');
  rating$: Observable<HotelPageInfoState['rating']> = this.hotelPageInfo.onChanges('rating');
  reviews$: Observable<HotelPageInfoState['reviews']> = this.hotelPageInfo.onChanges('reviews');

  constructor(
    private hotelPageInfo: HotelPageInfoStore,
    private information: InformationStore,
    errorModal: ErrorModalService,
    searchResult: SearchResultStore
  ) {
    super(errorModal, searchResult);
  }

  ngOnInit() {
    super.ngOnInit();
    this.searchResult.init();
    this.hotelPageInfo.init();
  }

  private onHotelInfoChange(): Observable<Hotel> {
    return this.information.onChanges('pageInfo').pipe(this.extractHotelInfo());
  }

  private extractHotelInfo(): OperatorFunction<PageInfo | undefined, Hotel> {
    return source$ => source$.pipe(
      filter((info): info is HotelPageInfo => info?.type === ToursCatalog.PageType.Hotel),
      map(page => page.hotel)
    );
  }
}
