<div class="wrapper bus-animation" id="bus-animation">
  <div id="bus">
    <div id="busframe">
      <div id="backseats">
        <div class="window"></div>
        <div class="window"></div>
        <div class="window"></div>
      </div>
      <div id="frontseat">
        <div class="window"></div>
        <div id="headlight"></div>
      </div>
    </div>
    <div class="wheel wheel-front"></div>
    <div class="wheel wheel-back"></div>
  </div>
  <div class="wrapper-2">
    <div class="road"></div>
  </div>
</div>
