import { initSearchDates } from '@helpers/date.helper';

import { SearchParams } from '@interfaces/modules/tours/search';

export function defaultSearch(): SearchParams {
  return {
    destination: undefined,
    hotels: [],
    resorts: [],
    departureCity: 1544,
    dates: initSearchDates(),
    duration: { from: 7, to: 9 },
    tourists: { adults: 2, children: [] },
    foods: [],
    transports: [],
    services: [],
    stars: [],
    ratingMin: 4,
    price: { min: undefined, max: undefined }
  };
}
