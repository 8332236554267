<ng-container *ngIf="(isLoading$ | async) === false; then availability; else preloader"></ng-container>
<ng-template #availability>
  <div class="hotel-availability" *ngIf="availableOffersStore.state$ | async as availableOffers">
    <div class="container" *ngIf="availableOffers.unfilteredOffers.length > 0">
      <div class="row hotel-availability__header">
        <div class="col-12 col-xl-3">
          <h2 class="hotel-availability__title">{{ 'HotelOverviewPage.YourOffer' | translate }}</h2>
          <span class="hotel-availability__offers-count"
                *ngIf="(availableOffersStore.state$ | async) as availableOffers">
            {{
            'HotelOverviewPage.AvailableOffers' | translate:{
              found: availableOffers.unfilteredOffers.length,
              displayed: availableOffers.offers.length
            }
            }}
            </span>
        </div>
        <div class="col-12 col-sm-10 col-xl-9 hotel-availability__display-options">
          <app-price-display-options-group class="d-none d-lg-flex"></app-price-display-options-group>
          <app-price-display-options-select class="d-lg-none"></app-price-display-options-select>
        </div>
        <div class="col-12 col-sm-2 d-xl-none hotel-availability__filter-toggle-button">
          <button class="btn btn-muted btn-option" (click)="toggleFilters()">
            <img src="/assets/icons/filter.svg" alt="Filter icon">
            <span>{{ 'Common.Filter' | translate }}</span>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-2">
          <app-collapsable-panel [isOpen]="isOpen">
            <app-filters-bar></app-filters-bar>
          </app-collapsable-panel>
        </div>
        <div class="col-12 col-md-4 order-md-1">
          <app-card>
            <app-selected-offer-panel
              *ngIf="selectionStore.state$ | async as selection"
              [selectedOffer]="selection.selectedOffer"
              [selectedFlights]="selection.selectedFlights">
            </app-selected-offer-panel>
          </app-card>
        </div>
        <div class="col-12 col-md-8 col-xl-6">
          <app-available-offers></app-available-offers>
        </div>
      </div>
    </div>
    <div class="container" *ngIf="availableOffers.unfilteredOffers.length === 0">
      <div class="row">
        <div class="col-12">
          {{ 'HotelOverviewPage.OffersNotFound' | translate }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #preloader>
  <app-bus-preloader></app-bus-preloader>
</ng-template>
