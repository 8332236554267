<div class="office-info" *ngIf="office">
  <div class="office-info__address">
    <ng-container *ngIf="showCity">{{ office.location.city.name }}, </ng-container>
    {{ office.address }}
  </div>
  <div class="office-info__schedule" *ngIf="office.isTemporarilyClosed && !office.info">
    {{ 'Common.TemporarilyClosed' | translate }}
  </div>
  <div class="office-info__schedule" *ngIf="!office.isTemporarilyClosed">
    <div class="office-info__schedule-list">
      <ng-container *ngFor="let item of office.workSchedule | workSchedule">{{ item }}<br></ng-container>
    </div>
  </div>
  <div class="office-info__info" *ngIf="office.info">
    {{ office.info }}
  </div>
  <div class="office-info__phone">
    <a
      *ngFor="let phone of office.contacts.phones"
      [href]="'tel:' + phone">
      {{ phone }}
    </a>
  </div>
  <div class="office-info__email">
    <a
      *ngFor="let email of office.contacts.emails"
      [href]="'mailto:' + email">
      {{ email }}
    </a>
  </div>
</div>
