import range from 'lodash/range';

import { RangeImpl } from './range-impl';

export class NumberRange extends RangeImpl<number> {
  static fromArray(range: number[]): NumberRange {
    const sorted: number[] = [...range].sort((a: number, b: number) => a - b);

    return new NumberRange(sorted[0], sorted[sorted.length - 1]);
  }

  static fromStart(from: number, length: number): NumberRange {
    return new NumberRange(from, from + length - 1);
  }

  isInside(nights: number): boolean {
    return nights >= this.from && nights <= this.to;
  }

  eachValue(): number[] {
    return range(this.from, this.to + 1);
  }

  merge(range: NumberRange): NumberRange {
    const union: number[] = [...range.toArray(), ...this.toArray()];

    return new NumberRange(Math.min(...union), Math.max(...union));
  }
}
