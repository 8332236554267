import { ToursSearch } from 'package-types';

export function sortOffersInAscendingOrderOfPrice(offers: ToursSearch.Offer[]): ToursSearch.Offer[] {
  return [...offers].sort((o1, o2) => o1.priceUAH.toNumber() - o2.priceUAH.toNumber());
}

export function findCheapestPricePerNights(offers: ToursSearch.Offer[]): number {
  return offers
    .map(({ priceUAH, nights }) => priceUAH.toNumber() / nights)
    .reduce((res, curr) => Math.min(res, curr));
}
