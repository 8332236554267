import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { HotelRating } from '../../services/hotel-page-info.state';

@Component({
  selector: 'app-overview-rating',
  templateUrl: './overview-rating.component.html',
  styleUrls: ['./overview-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewRatingComponent {
  @Input() rating?: HotelRating;
}
