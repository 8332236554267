<ng-select #select
           [multiple]="true"
           [items]="items"
           [searchable]="false"
           appendTo="body"
           [dropdownPosition]="'bottom'"
           [virtualScroll]="true"
           [(ngModel)]="selectedCode"
           [placeholder]="placeholder | translate">
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngFor="let item of items | slice:0:viewLabelsCount">
      <span class="ng-value-label">{{ [translationBase, item].join('.') | translate }}</span>
      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
    </div>
    <div class="ng-value" *ngIf="items.length > viewLabelsCount">
      <span class="ng-value-label">+{{ items.length - viewLabelsCount }}</span>
    </div>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    {{ [translationBase, item].join('.') | translate }}
  </ng-template>
</ng-select>
