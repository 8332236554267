import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-privacy-policy-popup',
  templateUrl: './privacy-policy-popup.component.html',
  styleUrls: ['./privacy-policy-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyPolicyPopupComponent implements OnInit {
  @HostBinding('style.display') display = 'none';

  private readonly confirmationCookieName = 'privacyPolicyConfirmation';

  constructor(private readonly cookie: CookieService) { }

  ngOnInit(): void {
    if (this.cookie.get(this.confirmationCookieName) !== 'true') {
      this.display = 'flex';
    }
  }

  confirmPrivacyPolicy(): void {
    this.cookie.set(this.confirmationCookieName, 'true', undefined, '/');
    this.display = 'none';
  }
}
