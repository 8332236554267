import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-table-scroll',
  templateUrl: './table-scroll.component.html',
  styleUrls: ['./table-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableScrollComponent {
  @Output() left = new EventEmitter<void>();
  @Output() right = new EventEmitter<void>();
}
