<ng-container [formGroup]="formGroup">
  <div class="col-12 form-group">
    <app-form-field>
      <input id="name"
             appFormInput
             type="text"
             formControlName="name"
             [placeholder]="('Common.Name' | translate) + '*'">
    </app-form-field>
    <ng-container *ngIf="formGroup.get('name') as name">
      <div class="form-group__error"
           *ngIf="name.touched && name.errors?.required">
        {{ 'ValidationErrors.EnterName' | translate }}
      </div>
    </ng-container>
  </div>
  <div class="col-12 col-md-6 form-group">
    <app-form-field>
      <app-phone-input formControlName="phone" inputClass="sv-input"></app-phone-input>
    </app-form-field>
    <ng-container *ngIf="formGroup.get('phone') as phone">
      <div class="form-group__error"
           *ngIf="phone.touched && (phone.errors?.required || phone.errors?.pattern)">
        {{ 'ValidationErrors.EnterPhone' | translate:{ format: '+38(0##)-###-##-##' } }}
      </div>
    </ng-container>
  </div>
  <div class="col-12 col-md-6 form-group">
    <app-form-field>
      <input appFormInput
             type="email"
             id="email"
             formControlName="email"
             placeholder="E-mail*">
    </app-form-field>
    <ng-container *ngIf="formGroup.get('email') as email">
      <div class="form-group__error"
           *ngIf="email.touched && (email.errors?.email || email.errors?.required)">
        {{ 'ValidationErrors.WrongEMail' | translate }}
      </div>
    </ng-container>
  </div>
  <div class="col-12 form-group">
    <app-form-field>
      <input
        appFormInput
        type="text"
        formControlName="title"
        [placeholder]="('Common.Title' | translate) + '*'">
    </app-form-field>
    <ng-container *ngIf="formGroup.get('title') as title">
      <div class="form-group__error"
           *ngIf="title.touched && title.errors?.required">
        {{ 'ValidationErrors.RequiredField' | translate }}
      </div>
    </ng-container>
  </div>
  <div class="col-12 form-group">
    <app-form-field>
        <textarea
          appFormInput
          type="text"
          formControlName="text"
          [placeholder]="('Common.Comment' | translate) + '*'">
        </textarea>
    </app-form-field>
    <ng-container *ngIf="formGroup.get('text') as text">
      <div class="form-group__error"
           *ngIf="text.touched && text.errors?.required">
        {{ 'ValidationErrors.RequiredField' | translate }}
      </div>
    </ng-container>
  </div>
</ng-container>
