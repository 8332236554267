import { BlockBase, PositionDrivenBlock } from './blocks-sorting.interfaces';

export function sortDisplayableBlocks<T extends BlockBase>(blocks: T[]): T[] {
  const fixedBlocks = findAndSortFixedBlocks(blocks);
  const otherBlocks = findAndSortNotFixedBlocks(blocks);
  const sparseBlocks: T[] = makeSparseFixedBlocks(fixedBlocks);

  return fillGapsInSparseBlocksAndCompact(sparseBlocks, otherBlocks);
}

function findAndSortFixedBlocks<T extends BlockBase>(blocks: T[]) {
  return blocks
    .filter((b): b is PositionDrivenBlock<T> => Boolean(b.displayOptions.positionIndex))
    .sort(({ displayOptions: a }, { displayOptions: b }) => a.positionIndex - b.positionIndex);
}

function findAndSortNotFixedBlocks<T extends BlockBase>(blocks: T[]) {
  return blocks
    .filter(b => Boolean(!b.displayOptions.positionIndex))
    .sort(({ displayOptions: a }, { displayOptions: b }) => (a.sortIndex ?? Infinity) - (b.sortIndex ?? Infinity));
}

function makeSparseFixedBlocks<T extends BlockBase>(fixedBlocks: PositionDrivenBlock<T>[]) {
  const result = fixedBlocks.reduce<{ shift: number, blocks: T[] }>(
    ({ blocks, shift: oldShift }, curr) => {
      const fixedBlockPosition = curr.displayOptions.positionIndex - 1;
      const isTargetPositionTaken = Boolean(blocks[fixedBlockPosition + oldShift]);
      const shift = isTargetPositionTaken ? oldShift + 1 : oldShift;

      if (isTargetPositionTaken) {
        console.error(new Error(`Blocks have duplicate elements with positionIndex: ${curr.displayOptions.positionIndex}`));
      }
      blocks[fixedBlockPosition + shift] = curr;

      return { blocks, shift };
    },
    { shift: 0, blocks: [] }
  );

  return result.blocks;
}

function fillGapsInSparseBlocksAndCompact<T extends BlockBase>(sparseBlocks: T[], fillerBlocks: T[]) {
  const sparseBlocksCopy = [...sparseBlocks];

  let fillerBlockIdx = 0;
  for (let i = 0; i < sparseBlocksCopy.length; i += 1) {
    if (sparseBlocksCopy[i]) {
      continue;
    }
    sparseBlocksCopy[i] = fillerBlocks[fillerBlockIdx];
    fillerBlockIdx += 1;
  }

  return [...sparseBlocksCopy.filter(b => Boolean(b)), ...fillerBlocks.slice(fillerBlockIdx)];
}
