import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import merge from 'lodash/merge';

import { initSearchDates } from '@helpers/date.helper';
import { QueryParamsService } from '@services/query-params.service';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { InformationStore } from '@services/store/information.store';
import { getDestinationId } from '@helpers/search.helper';
import { convertPageTypeToDestinationType } from '@helpers/converter.helper';
import { defaultSearch } from '@constants/default-search-params';
import { GlobalSearchService } from '@services/tours-search/global-search.service';

import { SearchParams } from '@interfaces/modules/tours/search';

@UntilDestroy()
@Component({
  selector: 'app-search-result-expired-modal',
  templateUrl: './search-result-expired-modal.component.html',
  styleUrls: ['../modal-common-style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultExpiredModalComponent {
  @Input() searchParams!: SearchParams;

  constructor(
    private modal: NgbActiveModal,
    private globalSearch: GlobalSearchService,
    private queryParams: QueryParamsService,
    private localStorage: LocalStorageService,
    private informationStore: InformationStore
  ) { }

  startSearching(): void {
    this.globalSearch
      .navigateToSearchPage(this.getFreshSearch())
      .pipe(untilDestroyed(this))
      .subscribe(() => this.modal.close());
  }

  close() {
    const search = this.getFreshSearch();
    this.localStorage.setSearchParams(search);
    this.queryParams.navigateWithNewParams({ search });
    this.modal.close();
  }

  private getFreshSearch(): SearchParams {
    const search = this.buildActualSearchParams(this.searchParams);

    return {
      ...search,
      dates: initSearchDates()
    };
  }

  private buildActualSearchParams(search: SearchParams): SearchParams {
    const { destination, ...searchWithoutDestination } = search;
    const pageInfo = this.informationStore.state.pageInfo;
    const newDestination = pageInfo
      ? { id: getDestinationId(pageInfo), type: convertPageTypeToDestinationType(pageInfo.type) }
      : destination;

    return merge({ ...defaultSearch(), destination: newDestination }, searchWithoutDestination);
  }
}
