<div class="container">
  <div class="tiles-grid" *ngIf="blocks">
    <ng-container *ngFor="let block of blocks">
      <ng-container [ngSwitch]="block.type">
        <div *ngIf="block.type"
             class="tiles-grid__item"
             [class.tiles-grid__item_hot-tours]="block.type === MainPageBlockType.HotTours"
             [class.tiles-grid__item_x2]="block.displayOptions.size === BlockSize.X2">
          <app-hot-tours-card *ngSwitchCase="MainPageBlockType.HotTours"></app-hot-tours-card>
          <app-html-block *ngSwitchCase="MainPageBlockType.Html" [block]="block"></app-html-block>
          <app-subscription
            class="tiles-grid__subscription-block"
            *ngSwitchCase="MainPageBlockType.MailSubscription">
          </app-subscription>
          <app-offices-card *ngSwitchCase="MainPageBlockType.Offices"></app-offices-card>
          <app-vlasnyi-rakhunok-block *ngSwitchCase="MainPageBlockType.VlasnyiRakhunok"></app-vlasnyi-rakhunok-block>
          <ng-container *ngSwitchCase="MainPageBlockType.Tour">
            <ng-container [ngSwitch]="block.displayOptions.size">
              <app-offer-tile-small *ngSwitchCase="BlockSize.X1" [block]="block"></app-offer-tile-small>
              <app-offer-tile *ngSwitchCase="BlockSize.X2" [block]="block"></app-offer-tile>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

