import { ToursSearch } from 'package-types';

import { TableFiller } from '@modules/tours/table-toolkit/classes/table-filler';

import { RoomFoodTable } from '../../interfaces/room-food-table';

export class RoomFoodTableFiller extends TableFiller<ToursSearch.Offer, ToursSearch.Food, string> {
  constructor(table: RoomFoodTable) {
    super(table);
  }

  protected colHeaderValue(item: ToursSearch.Offer): ToursSearch.Food {
    return item.food;
  }

  protected rowHeaderValue(item: ToursSearch.Offer): string {
    return item.room;
  }
}
