import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@modules/tours/shared/shared.module';
import { ArticleComponent } from './components/article/article.component';
import { LocationInfoPageComponent } from './location-info-page.component';
import { FromImageIdToLinksPipe } from './pipes/from-image-id-to-links.pipe';

@NgModule({
  declarations: [
    LocationInfoPageComponent,
    ArticleComponent,
    FromImageIdToLinksPipe
  ],
  imports: [
    CommonModule,
    LeafletModule,
    SharedModule,
    LazyLoadImageModule,
    TranslateModule
  ]
})
export class LocationInfoPageModule { }
