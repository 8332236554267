<nav class="nav-wrapper" [@openClose]="isOpen ? 'open' : 'closed'">
  <ul class="nav">
    <li *ngFor="let item of items"
        class="nav__link"
        [class.nav__link_active]="item.name === activeItem">
      <ng-container *ngIf="(item.link | typeof:'string'); then internalLink else externalLink"></ng-container>
      <ng-template #internalLink>
        <a class="link"
           [class.link_active]="item.name === activeItem"
           [routerLink]="$any(item.link) | currentLangLink">
          {{ item.translatePath | translate }}
        </a>
      </ng-template>
      <ng-template #externalLink>
        <a class="link" [href]="$any(item.link) | fieldTranslation">
          {{ item.translatePath | translate }}
        </a>
      </ng-template>
    </li>
  </ul>
  <div class="nav__bottom">
    <a [href]="'tel:' + phoneNumber" class="nav__phone phone phone_device-size_sm">
      {{ phoneNumber }}
    </a>
    <app-lang-selector></app-lang-selector>
  </div>
</nav>
