import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { RawSiteHotTour } from '@interfaces/services/api/tours-catalog.endpoint';

@Component({
  selector: 'app-hot-tours-list',
  templateUrl: './hot-tours-list.component.html',
  styleUrls: ['./hot-tours-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotToursListComponent {
  @Input() tours: RawSiteHotTour[] | null = null;
}
