import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ToursSearch } from 'package-types';

@Component({
  selector: 'app-icons-set',
  templateUrl: './icons-set.component.html',
  styleUrls: ['./icons-set.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconsSetComponent {
  @Input() stars: ToursSearch.Stars | null = null;
  @Input() transport: ToursSearch.Transport | null = null;
  @Input() food: ToursSearch.Food | null = null;
  @Input() accommodation: ToursSearch.Accommodation | null = null;
  @Input() adultsCount = 0;
  @Input() childrenCount = 0;
}
