import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { ToursCatalog } from 'package-types';

import { DynamicPage } from '@classes/decorators/dynamic-page.decorator';
import { SearchPageComponent } from '../dynamic-page-container/components/search-page/search-page.component';
import { SearchResultStore } from '../dynamic-page-container/services/store/search-result.store';
import { ErrorModalService } from '@modules/tours/core/services/error-modal.service';
import { ToursSearchService } from '@services/tours-search/tours-search.service';
import { InformationStore } from '@services/store/information.store';

@DynamicPage({
  pageName: ToursCatalog.PageName.HotTours,
  disableH1SmallUpdates: true
})
@Component({
  selector: 'app-hot-tours-page',
  templateUrl: './hot-tours-page.component.html',
  providers: [SearchResultStore, ToursSearchService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotToursPageComponent extends SearchPageComponent implements OnInit {
  resortPageInfo$ = this.information.resortPageInfo$;

  constructor(
    private readonly information: InformationStore,
    searchResult: SearchResultStore,
    errorModal: ErrorModalService
  ) {
    super(errorModal, searchResult);
  }

  ngOnInit() {
    super.ngOnInit();
    this.searchResult.init();
  }
}
