import { Observable, of, OperatorFunction, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { ActionState } from '@interfaces/action-state';

export type UpdateStatusHandler = (status: ActionState) => void;

export type RequestFactory<T> = () => Observable<T>;

export function replaceError<T>(msg: string, value: T): OperatorFunction<any, T> {
  return source$ => source$.pipe(
    catchError((err) => {
      console.error(msg, err);

      return of(value);
    })
  );
}

/**
 * asyncChange operator helps to pick a value after the microtask queue has stabilized.
 * It is used to prevent flickering if the component doesn't need to rerender it's parts after a change detection cycle.
 */
export function asyncChange<T>(): OperatorFunction<T, T> {
  return source$ => source$.pipe(
    debounceTime(0),
    distinctUntilChanged()
  );
}

export function wrapInMicrotask<T>(): OperatorFunction<T, T> {
  return source$ => source$.pipe(
    switchMap(val => Promise.resolve(val))
  );
}

export function followRequestStatus<T>(getRequest: RequestFactory<T>, statusHandler: UpdateStatusHandler) {
  statusHandler({ status: 'inProgress' });

  return getRequest().pipe(
    tap(() => statusHandler({ status: 'success' })),
    catchError((err) => {
      statusHandler({ status: 'error', error: err });

      return throwError(err);
    })
  );
}
