<ng-container *ngIf="selectedOptions$ | async as selected">
  <app-display-option-group
    *ngIf="currencySwitch$ | async as currencySwitch"
    [options]="currencySwitch"
    labelWidth="5em"
    [selection]="selected.currency"
    (selectionChange)="setOptions({ currency: $event })">
    <ng-template appOptionLabel let-option>{{ option.value | uppercase }}</ng-template>
  </app-display-option-group>
  <app-display-option-group
    *ngIf="pricePerNightsSwitch$ | async as pricePerNightsSwitch"
    [options]="pricePerNightsSwitch"
    labelWidth="9.786em"
    [selection]="selected.pricePerNights"
    (selectionChange)="setOptions({ pricePerNights: $event })">
    <ng-template appOptionLabel let-option>{{ option.translation | translate }}</ng-template>
  </app-display-option-group>
  <app-display-option-group
    *ngIf="pricePerTouristsSwitch$ | async as pricePerTouristsSwitch"
    [options]="pricePerTouristsSwitch"
    labelWidth="10.571em"
    [selection]="selected.pricePerTourists"
    (selectionChange)="setOptions({ pricePerTourists: $event })">
    <ng-template appOptionLabel let-option>{{ option.translation | translate }}</ng-template>
  </app-display-option-group>
</ng-container>
