import { Table } from '../classes/table';

export function fillEmptyCellsByUnfilteredOffers(unfiltered: Table<any, any, any>, filtered: Table<any, any, any>): Table<any, any, any> {
  for (let r = 0; r < filtered.height; r += 1) {
    for (let c = 0; c < filtered.width; c += 1) {
      const filteredCell = filtered.body.getCell(c, r);
      const unfilteredCell = unfiltered.body.getCell(c, r);

      if (filteredCell && !filteredCell?.data) {
        filteredCell.data = unfilteredCell?.data;
      }
    }
  }

  return filtered;
}

export function isValidTable(t: Table<any, any, any>): boolean {
  return Boolean(t && t.body && t.height && t.width);
}
