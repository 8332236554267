import { Pipe, PipeTransform } from '@angular/core';

import { Images } from 'package-types';

@Pipe({
  name: 'fromImageIdToLinks'
})
export class FromImageIdToLinksPipe implements PipeTransform {
  transform(imageId: string, images: Images.ImageResizesLinks): Images.ResizesLinks | undefined {
    return images[imageId];
  }
}
