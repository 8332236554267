import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-collapsable-panel',
  templateUrl: './collapsable-panel.component.html',
  styleUrls: ['./collapsable-panel.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '*'
      })),
      state('closed', style({
        height: '0'
      })),
      transition('open => closed', [
        animate('0.2s')
      ]),
      transition('closed => open', [
        animate('0.2s')
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapsablePanelComponent {
  @Input() isOpen: boolean = false;

  @HostBinding('@openClose')
  get openClose() {
    return this.isOpen ? 'open' : 'closed';
  }
}
