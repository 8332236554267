<div class="offices-card">
  <div class="offices-card__header">
    <span class="offices-card__title">{{ 'OfficesCardComponent.WhereCanIBuy' | translate }}</span>
    <div class="offices-card__selector" *ngIf="cities$ | async as cities">
      <div ngbDropdown>
        <button type="button" class="btn btn-outline-primary btn-sm" ngbDropdownToggle *ngIf="selectedCity">
          {{ selectedCity.name }}
        </button>
        <div ngbDropdownMenu>
          <button
            type="button"
            ngbDropdownItem
            *ngFor="let city of cities"
            (click)="selectedCity = city">
            {{ city.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="selectedCity?.offices?.length as officesAmount">
    <div class="offices-card__body" *ngIf="selectedCity?.offices as offices">
      <owl-carousel-o *appRenderOnlyInBrowser [options]="getOptions()">
        <ng-container *ngFor="let office of offices">
          <ng-template carouselSlide>
            <app-office-info [office]="office"></app-office-info>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
    <div *ngIf="officesAmount > 1" class="offices-card__offices-link">
      <a
        [routerLink]="'/offices' | currentLangLink"
        class="btn btn-outline-dark btn-block">
        {{ 'OfficesCardComponent.MoreOffices' | translate:{ offices: officesAmount - 1 } }}
      </a>
    </div>
  </ng-container>
</div>
