import { Table } from './table';
import { TableHeader } from './table-header';
import { TableBody } from './table-body';
import { TableCell } from './table-cell';
import { HeaderFactory } from './header-factory';

export class EmptyTableBuilder<T, C, R> {
  constructor(
    private rowLabels: R[],
    private colLabels: C[],
    private factory: HeaderFactory<C, R> = new HeaderFactory<C, R>()
  ) { }

  build(): Table<T, C, R> {
    const colHeader: TableHeader<C> = this.factory.getColHeader(this.colLabels);
    const rowHeader: TableHeader<R> = this.factory.getRowHeader(this.rowLabels);
    const body: TableBody<T> = this.createEmptyBody(this.rowLabels.length, this.colLabels.length);

    return new Table<T, C, R>(body, colHeader, rowHeader);
  }

  private createEmptyBody(x: number, y: number): TableBody<T> {
    return new TableBody(Array(x)
      .fill(null)
      .map((_, index) => this.createEmptyRow(y, index)));
  }

  private createEmptyRow(border: number, rowIdx: number): TableCell<T>[] {
    return Array(border)
      .fill(null)
      .map((_, index) => this.createEmptyCell(index, rowIdx));
  }

  private createEmptyCell(x: number, y: number): TableCell<T> {
    return new TableCell<T>(null, { x, y });
  }
}
