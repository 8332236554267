import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { valueAccessor } from '@helpers/angular.helper';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [valueAccessor(PhoneInputComponent)]
})
export class PhoneInputComponent implements ControlValueAccessor {
  @Input() inputId: string = '';
  @Input() inputClass?: string;

  private phoneValue: string | null = null;
  private phonePrefix = '38';
  private onChange = (_: string | null) => { };

  onTouched = () => { };

  set phone(val: string | null) {
    this.phoneValue  = val;
    this.onChange(val ? `${this.phonePrefix}${val}` : null);
  }

  get phone(): string | null {
    return this.phoneValue;
  }

  registerOnChange(fn: (val: string | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(val: string | null): void {
    if (typeof val === 'string') {
      this.phoneValue = this.sanitizePhonePrefix(val);
    } else {
      this.phoneValue = val;
    }
  }

  private sanitizePhonePrefix(val: string): string {
    if (val.startsWith(this.phonePrefix)) {
      return this.cutPrefix(val, this.phonePrefix);
    }
    if (val.startsWith(`+${this.phonePrefix}`)) {
      return this.cutPrefix(val, `+${this.phonePrefix}`);
    }

    return val;
  }

  private cutPrefix(phone: string, prefix: string) {
    return phone.substring(prefix.length);
  }
}
