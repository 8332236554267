import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'serviceTranslateMapper'
})
export class ServiceTranslateMapperPipe implements PipeTransform {
  private readonly translatePath = 'Services';

  transform(code: string): string {
    return `${this.translatePath}.${code}`;
  }
}
