import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { RawSeoLinkingLink } from '@interfaces/services/api/tours-catalog.endpoint';

@Component({
  selector: 'app-seo-linking-section',
  templateUrl: './seo-linking-section.component.html',
  styleUrls: ['./seo-linking-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeoLinkingSectionComponent {
  @Input() title = '';
  @Input() list: RawSeoLinkingLink[] = [];
  @Input() showPrice = true;
}
