<div class="subscription">
  <div class="subscription__icon"></div>
  <div class="subscription__head">
    <span class="subscription__title subscription__title_main">
      {{ 'Subscription.MainText' | translate }}
    </span>
    <span class="subscription__title subscription__title_minor">
      {{ 'Subscription.MinorText' | translate }}
    </span>
  </div>
  <div class="subscription__form">
    <input class="subscription__input"
           type="text"
           [placeholder]="'Subscription.NamePlaceholder' | translate">
    <input class="subscription__input subscription__input_email"
           type="text"
           [placeholder]="'Subscription.EmailPlaceholder' | translate">
    <button class="btn btn-accent subscription__send-btn"></button>
  </div>
</div>
