import { addDays, differenceInDays, isAfter, isWithinInterval, max, min } from 'date-fns';

import { RangeImpl } from './range-impl';

export class DateRange extends RangeImpl<Date> {
  static fromArray(range: Date[]): DateRange {
    const sorted: Date[] = [...range].sort((p: Date, n: Date) => Number(isAfter(p, n)));

    return new DateRange(sorted[0], sorted[sorted.length - 1]);
  }

  static fromStart(from: Date, length: number): DateRange {
    return new DateRange(from, addDays(from, length - 1));
  }

  isInside(date: Date): boolean {
    return isWithinInterval(date, { start: this.from, end: this.to });
  }

  eachValue(): Date[] {
    return this.eachDayOfInterval();
  }

  merge(range: DateRange): DateRange {
    const union: Date[] = [...range.toArray(), ...this.toArray()];

    return new DateRange(min(union), max(union));
  }

  private eachDayOfInterval(): Date[] {
    const result: Date[] = [];
    for (let i: number = 0; i <= differenceInDays(this.to, this.from); i += 1) {
      result.push(addDays(this.from, i));
    }

    return result;
  }
}
