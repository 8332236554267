<div class="filters-bar container">
  <app-card class="filters-bar__menu">
    <div class="filters-bar__head">
      <img src="/assets/icons/filter.svg" alt="Filter icon">
      <h4>{{ 'Common.Filter' | translate }}</h4>
    </div>
    <button class="btn btn-primary filters-bar__btn-close" (click)="cleanFilter()">
      <fa-icon [icon]="['fas', 'times']"></fa-icon>
    </button>
  </app-card>
  <div class="filters-bar__items row">
    <div class="col-12 col-sm-4 col-md-3 col-xl-12">
      <app-card class="filter">
        <span class="filter__title">{{ 'Common.TourOperators' | translate }}</span>
        <app-checkbox-filter class="filter__body"
                             [params]="$any((availableValues$ | async)?.operatorIds)"
                             [selectedValues]="$any((selectedValues$ | async)?.operatorIds)"
                             (availableValues)="filter({ operatorIds: $event })">
          <ng-template appCheckboxLabelTemplate let-label="label">
            {{ (label | tourOperator | async)?.name }}
          </ng-template>
        </app-checkbox-filter>
      </app-card>
    </div>
    <div class="col-12 col-sm-4 col-md-3 col-xl-12">
      <app-card class="filter">
        <span class="filter__title">{{ 'Common.Rooms' | translate }}</span>
        <app-checkbox-filter class="filter__body"
                             [params]="$any((availableValues$ | async)?.rooms)"
                             [selectedValues]="$any((selectedValues$ | async)?.rooms)"
                             (availableValues)="filter({ rooms: $event })">
        </app-checkbox-filter>
      </app-card>
    </div>
    <div class="col-12 col-sm-4 col-md-3 col-xl-12">
      <app-card class="filter">
        <span class="filter__title">{{ 'Common.Airlines' | translate }}</span>
        <app-checkbox-filter class="filter__body"
                             [params]="$any((availableValues$ | async)?.airlines)"
                             [selectedValues]="$any((selectedValues$ | async)?.airlines)"
                             (availableValues)="filter({ airlines: $event })">
        </app-checkbox-filter>
      </app-card>
    </div>
    <div class="col-12 col-sm-4 col-md-3 col-xl-12">
      <app-card class="filter">
        <span class="filter__title">{{ 'Common.DepartureTime' | translate }}</span>
        <app-checkbox-filter class="filter__body"
                             [params]="$any((availableValues$ | async)?.flightFromTimes)"
                             [selectedValues]="$any((selectedValues$ | async)?.flightFromTimes)"
                             (availableValues)="filter({ flightFromTimes: $event })">
        </app-checkbox-filter>
      </app-card>
    </div>
  </div>
</div>
