import { AbstractControl, ControlValueAccessor, FormGroup, ValidationErrors, Validator } from '@angular/forms';
import { HostListener, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

export abstract class NestedFormComponent implements ControlValueAccessor, Validator, OnDestroy {
  abstract formGroup: FormGroup;
  protected invalidMessage = 'invalidNestedForm';

  private subscription?: Subscription;

  onTouched = () => { };

  @HostListener('click')
  onHostClick() {
    this.onTouched();
  }

  registerOnChange(fn: (val: any) => { }): void {
    fn(this.formGroup.value);
    this.subscription = this.formGroup.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => { }): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    obj && this.formGroup.setValue(obj, { emitEvent: false });
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.pristine) {
      this.formGroup.markAsPristine();
    }
    if (control.untouched) {
      this.formGroup.markAsUntouched();
    }

    return this.formGroup.valid ? null : { [this.invalidMessage]: true };
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscription = undefined;
  }
}
