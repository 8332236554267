import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { HotToursCardComponent } from './hot-tours-card/hot-tours-card.component';
import { HotToursListComponent } from './hot-tours-list/hot-tours-list.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [HotToursCardComponent, HotToursListComponent],
  imports: [CommonModule, SharedModule, TranslateModule, RouterModule],
  exports: [HotToursCardComponent]
})
export class HotToursCardModule { }
