import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-load-more-button',
  templateUrl: './load-more-button.component.html',
  styleUrls: ['./load-more-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadMoreButtonComponent {
  @Input() isLoading = false;
  @Input() loadingMsg = '';
  @Input() loadMoreMsg = '';
}
