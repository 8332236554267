import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { MimeType } from 'package-types';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageUploaderComponent {
  @ViewChild('file', { static: true }) fileElemRef!: ElementRef<HTMLInputElement>;

  @Input() contentTypes: MimeType[] = [];

  @Output() files = new EventEmitter<File[]>();

  select() {
    this.fileElemRef.nativeElement.click();
  }

  fileSelected(): void {
    const fileList = this.fileElemRef.nativeElement.files ?? [];
    if (!fileList.length) {
      return;
    }

    const files = Array.from(fileList);
    this.files.emit(files);
    this.fileElemRef.nativeElement.value = '';
  }
}
