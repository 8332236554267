import { ComponentFactoryResolver, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Directive({
  selector: '[appSpinnerLoader]'
})
export class SpinnerLoaderDirective {
  constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly resolver: ComponentFactoryResolver
  ) { }

  @Input() set appSpinnerLoader(val: boolean) {
    this.viewContainer.clear();
    if (val) {
      this.createSpinnerComponent();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  private createSpinnerComponent() {
    const factory = this.resolver.resolveComponentFactory(FaIconComponent);
    const { instance } = this.viewContainer.createComponent(factory);
    instance.icon = ['fas', 'spinner'];
    instance.size = '1x';
    instance.spin = true;
    instance.render();
  }
}
