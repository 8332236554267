import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

import { InformationStore } from '@services/store/information.store';
import { DynamicPageContainerComponent } from '../dynamic-page-container.component';

@Injectable({
  providedIn: 'root'
})
export class DynamicPageDeallocationGuard implements CanDeactivate<DynamicPageContainerComponent> {
  constructor(private readonly informationStore: InformationStore) { }

  canDeactivate(): boolean {
    this.informationStore.dropPageInfo();

    return true;
  }
}
