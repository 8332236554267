import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ToursSearch } from 'package-types';

import { TableDrivenFilterStore } from '../../services/store/table-driven-filter.store';
import { SelectionStore } from '../../../hotel-availability/services/store/selection.store';
import { CellBase } from '../../classes/cell-base';
import { filterOfferByFoodRoom } from '../../helpers/night-date.helper';
import { filterOfferByNightDate } from '../../helpers/room-food-helper';
import { HotelSearchService } from '../../../hotel-availability/services/hotel-search.service';

import { CellLayout } from '../../interfaces/cell-layout.enum';

@Component({
  selector: 'app-room-food-cell',
  templateUrl: './room-food-cell.component.html',
  styles: [':host { width: 100%; height: 100%; }'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoomFoodCellComponent extends CellBase {
  CellLayout = CellLayout;

  constructor(
    protected selectionStore: SelectionStore,
    protected search: HotelSearchService,
    private filterStore: TableDrivenFilterStore
  ) {
    super(selectionStore, search);
  }

  isSelectedCell(): boolean {
    return filterOfferByFoodRoom(this.offer, this.filterStore.state);
  }

  protected getLayout(offer: ToursSearch.Offer | null): CellLayout {
    if (this.isUnfiltered()) {
      return CellLayout.AdditionalInfo;
    }
    if (!this.offer) return CellLayout.EmptyOffer;

    return CellLayout.None;
  }

  isUnfiltered(): boolean {
    return !!this.offer && !filterOfferByNightDate(this.offer, this.filterStore.state);
  }
}
