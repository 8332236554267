import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CellColor } from '../../interfaces/cell-color';

@Component({
  selector: 'app-cell-skeleton',
  templateUrl: './cell-skeleton.component.html',
  styleUrls: ['./cell-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CellSkeletonComponent {
  @Input() type?: CellColor | null;
  @Input() mainInfo?: string;

  CellColor = CellColor;
}
