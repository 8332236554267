<div class="app__content container-fluid">
  <app-tour-offers-content class="row"></app-tour-offers-content>
  <div class="row">
    <app-offer-me-tour-card class="col-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6"></app-offer-me-tour-card>
  </div>
</div>
<ng-template appHeaderSubtitle>
  <app-subtitle-link
    *ngIf="resortPageInfo$ | async as info"
    [names]="info.resort.countryNames"
    [url]="info.resort.countryUrl">
  </app-subtitle-link>
</ng-template>
