<app-card
  class="flight"
  *ngFor="let flight of flights"
  [class.flight_selected]="flight === selectedFlight"
  (click)="selectedFlightChange.emit(flight)">
  <div class="flight__header">
    <div class="flight__code">{{ flight.code }}</div>
    <div class="flight__company">{{ flight.line }}</div>
  </div>
  <div class="flight__way way">
    <div class="way__from">
      <div class="way__airport">{{ flight.airport.from }}</div>
      <div class="way__date">{{ flight.timeRange.from | flightDate }}</div>
    </div>
    <div class="way__to">
      <div class="way__airport">{{ flight.airport.to }}</div>
      <div class="way__date">{{ flight.timeRange.to | flightDate }}</div>
    </div>
  </div>
  <img class="flight__image" src="/assets/icons/flight.svg" alt="Flight icon">
  <div class="flight__duration" *ngIf="flight.timeRange as range">
    <ng-container *ngIf="range.from && range.to">
      {{ range.from | flightDuration: range.to }}
    </ng-container>
  </div>
</app-card>
