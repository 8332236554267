import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export class Translatable implements OnDestroy {
  private onLangChange?: Subscription;

  constructor(protected translate: TranslateService, protected cd: ChangeDetectorRef) { }

  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
    this.onLangChange = undefined;
  }

  protected ensureSubscriptionOnLangChange(): void {
    if (!this.onLangChange) {
      this.onLangChange = this.translate.onLangChange.subscribe(() => this.cd.markForCheck());
    }
  }
}
