import { Store } from 'rxjs-observable-store';
import { Injectable } from '@angular/core';

import { ErrorModalState } from './error-modal.state';
import { errorMessagesDictionary } from '@constants/translation-dictionaries';
import { ErrorMessage } from '@constants/error-message.enum';

@Injectable({
  providedIn: 'root'
})
export class ErrorModalStore extends Store<ErrorModalState> {
  constructor() {
    super(new ErrorModalState());
  }

  setMessageTranslationOrUseDefault(...messages: ErrorMessage[]): void {
    const messageTranslation = messages
      .map(message => errorMessagesDictionary[message])
      .filter(message => message != null);
    this.setState({ ...this.state, messageTranslation });
  }
}
