<div class="input">
  <label class="input__label">{{ label }}</label>
  <div class="input__group group" [ngClass]="classList">
    <div class="group__field">
      <fa-icon *ngIf="iconName" class="group__icon" [icon]="['fas', iconName]"></fa-icon>
      <div class="group__content" [style.width]="iconName ? 'calc(90% + 10px)' : '100%'" [style.margin-left.px]="iconName ? -10 : null">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
