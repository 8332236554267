import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import { Params } from '@angular/router';
import { Decimal } from 'decimal.js';

import { ToursCatalog, ToursSearch } from 'package-types';
import { cleanDeep } from 'package-utils';

import { parseDate } from './date.helper';
import { parseNormalizedParams } from './url-params.helper';

import { Destination, SearchParams } from '@interfaces/modules/tours/search';
import { RawSuggestion } from '@interfaces/services/api/tours-search.endpoint';
import { ParsedParams } from '@interfaces/parsed-params';

const dateJsonFormatPattern: RegExp = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/;

export function convertDatesInParsedJson(input: unknown): Date | undefined {
  if (Array.isArray(input)) {
    input.forEach((val, idx) => input[idx] = convertDatesInParsedJson(val) ?? val);
  }

  if (isObject(input)) {
    Object.entries(input).forEach(([key, val]) => {
      (input as any)[key] = convertDatesInParsedJson(val) ?? val;
    });
  }

  if (typeof input === 'string' && dateJsonFormatPattern.test(input)) {
    return new Date(input);
  }

  return undefined;
}

export function convertSearchParamsToSearchQuery(search: SearchParams): ToursSearch.Query {
  const dates = {
    checkIn: search.dates?.checkIn && parseDate(search.dates.checkIn),
    checkOut: search.dates?.checkOut && parseDate(search.dates.checkOut)
  };
  const rating = {
    min: search.ratingMin,
    max: search.ratingMax
  };
  const tourists = {
    adults: search.tourists?.adults,
    children: search.tourists?.children ?? []
  };

  return <ToursSearch.Query> cleanDeep(
    {
      dates,
      rating,
      tourists,
      departureCityId: search.departureCity,
      destinationId: search.destination?.id,
      destinationType: search.destination?.type,
      duration: search.duration,
      foods: search.foods,
      hotels: search.hotels,
      resorts: search.resorts,
      services: search.services,
      stars: search.stars,
      transports: search.transports,
      price: search.price
    },
    { emptyArrays: false }
  );
}

export function convertQueryParams(params: Params): ParsedParams {
  const result: ParsedParams = { };

  if (params.searchId) {
    result.searchId = params.searchId;
  }

  if (params.offerId) {
    result.offerId = params.offerId;
  }

  if (!isEmpty(params)) {
    const parsed = parseNormalizedParams(params);
    if (!isEmpty(parsed)) {
      result.search = parsed;
    }
  }

  return result;
}

export function convertRawSuggestion({ destination, country, priceUAH, weight }: RawSuggestion): Destination {
  return {
    ...destination,
    weight,
    countryId: country?.id,
    countryName: country?.name,
    price: priceUAH ? parseFloat(priceUAH) : undefined,
  };
}

export function convertPageTypeToDestinationType(pageType: ToursCatalog.PageType) {
  switch (pageType) {
    case ToursCatalog.PageType.Country:
      return ToursSearch.DestinationType.Country;
    case ToursCatalog.PageType.Resort:
      return ToursSearch.DestinationType.Resort;
    case ToursCatalog.PageType.Hotel:
      return ToursSearch.DestinationType.Hotel;
    default:
      throw new Error(`PageType '${pageType}' cannot be converted to DestinationType`);
  }
}

export function convertPricesInHotelProposals(hotels: ToursSearch.HotelProposal[]): void {
  for (const hotel of hotels) {
    for (const offer of hotel.offers) {
      if (offer.price) {
        offer.price = new Decimal(offer.price);
      }
      if (offer.priceUAH) {
        offer.priceUAH = new Decimal(offer.priceUAH);
      }
    }
  }
}
