import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { SeoLinkingStore } from '../../services/seo-linking.store';
import { SeoLinkingState } from '../../services/seo-linking.state';

import { RawSeoLinkingLink } from '@interfaces/services/api/tours-catalog.endpoint';

@Component({
  selector: 'app-seo-linking',
  templateUrl: './seo-linking.component.html',
  styleUrls: ['./seo-linking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SeoLinkingStore]
})
export class SeoLinkingComponent implements OnInit {
  @Input() display = {
    popular: true,
    resorts: true,
    hotels: true
  };

  popularCountries$: Observable<RawSeoLinkingLink[]> = this.seoLinking.onChanges('popularCountries');
  resorts$: Observable<RawSeoLinkingLink[]> = this.seoLinking.onChanges('resorts');
  hotels$: Observable<RawSeoLinkingLink[]> = this.seoLinking.onChanges('hotels');
  titles$: Observable<SeoLinkingState['titles']> = this.seoLinking.onChanges('titles');

  constructor(private readonly seoLinking: SeoLinkingStore) { }

  ngOnInit(): void {
    this.seoLinking.init(this.display);
  }
}
