import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-seo-text',
  templateUrl: './seo-text.component.html',
  styleUrls: ['./seo-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeoTextComponent {
  @Input() seoText: string = '';
}
