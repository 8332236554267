import { Injectable } from '@angular/core';
import { Store } from 'rxjs-observable-store';

import { Currency } from 'package-types';

import { PriceDisplayOptionsState } from './price-display-options.state';

import { DisplaySwitch, PriceDisplayOptions } from '../../interfaces/offer-management';

@Injectable()
export class PriceDisplayOptionsStore extends Store<PriceDisplayOptionsState> {
  constructor() {
    super(new PriceDisplayOptionsState());
  }

  update(opts: Partial<PriceDisplayOptions>): void {
    this.patchState({ ...this.state.values, ...opts }, 'values');
  }

  setCurrencySwitch(currency: DisplaySwitch<Currency | string>[]): void {
    if (currency.length === 0) {
      return;
    }
    this.patchState(currency, 'switches', 'currency');

    const isSwitchIntersectsWithSelectedValue = currency.some(c => c.value === this.state.values.currency);
    if (!isSwitchIntersectsWithSelectedValue) {
      this.patchState(currency[0].value, 'values', 'currency');
    }
  }
}
