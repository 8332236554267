<div *ngIf="relevanceLoading$ | async" class="d-flex flex-column justify-content-center align-items-center">
  <app-bus-preloader></app-bus-preloader>
  <span class="h3">{{ 'HotelOverviewPage.OfferRelevance.RelevanceChecking' | translate }}</span>
</div>
<ng-container *ngIf="relevance$ | async as relevance">
  <ng-container [ngSwitch]="relevance.result?.status">
    <ng-container *ngSwitchCase="RelevanceStatus.NewPrice">
      <ng-container *ngTemplateOutlet="offerChanged; context: { $implicit: relevance.result?.actualOffer }">
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="RelevanceStatus.UpdatedPrice">
      <ng-container *ngTemplateOutlet="offerChanged; context: { $implicit: relevance.result?.actualOffer }">
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="RelevanceStatus.PriceNotFound">
      <div class="h2 text-info">
        <fa-icon [icon]="['far', 'frown']"></fa-icon>
        {{ 'HotelOverviewPage.OfferRelevance.OfferNotFound' | translate }}
      </div>
      <button type="button" class="btn btn-warning" (click)="cancelOrder()">
        {{ 'HotelOverviewPage.OfferRelevance.ChooseAnotherTour' | translate }}
      </button>
    </ng-container>
    <div *ngSwitchDefault class="h2 text-success">
      <fa-icon [icon]="['fas', 'check']"></fa-icon>
      {{ 'HotelOverviewPage.OfferRelevance.PriceIsRelevant' | translate }}
    </div>
  </ng-container>
</ng-container>
<ng-template #offerChanged let-actualOffer>
  <div class="h2 text-warning">
    <fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>
    {{ 'HotelOverviewPage.OfferRelevance.PriceChanged' | translate }}
  </div>
  <span
    [innerHtml]="'HotelOverviewPage.OfferRelevance.PriceChangedMessage' | translate:{
      oldPrice: '<b>' + offer.priceUAH + '</b>',
      newPrice: '<b>' + actualOffer.priceUAH + '</b>'
    }">
  </span>
  <div class="d-flex justify-content-between align-items-center mt-4">
    <button type="button" class="btn btn-warning" (click)="cancelOrder()">
      {{ 'HotelOverviewPage.OfferRelevance.ChooseAnotherTour' | translate }}
    </button>
    <button type="button" class="btn btn-primary ml-4" (click)="applyNewOffer(actualOffer)">
      {{ 'HotelOverviewPage.OfferRelevance.ApplyNewOffer' | translate }}
    </button>
  </div>
</ng-template>
