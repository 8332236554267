import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';

import { TourOrders } from 'package-types';

import { TourOrderCreationService } from '../../services/tour-order-creation.service';

import { ContactInfo } from '@interfaces/modules/tours/shared/contact-form';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactFormComponent implements OnInit {
  isOfficeSelectOpen = false;

  readonly configResult$ = this.tourOrderCreation.configResult$.pipe(map(r => r.result ?? { allowOnlinePayment: false }));

  readonly PaymentType = TourOrders.PaymentType;
  readonly contactForm = this.fb.group(
    {
      name: [null, Validators.required],
      phone: [null, Validators.compose([Validators.required, Validators.pattern(/380\d{9}/)])],
      email: [null, Validators.compose([Validators.required, Validators.email])],
      comment: [null],
      office: [null]
    },
    { updateOn: 'blur' }
  );

  @Input() isLoading: boolean | null = false;

  @Output() readonly submitContact = new EventEmitter<ContactInfo>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly tourOrderCreation: TourOrderCreationService,
  ) { }

  ngOnInit(): void {
    this.tourOrderCreation.loadConfig();
  }

  toggleOfficeSelect(): void {
    this.isOfficeSelectOpen = !this.isOfficeSelectOpen;
  }

  contactFormSubmit(payment: TourOrders.PaymentType) {
    if (this.contactForm.valid && !this.isLoading) {
      this.submitContact.emit({ payment, ...this.contactForm.value });
    }
  }
}
