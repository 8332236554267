import { Pipe, PipeTransform } from '@angular/core';

type Type = 'string' | 'object' | 'number' | 'boolean' | 'symbol' | 'undefined' | 'function';

@Pipe({
  name: 'typeof'
})
export class TypeofPipe implements PipeTransform {
  transform(value: unknown, type: Type): boolean {
    return typeof value === type;
  }
}
