<div class="row">
  <div class="col-12 title">
    <span>{{ 'FooterComponent.Feedback' | translate }}</span>
  </div>
</div>
<form [formGroup]="feedbackForm" (ngSubmit)="submit()" class="row form-spacing">
  <div class="col-12">
    <app-form-field label="Common.Name">
      <input appFormInput
             name="name"
             type="text"
             formControlName="name"
             [placeholder]="('Common.Name' | translate) + '*'">
    </app-form-field>
    <ng-container *ngIf="feedbackForm.get('name') as name">
      <div class="input-error" *ngIf="name.touched && name.errors?.required">
        {{ 'ValidationErrors.EnterName' | translate }}
      </div>
    </ng-container>
  </div>
  <div class="col-12">
    <app-form-field label="Common.Phone">
      <app-phone-input formControlName="phone" inputClass="sv-input"></app-phone-input>
    </app-form-field>
    <ng-container *ngIf="feedbackForm.get('phone') as phone">
      <div class="input-error" *ngIf="phone.touched && phone.errors?.pattern">
        {{ 'ValidationErrors.EnterPhone' | translate:{ format: '+38(0##)-###-##-##' } }}
      </div>
    </ng-container>
  </div>
  <div class="col-12">
    <app-form-field label='E-mail:'>
      <input appFormInput type="text" name="email" formControlName="email" placeholder="E-mail*">
    </app-form-field>
    <ng-container *ngIf="feedbackForm.get('email') as email">
      <div class="input-error" *ngIf="email.touched && (email.errors?.email || email.errors?.required)">
        {{ 'ValidationErrors.WrongEMail' | translate }}
      </div>
    </ng-container>
  </div>
  <div class="col-12">
    <app-form-field label="Common.Message">
      <textarea appFormInput
                type="text"
                name="text"
                formControlName="text"
                [placeholder]="('Common.Message' | translate) + '*'">
      </textarea>
    </app-form-field>
    <ng-container *ngIf="feedbackForm.get('text') as text">
      <div class="input-error" *ngIf="text.touched && text.errors?.required">
        {{ 'ValidationErrors.RequiredField' | translate }}
      </div>
    </ng-container>
  </div>
  <button
    class="btn btn-accent offset-4 col-4"
    [disabled]="feedbackForm.invalid || (isLoading$ | async)"
    type="submit">
    <ng-template [appSpinnerLoader]="$any(isLoading$ | async)">
      {{ 'Common.SendMessage' | translate }}
    </ng-template>
  </button>
</form>
