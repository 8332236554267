import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import isEqual from 'lodash/isEqual';

import { Lang2, ToursCatalog } from 'package-types';

import { Translatable } from '@classes/translatable';
import { UrlService } from '@services/url.service';

import { Hotel } from '@interfaces/services/api/tours-catalog.endpoint';

@Pipe({
  name: 'hotelJsonLd',
  pure: false
})
export class HotelJsonLdPipe extends Translatable implements PipeTransform {
  constructor(
    private readonly url: UrlService,
    private readonly currency: CurrencyPipe,
    translate: TranslateService,
    cd: ChangeDetectorRef
  ) {
    super(translate, cd);
  }

  transform(h: Hotel, image?: ToursCatalog.PageHeadImage | null): Record<string, any> {
    this.ensureSubscriptionOnLangChange();
    const lang = this.translate.currentLang as Lang2;
    const imageLinks = image ? Object.values(image.links) : [];
    const ratingValue = parseInt(h.stars.code, 10);

    return {
      '@context': 'https://schema.org',
      '@type': 'Hotel',
      name: h.names.name,
      description: h.descriptions[lang].common,
      image: imageLinks.length ? imageLinks[imageLinks.length - 1] : undefined,
      url: this.url.getOrigin() + h.hotelUrl,
      telephone: h.address.phones[0],
      email: h.address.email,
      priceRange: h.offer.price && h.offer.currency
        ? this.currency.transform(h.offer.price, h.offer.currency.toUpperCase(), 'symbol-narrow', '1.0-0')
        : undefined,
      address: {
        '@type': 'PostalAddress',
        streetAddress: h.address.address,
        addressLocality: h.resort.names[lang].name,
        addressCountry: {
          '@type': 'Country',
          name: h.country.names[lang].name
        }
      },
      starRating: ratingValue > 0 ? this.createStarRating(ratingValue) : undefined,
      geo: isEqual(h.location.coordinates, [0, 0]) ?
        undefined
        : {
          '@type': 'Geo',
          longitude: h.location.coordinates[0],
          latitude: h.location.coordinates[1]
        },
      aggregateRating: h.reviews.count > 0
        ? this.createAggregateRating(h.reviews.count, h.reviews.rating)
        : undefined
    };
  }

  private createAggregateRating(reviewCount: number, ratingValue: number): Record<string, string | number> {
    return {
      reviewCount,
      ratingValue,
      '@type': 'AggregateRating'
    };
  }

  private createStarRating(rating: number): Record<string, string | number> {
    return {
      '@type': 'Rating',
      ratingValue: rating,
      worstRating: 1,
      bestRating: 10
    };
  }
}
