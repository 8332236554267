import { LayoutElementsVisibility } from '@classes/decorators/interfaces';

export const enum SpecialLayout {
  Article = 'Article'
}

export const specialLayouts: Record<SpecialLayout, Readonly<Required<LayoutElementsVisibility>>> = {
  [SpecialLayout.Article]: {
    thematicSectionsMenu: false,
    searchForm: false,
    breadcrumbs: false
  }
};
