import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { Lang2, ToursSearch } from 'package-types';

@Pipe({
  name: 'departureCityLabel',
  pure: false
})
export class DepartureCityLabelPipe implements PipeTransform, OnDestroy {
  private onLangChange?: Subscription;

  constructor(private translateService: TranslateService, private cd: ChangeDetectorRef) { }

  transform(value?: ToursSearch.DepartureCity | null): string {
    if (!this.onLangChange) {
      this.onLangChange = this.translateService.onLangChange.subscribe(() => this.cd.markForCheck());
    }
    if (!value) {
      return '';
    }

    // TODO: use translation service
    const fromText = this.translateService.currentLang === Lang2.Ru ? 'из' : 'з';

    return `${fromText} ${value?.nameFrom}`;
  }

  ngOnDestroy(): void {
    if (this.onLangChange) {
      this.onLangChange.unsubscribe();
      this.onLangChange = undefined;
    }
  }
}
