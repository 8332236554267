import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { TransportMenuComponent } from './components/transport-menu/transport-menu.component';
import { SharedModule } from '@modules/tours/shared/shared.module';
import { FlightsListComponent } from './components/flights-list/flights-list.component';
import { NightDateTableComponent } from './components/night-date-table/night-date-table.component';
import { RoomFoodTableComponent } from './components/room-food-table/room-food-table.component';
import { AvailableOffersComponent } from './available-offers.component';
import { HotelAvailabilityCoreModule } from '../hotel-availability-core/hotel-availability-core.module';
import { NightDateCellComponent } from './components/night-date-cell/night-date-cell.component';
import { RoomFoodCellComponent } from './components/room-food-cell/room-food-cell.component';
import { TableToolkitModule } from '@modules/tours/table-toolkit/table-toolkit.module';
import { FilterTabComponent } from './components/filter-tab/filter-tab.component';
import { FilterTabHeaderComponent } from './components/filter-tab-header/filter-tab-header.component';
import { FilterTabContentDirective } from './directives/filter-tab-content.directive';
import { AdditionalOfferInfoComponent } from './components/additional-offer-info/additional-offer-info.component';
import { OfferOptionComponent } from './components/offer-option/offer-option.component';
import { TableScrollComponent } from './components/table-scroll/table-scroll.component';

@NgModule({
  declarations: [
    AvailableOffersComponent,
    FlightsListComponent,
    NightDateCellComponent,
    NightDateTableComponent,
    RoomFoodCellComponent,
    RoomFoodTableComponent,
    TransportMenuComponent,
    FilterTabComponent,
    FilterTabHeaderComponent,
    FilterTabContentDirective,
    AdditionalOfferInfoComponent,
    OfferOptionComponent,
    TableScrollComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    HotelAvailabilityCoreModule,
    TableToolkitModule,
    FontAwesomeModule,
    NgbTooltipModule
  ],
  exports: [
    AvailableOffersComponent
  ]
})
export class AvailableOffersModule { }
