export class TableHeader<T> {
  constructor(private segments: T[]) { }

  getSegments(): T[] {
    return this.segments;
  }

  getSegment(idx: number): T {
    return this.segments[idx];
  }

  findIndex(segment: T): number {
    return this.segments.findIndex((s: T) => this.isEqual(s, segment));
  }

  length(): number {
    return this.segments.length;
  }

  protected isEqual(s1: T, s2: T): boolean {
    return s1 === s2;
  }
}
