import { Subject } from 'rxjs';

import { ToursSearch } from 'package-types';

import { Search } from './search';
import { SearchTtlCollection } from './search-ttl-collection';
import { TooLongSearchError } from '@services/tours-search/errors/too-long-search.error';

export class SearchCollection {
  private readonly items = new Map<string, Search>();
  private readonly ttlCollection = new SearchTtlCollection();
  private readonly destroySearch$$ = new Subject<string>();

  readonly destroySearch$ = this.destroySearch$$.asObservable();

  forEach(callback: (search: Search) => void) {
    this.items.forEach(callback);
  }

  get(id: string): Search | undefined {
    return this.items.get(id);
  }

  consumeResult(id: string, response: ToursSearch.Actions.GetResultResult) {
    const search = this.items.get(id);
    if (!search) {
      return;
    }
    if (response.status === ToursSearch.ResultStatus.Ready || response.status === ToursSearch.ResultStatus.Failed) {
      search.finish(response);
      this.delete(id);
    } else {
      search.next(response);
    }
  }

  set(search: Search): void {
    const searchId = search.getSearchId();
    this.items.set(searchId, search);
    this.ttlCollection.add(searchId, () => this.destroyByTimeout(searchId));
  }

  private destroyByTimeout(id: string) {
    this.destroySearch$$.next(id);
    const search = this.items.get(id);
    if (search) {
      search.error(new TooLongSearchError(search.getSearchId(), search.getPageNumber(), search.getParams()));
      this.items.delete(id);
    }
  }

  delete(id: string): void {
    const search = this.items.get(id);
    if (search) {
      this.destroySearch$$.next(id);
      search.destroy();
      this.items.delete(id);
      this.ttlCollection.delete(id);
    }
  }

  destroy(): void {
    for (const [searchId] of this.items) {
      this.delete(searchId);
    }
  }
}
