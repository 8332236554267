import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { ToursCatalog } from 'package-types';

import { NestedFormComponent } from '@classes/nested-form-component';
import { validators, valueAccessor } from '@helpers/angular.helper';

@Component({
  selector: 'app-votes-group',
  templateUrl: './votes-group.component.html',
  styleUrls: ['./votes-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [valueAccessor(VotesGroupComponent), validators(VotesGroupComponent)]
})
export class VotesGroupComponent extends NestedFormComponent {
  VoteCode = ToursCatalog.VoteCode;
  invalidMessage = 'invalidVotes';
  formGroup = this.fb.group({
    common: [10, Validators.required],
    [ToursCatalog.VoteCode.Cleaning]: [10, Validators.required],
    [ToursCatalog.VoteCode.Food]: [10, Validators.required],
    [ToursCatalog.VoteCode.Rooms]: [10, Validators.required],
    [ToursCatalog.VoteCode.Service]: [10, Validators.required],
    [ToursCatalog.VoteCode.Infrastructure]: [10, Validators.required]
  });

  constructor(private readonly fb: FormBuilder) {
    super();
  }
}
