import { Currency } from 'package-types';

import { PricePerTourists } from '../../interfaces/price-per-tourists';
import { PricePerNights } from '../../interfaces/price-per-nights';
import { DisplaySwitches, PriceDisplayOptions } from '../../interfaces/offer-management';

export class PriceDisplayOptionsState {
  values: PriceDisplayOptions = {
    currency: Currency.UAH,
    pricePerTourists: PricePerTourists.Total,
    pricePerNights: PricePerNights.Total
  };
  switches: DisplaySwitches = {
    currency: [{ value: Currency.UAH }],
    pricePerTourists: [
      {
        value: PricePerTourists.PerTourist,
        translation: 'HotelOverviewPage.PriceTable.DisplayOptions.PricePerTourist.PerTourist'
      },
      {
        value: PricePerTourists.Total,
        translation: 'HotelOverviewPage.PriceTable.DisplayOptions.PricePerTourist.Total'
      }
    ],
    pricePerNights: [
      {
        value: PricePerNights.PerNight,
        translation: 'HotelOverviewPage.PriceTable.DisplayOptions.PricePerNights.PerNight'
      },
      {
        value: PricePerNights.Total,
        translation: 'HotelOverviewPage.PriceTable.DisplayOptions.PricePerNights.Total'
      }
    ]
  };
}
