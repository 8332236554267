<ng-select [multiple]="true"
           [items]="stars"
           appendTo="body"
           [virtualScroll]="true"
           [(ngModel)]="selectedStars"
           [placeholder]="placeholder | translate">
  <ng-template ng-multi-label-tmp let-stars="items" let-clear="clear">
    <div class="ng-value" *ngFor="let star of stars | slice:0:2">
      <span class="ng-value-label">{{star}}*</span>
      <span class="ng-value-icon right" (click)="clear(star)" aria-hidden="true">×</span>
    </div>
    <div class="ng-value" *ngIf="stars.length > 2">
      <span class="ng-value-label">+{{stars.length - 2}}</span>
    </div>
  </ng-template>
  <ng-template ng-option-tmp let-star="item">
    {{ star }}*
  </ng-template>
</ng-select>
