<img
  src="/assets/icons/info.svg"
  alt="Info icon"
  container="body"
  placement="top-right"
  tooltipClass="nd-tooltip"
  [ngbTooltip]="tooltip">
<ng-template #tooltip>
  <div class="content">
    <ng-content></ng-content>
  </div>
</ng-template>
