import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReadMoreComponent implements OnInit {
  @Input() text: string = '';
  @Input() maxHeight: number = 700;
  public isCollapsed: boolean = true;

  ngOnInit(): void {
    if (this.text.length <= this.maxHeight) {
      this.isCollapsed = false;
    }
  }

  public showText(): void {
    this.isCollapsed = !this.isCollapsed;
    delete this.maxHeight;
  }
}
