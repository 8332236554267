<div class="hotel-info" [ngClass]="{'hotel-info_theme_white': theme === 'white'}" *ngIf="data">
  <span class="hotel-info__hotel-name">{{ hotel.name }}</span>
  <span class="hotel-info__options">
      <span>{{ hotel.countryName | fieldTranslation }}, {{ hotel.resortName | fieldTranslation }}</span>
      <span>{{ 'MainPage.HotelInfo.Duration' | translate:{ nights: offer.nights } }},
        {{ 'MainPage.HotelInfo.DateFrom' | translate:{ date: offer.checkIn | date: 'dd.MM' } }}
      </span>
  </span>
  <span class="hotel-info__price">{{ calcPricePerTourist() | currency: 'UAH':'symbol-narrow': '1.0-0' }}</span>
  <span class="hotel-info__options">{{'MainPage.HotelInfo.RoomDescription' | translate: { room: offer.room } }}, {{ offer.accommodation }}</span>
</div>
