import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchInputComponent implements OnInit{
  @Input() label: string = '';
  @Input() iconName?: IconName;
  @Input() backgroundTransparent: boolean = false;

  classList: string[] = [];

  ngOnInit() {
    this.buildClassList();
  }

  private buildClassList(): void {
    if (this.backgroundTransparent) {
      this.classList.push('group_bgc_transparent');
    }
  }
}
