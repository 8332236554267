<ng-container *ngIf="block?.url; then blockContentWithLink else blockContent"></ng-container>
<!-- TODO: extract component for router and external links combination after block url reworking -->
<ng-template #blockContentWithLink>
  <a [href]="block.url" *ngIf="block">
    <ng-container *ngTemplateOutlet="blockContent"></ng-container>
  </a>
</ng-template>
<ng-template #blockContent>
  <ng-container *ngIf="block">
    <app-overlaid-image
      *ngIf="block | blockImageLinks as imageLinks"
      [resizesLinks]="imageLinks"
      imageFit="contain"
      imgAlt="Html Block">
    </app-overlaid-image>
    <div *ngIf="block.html" class="html" [innerHTML]="block | mainPageBlockHtml"></div>
  </ng-container>
</ng-template>
