import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertBytes'
})
export class ConvertBytesPipe implements PipeTransform {
  private readonly base = 2;
  private readonly exponent = { MB: 2 * 10 } as const;

  transform(value: number, units: 'MB'): number {
    return value / Math.pow(this.base, this.exponent[units]);
  }
}
