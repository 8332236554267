import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OfferPricePipe } from './pipes/offer-price.pipe';
import { CardComponent } from './components/card/card.component';
import { FlightDurationPipe } from './pipes/flight-duration.pipe';
import { KnownTourOptionsPipe } from './pipes/known-tour-options.pipe';

@NgModule({
  declarations: [
    OfferPricePipe,
    FlightDurationPipe,
    CardComponent,
    KnownTourOptionsPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    OfferPricePipe,
    FlightDurationPipe,
    CardComponent,
    KnownTourOptionsPipe
  ]
})
export class HotelAvailabilityCoreModule { }
