import { Store } from 'rxjs-observable-store';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, forkJoin, Observable, of, OperatorFunction } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { ToursCatalog } from 'package-types';

import { HotelPageInfoState } from './hotel-page-info.state';
import { ToursCatalogEndpoint } from '@services/api/tours-catalog.endpoint';
import { InformationStore } from '@services/store/information.store';
import { getStoreRequestStateUpdater } from '@helpers/endpoint.helper';
import { ImagesEndpoint } from '@services/api/images.endpoint';
import { replaceError } from '@helpers/rx.helper';
import { PlatformService } from '@services/platform.service';
import { NotificationService } from '@services/notification.service';
import { ErrorMessage } from '@constants/error-message.enum';
import { collectImagesIds } from '@helpers/images.helper';
import { buildFacilities, buildRating, enrichReviewsByImageLinks } from '../helpers/hotel-page-info.helper';
import { AppConfig, APP_CONFIG } from '../../../../app-config.module';
import { onLangChange } from '@helpers/language.helper';

import { DisplayableHotelReview, HotelReview } from '@interfaces/hotel-review';
import { Hotel, HotelPageInfo } from '@interfaces/services/api/tours-catalog.endpoint';

@UntilDestroy()
@Injectable()
export class HotelPageInfoStore extends Store<HotelPageInfoState> {
  private readonly storeRequestStateUpdater = getStoreRequestStateUpdater(this);
  private readonly hotel$ = this.loadAndShareHotelPageInfo();

  constructor(
    private translate: TranslateService,
    private toursCatalog: ToursCatalogEndpoint,
    private images: ImagesEndpoint,
    private informationStore: InformationStore,
    private platform: PlatformService,
    private notification: NotificationService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    super(new HotelPageInfoState());
  }

  init(): void {
    this.setHotelInfoOnHotelChange();
    this.loadImagesOnHotelChange();
    this.loadReviewsOnHotelChange();
  }

  loadNextPage(): void {
    of(this.state.hotelInfo?.hotelId).pipe(
      filter((hotelId): hotelId is number => hotelId !== undefined),
      this.loadReviewsPage(this.state.reviews.length ? new Date(this.getLastLoadedReview().message.leftAt) : undefined),
      this.loadReviewsImages(),
      untilDestroyed(this)
    ).subscribe({
      next: reviews => this.patchState([...this.state.reviews, ...reviews], 'reviews'),
      error: err => console.error('Failed to load hotel reviews', err)
    });
  }

  private loadAndShareHotelPageInfo(): Observable<Hotel> {
    return this.informationStore.onChanges('pageInfo').pipe(
      filter((pageInfo): pageInfo is HotelPageInfo => pageInfo?.type === ToursCatalog.PageType.Hotel),
      map(({ hotel }) => hotel),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private setHotelInfoOnHotelChange() {
    combineLatest([this.hotel$, onLangChange(this.translate)])
      .pipe(
        distinctUntilChanged((prev, curr) => {
          const [prevHotel, prevLang] = prev;
          const [currHotel, currLang] = curr;

          return prevHotel.hotelId === currHotel.hotelId && prevLang === currLang;
        }),
        untilDestroyed(this)
      )
      .subscribe(([hotel, lang]) => this.setState({
        ...this.state,
        hotelInfo: hotel,
        facilities: buildFacilities(hotel, lang),
        rating: buildRating(hotel),
        commonDescription: hotel.descriptions[lang]?.common
      }));
  }

  private loadImagesOnHotelChange() {
    this.hotel$.pipe(
      switchMap(({ hotelId }) => forkJoin({
        hotelId: of(hotelId),
        images: this.images.getHotelImagesLinks([hotelId])
      })),
      map(({ hotelId, images: { imageResizesLinks } }) => imageResizesLinks[hotelId] ? Object.values(imageResizesLinks[hotelId]) : []),
      untilDestroyed(this)
    ).subscribe({
      next: images => this.patchState(images, 'images'),
      error: () => this.notification.error(ErrorMessage.ImageLoadingFailure)
    });
  }

  private loadReviewsOnHotelChange() {
    this.hotel$.pipe(
      this.platform.accessForBrowser(),
      map(({ hotelId }) => hotelId),
      this.loadReviewsPage(),
      this.loadReviewsImages(),
      untilDestroyed(this)
    ).subscribe({
      next: reviews => this.patchState(reviews, 'reviews'),
      error: err => console.error('Failed to load hotel reviews', err)
    });
  }

  private getLastLoadedReview() {
    return this.state.reviews[this.state.reviews.length - 1];
  }

  private loadReviewsPage(from = new Date()): OperatorFunction<number, HotelReview[]> {
    return source$ => source$.pipe(
      switchMap(hotelId => this.toursCatalog.getHotelReviews(
        hotelId,
        from,
        this.config.pages.hotelPageInfo.reviewsPerPage,
        this.storeRequestStateUpdater
      ).pipe(
        map(({ reviews }) => reviews),
        tap(reviews => this.patchState(!reviews.length, 'isLastReviewsPage'))
      ))
    );
  }

  private loadReviewsImages(): OperatorFunction<HotelReview[], DisplayableHotelReview[]> {
    return source$ => source$.pipe(
      switchMap(reviews => forkJoin({
        reviews: of(reviews),
        links: this.images.getDestinationReviewImages(collectImagesIds(reviews))
          .pipe(
            map(({ imageResizesLinks }) => imageResizesLinks),
            replaceError('Images links are not loaded', { })
          )
      })),
      map(({ reviews, links }) => enrichReviewsByImageLinks(reviews, links))
    );
  }
}
