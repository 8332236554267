import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, of, OperatorFunction } from 'rxjs';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express-serve-static-core';

import { ToursCatalogEndpoint } from '@services/api/tours-catalog.endpoint';
import { PlatformService } from '@services/platform.service';
import { transformLinkWithCurrentLang } from '@helpers/language.helper';
import { NotFoundServiceService } from '@services/not-found-service.service';

import { PageInfo } from '@interfaces/services/api/tours-catalog.endpoint';

@Injectable({
  providedIn: 'root'
})
export class PageResolverService implements Resolve<PageInfo | null> {
  constructor(
    private readonly toursCatalog: ToursCatalogEndpoint,
    private readonly router: Router,
    private readonly injector: Injector,
    private readonly platform: PlatformService,
    private readonly notFoundService: NotFoundServiceService
  ) { }

  resolve({ data }: ActivatedRouteSnapshot, { url }: RouterStateSnapshot): Observable<PageInfo | null> {
    if (this.platform.isServer()) {
      return this.toursCatalog.getPageInfo(url)
        .pipe(
          map((res) => {
              if (res.type === 'Redirect') {
                const response = this.injector.get<Response>(RESPONSE);
                response.redirect(res.httpCode, transformLinkWithCurrentLang(res.location, res.lang));

                return null;
              }

              return res.pageInfo;
            }
          ),
          this.handleError(url)
        );
    }

    return this.toursCatalog.getPageInfo(url)
      .pipe(
        switchMap(res => res.type === 'Redirect'
          ? this.router.navigateByUrl(transformLinkWithCurrentLang(res.location, res.lang)).then(() => null)
          : of(res.pageInfo)
        ),
        this.handleError(url)
      );
  }

  private handleError(url: string): OperatorFunction<any, null> {
    return source$ => source$.pipe(
      catchError((err) => {
        this.notFoundService.navigateToNotFoundPage(url);
        console.error(err);

        return of(null);
      })
    );
  }
}
