<div class="container">
  <div class="offers-grid row">
    <div class="offers-grid__card col-12 col-sm-6" *ngFor="let hotel of hotels; trackBy: trackByFn">
      <app-hotel-card
        [galleryImages]="hotel.images | ngxGalleryImages"
        [location]="hotel.location"
        [hotelUrl]="hotel.hotelUrl"
        [hotelName]="hotel.name"
        [hotelId]="hotel.hotelId"
        [rating]="hotel.rating"
        [resortName]="hotel.resortName"
        [countryName]="hotel.countryName"
        [stars]="hotel.stars"
        [offer]="hotel.offers | cheapestEntity"
      ></app-hotel-card>
    </div>
  </div>
  <div class="row">
    <div class="offset-3 col-6 offset-md-4 col-md-4 offers-grid__load-more-btn">
      <ng-content></ng-content>
    </div>
  </div>
</div>
