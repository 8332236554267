<div class="btn-group" *ngIf="options.length">
  <span class="btn btn-muted btn-option"
        [style.width]="labelWidth"
        *ngIf="pickSelected(selection, options) as selectedOption">
    <ng-container
      [ngTemplateOutlet]="value"
      [ngTemplateOutletContext]="{ $implicit: selectedOption }">
    </ng-container>
  </span>
  <div class="btn-group" ngbDropdown placement="bottom-right bottom-left">
    <button class="btn btn-muted btn-option dropdown-toggle-split" ngbDropdownToggle></button>
    <div class="dropdown-menu" ngbDropdownMenu>
      <button
        ngbDropdownItem
        class="dropdown-button"
        *ngFor="let option of options"
        (click)="selectionChange.emit(option.value)">
        <ng-container
          [ngTemplateOutlet]="label"
          [ngTemplateOutletContext]="{ $implicit: option }">
        </ng-container>
      </button>
    </div>
  </div>
</div>
