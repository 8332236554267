import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

import { validators, valueAccessor } from '@helpers/angular.helper';
import { NestedFormComponent } from '@classes/nested-form-component';

@UntilDestroy()
@Component({
  selector: 'app-message-group',
  templateUrl: './message-group.component.html',
  styleUrls: ['./message-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [valueAccessor(MessageGroupComponent), validators(MessageGroupComponent)]
})
export class MessageGroupComponent extends NestedFormComponent {
  @HostBinding('class') hostClasses = 'row';

  formGroup = this.fb.group({
    name: [null, Validators.required],
    email: [null, Validators.compose([Validators.required, Validators.email])],
    phone: [null, Validators.pattern(/380\d{9}/)],
    text: [null, Validators.required],
    title: [null, Validators.required]
  });

  protected invalidMessage = 'invalidMessage';

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  writeValue(obj: any): void {
    if (obj) {
      this.formGroup.setValue(obj, { emitEvent: false });
    } else {
      this.resetForm();
    }
  }

  private resetForm() {
    this.formGroup.reset({
      name: null,
      email: null,
      phone: null,
      text: null,
      title: null
    });
    this.formGroup.markAsUntouched();
    this.formGroup.markAsPristine();
  }
}
