import { Pipe, PipeTransform } from '@angular/core';

import { ToursCatalog } from 'package-types';

@Pipe({
  name: 'hotelSelectorData'
})
export class HotelSelectorDataPipe implements PipeTransform {
  transform(hotel: ToursCatalog.SelectiveHotel): string {
    const resortName = this.createDisplayableName(hotel.resortName);
    const rating = this.createDisplayableRating(hotel.rating);

    return [resortName, rating].join(' ');
  }

  private createDisplayableName(name?: string): string {
    return name ?? '';
  }

  private createDisplayableRating(rating?: number): string {
    return rating === undefined ? '' : `${rating}/10`;
  }
}
