import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HotelPageInfoStore } from '../../services/hotel-page-info.store';
import { HotelPageInfoState } from '../../services/hotel-page-info.state';
import { pickGalleryImage } from '@helpers/images.helper';

@Component({
  selector: 'app-hotel-info',
  templateUrl: './hotel-info.component.html',
  styleUrls: ['./hotel-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotelInfoComponent {
  pageInfo$: Observable<HotelPageInfoState['hotelInfo']> = this.hotelPageInfo.onChanges('hotelInfo');
  commonDescription$: Observable<HotelPageInfoState['commonDescription']> = this.hotelPageInfo.onChanges('commonDescription');
  facilities$: Observable<HotelPageInfoState['facilities']> = this.hotelPageInfo.onChanges('facilities');
  galleryImages$: Observable<NgxGalleryImage[]> = this.hotelPageInfo.onChanges('images')
    .pipe(
      map(images => images.map(img => pickGalleryImage(img)))
    );
  readonly galleryOptions: NgxGalleryOptions[] = [
    {
      width: '100%',
      height: '100%',
      thumbnailsColumns: 3,
      imageAnimation: NgxGalleryAnimation.Slide,
      imagePercent: 75,
      thumbnailsPercent: 25,
      thumbnailsMargin: 5,
      thumbnailMargin: 5,
      imageArrowsAutoHide: true,
      thumbnailsArrowsAutoHide: true,
      previewArrowsAutoHide: true,
      thumbnailsSwipe: true,
      imageSwipe: true,
      previewCloseOnEsc: true,
      previewKeyboardNavigation: true
    }
  ];

  constructor(private readonly hotelPageInfo: HotelPageInfoStore) { }
}
