import { SiteMenuSection } from '@constants/site-menu-section';
import { buildPageDecorator } from './helpers';
import { createDefaultElementsVisibility, GlobalLayoutState } from '@services/store/global-layout.state';

import { StaticPageDecorator } from './interfaces';

type GlobalLayoutStatePatch = Pick<GlobalLayoutState,
  | 'activeSiteMenuSection'
  | 'specialLayout'
  | 'breadcrumbs'
  | 'thematicSectionsMenu'
  | 'header'
  | 'elementsVisibility'>;

// tslint:disable-next-line:variable-name
export const StaticPage: StaticPageDecorator = (
  {
    activeSiteMenuSection = SiteMenuSection.Tours,
    specialLayout,
    layoutElementsVisibility = { },
    seoInfoUploadToken,
    headingImageLink,
    breadcrumbs = [],
    thematicSectionsMenu = [],
    skipIndexing,
  } = { }
) => {
  return buildPageDecorator({
    onInitMixin({ globalLayout, staticPageInfoLoader, searchForm, htmlHead }) {
      const nextStatePatch: GlobalLayoutStatePatch = {
        activeSiteMenuSection,
        specialLayout,
        breadcrumbs,
        thematicSectionsMenu,
        header: { ...globalLayout.state.header, backgroundImageLink: headingImageLink },
        elementsVisibility: { ...createDefaultElementsVisibility(), ...layoutElementsVisibility }
      };
      globalLayout.setState({ ...globalLayout.state, ...nextStatePatch });
      searchForm.setStaticPageSearchParams();

      if (seoInfoUploadToken !== undefined) {
        staticPageInfoLoader.loadTagsForStaticPage(seoInfoUploadToken);
      }
      if (skipIndexing) {
        htmlHead.update({ meta: [{ name: 'robots', content: 'noindex, nofollow' }] });
      }
    },
    onDestroyMixin({ staticPageInfoLoader, htmlHead }) {
      if (seoInfoUploadToken !== undefined) {
        staticPageInfoLoader.resetLoadedTags();
      }
      if (skipIndexing) {
        htmlHead.remove({ meta: [{ name: 'robots', content: 'noindex, nofollow' }] });
      }
    },
    onLangChange({ staticPageInfoLoader }) {
      if (seoInfoUploadToken !== undefined) {
        staticPageInfoLoader.loadTagsForStaticPage(seoInfoUploadToken);
      }
    }
  });
};
