import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { TableCellTemplateDirective } from '../../directives/table-cell-template.directive';
import { TableColumnLabelTemplateDirective } from '../../directives/table-column-label-template.directive';
import { TableRowLabelTemplateDirective } from '../../directives/table-row-label-template.directive';
import { Table } from '../../classes/table';
import { TableCell } from '../../classes/table-cell';

@Component({
  selector: 'app-table-skeleton',
  templateUrl: './table-skeleton.component.html',
  styleUrls: ['./table-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableSkeletonComponent<T, C, R> {
  @Input() title = '';
  @Input() emptyTableMessage!: string;
  @Input() table!: Table<T, C, R>;

  @Output() cellClick: EventEmitter<TableCell<T>> = new EventEmitter<TableCell<T>>();

  @ContentChild(TableCellTemplateDirective, { read: TemplateRef, static: true }) cellTemplate!: TemplateRef<any>;
  @ContentChild(TableColumnLabelTemplateDirective, { read: TemplateRef, static: true }) columnLabelTemplate!: TemplateRef<any>;
  @ContentChild(TableRowLabelTemplateDirective, { read: TemplateRef, static: true }) rowLabelTemplate!: TemplateRef<any>;

  get hasData(): boolean {
    return this.table.width > 0 && this.table.height > 0;
  }

  cellClicked(cell: TableCell<T>): void {
    this.cellClick.emit(cell);
  }
}
