<footer class="footer container">
  <div class="footer__main row no-gutters align-items-center">
    <div class="footer__phone col-6 col-md-3">
      <a [href]="'tel:' + phoneNumber" class="phone phone_big">
        {{ phoneNumber }}
      </a>
    </div>
    <div class="footer__nav d-none d-md-block col-md-6 col-lg-7">
      <ul class="nav">
        <li class="nav__link" *ngFor="let item of mainMenu">
          <ng-container *ngIf="(item.link | typeof:'string'); then internalLink else externalLink"></ng-container>
          <ng-template #internalLink>
            <a class="link link-active" [routerLink]="$any(item.link) | currentLangLink">
              {{ item.translatePath | translate }}
            </a>
          </ng-template>
          <ng-template #externalLink>
            <a class="link link-active" [href]="$any(item.link) | fieldTranslation">
              {{ item.translatePath | translate }}
            </a>
          </ng-template>
        </li>
      </ul>
    </div>
    <div class="footer__social col-6 col-md-3 col-lg-2">
      <div class="social">
        <div class="social-btn">
          <a
            class="social-btn__link"
            href="https://www.instagram.com/silpo.voyage/"
            title="Instagram SilpoVoyage"
            target="_blank"
            rel="noopener noreferrer">
            <fa-icon class="social-btn__icon" [icon]="['fab', 'instagram']"></fa-icon>
          </a>
        </div>
        <div class="social-btn">
          <a
            class="social-btn__link"
            href="https://www.facebook.com/silpovoyage/"
            title="Facebook SilpoVoyage"
            target="_blank"
            rel="noopener noreferrer">
            <fa-icon class="social-btn__icon" [icon]="['fab', 'facebook-f']"></fa-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="footer__links row align-items-center">
    <ul class="footer__links-list col-12">
      <li class="footer__link" *ngFor="let item of footerMenu">
        <a class="link-footer" [routerLink]="item.link | currentLangLink">{{ item.translatePath | translate }}</a>
      </li>
      <li class="footer__link">
        <button type="button" class="link-footer link-footer__button" (click)="openSendSiteFeedbackModal()">
          {{ 'FooterComponent.Feedback' | translate }}
        </button>
      </li>
    </ul>
  </div>
</footer>
