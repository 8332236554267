<div class="hotel-info container">
  <div class="row" *ngIf="commonDescription$ | async as descriptionHtml">
    <div class="col-12">
      <div class="hotel-info__about">
        <h2 class="hotel-info__title">{{ 'HotelOverviewPage.About' | translate }}</h2>
        <p class="hotel-info__description" [innerHTML]="descriptionHtml"></p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6 hotel-info__block">
      <ngx-gallery
        *ngIf="galleryImages$ | async as images"
        class="hotel-info__gallery"
        [options]="galleryOptions"
        [images]="images">
      </ngx-gallery>
    </div>
    <div class="col-12 col-lg-6 hotel-info__block">
      <app-hotel-contacts [hotel]="pageInfo$ | async"></app-hotel-contacts>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-4 col-lg-3" *ngFor="let facility of facilities$ | async">
      <app-facility-card [facility]=facility></app-facility-card>
    </div>
  </div>
</div>
