import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Required } from 'package-utils';

import { MainPageBlockWithResizes } from '../../interfaces/main-page-block';

@Component({
  selector: 'app-offer-tile',
  templateUrl: './offer-tile.component.html',
  styleUrls: ['./offer-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferTileComponent {
  @Input() @Required block!: MainPageBlockWithResizes;
}
