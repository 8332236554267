import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { HotelFacility } from '../../services/hotel-page-info.state';

@Component({
  selector: 'app-facility-card',
  templateUrl: './facility-card.component.html',
  styleUrls: ['./facility-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilityCardComponent {
  @Input() facility!: HotelFacility;
}
