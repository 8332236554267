import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { TranslateModule } from '@ngx-translate/core';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { RouterModule } from '@angular/router';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';

import { PackageAngularModule } from 'package-angular';

import { FacilityCardComponent } from './components/facility-card/facility-card.component';
import { HotelInfoComponent } from './components/hotel-info/hotel-info.component';
import { HotelContactsComponent } from './components/hotel-contacts/hotel-contacts.component';
import { HotelReviewsComponent } from './components/hotel-reviews/hotel-reviews.component';
import { OverviewRatingComponent } from './components/overview-rating/overview-rating.component';
import { SharedModule } from '@modules/tours/shared/shared.module';
import { HotelOverviewPageComponent } from './hotel-overview-page.component';
import { HotelAvailabilityModule } from './modules/hotel-availability/hotel-availability.module';
import { DescriptionTranslateMapperPipe } from './pipes/description-translate-mapper.pipe';
import { ServiceTranslateMapperPipe } from './pipes/service-translate-mapper.pipe';
import { TurpravdaLinkPipe } from './pipes/turpravda-link.pipe';
import { SeoModule } from '@modules/tours/seo/seo.module';
import { PostReviewModalComponent } from './components/post-review-modal/post-review-modal.component';
import { ReviewModule } from '@modules/tours/review/review.module';
import { HotelJsonLdPipe } from './pipes/hotel-json-ld.pipe';

@NgModule({
  declarations: [
    HotelOverviewPageComponent,
    OverviewRatingComponent,
    HotelInfoComponent,
    HotelReviewsComponent,
    HotelContactsComponent,
    FacilityCardComponent,
    DescriptionTranslateMapperPipe,
    ServiceTranslateMapperPipe,
    TurpravdaLinkPipe,
    PostReviewModalComponent,
    HotelJsonLdPipe
  ],
  imports: [
    CommonModule,
    LeafletModule,
    SharedModule,
    HotelAvailabilityModule,
    TranslateModule,
    NgxGalleryModule,
    PackageAngularModule,
    SeoModule,
    ReviewModule,
    RouterModule,
    NgxJsonLdModule
  ]
})
export class HotelOverviewPageModule { }
