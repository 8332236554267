import { Pipe, PipeTransform } from '@angular/core';

import { ToursSearch } from 'package-types';

@Pipe({
  name: 'knownTourOptions'
})
export class KnownTourOptionsPipe implements PipeTransform {
  private readonly KNOWN_OPTIONS = ['transfer', 'insurance'];

  transform(options: ToursSearch.Offer['tourOptions']): string[] {
    return options.filter(o => this.KNOWN_OPTIONS.includes(o));
  }
}
