import { Store } from 'rxjs-observable-store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';

import { DestinationSelectors, DestinationSelectorsService, numbersRangeValidator } from 'package-angular';

import { SearchFormState } from './search-form.state';
import { ToursSearchEndpoint } from '../api/tours-search.endpoint';
import { onLangChange } from '@helpers/language.helper';
import { filterSelectedHotelIdsByHotels, filterSelectiveHotelsBySelectedResortIds } from '@modules/tours/search/helpers/search.helper';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { sanitizeSearch } from '@helpers/search.helper';
import { defaultSearch } from '@constants/default-search-params';
import { ToursCatalogEndpoint } from '@services/api/tours-catalog.endpoint';
import { PlatformService } from '../platform.service';

import { Destination, SearchParams } from '@interfaces/modules/tours/search';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class SearchFormStore extends Store<SearchFormState> {
  formGroup = this.createFormGroup();

  private readonly destination$$ = new Subject<Destination | undefined>();
  private readonly departureCities$ = combineLatest([this.destination$$.asObservable(), onLangChange(this.translate)])
    .pipe(
      this.platformService.accessForBrowser(),
      map(([destination, lang]) => ({ lang, locationId: destination?.countryId ?? destination?.id })),
      switchMap(({ lang, locationId }) => this.toursSearchEndpoint.getDepartureCities(lang, locationId)),
      untilDestroyed(this)
    );
  private readonly destinationSelectors$ = combineLatest([this.destination$$.asObservable(), onLangChange(this.translate)])
    .pipe(
      switchMap(([dest]) => this.loadDestinationSelections(dest)),
      untilDestroyed(this)
    );

  constructor(
    private readonly fb: FormBuilder,
    private readonly translate: TranslateService,
    private readonly destinationSelectors: DestinationSelectorsService,
    private readonly localStorageService: LocalStorageService,
    private readonly platformService: PlatformService,
    // API
    private readonly toursSearchEndpoint: ToursSearchEndpoint,
    private readonly toursCatalogEndpoint: ToursCatalogEndpoint
  ) {
    super(new SearchFormState());
  }

  setSearchParams(search: SearchParams) {
    this.formGroup.patchValue(cloneDeep(search), { emitEvent: false });
    this.destination$$.next(search.destination);
  }

  init(): void {
    this.departureCities$.subscribe(cities => this.patchState(cities, 'departureCities'));
    this.destinationSelectors$.subscribe(selectors => this.updateSelectors(selectors));
    const destinationControl = this.formGroup.get('destination');
    destinationControl?.valueChanges
      .pipe(
        startWith(destinationControl?.value),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(destination => this.destination$$.next(destination));
    this.formGroup.get('resorts')?.valueChanges
      .pipe(
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(resorts => this.excludeInvalidPickedHotels(resorts));
    this.loadStaticSelectors();
  }

  storeFormData() {
    this.localStorageService.setSearchParams(this.formGroup.value).toPromise();
  }

  setStaticPageSearchParams(): void {
    this.localStorageService.getSearchParams()
      .pipe(
        filter((search): search is SearchParams => search !== undefined),
        map(search => sanitizeSearch(search)),
        untilDestroyed(this)
      )
      .subscribe(search => this.setSearchParams(search));
  }

  private createFormGroup(): FormGroup {
    const form = this.fb.group({
      destination: [null, Validators.required],
      hotels: null,
      resorts: null,
      departureCity: [null, Validators.required],
      dates: [null, Validators.required],
      duration: [null, Validators.required],
      tourists: [null, Validators.required],
      foods: null,
      transports: null,
      services: null,
      stars: null,
      ratingMin: null,
      price: this.fb.group({
        min: [null, numbersRangeValidator()],
        max: [null, numbersRangeValidator()]
      })
    });
    form.patchValue(defaultSearch(), { emitEvent: false });

    return form;
  }

  private loadStaticSelectors(): void {
    this.toursCatalogEndpoint.getParams()
      .pipe(untilDestroyed(this))
      .subscribe(({ params }) => this.patchState(params, 'selectors'));
  }

  private loadDestinationSelections(destination?: Destination): Observable<DestinationSelectors> {
    if (!destination) {
      return of({ resorts: [], hotels: [] });
    }

    return this.destinationSelectors.getSelectors(destination);
  }

  private excludeInvalidPickedHotels(resorts: number[]): void {
    const { originalHotelsSelector } = this.state;
    const hotels = this.formGroup.getRawValue().hotels;

    const filteredHotelSelectors = filterSelectiveHotelsBySelectedResortIds(originalHotelsSelector, resorts);
    const filteredHotelIds = filterSelectedHotelIdsByHotels(hotels, filteredHotelSelectors);

    const filteredHotelsValue = this.filterDestinationIds(filteredHotelSelectors, filteredHotelIds);

    this.formGroup.patchValue({ hotels: filteredHotelsValue });
    this.patchState(filteredHotelSelectors, 'destinationSelectors', 'hotels');
  }

  private updateSelectors(selectors: DestinationSelectors): void {
    const resorts = this.formGroup.get('resorts')?.value ?? [];
    const hotels = this.formGroup.get('hotels')?.value ?? [];

    const filteredHotelSelectors = filterSelectiveHotelsBySelectedResortIds(selectors.hotels, resorts);
    const filteredHotelIds = filterSelectedHotelIdsByHotels(hotels, filteredHotelSelectors);

    const filteredHotelsValue = this.filterDestinationIds(selectors.hotels, filteredHotelIds);
    const filteredResortsValue = this.filterDestinationIds(selectors.resorts, resorts);

    this.formGroup.patchValue({ resorts: filteredResortsValue, hotels: filteredHotelsValue }, { emitEvent: false });
    this.setState({
      ...this.state,
      originalHotelsSelector: selectors.hotels,
      destinationSelectors: { resorts: selectors.resorts, hotels: filteredHotelSelectors }
    });
  }

  private filterDestinationIds<T extends { id: number }>(list: T[], destinationIds: number[]): number[] {
    return destinationIds.filter(destinationId => list.some(i => i.id === destinationId));
  }
}
