import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { GtagService } from './gtag.service';
import { GtagConfig } from './gtag-config.interface';
import { GTAG_CONFIG } from './gtag-config.token';
import { GTAG } from './gtag.token';
import { WINDOW } from '@services/window.service';
import { gtagFactory } from './gtag.factory';
import { PlatformService } from '@services/platform.service';


@NgModule({
  providers: [GtagService],
  declarations: [],
  imports: [],
})
export class GtagModule {
  static forRoot(config: GtagConfig): ModuleWithProviders<GtagModule> {
    return {
      ngModule: GtagModule,
      providers: [
        { provide: GTAG_CONFIG, useValue: config },
        { provide: GTAG, useFactory: gtagFactory, deps: [GTAG_CONFIG, WINDOW, DOCUMENT, PlatformService] },
        // Eagerly instantiate GtagService on application start
        { provide: APP_INITIALIZER, useFactory: (s: GtagService) => () => s, deps: [GtagService], multi: true },
      ],
    };
  }
}
