import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ToursSearch } from 'package-types';

import { ToursSearchService } from '@services/tours-search/tours-search.service';

import { SearchParams } from '@interfaces/modules/tours/search';
import { HotelSearchEvent, SearchEventType } from '../interfaces/hotel-search';

import ResultStatus = ToursSearch.ResultStatus;

@UntilDestroy()
@Injectable()
export class HotelSearchService {
  public readonly search$: Observable<HotelSearchEvent>;

  private readonly search$$ = new Subject<HotelSearchEvent>();

  constructor(public readonly searchService: ToursSearchService) {
    this.search$ = this.search$$.asObservable();
  }

  search(params: SearchParams) {
    this.search$$.next({ params, type: SearchEventType.Start });
    this.searchService.search(params)
      .pipe(
        filter(val => val.res.status === ResultStatus.Ready),
        untilDestroyed(this)
      )
      .subscribe({
        next: res => this.search$$.next({ ...res, type: SearchEventType.Result })
      });
  }
}
