import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ThematicSectionLink } from '@services/store/global-layout.state';

@Component({
  selector: 'app-thematic-sections-menu',
  templateUrl: './thematic-sections-menu.component.html',
  styleUrls: ['./thematic-sections-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThematicSectionsMenuComponent {
  @Input() items: ThematicSectionLink[] = [];
  public isOpen: boolean = false;

  toggleMenu(): void {
    this.isOpen = !this.isOpen;
  }
}
