import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

import { validators, valueAccessor } from '@helpers/angular.helper';
import { fileType, maxFileSize } from '@helpers/validators.helper';
import { AppConfig, APP_CONFIG } from '../../../../../app-config.module';
import { NestedFormComponent } from '@classes/nested-form-component';

import { ActionStatus } from '@interfaces/action-status';

@UntilDestroy()
@Component({
  selector: 'app-review-image',
  templateUrl: './review-image.component.html',
  styleUrls: ['./review-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [valueAccessor(ReviewImageComponent), validators(ReviewImageComponent)]
})
export class ReviewImageComponent extends NestedFormComponent {
  formGroup: FormGroup;

  protected invalidMessage = 'unsupportedFile';

  @Input() uploadStatus: ActionStatus = 'pristine';

  @Output() remove = new EventEmitter<void>();

  constructor(
    private readonly fb: FormBuilder,
    @Inject(APP_CONFIG) public readonly config: AppConfig
  ) {
    super();
    this.formGroup = this.createFormGroup(config);
  }

  private createFormGroup(config: AppConfig) {
    return this.fb.group({
      file: [
        null,
        Validators.compose([
          Validators.required,
          maxFileSize(config.imageUpload.maxSizeInBytes),
          fileType(config.imageUpload.imageTypes)
        ])
      ],
      title: null
    });
  }
}
