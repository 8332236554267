<ng-container *ngIf="(isOffersLoading$ | async) === false; then grid; else preloader"></ng-container>
<ng-template #grid>
  <div class="container" *ngIf="hotels$ | async as hotels">
    <app-offers-grid [hotels]="hotels">
      <app-load-more-button
        *ngIf="hasNext$ | async"
        [loadingMsg]="('TourOffersPage.LoadMore.Loading' | translate)!"
        [loadMoreMsg]="('TourOffersPage.LoadMore.Static' | translate)!"
        [isLoading]="(isNextPageLoading$ | async)!"
        (click)="loadNextPage()">
      </app-load-more-button>
    </app-offers-grid>
  </div>
</ng-template>
<ng-template #preloader>
  <div class="container">
    <app-bus-preloader class="row"></app-bus-preloader>
  </div>
</ng-template>
