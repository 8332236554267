import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { toggleAdditionalOptions } from '../../animations/toggle-additional-options';
import { ErrorModalService } from '@modules/tours/core/services/error-modal.service';
import { ErrorMessage } from '@constants/error-message.enum';
import { SearchBaseComponent } from '@modules/tours/search/components/search-base/search-base.component';
import { ToursSearchEndpoint } from '@services/api/tours-search.endpoint';
import { GlobalSearchService } from '@services/tours-search/global-search.service';
import { SearchErrorsService } from '@services/tours-search/search-errors.service';
import { SearchFormStore } from '@services/store/search-form.store';

import { SearchParams } from '@interfaces/modules/tours/search';

@UntilDestroy()
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [toggleAdditionalOptions],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent extends SearchBaseComponent {
  public isOptionsOpen: boolean = false;

  constructor(
    localSearch: SearchFormStore,
    private errorModalService: ErrorModalService,
    private toursSearch: ToursSearchEndpoint,
    private router: Router,
    private route: ActivatedRoute,
    private globalSearch: GlobalSearchService,
    private searchErrorsService: SearchErrorsService
  ) {
    super(localSearch);
  }

  searchTour(): void {
    if (this.searchFormGroup.valid) {
      this.searchTourAndNavigateToFoundPage(this.searchFormGroup.value);
      this.searchForm.storeFormData();
    } else {
      this.showErrorModal();
    }
  }

  toggleMenu(): void {
    this.isOptionsOpen = !this.isOptionsOpen;
  }

  private searchTourAndNavigateToFoundPage(search: SearchParams): void {
    this.globalSearch.navigateToSearchPage(search)
      .pipe(untilDestroyed(this))
      .subscribe({
        error: err => this.searchErrorsService.handleSearchErrors(err, search)
      });
  }

  private showErrorModal() {
    const errors: ErrorMessage[] = [];

    if (this.searchFormGroup.get('destination')?.invalid) {
      errors.push(ErrorMessage.DestinationRequired);
    }

    if (this.searchFormGroup.get('price')?.invalid) {
      errors.push(ErrorMessage.InvalidPriceRange);
    }

    this.errorModalService.open(...errors);
  }
}
