<div class="reviews">
  <div class="reviews__header">
    <h3 class="reviews__title">{{ 'Common.Reviews' | translate }}</h3>
    <a class="reviews__turpravda-link"
       target="_blank"
       *ngIf="hotel"
       [href]="hotel | turpravdaLink">
      <img class="reviews__logo" src="/assets/images/logo_turpravda.svg" [alt]="('Reviews.TourPravda' | translate)!">
    </a>
    <div class="reviews__add-review-btn">
      <button class="btn btn-primary" (click)="openPostReviewModal()">{{ 'Reviews.AddReview' | translate }}</button>
    </div>
  </div>
  <div class="reviews__body">
    <ng-container *ngFor="let review of reviews; trackBy: trackByFn">
      <app-review [review]="review"></app-review>
    </ng-container>
  </div>
  <div class="reviews__load-more" *ngIf="!(lastPage$ | async)">
    <app-load-more-button
      [loadingMsg]="('Reviews.Loading' | translate)!"
      [loadMoreMsg]="('Reviews.LoadMore' | translate)!"
      [isLoading]="(isLoading$ | async)!"
      (click)="loadNextPage()">
    </app-load-more-button>
  </div>
</div>
