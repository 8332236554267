import { Inject, Injectable, InjectionToken } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { MetaDefinition } from '@angular/platform-browser';

import { Lang2 } from 'package-types';

import { HtmlHeadService } from './html-head.service';
import { transformLinkWithCurrentLang } from '@helpers/language.helper';
import { GlobalLayoutStore } from './store/global-layout.store';
import { ToursCatalogEndpoint } from './api/tours-catalog.endpoint';

import { HeadTagsArgs, SeoSettings, SocialSettings } from '@interfaces/services/seo.service';

export const SEO_SETTINGS = new InjectionToken<SeoSettings>('SEO');

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(
    private readonly translate: TranslateService,
    private readonly head: HtmlHeadService,
    private readonly router: Router,
    private readonly globalLayout: GlobalLayoutStore,
    private readonly toursCatalog: ToursCatalogEndpoint,
    @Inject(SEO_SETTINGS) private readonly settings: SeoSettings,
    @Inject(DOCUMENT) private readonly document: Document,
  ) { }

  init(): void {
    this.applyStaticTags(this.settings.social);
    this.startOnLangChangeListener();
    this.startRouteListener();
  }

  patchPageTags({ seo = { }, img = { }, coordinates }: HeadTagsArgs, { skipH1Update: skipH1SmallUpdate = false } = { }): void {
    const { keywords, description, title, metaTitle, h1, h1Small } = seo;
    const { src, alt } = img;
    const [lat, lng] = coordinates ?? [];
    if (h1) {
      this.globalLayout.setTitle(h1);
    } else {
      this.globalLayout.removeTitle();
    }
    if (!skipH1SmallUpdate) {
      if (h1Small) {
        this.globalLayout.setSubtitle(h1Small);
      } else {
        this.globalLayout.removeSubtitle();
      }
    }

    const meta: MetaDefinition[] = [
      { name: 'keywords', content: keywords?.join(', ') ?? '' },
      { name: 'description', content: description ?? '' },
      { property: 'og:description', content: description ?? '' },
      { property: 'og:title', name: 'twitter:title', content: metaTitle ?? '' },
      { property: 'og:description', itemprop: 'itemprop', name: 'twitter:description', content: description ?? '' },
      { property: 'og:image', itemprop: 'image', name: 'twitter:image', content: src ?? '' },
      { property: 'og:image:alt', content: alt ?? '' },
      { property: 'place:location:latitude', content: lat?.toString() ?? '' },
      { property: 'place:location:longitude', content: lng?.toString() ?? '' }
    ];
    this.head.update({ title, meta: meta.filter(m => m.content !== '') });
  }

  reset(): void {
    this.head.remove({
      title: undefined,
      meta: [
        { name: 'keywords' },
        { name: 'description' },
        { property: 'og:image:alt' },
        { property: 'og:description' },
        { property: 'place:location:latitude' },
        { property: 'place:location:longitude' },
        { property: 'og:title', name: 'twitter:title' },
        { property: 'og:image', itemprop: 'image', name: 'twitter:image' },
        { property: 'og:description', itemprop: 'itemprop', name: 'twitter:description' }
      ]
    });
  }

  setPaginationTags(prevHref?: string, nextHref?: string): void {
    if (prevHref) {
      this.head.update({ links: [{ rel: 'prev', href: prevHref }] });
    } else {
      this.head.remove({ links: [{ rel: 'prev' }] });
    }
    if (nextHref) {
      this.head.update({ links: [{ rel: 'next', href: nextHref }] });
    } else {
      this.head.remove({ links: [{ rel: 'next' }] });
    }
  }

  private applyStaticTags({ fbAppId, fbAdmin, googleId, itRatingId }: SocialSettings): void {
    this.head.updateMeta([
      { name: 'google-site-verification', content: googleId },
      { name: 'it-rating', content: itRatingId },
      { property: 'fb:app_id', content: fbAppId },
      { property: 'fb:admins', content: fbAdmin },
    ]);
  }

  private startOnLangChangeListener(): void {
    this.translate.onLangChange
      .pipe(untilDestroyed(this))
      .subscribe(({ lang, translations }) => this.langChanges(lang as Lang2, translations));
  }

  private startRouteListener(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      untilDestroyed(this)
    ).subscribe(() => {
      const { origin, pathname } = this.document.location;
      this.head.update({
        meta: [
          { property: 'og:url', name: 'twitter:url', content: origin + pathname }
        ],
        links: [
          { rel: 'canonical', href: origin + pathname },
          { rel: 'alternate', href: origin + transformLinkWithCurrentLang(pathname, Lang2.Ru), hreflang: 'ru-UA' },
          { rel: 'alternate', href: origin + transformLinkWithCurrentLang(pathname, Lang2.Uk), hreflang: 'uk-UA' }
        ]
      });
    });
  }

  private langChanges(lang: Lang2, translations: any): void {
    this.head.update({
      lang,
      meta: [
        { name: 'apple-mobile-web-app-title', content: translations.Common.SilpoVoyage() },
        { name: 'application-name', content: translations.Common.SilpoVoyage() },
        { property: 'og:locale', content: `${lang}_UA` },
        { property: 'og:site_name', itemprop: 'name', content: translations.Common.SilpoVoyage() },
      ],
      links: [
        { rel: 'manifest', href: this.settings.manifestLinks[lang] }
      ]
    });
  }
}
