<div class="sv-form-field">
  <label *ngIf="label" class="sv-form-field__label">{{ label | translate }}</label>
  <div
    class="sv-form-field__control sv-form-control"
    [class.sv-form-field__control_empty-label]="!label">
    <div class="sv-form-control__field">
      <ng-content></ng-content>
    </div>
  </div>
</div>
