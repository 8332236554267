<ng-container *ngIf="selectedOptions$ | async as selected">
  <app-display-option-select
    *ngIf="currencySwitch$ | async as currencySwitch"
    [options]="currencySwitch"
    labelWidth="5em"
    [selection]="selected.currency"
    (selectionChange)="setOptions({ currency: $event })">
    <ng-template appOptionLabel let-option>{{ option.value | uppercase }}</ng-template>
    <ng-template appOptionValue let-value>{{ value.value | uppercase }}</ng-template>
  </app-display-option-select>
  <app-display-option-select
    *ngIf="pricePerNightsSwitch$ | async as pricePerNightsSwitch"
    [options]="pricePerNightsSwitch"
    labelWidth="9.786em"
    [selection]="selected.pricePerNights"
    (selectionChange)="setOptions({ pricePerNights: $event })">
    <ng-template appOptionLabel let-option>{{ option.translation | translate }}</ng-template>
    <ng-template
      appOptionValue
      let-value>
      {{ value.translation! | translate }}
    </ng-template>
  </app-display-option-select>
  <app-display-option-select
    *ngIf="pricePerTouristsSwitch$ | async as pricePerTouristsSwitch"
    [options]="pricePerTouristsSwitch"
    labelWidth="10.571em"
    [selection]="selected.pricePerTourists"
    (selectionChange)="setOptions({ pricePerTourists: $event })">
    <ng-template appOptionLabel let-option>{{ option.translation | translate }}</ng-template>
    <ng-template
      appOptionValue
      let-value>
      {{ value.translation! | translate }}
    </ng-template>
  </app-display-option-select>
</ng-container>
