import { Pipe, PipeTransform } from '@angular/core';

import { cleanDeep } from 'package-utils';

import { stringifyUrlParams } from '@helpers/url-params.helper';
import { Translatable } from '@classes/translatable';

export interface MailtoUrlParams {
  subject?: string;
}

@Pipe({
  name: 'mailto',
  pure: false
})
export class MailtoPipe extends Translatable implements PipeTransform {
  transform(email: string, params: MailtoUrlParams = { }): string {
    this.ensureSubscriptionOnLangChange();

    const definedParams = cleanDeep(params) as Record<string, string>;
    const urlParams = stringifyUrlParams(definedParams);

    return `mailto:${email}${urlParams ? `?${urlParams}` : ''}`;
  }
}
