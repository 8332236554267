import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safeJoin'
})
export class SafeJoinPipe implements PipeTransform {
  transform(separator: string, ...pieces: (string | number | undefined | null)[]): string {
    return pieces.filter(p => p).join(separator);
  }
}
