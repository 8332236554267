import { Pipe, PipeTransform } from '@angular/core';

import { Hotel } from '@interfaces/services/api/tours-catalog.endpoint';

@Pipe({
  name: 'turpravdaLink'
})
export class TurpravdaLinkPipe implements PipeTransform {
  transform({ country: { isoCode }, resort: { otpuskCode }, names: { nameH }, hotelId }: Hotel): string {
    return `https://www.turpravda.com/${isoCode}/${otpuskCode}/${nameH}-h${hotelId}.html`;
  }
}
