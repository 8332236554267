import { Injectable } from '@angular/core';
import { Store } from 'rxjs-observable-store';

import { FiltersState } from './filters.state';

import { GlobalFilter } from '../../interfaces/offer-management';

@Injectable()
export class FiltersStore extends Store<FiltersState> {
  constructor() {
    super(new FiltersState());
  }

  patchAvailableValues(filter: GlobalFilter): void {
    this.patchState({ ...this.state.availableValues, ...filter }, 'availableValues');
  }

  patchSelectedValues(filter: Partial<GlobalFilter>): void {
    this.patchState({ ...this.state.selectedValues, ...filter }, 'selectedValues');
  }

  reloadFilter(): void {
    const { airlines, flightFromTimes, operatorIds, rooms } = this.state.selectedValues;
    const newFilter = {
      airlines: [...airlines],
      flightFromTimes: [...flightFromTimes],
      operatorIds: [...operatorIds],
      rooms: [...rooms]
    };
    this.patchState(newFilter, 'selectedValues');
  }

  clean(): void {
    this.patchState(this.emptyFilter(), 'selectedValues');
  }

  private emptyFilter(): GlobalFilter {
    return { operatorIds: [], rooms: [], airlines: [], flightFromTimes: [] };
  }
}
