import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { Lang2 } from 'package-types';

import { transformLinkWithCurrentLang } from '@helpers/language.helper';

import { Nullish } from '@interfaces/type-helpers';


@Pipe({
  name: 'currentLangLink',
  pure: false
})
export class CurrentLangLinkPipe implements PipeTransform, OnDestroy {
  private onLangChange?: Subscription;

  constructor(private translate: TranslateService, private cd: ChangeDetectorRef) { }

  transform(link: Nullish<string>): string | null {
    if (link === null || link === undefined) {
      return null;
    }

    if (!this.onLangChange) {
      this.onLangChange = this.translate.onLangChange.subscribe(() => this.cd.markForCheck());
    }

    return transformLinkWithCurrentLang(link, this.translate.currentLang as Lang2);
  }

  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
    this.onLangChange = undefined;
  }
}
