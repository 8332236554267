import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  template: '',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {
  @Input()
  @HostBinding('style.width')
  @HostBinding('style.height')
  side: string = '2em';

  @HostBinding('style.borderWidth')
  get borderWidth() {
    return `${parseFloat(this.side) / 6}${/[a-z]+/g.exec(this.side)?.[0]}`;
  }
}
