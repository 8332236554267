import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Required } from 'package-utils';

@Component({
  selector: 'app-child-label',
  templateUrl: './child-label.component.html',
  styleUrls: ['./child-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChildLabelComponent {
  @Input() @Required childAge!: number;
  @Input() showCloseIcon: boolean = false;
}
