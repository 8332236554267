import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import omit from 'lodash/omit';

import { Cashier, Lang2, ServiceName, TourOrders, Util } from 'package-types';

import { environment } from '@environment';

type CreateTourSelectionClaimParams = TourOrders.Actions.SiteCreateTourSelectionClaimArguments['params'];
type CreateTourOrderParams = TourOrders.Actions.SiteCreateTourOrderArguments['params'];
type ConfirmForeignPassportsParams = Util.Modify<TourOrders.Actions.SiteConfirmForeignPassportsArguments['params'], { orderId: string }>;

type PaymentProviderMap = {
  [Cashier.PaymentProvider.Test]: Cashier.TestProviderInitPaymentPayload,
  [Cashier.PaymentProvider.WayForPay]: Cashier.WayForPayInitPaymentRequest,
};

@Injectable({
  providedIn: 'root'
})
export class TourOrdersEndpoint {
  private readonly basePath = `${environment.apiUrl}/${ServiceName.TourOrders}`;

  constructor(private readonly http: HttpClient) { }

  createTourSelectionClaim(body: CreateTourSelectionClaimParams) {
    return this.http.post<TourOrders.Actions.SiteCreateTourSelectionClaimResult>(
      `${this.basePath}/site/tourSelectionClaim`,
      body
    );
  }

  createTourOrder(body: CreateTourOrderParams) {
    return this.http.post<TourOrders.Actions.SiteCreateTourOrderResult>(
      `${this.basePath}/site/tourOrders`,
      body
    );
  }

  initPayment<Provider extends Cashier.PaymentProvider>(orderId: string, lang: Lang2, provider: Provider, accessKey?: string) {
    return this.http.post<{ payload: PaymentProviderMap[typeof provider] }>(
      `${this.basePath}/site/tourOrders/${orderId}/payment`,
      { lang, provider, accessKey }
    );
  }

  updateContacts(orderId: string, contacts: TourOrders.Contacts, accessKey?: string) {
    return this.http.post<TourOrders.Actions.SiteUpdateContactsResult>(
      `${this.basePath}/site/tourOrders/${orderId}/contacts`,
      { contacts, accessKey }
    );
  }

  updateForeignPassport(orderId: string, passportId: string, passport: TourOrders.ForeignPassport, accessKey?: string) {
    return this.http.put<TourOrders.Actions.SiteUpdateForeignPassportResult>(
      `${this.basePath}/site/tourOrders/${orderId}/foreignPassports/${passportId}`,
      { passport, accessKey }
    );
  }

  confirmForeignPassports(params: ConfirmForeignPassportsParams) {
    return this.http.post<TourOrders.Actions.SiteConfirmForeignPassportsResult>(
      `${this.basePath}/site/tourOrders/${params.orderId}/foreignPassports/confirmation`,
      omit(params, 'orderId')
    );
  }

  updateUkrainianDocument(orderId: string, document: TourOrders.UkrainianDocument, accessKey?: string) {
    return this.http.put<TourOrders.Actions.SiteUpdateUkrainianDocumentResult>(
      `${this.basePath}/site/tourOrders/${orderId}/ukrainianDocument`,
      { document, accessKey }
    );
  }

  getOnlineCheckoutState(orderId: string, accessKey?: string) {
    const params: Record<string, string> = { };
    if (accessKey) {
      params.accessKey = accessKey;
    }

    return this.http.get<TourOrders.Actions.SiteGetOnlineCheckoutStateResult>(
      `${this.basePath}/site/tourOrders/${orderId}/checkoutState`,
      { params }
    );
  }

  createTourOrderAgreement(orderId: string, document: TourOrders.UkrainianDocument, accessKey?: string) {
    return this.http.post<TourOrders.Actions.SiteCreateTourOrderAgreementResult>(
      `${this.basePath}/site/tourOrders/${orderId}/agreement`,
      { document, accessKey }
    );
  }

  signTourOrderAgreement(orderId: string, signatureCode: string, accessKey?: string) {
    return this.http.post<TourOrders.Actions.SiteSignTourOrderAgreementResult>(
      `${this.basePath}/site/tourOrders/${orderId}/agreement/signature`,
      { signatureCode, accessKey }
    );
  }

  getTourAgreementLink(orderId: string, accessKey?: string) {
    const params: Record<string, string> = { };
    if (accessKey) {
      params.accessKey = accessKey;
    }

    return this.http.get<TourOrders.Actions.SiteGetTourAgreementLinkResult>(
      `${this.basePath}/site/tourOrders/${orderId}/agreement/link`,
      { params }
    );
  }

  getStandaloneTourOrderAgreement(orderId: string, accessKey: string) {
    return this.http.get<TourOrders.Actions.SiteGetStandaloneTourOrderAgreementResult>(
      `${this.basePath}/site/tourOrders/${orderId}/agreement/standalone`,
      { params: { accessKey } }
    );
  }

  signStandaloneTourOrderAgreement(orderId: string, signatureCode: string, accessKey: string) {
    return this.http.post<TourOrders.Actions.SiteSignStandaloneTourOrderAgreementResult>(
      `${this.basePath}/site/tourOrders/${orderId}/agreement/standalone/signature`,
      { signatureCode, accessKey }
    );
  }

  restoreOnlineCheckoutAccess(orderId: string) {
    return this.http.post<TourOrders.Actions.SiteRestoreOnlineCheckoutAccessResult>(
      `${this.basePath}/site/tourOrders/${orderId}/restoreAccess`,
      null
    );
  }

  checkOfferRelevance(offerId: string) {
    return this.http.get<TourOrders.Actions.SiteCheckOfferRelevanceResult>(
      `${this.basePath}/site/offer/${offerId}/relevance`
    );
  }

  getTourOrdersConfig() {
    return this.http.get<TourOrders.Actions.SiteGetTourOrdersConfigResult>(
      `${this.basePath}/site/tourOrders/config`
    );
  }
}
