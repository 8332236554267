import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { differenceInDays, differenceInYears, format, isBefore, isMatch, parse } from 'date-fns';

import { constants, MimeType, TourOrders } from 'package-types';

export function strictTourRange(control: AbstractControl): ValidationErrors | null {
  const { checkIn, checkOut } = control.value ?? { };

  return checkIn && checkOut && isBefore(new Date(checkIn), new Date(checkOut)) ? null : { unnaturalInterval: true };
}

export function maxFileSize(maxSize: number): ValidatorFn {
  return (control: AbstractControl) => {
    const file = control.value as File | null;
    if (!file) {
      return null;
    }

    return file.size <= maxSize ? null : { maxFileSize: true };
  };
}

export function fileType(types: MimeType[]): ValidatorFn {
  return (control: AbstractControl) => {
    const file = control.value as File | null;
    if (!file) {
      return null;
    }

    return types.includes(file.type as MimeType) ? null : { fileType: true };
  };
}

export function passportNumber(numberPath: string, countryCodePath: string): ValidatorFn {
  return (control) => {
    const countryCode = control.get(countryCodePath);
    const passportNumber = control.get(numberPath);
    if (!countryCode || !passportNumber) {
      throw new Error('Wrong paths of number or countryCode controls');
    }
    if (!Object.values(TourOrders.CountryCode).includes(countryCode.value)) {
      return { countryCode: true };
    }
    const pattern = constants.DOCUMENT_NUMBER_PATTERNS.FOREIGN_PASSPORT[countryCode.value as TourOrders.CountryCode];
    const result = new RegExp(pattern).test(passportNumber.value);

    return result ? null : { foreignPassport: true };
  };
}

export function dateOfExpiryActualAtOfferEndDay(tourEnd: Date): ValidatorFn {
  return ({ value }) => {
    if (!isDocumentDateString(value)) {
      return { invalidDate: true };
    }
    const minAllowableExpirationOverageInDays = 6 * 30;
    const overage = differenceInDays(parseAsDocumentDate(value), tourEnd);

    return overage >= minAllowableExpirationOverageInDays ? null : { minOverage: true };
  };
}

export function adult(tourStart: Date): ValidatorFn {
  return ({ value }) => {
    if (!isDocumentDateString(value)) {
      return { invalidDate: true };
    }

    return isTouristAChildOnOfferStartDay(tourStart, parseAsDocumentDate(value)) ? { adult: true } : null;
  };
}

export function child(tourStart: Date): ValidatorFn {
  return ({ value }) => {
    if (!isDocumentDateString(value)) {
      return { invalidDate: true };
    }

    return isTouristAChildOnOfferStartDay(tourStart, parseAsDocumentDate(value)) ? null : { child: true };
  };
}

/**
 * This function takes a Date object as input, discards time in ISO representation and returns
 * a string in a document date format - 'dd.MM.yyyy'.
 */
export function formatAsDocumentDate(date: Date): string {
  return date.toISOString().substr(0, 10).split('-').reverse().join('.');
}

/**
 * This function takes a string representing a date in the format 'dd.MM.yyyy'
 * and returns a Date object which represents ISO date 'dd.MM.yyyyT00:00:00.000Z'.
 */
export function parseAsDocumentDate(date: string): Date {
  if (!isDocumentDateString(date)) {
    return new Date(NaN);
  }
  const reversedDateString = date.split('.').reverse().join('-');

  return new Date(reversedDateString);
}

function isDocumentDateString(val: any): boolean {
  return typeof val === 'string' && val.length === 10 && isMatch(val, 'dd.MM.yyyy');
}

export function isTouristAChildOnOfferStartDay(tourStart: Date, dateOfBirth: Date): boolean {
  const adultAgeThreshold = 16;
  const passportOwnerAge = differenceInYears(tourStart, dateOfBirth);

  return passportOwnerAge < adultAgeThreshold;
}
