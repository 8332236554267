import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class AppMissingTranslationHandler extends MissingTranslationHandler {
  handle({ key }: MissingTranslationHandlerParams): string {
    if (key.startsWith('Services.')) {
      return this.getEmptyValue();
    }

    return this.getLastTranslationSegment(key);
  }

  private getEmptyValue() {
    return '';
  }

  private getLastTranslationSegment(key: string) {
    const translationPath = key.split('.');

    return translationPath[translationPath.length - 1];
  }
}
