<ng-container *ngIf="offer; then offerInfo else infoIsAbsent"></ng-container>
<ng-template #offerInfo let-offer>
  <div class="tour-options">
    <ul>
      <li>
        <span class="tour-options__title">
          {{ 'TourOffer.Options.TourOperator' | translate }}
        </span>
        <span class="tour-options__value">
          {{ (offer.operatorId | tourOperator | async)?.name }}
        </span>
      </li>
      <li>
        <span class="tour-options__title">
          {{ 'TourOffer.Options.DepartureDate' | translate }}
        </span>
        <span class="tour-options__value">
          {{ offer.dateRange?.from | dateFormat:'dd MMM, EEEEEE' }}
        </span>
      </li>
      <li>
        <span class="tour-options__title">
          {{ 'TourOffer.Options.Nights' | translate }}
        </span>
        <span class="tour-options__value">
          {{ offer.nights }}
        </span>
      </li>
      <li>
        <span class="tour-options__title">
          {{ 'TourOffer.Options.Food' | translate }}
        </span>
        <span class="tour-options__value">
          {{ offer.food | foodTranslationPath | translate }}
        </span>
      </li>
      <li>
        <span class="tour-options__title">
          {{ 'TourOffer.Options.Accommodation' | translate }}
        </span>
        <span class="tour-options__value">
          {{ 'TouristsComponent.AdultsCount' | translate: { count: offer.tourists.adults } }}
            {{ 'TouristsComponent.ChildrenCount' | translate: { count: offer.tourists.childrenCount } }}
        </span>
      </li>
      <li>
        <span class="tour-options__title">
          {{ 'TourOffer.Options.DepartureCity' | translate }}
        </span>
        <span class="tour-options__value">
          {{ offer.departureCityId | departureCitySelector | async | departureCityLabel }}
        </span>
      </li>
      <li>
        <span class="tour-options__title">
          {{ 'TourOffer.Options.Room' | translate }}
        </span>
        <span class="tour-options__value">
          {{ offer.room }}
        </span>
      </li>
      <li>
        <span class="tour-options__title">
          {{ 'TourOffer.Options.RoomType' | translate }}
        </span>
        <span class="tour-options__value">
          {{ offer.tourists?.accommodation }}
        </span>
      </li>
      <li>
        <span class="tour-options__title">
          {{ 'TourOffer.Options.Transport' | translate }}
        </span>
        <span class="tour-options__value">
          {{ offer.transport | transportTranslationPath | translate }}
        </span>
      </li>
      <li>
        <span class="tour-options__title">
          {{ 'TourOffer.Options.There' | translate }}
        </span>
        <span class="tour-options__value">
          <ng-container *ngIf="flights?.from; then flightInfo else infoIsAbsent"></ng-container>
        </span>
      </li>
      <li>
        <span class="tour-options__title">
          {{ 'TourOffer.Options.Back' | translate }}
        </span>
        <span class="tour-options__value">
          <ng-container *ngIf="flights?.to; then flightInfo else infoIsAbsent"></ng-container>
        </span>
      </li>
    </ul>
  </div>
</ng-template>
<ng-template #flightInfo let-info>
  {{ info.timeRange.from | flightDate }}
  ({{ info.airport.from }})
  -
  {{ info.timeRange.to | flightDate }}
  ({{ info.airport.to }})
  -
  {{ info.line }} - {{ info.code }}
</ng-template>
<ng-template #infoIsAbsent>
  {{ 'Common.InfoIsAbsent' | translate }}
</ng-template>
