import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

import { Otpusk, ToursSearch } from 'package-types';
import { Required } from 'package-utils';

import { valueAccessor } from '@helpers/angular.helper';

@Component({
  selector: 'app-search-selector',
  templateUrl: './search-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [valueAccessor(SearchSelectorComponent)]
})
export class SearchSelectorComponent implements ControlValueAccessor {
  @Input() @Required placeholder!: string;
  @Input() @Required translationBase!: string;
  @Input() items: string[] = [];

  @ViewChild('select') @Required private selectElem!: NgSelectComponent;

  public viewLabelsCount: number = 1;

  private selectedCodeValue: string[] = [];

  private onTouched: Function = () => { };
  private onChange: Function = () => { };

  constructor(private cd: ChangeDetectorRef) { }

  get selectedCode() {
    return this.selectedCodeValue;
  }

  set selectedCode(selectedCode) {
    this.selectedCodeValue = selectedCode;
    this.onTouched();
    this.onChange(selectedCode);
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  writeValue(items: (ToursSearch.Transport | ToursSearch.Food | Otpusk.MainService)[]): void {
    this.selectedCodeValue = items;
    this.cd.markForCheck();
  }
}
