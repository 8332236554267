<div class="facilities">
  <h3 class="facilities__title">
    {{ facility.title | descriptionTranslateMapper | translate }}
  </h3>
  <span class="facilities__text" [innerHTML]="facility.text"></span>
  <ul *ngIf="facility.services.length > 0">
    <ng-container *ngFor="let service of facility.services">
      <li class="service"
          *ngIf="service.code | serviceTranslateMapper | translate as translatedValue"
          [class.service_cost_pay]="service.value === 'pay'"
          [class.service_cost_free]="service.value === 'free'">
        <span>{{ translatedValue }}</span>
      </li>
    </ng-container>
  </ul>
</div>
