import { ToursCatalog, ToursSearch } from 'package-types';
import { DestinationSelectors } from 'package-angular';

export class SearchFormState {
  departureCities: ToursSearch.DepartureCity[] = [];
  destinationSelectors: DestinationSelectors = { resorts: [], hotels: [] };
  originalHotelsSelector: ToursCatalog.SelectiveHotel[] = [];
  selectors: ToursCatalog.SearchSelectors = {
    duration: [],
    food: [],
    services: [],
    transport: []
  };
}
