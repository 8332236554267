import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appBackgroundImage]'
})
export class BackgroundImageDirective {
  constructor(
    private readonly element: ElementRef,
    private readonly renderer: Renderer2
  ) { }

  @Input()
  set appBackgroundImage(link: string | undefined | null) {
    if (link) {
      this.renderer.setStyle(this.element.nativeElement, 'background-image', `url(${link})`);
    } else {
      this.renderer.removeStyle(this.element.nativeElement, 'background-image');
    }
  }
}
