import { Inject, Injectable, Injector } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { DOCUMENT } from '@angular/common';
import { Request } from 'express-serve-static-core';

import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  constructor(
    private readonly platform: PlatformService,
    private readonly injector: Injector,
    @Inject(DOCUMENT) private readonly document: Document
  ) { }

  getOrigin(): string {
    if (this.platform.isBrowser()) {
      return this.document.location.origin;
    }
    const request = this.injector.get<Request>(REQUEST);
    const forwardedProtocol = request.headers['x-forwarded-proto'];
    const forwardedHost = request.headers['x-forwarded-host'];

    return `${forwardedProtocol}://${forwardedHost}`;
  }
}
