import { BehaviorSubject, Observable, of, OperatorFunction, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ActionStatus } from '@interfaces/action-status';

export interface ApiSuccess<T> {
  error: null;
  result: T;
}

export interface ApiError {
  error: Error;
  result: null;
}

export interface ApiData<P, C> {
  params: P;
  context: C;
}

export type ApiRequestResult<R, P, C> = ApiData<P, C> & (ApiSuccess<R> | ApiError);

export class ApiLoader<T = { }, C = { }> {
  status$: Observable<ActionStatus>;
  data$: Observable<ApiData<T, C>>;

  private readonly data$$ = new ReplaySubject<ApiData<T, C>>(1);
  private readonly status$$ = new BehaviorSubject<ActionStatus>('pristine');

  constructor() {
    this.status$ = this.status$$.asObservable();
    this.data$ = this.data$$.asObservable();
  }

  fetch(params: T, context: C): void {
    this.status$$.next('inProgress');
    this.data$$.next({ params, context });
  }

  completion<R>(data: ApiData<T, C>): OperatorFunction<R, ApiRequestResult<R, T, C>> {
    return source$ => source$.pipe(
      map((res) => {
        this.status$$.next('success');

        return { ...data, error: null, result: res };
      }),
      catchError((err) => {
        this.status$$.next('error');

        return of({ ...data, error: err, result: null });
      })
    );
  }
}
