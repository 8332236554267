export enum ErrorMessage {
  DestinationRequired = 'DestinationRequired',
  InvalidPriceRange = 'InvalidPriceRange',
  ImageLoadingFailure = 'ImageLoadingFailure',
  OfficesLoadingFailure = 'OfficesLoadingFailure',
  SendingReviewFailure = 'SendingReviewFailure',
  TourOrderCreationFailed = 'TourOrderCreationFailed',
  TourSelectionClaimCreationFailed = 'TourSelectionClaimCreationFailed',
  SiteFeedbackPostingFailed = 'SiteFeedbackPostingFailed',
  Default = 'Default'
}
