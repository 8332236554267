import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-hotel-popup',
  templateUrl: './hotel-popup.component.html',
  styleUrls: ['./hotel-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotelPopupComponent implements OnInit {
  @Input() priceUAH?: string;
  @Input() hotelName: string = '';
  @Input() hotelUrl: string = '';
  @Input() imageLink?: string;

  constructor(
    private readonly modalService: NgbModal,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe(() => this.modalService.dismissAll());
  }
}
