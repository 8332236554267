<h1 class="title__h1 preserve-text-size">
  {{ title$ | async }}
</h1>
<h2 class="title__h2" *ngIf="subtitle$ | async as subtitle">
  <ng-container *ngIf="subtitle | typeof: 'string'">
    {{ subtitle }}
  </ng-container>
  <ng-container
    *ngIf="subtitle | typeof: 'object'"
    [ngTemplateOutlet]="$any(subtitle)">
  </ng-container>
</h2>
<ng-container
  *ngIf="widget$ | async as widget"
  [ngTemplateOutlet]="widget">
</ng-container>
