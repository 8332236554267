export class SearchTtl {
  private static readonly defaultTtl = 90 * 1000;
  private timeoutId?: number;

  constructor(private readonly clear: () => void, private readonly ttl = SearchTtl.defaultTtl) { }

  start(): void {
    if (!this.timeoutId) {
      this.timeoutId = this.generateTimeout();
    }
  }

  stop(): void {
    clearTimeout(this.timeoutId);
    this.clear();
    this.timeoutId = undefined;
  }

  private generateTimeout(): number {
    return setTimeout(() => this.stop(), this.ttl) as any as number;
  }
}

export class SearchTtlCollection {
  private readonly store = new Map<string, number>();
  private readonly defaultTtl = 90 * 1000;

  add(id: string, clear: () => any, ttl = this.defaultTtl): void {
    this.store.set(id, this.generateTimeout(id, clear, ttl));
  }

  delete(id: string): void {
    const timeout = this.store.get(id);
    if (timeout) {
      clearTimeout(timeout);
      this.store.delete(id);
    }
  }

  private generateTimeout(id: string, clear: () => any, ttl: number): number {
    return setTimeout(
      () => {
        this.delete(id);
        clear();
      },
      ttl
    ) as any as number;
  }
}
