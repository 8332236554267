<ng-select name="hotel"
           bindValue="id"
           bindLabel="name"
           [multiple]="true"
           [items]="resorts"
           appendTo="body"
           [virtualScroll]="true"
           [(ngModel)]="selectedResorts"
           [placeholder]="placeholder | translate">
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngFor="let item of items | slice:0:1">
      <span class="ng-value-label">{{ $any(item).name }}</span>
      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
    </div>
    <div class="ng-value" *ngIf="items.length > 1">
      <span class="ng-value-label">+{{ items.length - 1 }}</span>
    </div>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    <app-search-picker-option [name]="item.name"></app-search-picker-option>
  </ng-template>
</ng-select>
