import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { Lang2, ServiceName, ToursCatalog, ToursSearch, Util } from 'package-types';
import { cleanDeep } from 'package-utils';

import { TransferHttpService } from '@services/transfer-http.service';
import { environment } from '@environment';
import { convertPricesInHotelProposals } from '@helpers/converter.helper';

import {
  CountryOverview,
  GetPageInfo,
  HotelOverview,
  NewSiteFeedback,
  RawSiteHotTour,
  RawSiteMainPageBlock,
  ResortOverview,
  SeoLinkingResult,
  SiteStaticPage
} from '@interfaces/services/api/tours-catalog.endpoint';
import { StoreRequestStateUpdater } from '@interfaces/helpers/store-request-state-updater';
import { HotelReview, ReviewForPage } from '@interfaces/hotel-review';

type GetHotelsForPageParams = ToursCatalog.Actions.GetHotelsForPageActionArguments['params'];

type GetHotelReviewsRes = Util.Modify<ToursCatalog.Actions.SiteGetHotelReviewsResult, { reviews: HotelReview[] }>;

type GetReviewsForPage = Util.Modify<ToursCatalog.Actions.SiteGetReviewsForPageResult, { reviews: ReviewForPage[] }>;

type PostDestinationReviewRes = Util.Modify<ToursCatalog.Actions.SitePostSiteDestinationReviewResult, { id: string }>;

type PostCompanyReviewRes = Util.Modify<ToursCatalog.Actions.SitePostCompanyReviewResult, { id: string }>;

type GetCountriesOverviewRes = Util.Modify<ToursCatalog.Actions.SiteGetCountriesOverviewResult, { countries: CountryOverview[] }>;

type GetResortsOverviewRes = Util.Modify<ToursCatalog.Actions.SiteGetResortsOverviewResult, { resorts: ResortOverview[] }>;

type GetHotelsCatalogRes = Util.Modify<ToursCatalog.Actions.SiteGetHotelsCatalogActionResult, { hotels: HotelOverview[] }>;

type GetStaticPageRes = Util.Modify<ToursCatalog.Actions.SiteGetStaticPageResult, { page: SiteStaticPage }>;

type GetTourOperatorsRes = ToursCatalog.Actions.SiteGetTourOperatorsResult;

type SendFeedbackRes = ToursCatalog.Actions.SiteSendFeedbackResult;

type GetMediaArticlesRes = ToursCatalog.Actions.SiteGetMediaArticlesResult;

@Injectable({
  providedIn: 'root'
})
export class ToursCatalogEndpoint {
  private readonly basePath = `${environment.apiUrl}/${ServiceName.ToursCatalog}`;

  constructor(
    private readonly http: HttpClient,
    private readonly transferHttp: TransferHttpService
  ) { }

  getParams() {
    return this.transferHttp.get<ToursCatalog.Actions.GetSearchFormParamsResult>(`${this.basePath}/searchFormParams`);
  }

  getPageInfo(url: string): Observable<GetPageInfo> {
    return this.transferHttp.get<GetPageInfo>(`${this.basePath}/getPageInfo`, { params: { url } });
  }

  getSeoInfo(pageType: ToursCatalog.StaticPageType): Observable<ToursCatalog.Actions.GetSeoInfoResult> {
    return this.transferHttp.get<ToursCatalog.Actions.GetSeoInfoResult>(`${this.basePath}/getSeoInfo`, { params: { pageType } });
  }

  getHotelsForPage(
    params: GetHotelsForPageParams,
    updater?: StoreRequestStateUpdater
  ): Observable<ToursCatalog.Actions.GetHotelsForPageActionResult> {
    const concreteRequestUpdater = updater?.bind(null, ToursCatalog.Actions.Name.GetHotelsForPage);
    concreteRequestUpdater?.({ inProgress: true });

    return this.transferHttp.get<ToursCatalog.Actions.GetHotelsForPageActionResult>(
      `${this.basePath}/page/hotels`,
      { params } as unknown as { params: { [param: string]: string } }
    ).pipe(
      tap(res => convertPricesInHotelProposals(res.hotels)),
      tap(() => concreteRequestUpdater?.({ inProgress: false, success: true })),
      catchError((err) => {
        concreteRequestUpdater?.({ inProgress: false, error: true });

        return throwError(err);
      }),
    );
  }

  getCountryHotels(countryId: number): Observable<ToursCatalog.Actions.GetCountryHotelsResult> {
    return this.transferHttp.get<ToursCatalog.Actions.GetCountryHotelsResult>(
      `${this.basePath}/country/hotels`,
      { params: { countryId: String(countryId) } }
    );
  }

  getCountryResorts(lang: Lang2, countryId: number): Observable<ToursCatalog.Actions.GetCountryResortsResult> {
    return this.transferHttp.get<ToursCatalog.Actions.GetCountryResortsResult>(
      `${this.basePath}/country/resorts`,
      { params: { lang, countryId: String(countryId) } }
    );
  }

  getResortHotels(resortId: number): Observable<ToursCatalog.Actions.GetResortHotelsResult> {
    return this.transferHttp.get<ToursCatalog.Actions.GetResortHotelsResult>(
      `${this.basePath}/resort/hotels`,
      { params: { resortId: String(resortId) } }
    );
  }

  getMainPageBlocks() {
    return this.transferHttp.get<{ blocks: RawSiteMainPageBlock[] }>(
      `${this.basePath}/mainPageBlocks`
    );
  }

  getHotToursBlock() {
    return this.transferHttp.get<{ hotToursBlock: RawSiteHotTour[] }>(
      `${this.basePath}/hotToursBlock`
    );
  }

  getLinkingPopularCountries() {
    return this.transferHttp.get<SeoLinkingResult>(`${this.basePath}/linking/popularCountries`);
  }

  getLinkingResorts(countryId: number) {
    return this.transferHttp.get<SeoLinkingResult>(
      `${this.basePath}/linking/resorts`,
      { params: { countryId: countryId.toString() } }
    );
  }

  getLinkingHotels(resortId: number) {
    return this.transferHttp.get<SeoLinkingResult>(
      `${this.basePath}/linking/hotels`,
      { params: { resortId: resortId.toString() } }
    );
  }

  getHotelReviews(hotelId: number, from: Date, limit: number, updater?: StoreRequestStateUpdater) {
    const concreteRequestUpdater = updater?.bind(null, ToursCatalog.Actions.Name.SiteGetHotelReviews);
    concreteRequestUpdater?.({ inProgress: true });

    return this.transferHttp.get<GetHotelReviewsRes>(
      `${this.basePath}/site/hotels/${hotelId}/reviews`,
      { params: { from: from.toISOString(), limit: limit.toString() } }
    ).pipe(
      tap(() => concreteRequestUpdater?.({ inProgress: false, success: true })),
      catchError((err) => {
        concreteRequestUpdater?.({ inProgress: false, error: true });

        return throwError(err);
      }),
    );
  }

  postDestinationReview(body: ToursCatalog.SiteDestinationReviewParams) {
    return this.http.post<PostDestinationReviewRes>(`${this.basePath}/site/siteDestinationReviews`, body);
  }

  uploadDestinationReviewImage(reviewId: string, img: File, title?: string) {
    const formData = new FormData();
    formData.append('file', img, img.name);
    title && formData.append('title', title);

    return this.http.post<ToursCatalog.Actions.SiteUploadDestinationReviewImageResult>(
      `${this.basePath}/site/siteDestinationReviews/${reviewId}/images`,
      formData
    );
  }

  postCompanyReview(body: ToursCatalog.CompanyReviewParams) {
    return this.http.post<PostCompanyReviewRes>(`${this.basePath}/site/companyReviews`, body);
  }

  getReviewsForPage(skip: number, limit: number) {
    return this.transferHttp.get<GetReviewsForPage>(
      `${this.basePath}/site/reviews`,
      { params: { skip: skip.toString(), limit: limit.toString() } }
    );
  }

  getCountriesOverview() {
    return this.transferHttp.get<GetCountriesOverviewRes>(`${this.basePath}/site/countries/overview`);
  }

  getResortsOverview(countryId: number) {
    return this.transferHttp.get<GetResortsOverviewRes>(
      `${this.basePath}/site/resorts/overview`,
      { params: { countryId: countryId.toString() } }
    );
  }

  getHotelsCatalog(skip: number, limit: number, stars?: ToursSearch.Stars) {
    const params = cleanDeep<{ [key: string]: any }>({
      stars: stars?.toString(),
      skip: skip.toString(),
      limit: limit.toString()
    });

    return this.transferHttp.get<GetHotelsCatalogRes>(`${this.basePath}/site/hotels/catalog`, { params });
  }

  getStaticPage(path: string, lang: Lang2) {
    return this.transferHttp.get<GetStaticPageRes>(`${this.basePath}/site/staticPage`, { params: { path, lang } });
  }

  getTourOperators() {
    return this.transferHttp.get<GetTourOperatorsRes>(`${this.basePath}/site/tourOperators`);
  }

  createSiteFeedback(newFeedback: NewSiteFeedback) {
    return this.http.post<SendFeedbackRes>(`${this.basePath}/site/feedbacks`, newFeedback);
  }

  getMediaArticles() {
    return this.transferHttp.get<GetMediaArticlesRes>(`${this.basePath}/site/mediaArticles`);
  }
}
