import { Pipe, PipeTransform } from '@angular/core';

import { ToursCatalog } from 'package-types';

import { sortDisplayableBlocks } from '../helpers/blocks-sorting.helper';

@Pipe({
  name: 'blocksSorting'
})
export class BlocksSortingPipe implements PipeTransform {
  transform<T extends { displayOptions: ToursCatalog.DisplayOptions }>(value: T[]): T[] {
    return sortDisplayableBlocks(value);
  }
}
