<form class="search container" [formGroup]="searchFormGroup">
  <div class="search__inner">
    <div class="search__block col-zero-padding col-12 col-md-6 col-xl-3 order-1">
      <app-search-input [label]="searchComponentDictionary.LocationLabel | translate" [iconName]="'map-marker-alt'">
        <app-destination
          class="search__input"
          formControlName="destination"
          [placeholder]="searchComponentDictionary.LocationPlaceHolder | translate">
        </app-destination>
      </app-search-input>
    </div>
    <div class="search__block search__tours row row-zero-margin col-zero-padding order-1 order-md-2 order-xl-3">
      <ng-container *ngIf="resorts$ | async as resorts">
        <div *ngIf="resorts.length" class="search__block col-zero-padding col-12 col-md-6 order-0">
          <app-search-input
            [label]="searchComponentDictionary.ResortsPickerLabel | translate: { count: resorts.length}">
            <app-search-resorts-picker
              class="search__input"
              formControlName="resorts"
              [placeholder]="searchComponentDictionary.ResortsPickerPlaceHolder"
              [resorts]="resorts">
            </app-search-resorts-picker>
          </app-search-input>
        </div>
      </ng-container>
      <ng-container *ngIf="(hotels$ | async)! as hotels">
        <div *ngIf="hotels.length"
             class="search__block col-zero-padding col-12 col-md-6 order-0">
          <app-search-input
            [label]="searchComponentDictionary.HotelsPickerLabel | translate: { count: hotels.length }">
            <app-search-hotels-picker
              class="search__input"
              formControlName="hotels"
              [placeholder]="searchComponentDictionary.HotelsPickerPlaceHolder"
              [hotels]="hotels">
            </app-search-hotels-picker>
          </app-search-input>
        </div>
      </ng-container>
    </div>
    <div class="search__block col-zero-padding col-6 col-md-2 col-xl-2 order-1">
      <app-search-input [label]="searchComponentDictionary.DepartureCityLabel | translate">
        <app-departure-city class="search__input" formControlName="departureCity"></app-departure-city>
      </app-search-input>
    </div>
    <div class="search__block col-zero-padding col-6 col-md-5 col-xl-1 order-1 order-md-2">
      <app-search-input [label]="searchComponentDictionary.TourDurationLabel | translate">
        <app-tour-duration class="search__input" formControlName="duration"></app-tour-duration>
      </app-search-input>
    </div>
    <div class="search__block col-zero-padding col-12 col-md-4 col-xl-3 order-1">
      <app-search-input [label]="searchComponentDictionary.DateRangeLabel | translate" [iconName]="'calendar-alt'">
        <app-date-range
          class="search__input"
          formControlName="dates"
          [minDate]="minAvailableSearchDate"
          [maxDate]="maxAvailableSearchDate"
          [maxRangeLength]="availableDaysRange">
        </app-date-range>
      </app-search-input>
    </div>
    <div class="search__block col-zero-padding col-12 col-md-5 col-xl-2 order-2">
      <app-search-input [label]="searchComponentDictionary.TouristsLabel | translate">
        <app-tourists class="search__input" formControlName="tourists"></app-tourists>
      </app-search-input>
    </div>
    <div class="search__block col-zero-padding col-12 col-md-2 col-xl-1 order-3 order-md-2">
      <button
        class="search__btn btn btn-accent button_right-border_md"
        [disabled]="!searchFormGroup.valid"
        (click)="searchTour()">
        {{ searchComponentDictionary.SearchButton | translate }}
      </button>
    </div>
    <app-search-additional-options
      class="search__additional container container-zero-padding col-12 order-2 order-xl-3"
      [additionalOptionsForm]="searchFormGroup"
      [@toggleAdditionalOptions]="isOptionsOpen ? 'open' : 'closed'"
      [foods]="(foods$ | async)!"
      [services]="(services$ | async)!"
      [transport]="(transport$ | async)!">
    </app-search-additional-options>
    <div class="search__block col-zero-padding col-12 col-md-5 col-lg-4 offset-lg-4 offset-md-2 order-2 order-md-3 order-xl-5">
      <button type="button" class="search__btn btn btn-secondary" (click)="toggleMenu()">
        {{ searchComponentDictionary.OpenAdditionalSearchOptionsButton | translate | uppercase }}
        <fa-icon [icon]="['fas', 'caret-up']"></fa-icon>
      </button>
    </div>
    <div class="search__block col-zero-padding col-12 col-md-5 col-lg-4 order-4 order-md-2 order-xl-4">
      <a class="search__btn btn btn-secondary"
         [routerLink]="'/offer-me-tour' | currentLangLink">
        {{ searchComponentDictionary.OfferMeTourButton | translate | uppercase }}
      </a>
    </div>
  </div>
</form>
