import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';

import { GlobalLayoutStore } from '@services/store/global-layout.store';

@Component({
  selector: 'app-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderTitleComponent {
  title$: Observable<string | undefined> = this.layoutStore.onChanges('header', 'title');
  subtitle$: Observable<TemplateRef<any> | string | undefined> = this.layoutStore.onChanges('header', 'subtitle');
  widget$: Observable<TemplateRef<any> | undefined> = this.layoutStore.onChanges('header', 'widget');

  constructor(private layoutStore: GlobalLayoutStore) { }
}
