import { Directive, OnInit, Self, TemplateRef } from '@angular/core';

import { GlobalLayoutStore } from '@services/store/global-layout.store';

@Directive({
  selector: '[appHeaderSubtitle]'
})
export class HeaderSubtitleDirective implements OnInit {
  constructor(@Self() private templateRef: TemplateRef<any>, private globalLayoutStore: GlobalLayoutStore) { }

  ngOnInit(): void {
    this.globalLayoutStore.setSubtitle(this.templateRef);
  }
}
