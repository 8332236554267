import { Injectable } from '@angular/core';
import { Store } from 'rxjs-observable-store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import flatten from 'lodash/flatten';
import { map, switchMap } from 'rxjs/operators';

import { Lang2, ToursCatalog, ToursSearch } from 'package-types';
import { cleanDeep } from 'package-utils';

import { ToursCatalogEndpoint } from '@services/api/tours-catalog.endpoint';
import { HotelsPageState } from './hotels-page.state';
import { getStoreRequestStateUpdater } from '@helpers/endpoint.helper';

import { StoreRequestStateUpdater } from '@interfaces/helpers/store-request-state-updater';

export type PageParams = Omit<ToursCatalog.Actions.GetHotelsForPageActionArguments['params'], 'page' | 'lang'>;

@UntilDestroy()
@Injectable()
export class HotelsPageStore extends Store<HotelsPageState> {
  private searchParams: PageParams = { };
  private readonly storeRequestStateUpdater: StoreRequestStateUpdater;

  constructor(
    private readonly toursCatalog: ToursCatalogEndpoint,
    private readonly translate: TranslateService
  ) {
    super(new HotelsPageState());
    this.storeRequestStateUpdater = getStoreRequestStateUpdater(this);
  }

  init(params: PageParams = { }): void {
    this.searchParams = cleanDeep(params);
    this.loadNextPage();
    this.startPagesReloadListener();
  }

  loadNextPage(): void {
    const nextPage = this.state.page + 1;
    const lang = this.translate.currentLang as Lang2;
    this.loadPage(nextPage, lang, this.storeRequestStateUpdater)
      .pipe(untilDestroyed(this))
      .subscribe(hotels => this.setState({
        ...this.state,
        page: nextPage,
        hasAvailableHotels: Boolean(hotels.length),
        hotels: [...this.state.hotels, ...hotels]
      }));
  }

  private startPagesReloadListener() {
    this.translate.onLangChange
      .pipe(
        map(({ lang }) => this.preparePagesBunch(lang as Lang2)),
        switchMap((requests: Observable<ToursSearch.HotelProposal[]>[]) => forkJoin(requests)),
        untilDestroyed(this)
      ).subscribe(chunks => this.patchState(flatten(chunks), 'hotels'));
  }

  private preparePagesBunch(lang: Lang2): Observable<ToursSearch.HotelProposal[]>[] {
    return Array(this.state.page)
      .fill(null)
      .map((_, idx) => this.loadPage(idx + 1, lang));
  }

  private loadPage(page: number, lang: Lang2, updater?: StoreRequestStateUpdater): Observable<ToursSearch.HotelProposal[]> {
    return this.toursCatalog.getHotelsForPage({ page, lang, ...this.searchParams }, updater)
      .pipe(map(({ hotels }) => hotels));
  }
}
