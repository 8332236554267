<div class="offer-details">
  <ng-container *ngIf="selectedOffer; then card else notSelectedOffer"></ng-container>
</div>
<ng-template #card>
  <ng-container *ngIf="selectedOffer">
    <div class="details-card">
      <div class="details-card__content">
        <img class="details-card__image"
             *ngIf="selectedOffer.operatorId | tourOperator | async as tourOperator"
             [src]="tourOperator.logo"
             [alt]="tourOperator.name">
        <div class="booking-card">
          <div class="booking-card__price">
            {{ selectedOffer.priceUAH.toNumber() | currency: 'UAH':'symbol-narrow': '1.0-0' }}
          </div>
          <div class="booking-card__tourists">
            {{
            'SelectedOffersPanel.Tourists'| translate: {
              adults: selectedOffer.tourists.adults,
              children: selectedOffer.tourists?.childrenCount || 0
            }
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="options">
      <app-offer-option
        class="options__item"
        iconSrc="/assets/icons/nights.svg"
        [iconAlt]="'TourOffer.Options.Dates' | translate"
        [mainText]="selectedOffer.dateRange?.from | dateFormat:'dd.LL (EEEEEE)' | join:' - ':(selectedOffer.dateRange?.to | dateFormat:'dd.LL (EEEEEE)')"
        [minorText]="'HotelOverviewPage.Nights' | translate: { nights: selectedOffer.nights }">
      </app-offer-option>
      <app-offer-option
        class="options__item"
        iconSrc="/assets/icons/transport.svg"
        [iconAlt]="'TourOffer.Options.Transport' | translate"
        [mainText]="(selectedOffer.transport | transportTranslationPath | translate) + ' ' + (selectedOffer.departureCityId | departureCitySelector | async | departureCityLabel)"
        [minorText]="'TourOffer.Options.Transport' | translate">
      </app-offer-option>
      <app-offer-option
        class="options__item"
        iconSrc="/assets/icons/room.svg"
        [iconAlt]="'TourOffer.Options.Room' | translate"
        [mainText]="selectedOffer.room"
        [minorText]="'TourOffer.Options.Room' | translate">
      </app-offer-option>
      <app-offer-option
        class="options__item"
        iconSrc="/assets/icons/food.svg"
        [iconAlt]="'TourOffer.Options.Food' | translate"
        [mainText]="selectedOffer.food | foodTranslationPath | translate"
        [minorText]="'TourOffer.Options.Food' | translate">
      </app-offer-option>
    </div>
    <ng-container *ngIf="selectedOffer.tourOptions | knownTourOptions as options">
      <div class="additional-options" *ngIf="options.length">
        <span class="additional-options__title">{{ 'Common.Included' | translate }}</span>
        <span>: </span>
        <ng-container *ngFor="let option of options">
          <span class="additional-options__item">{{ 'Common.' + (option | titlecase) | translate }}</span>
        </ng-container>
      </div>
    </ng-container>
    <button class="btn btn-accent book-button" (click)="bookSelectedOffer()">
      {{ 'SelectedOffersPanel.BookButton' | translate }}
    </button>
  </ng-container>
</ng-template>
<ng-template #notSelectedOffer>{{ 'SelectedOffersPanel.NotSelectedOffer' | translate }}</ng-template>
