<ngx-json-ld *ngIf="hotel$ | async as hotel" [json]="hotel | hotelJsonLd:(headImage$ | async)"></ngx-json-ld>
<div class="app__block app__block_color_white">
  <div class="container">
    <app-hotel-availability></app-hotel-availability>
  </div>
</div>
<div class="app__block" *ngIf="rating$ | async as rating">
  <div class="container">
    <app-overview-rating [rating]="rating"></app-overview-rating>
  </div>
</div>
<div class="app__block app__block_color_white">
  <div class="container">
    <app-hotel-info></app-hotel-info>
  </div>
</div>
<div class="app__block">
  <div class="container">
    <div class="row">
      <div class="col-12 col-xl-9">
        <app-hotel-reviews [reviews]="(reviews$ | async)!" [hotel]="hotel$ | async"></app-hotel-reviews>
      </div>
<!--      <div class="col-12 col-xl-3">-->
<!--        <app-subscription></app-subscription>-->
<!--      </div>-->
    </div>
  </div>
</div>
<app-seo-linking></app-seo-linking>
<ng-template appHeaderSubtitle>
  <ng-container *ngIf="hotelPageInfo$ | async as info">
    <app-subtitle-link [names]="info.country.names" [url]="info.countryUrl"></app-subtitle-link>
    <span>, </span>
    <app-subtitle-link [names]="info.resort.names" [url]="info.resortUrl"></app-subtitle-link>
  </ng-container>
</ng-template>
