<div class="thematic-sections-menu" *ngIf="items.length">
  <div class="thematic-sections-menu__header">
    <a class="thematic-sections-menu__link"
       *ngIf="items[0] as head"
       [routerLink]="head.link | currentLangLink"
       appActiveUrlPath="thematic-sections-menu__link_selected"
       [urlMatcher]="head.urlMatcher"
       [cutLanguagePrefix]="true">
      {{ head.title }}
    </a>
  </div>
  <div class="thematic-sections-menu__hamburger">
    <app-hamburger (click)="toggleMenu()" [isOpen]="isOpen"></app-hamburger>
  </div>
  <app-collapsable-panel [isOpen]="isOpen" class="thematic-sections-menu__body">
    <ul class="thematic-sections-menu__list">
      <li *ngFor="let item of items | slice:1">
        <a class="thematic-sections-menu__link"
           [routerLink]="item.link | currentLangLink"
           appActiveUrlPath="thematic-sections-menu__link_selected"
           [urlMatcher]="item.urlMatcher"
           [cutLanguagePrefix]="true">
          {{ item.title }}
        </a>
      </li>
    </ul>
  </app-collapsable-panel>
</div>
