import { TableHeader } from './table-header';

export class HeaderFactory<C, R> {
  getColHeader(items: C[]): TableHeader<C> {
    return new TableHeader<C>(items);
  }

  getRowHeader(items: R[]): TableHeader<R> {
    return new TableHeader<R>(items);
  }
}
