<ng-container *ngIf="formGroup.get('file')?.errors; then errorsLayout else imageForm"></ng-container>
<ng-template [formGroup]="formGroup" #imageForm>
  <app-image-preview formControlName="file"></app-image-preview>
  <app-form-field>
    <input
      appFormInput
      type="text"
      formControlName="title"
      [placeholder]="'Reviews.PhotoTitle' | translate">
  </app-form-field>
</ng-template>
<ng-template #errorsLayout>
  <ngb-alert *ngIf="formGroup.get('file')?.errors as errors" [dismissible]="false" type="danger">
    <h4>{{ 'ValidationErrors.ImageLoadingFailed' | translate }}</h4>
    <div *ngIf="errors.maxFileSize">
      {{ 'ValidationErrors.FileSizeLimitExceeded' | translate:{
      limitMb: config.imageUpload.maxSizeInBytes
        | convertBytes:'MB'
        | number:'0.0-2'
    } }}
    </div>
    <div *ngIf="errors.fileType">
      {{ 'ValidationErrors.WrongFileType' | translate: {
      types: config.imageUpload.imageTypes | mimeTypesExtensions
    } }}
    </div>
  </ngb-alert>
</ng-template>
<ngb-alert *ngIf="uploadStatus === 'success'" class="upload-status" [dismissible]="false" type="success">
  <fa-icon [icon]="['fas', 'check']" size="2x"></fa-icon>
</ngb-alert>
<ngb-alert *ngIf="uploadStatus === 'inProgress'" class="upload-status" [dismissible]="false" type="info">
  <fa-icon [icon]="['fas', 'spinner']" size="2x" [spin]="true"></fa-icon>
</ngb-alert>
<ngb-alert *ngIf="uploadStatus === 'error'" class="upload-status" [dismissible]="false" type="danger">
  <fa-icon [icon]="['fas', 'times']" size="2x"></fa-icon>
</ngb-alert>
<button type="button" class="btn-close btn btn-danger" [disabled]="uploadStatus === 'inProgress'" (click)="remove.emit()">
  <fa-icon [icon]="['fas', 'times']"></fa-icon>
</button>
