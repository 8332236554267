import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeRu from '@angular/common/locales/ru';
import localeUk from '@angular/common/locales/uk';
import { APP_ID, ErrorHandler, Inject, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MESSAGE_FORMAT_CONFIG } from 'ngx-translate-messageformat-compiler';
import { NotifierModule } from 'angular-notifier';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';

import { API_URL, TRANSLATION_INFO } from 'package-angular';

import { environment } from '@environment';
import seoSettings from '@constants/seo';
import { IconsModule } from './icons.module';
import { AppComponent } from './app.component';
import { SEO_SETTINGS } from '@services/seo.service';
import { CustomErrorHandler } from '@services/custom-error-handler';
import { SharedModule } from '@modules/tours/shared/shared.module';
import { PlatformService } from '@services/platform.service';
import { StaticInjectorModule } from '@classes/decorators/static-injector.module';
import { AppConfigModule, config } from './app-config.module';
import { WINDOW, windowFactory } from '@services/window.service';
import { AppRoutingModule } from './app-routing.module';
import { SessionInterceptor } from '@services/http-interceptors/session.interceptor';
import { ApiInterceptor } from '@services/http-interceptors/api.interceptor';
import { GtagModule } from '@modules/gtag/gtag.module';

registerLocaleData(localeUk);
registerLocaleData(localeRu);


@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    StaticInjectorModule,
    BrowserModule.withServerTransition({ appId: 'web-app' }),
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NotifierModule,
    IconsModule,
    SharedModule,
    AppConfigModule,
    NgxJsonLdModule,
    InlineSVGModule.forRoot({ bypassHttpClientInterceptorChain: true }),
    GtagModule.forRoot({ trackingId: environment.gtagTrackingId, eventTimeout: 3000 }),
  ],
  providers: [
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: LOCALE_ID, useValue: config.languages.primary },
    { provide: TRANSLATION_INFO, useExisting: TranslateService },
    { provide: API_URL, useValue: environment.apiUrl },
    { provide: SEO_SETTINGS, useValue: seoSettings },
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: {
        locales: [config.languages.primary, config.languages.secondary]
      }
    },
    { provide: WINDOW, useFactory: windowFactory, deps: [PlatformService] },
    { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }
  ]
})

export class AppModule {
  constructor(platformService: PlatformService, @Inject(APP_ID) appId: string) {
    const platform = platformService.isBrowser() ? 'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
