import uniqBy from 'lodash/uniqBy';

import { ToursSearch } from 'package-types';

import { filterOffers } from '../helpers/offer-options.helper';
import { sortOffersInAscendingOrderOfPrice } from '../../available-offers/helpers/offers.helper';

import { GlobalFilter } from '../interfaces/offer-management';

export class OffersProcessor {
  constructor(private offers: ToursSearch.Offer[]) { }

  sortOffersInAscendingOrderOfPrice(): OffersProcessor {
    this.offers = sortOffersInAscendingOrderOfPrice(this.offers);

    return this;
  }

  filter(filter: GlobalFilter): OffersProcessor {
    this.offers = filterOffers(this.offers, filter);

    return this;
  }

  uniqBy(field: string): OffersProcessor {
    this.offers = uniqBy(this.offers, field);

    return this;
  }

  join(offers: ToursSearch.Offer[]): OffersProcessor {
    this.offers = [...this.offers, ...offers];

    return this;
  }

  get(): ToursSearch.Offer[] {
    return this.offers;
  }
}
