import { ChangeDetectionStrategy, Component } from '@angular/core';
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { forkJoin, Observable, of, OperatorFunction } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { Lang2, ToursCatalog } from 'package-types';

import { GlobalLayoutStore } from '@services/store/global-layout.store';
import { mainMenu } from '@constants/site-menu';
import { InformationStore } from '@services/store/information.store';
import { ClientSectionLinks, SectionLinks, ServerSectionLinks, ThematicSectionLink } from '@services/store/global-layout.state';
import { SiteMenuSection } from '@constants/site-menu-section';
import { SpecialLayout, specialLayouts } from './special-layout';
import { wrapInMicrotask } from '@helpers/rx.helper';

import { LayoutElementsVisibility } from '@classes/decorators/interfaces';

@Component({
  selector: 'app-desktop-layout',
  templateUrl: './desktop-layout.component.html',
  styleUrls: ['./desktop-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesktopLayoutComponent {
  public readonly showThematicSectionsMenu$: Observable<boolean> = this.visibility('thematicSectionsMenu');
  public readonly showBreadcrumbs$: Observable<boolean> = this.visibility('breadcrumbs');
  public readonly showSearchForm$: Observable<boolean> = this.visibility('searchForm');
  public readonly activeMenuItem$: Observable<SiteMenuSection> = this.globalLayoutStore.onChanges('activeSiteMenuSection')
    .pipe(distinctUntilChanged(), wrapInMicrotask());
  public readonly backgroundImageLink$: Observable<string | undefined> = this.globalLayoutStore.onChanges('header', 'backgroundImageLink')
    .pipe(distinctUntilChanged(), wrapInMicrotask());
  public readonly specialLayout$: Observable<SpecialLayout | undefined> = this.globalLayoutStore.onChanges('specialLayout')
    .pipe(distinctUntilChanged(), wrapInMicrotask());
  public readonly breadcrumbs$ = this.onBreadcrumbsChange();
  public readonly thematicSectionsMenu$ = this.onMenuChange();
  public readonly siteMenu = mainMenu;

  constructor(
    private readonly globalLayoutStore: GlobalLayoutStore,
    private readonly information: InformationStore,
    private readonly translate: TranslateService
  ) { }

  private visibility(element: keyof LayoutElementsVisibility): Observable<boolean> {
    return this.globalLayoutStore.state$.pipe(
      map(({ specialLayout, elementsVisibility }): boolean => {
        switch (specialLayout) {
          case SpecialLayout.Article:
            return specialLayouts[SpecialLayout.Article][element];
          default:
            return elementsVisibility[element];
        }
      }),
      distinctUntilChanged(),
      wrapInMicrotask()
    );
  }

  private onMenuChange(): Observable<ToursCatalog.SiteSectionLink[]> {
    return this.globalLayoutStore.onChanges('thematicSectionsMenu')
      .pipe(this.transformThematicSections(), wrapInMicrotask());
  }

  private transformThematicSections(): OperatorFunction<SectionLinks, ThematicSectionLink[]> {
    return input$ => input$.pipe(
      switchMap(links => Array.isArray(links) ? this.sectionFromClientLinks(links) : this.fromServerLinks(links))
    );
  }

  private sectionFromClientLinks(links: ClientSectionLinks): Observable<ThematicSectionLink[]> {
    return this.fromClientLinks(links)
      .pipe(
        map(result => links.map(({ urlMatcher }, idx) => ({ ...result[idx], urlMatcher })))
      );
  }

  private onBreadcrumbsChange(): Observable<ToursCatalog.SiteSectionLink[]> {
    return this.globalLayoutStore.onChanges('breadcrumbs')
      .pipe(this.transformSectionsLinksToSiteSectionLinks(), wrapInMicrotask());
  }

  private transformSectionsLinksToSiteSectionLinks(): OperatorFunction<SectionLinks, ToursCatalog.SiteSectionLink[]> {
    return input$ => input$.pipe(
      switchMap(links => Array.isArray(links) ? this.fromClientLinks(links) : this.fromServerLinks(links))
    );
  }

  private fromClientLinks(links: ClientSectionLinks): Observable<ToursCatalog.SiteSectionLink[]> {
    if (!links.length) {
      return of([]);
    }

    return this.translate.onLangChange
      .pipe(
        startWith({ lang: this.translate.currentLang }),
        switchMap(({ lang }) => forkJoin(
          links.map(val => forkJoin({
            title: 'translation' in val
              ? this.translate.get(val.translation.path, val.translation.params)
              : of(val.title),
            link: of(val.link)
          }))
        ))
      );
  }

  private fromServerLinks(links: ServerSectionLinks): Observable<ToursCatalog.SiteSectionLink[]> {
    return this.translate.onLangChange
      .pipe(
        startWith({ lang: this.translate.currentLang }),
        map(({ lang }) => links[lang as Lang2])
      );
  }
}
