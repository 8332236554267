<app-cell-skeleton [type]="type$ | async" [mainInfo]="offer | offerPrice">
  <ng-container [ngSwitch]="layout$ | async">
    <app-additional-offer-info *ngSwitchCase="CellLayout.AdditionalInfo">
      <span *ngIf="offer?.food as food">
        {{ food | foodTranslationPath | translate }}
      </span>
      <span *ngIf="offer?.room as room">{{ room }}</span>
    </app-additional-offer-info>
    <span *ngSwitchCase="CellLayout.EmptyOffer">-</span>
    <span *ngSwitchCase="CellLayout.PossibleLoading">
      <fa-icon [icon]="['far', 'plus-square']"></fa-icon>
    </span>
    <span *ngSwitchCase="CellLayout.Loading">
      <img class="spinner" src="/assets/icons/loading.svg" alt="Loading icon">
    </span>
  </ng-container>
</app-cell-skeleton>
