import { ChangeDetectionStrategy, Component } from '@angular/core';

import { PriceDisplayOptionsComponent } from '../price-display-options/price-display-options.component';

@Component({
  selector: 'app-price-display-options-group',
  templateUrl: 'price-display-options-group.component.html',
  styleUrls: ['../price-display-options/price-display-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceDisplayOptionsGroupComponent extends PriceDisplayOptionsComponent { }
