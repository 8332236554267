import { Store } from 'rxjs-observable-store';
import { filter, map, startWith, switchMap, take } from 'rxjs/operators';
import { combineLatest, forkJoin, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Lang2, ToursCatalog, Util } from 'package-types';

import { SeoLinkingState } from './seo-linking.state';
import { ToursCatalogEndpoint } from '@services/api/tours-catalog.endpoint';
import { InformationStore } from '@services/store/information.store';
import { handleSeoLinking } from '@helpers/seo-linking.helper';

import { PageInfo } from '@interfaces/services/api/tours-catalog.endpoint';

@UntilDestroy()
@Injectable()
export class SeoLinkingStore extends Store<SeoLinkingState> {
  constructor(
    private readonly toursCatalog: ToursCatalogEndpoint,
    private readonly informationStore: InformationStore,
    private readonly translate: TranslateService
  ) {
    super(new SeoLinkingState());
  }

  init(display: { popular: boolean, resorts: boolean, hotels: boolean }): void {
    this.informationStore.onChanges('pageInfo').pipe(
      filter((info): info is PageInfo => info !== undefined),
      take(1),
      map(info => this.createIds(info)),
      switchMap(({ countryId, resortId }) => forkJoin({
        popularCountries: display.popular ? this.toursCatalog.getLinkingPopularCountries().pipe(handleSeoLinking()) : of([]),
        resorts: countryId && display.resorts ? this.toursCatalog.getLinkingResorts(countryId).pipe(handleSeoLinking()) : of([]),
        hotels: resortId && display.hotels ? this.toursCatalog.getLinkingHotels(resortId).pipe(handleSeoLinking()) : of([])
      })),
      untilDestroyed(this)
    ).subscribe(linking => this.setState({ ...this.state, ...linking }));

    combineLatest([
      this.translate.onLangChange.pipe(
        map(({ lang }) => lang),
        startWith(this.translate.currentLang)
      ),
      this.informationStore.onChanges('pageInfo').pipe(
        filter((info): info is PageInfo => info !== undefined)
      )
    ])
      .pipe(
        map(([lang, info]) => this.createOpts(lang as Lang2, info)),
        switchMap(({ countryName = '', resortName = '' }) => forkJoin({
          popular: this.translate.get('SeoLinking.Popular'),
          resorts: this.translate.get('SeoLinking.Resorts', { country: countryName }),
          hotels: this.translate.get('SeoLinking.Hotels', { country: countryName, resort: resortName })
        })),
        untilDestroyed(this)
      )
      .subscribe(titles => this.patchState(titles, 'titles'));
  }

  private createIds(info: PageInfo): { countryId?: number, resortId?: number } {
    switch (info.type) {
      case ToursCatalog.PageType.Country:
        return { countryId: info.country.otpuskId };
      case ToursCatalog.PageType.Resort:
        return { countryId: info.resort.countryId, resortId: info.resort.otpuskId };
      case ToursCatalog.PageType.Hotel:
        return { countryId: info.hotel.countryId, resortId: info.hotel.resortId };
      default:
        return { };
    }
  }

  private createOpts(lang: Lang2, info: PageInfo): { countryName?: string, resortName?: string } {
    switch (info.type) {
      case ToursCatalog.PageType.Country:
        return { countryName: info.country.names[lang]?.name };
      case ToursCatalog.PageType.Resort:
        return { countryName: info.resort.countryNames[lang]?.name, resortName: info.resort.names[lang]?.name };
      case ToursCatalog.PageType.Hotel:
        return { countryName: info.hotel.country.names?.[lang]?.name, resortName: info.hotel.resort.names?.[lang]?.name };
      default:
        return { };
    }
  }
}
