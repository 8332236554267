import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { CheckboxLabelTemplateDirective } from '../../directives/checkbox-label-template.directive';

import { FilterCheckbox, GlobalFilter } from '../../interfaces/offer-management';

@Component({
  selector: 'app-checkbox-filter',
  templateUrl: './checkbox-filter.component.html',
  styleUrls: ['./checkbox-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxFilterComponent<T extends GlobalFilter[keyof GlobalFilter]> {
  checkboxes: FilterCheckbox<T>[] = [];
  @ContentChild(CheckboxLabelTemplateDirective, { read: TemplateRef, static: true }) labelTemplate!: TemplateRef<any>;

  @Output() availableValues = new EventEmitter<T[]>();

  @Input() set params(params: T[]) {
    this.checkboxes = this.createCheckboxes(params);
  }

  @Input() set selectedValues(values: T[]) {
    this.checkboxes = this.checkboxes.map((c) => {
      const foundItem = values.find(v => v === c.value);

      return { ...c, checked: foundItem != null };
    });
  }

  toggle(operator: FilterCheckbox<T>): void {
    operator.checked = !operator.checked;

    const selectedValues = this.checkboxes
      .filter(c => c.checked)
      .map(c => c.value);

    this.availableValues.emit(selectedValues);
  }

  private createCheckboxes(params: T[]): FilterCheckbox<T>[] {
    return params.map((id) => {
      const checkbox = this.findCheckbox(id);

      return checkbox || { value: id, checked: false };
    });
  }

  private findCheckbox(id: T) {
    return this.checkboxes.find(c => c.value === id);
  }
}
