import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, Input, QueryList, TemplateRef } from '@angular/core';

import { GridContentTemplateDirective } from './grid-content-template.directive';

export interface Grid {
  base: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

@Component({
  selector: 'app-locations-grid',
  templateUrl: './locations-grid.component.html',
  styleUrls: ['./locations-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationsGridComponent {
  gridClassList = '';

  @ContentChildren(GridContentTemplateDirective, { read: TemplateRef }) cards!: QueryList<TemplateRef<any>>;

  constructor(private readonly cd: ChangeDetectorRef) { }

  @Input() set grid(grid: Grid | undefined) {
    this.gridClassList = Object.entries(grid ?? { base: 12 })
      .map(([breakpoint, columns]) => breakpoint === 'base' ? `col-${columns}` : `col-${breakpoint}-${columns}`)
      .join(' ');
    this.cd.markForCheck();
  }
}
