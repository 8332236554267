<ng-container *ngIf="isMapVisible; then map else overlay"></ng-container>
<ng-template #map>
  <div
    class="map"
    leaflet
    [leafletOptions]="options">
  </div>
</ng-template>
<ng-template #overlay>
  <div class="overlay" (click)="showMap()">
    <fa-icon class="overlay__icon" [icon]="['fas', 'map']"></fa-icon>
    <span class="overlay__label">{{ 'Common.ShowOnMap' | translate }}</span>
  </div>
</ng-template>
