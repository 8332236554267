import { Table } from './table';
import { TableCell } from './table-cell';

export abstract class TableFiller<T, C, R> {
  protected constructor(private table: Table<T, C, R>) { }

  fill(items: T[]): Table<T, C, R> {
    items.forEach((item: T) => {
      const colIndex: number = this.findColIndex(item);
      const rowIndex: number = this.findRowIndex(item);

      this.fillTheCell(item, colIndex, rowIndex);
    });

    return this.table;
  }

  protected fillTheCell(item: T, x: number, y: number): void {
    const cell = this.table.body.getCell(x, y);
    if (cell !== null && !cell.data) {
      cell.data = item;
    }
  }

  protected findColIndex(item: T): number {
    return this.table.colHeader.findIndex(this.colHeaderValue(item));
  }

  protected findRowIndex(item: T): number {
    return this.table.rowHeader.findIndex(this.rowHeaderValue(item));
  }

  protected abstract colHeaderValue(item: T): C;

  protected abstract rowHeaderValue(item: T): R;
}
