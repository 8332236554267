import { ToursCatalog } from 'package-types';

import SelectiveHotel = ToursCatalog.SelectiveHotel;

export function filterSelectiveHotelsBySelectedResortIds(hotels: SelectiveHotel[], resortIds: number[]): SelectiveHotel[] {
  if (!resortIds.length) {
    return hotels;
  }

  return hotels.filter(hotel => resortIds.includes(hotel.resortId));
}

export function filterSelectedHotelIdsByHotels(hotelIds: number[], hotels: SelectiveHotel[]): number[] {
  if (!hotels.length) {
    return hotelIds;
  }

  return hotelIds.filter(id => hotels.find(hotel => hotel.id === id));
}
