import { Lang2 } from 'package-types';

export default {
  [Lang2.Ru]: {
    month: [
      'январь',
      'февраль',
      'март',
      'апрель',
      'май',
      'июнь',
      'июль',
      'август',
      'сентябрь',
      'октябрь',
      'ноябрь',
      'декабрь'
    ],
    weekday: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']
  },
  [Lang2.Uk]: {
    month: [
      'січень',
      'лютий',
      'березень',
      'квітень',
      'травень',
      'червень',
      'липень',
      'серпень',
      'вересень',
      'жовтень',
      'листопад',
      'грудень'
    ],
    weekday: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'нд']
  }
} as const;
