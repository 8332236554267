import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Otpusk, ToursSearch } from 'package-types';
import { Required } from 'package-utils';

import { searchComponentDictionary, searchSelectorsTranslations } from '@constants/translation-dictionaries';

@Component({
  selector: 'app-search-additional-options',
  templateUrl: './search-additional-options.component.html',
  styleUrls: ['./search-additional-options.component.scss']
})
export class SearchAdditionalOptionsComponent {
  @Input() @Required additionalOptionsForm!: FormGroup;
  @Input() @Required foods!: ToursSearch.Food[];
  @Input() @Required transport!: ToursSearch.Transport[];
  @Input() @Required services!: Otpusk.MainService[];

  searchComponentDictionary = searchComponentDictionary;
  searchSelectorsTranslations = searchSelectorsTranslations;
}
