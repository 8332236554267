<div class="offer-me-tour-card">
  <div class="offer-me-tour-card__text">
    <h2 class="offer-me-tour-card__heading">{{ 'OfferMeTour.Title' | translate }}</h2>
    <p class="offer-me-tour-card__message">{{ 'OfferMeTour.Message' | translate }}</p>
  </div>
  <a class="preserve-text-size btn btn-accent offer-me-tour-card__link"
     [routerLink]="'/offer-me-tour' | currentLangLink">
    {{ 'OfferMeTour.Button' | translate }}
  </a>
</div>
