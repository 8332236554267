import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { filter, mergeMap } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Lang2 } from 'package-types';

import { ToursCatalogEndpoint } from './api/tours-catalog.endpoint';
import { extractLangFromUrl, transformUrlByLang } from '@helpers/language.helper';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class LanguageManagerService {
  constructor(
    private toursCatalogService: ToursCatalogEndpoint,
    private router: Router,
    private translate: TranslateService
  ) { }

  setLang(lang: Lang2): void {
    this.updateRoute(lang);
  }

  init(): void {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      mergeMap(event => this.applyLangFromUrl(event.url)),
      untilDestroyed(this)
    ).subscribe();
  }

  private applyLangFromUrl(url: string): Observable<any> {
    return this.translate.use(extractLangFromUrl(url));
  }

  private updateRoute(langId: Lang2): void {
    const urlElements: string[] = transformUrlByLang(this.router.url, langId);
    this.router
      .navigate(urlElements, { queryParamsHandling: 'merge', preserveFragment: false })
      .catch(err => console.error(`Failed to change language to ${langId} `, err));
  }
}
