import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ToursCatalog, Util } from 'package-types';

@Component({
  selector: 'app-subtitle-link',
  templateUrl: './subtitle-link.component.html',
  styleUrls: ['./subtitle-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubtitleLinkComponent {
  @Input() url: string | null = null;
  @Input() names: Util.Translation<ToursCatalog.Name> | null = null;
}
