import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Nullish } from '@interfaces/type-helpers';

@Component({
  selector: 'app-location-card',
  templateUrl: './location-card.component.html',
  styleUrls: ['./location-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationCardComponent {
  @Input() title: Nullish<string> = '';
  @Input() infoList: string[] = [];
  @Input() locationLink: Nullish<string> = '';
  @Input() price: Nullish<number> = 0;
  @Input() imageSrcSet: Nullish<string> = '';
  @Input() imageAlt: Nullish<string> = '';
}
