import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HotelOverviewPageModule } from '../hotel-overview-page/hotel-overview-page.module';
import { HotelsPageModule } from '../hotels-page/hotels-page.module';
import { LocationInfoPageModule } from '../location-info-page/location-info-page.module';
import { TourOffersPageModule } from '../tour-offers-page/tour-offers-page.module';
import { DynamicPageContainerComponent } from './dynamic-page-container.component';
import { SearchPageComponent } from './components/search-page/search-page.component';

const dynamicPages = [
  HotelOverviewPageModule,
  TourOffersPageModule,
  LocationInfoPageModule,
  HotelsPageModule
];

@NgModule({
  declarations: [
    DynamicPageContainerComponent,
    SearchPageComponent
  ],
  imports: [
    CommonModule,
    ...dynamicPages
  ]
})
export class DynamicPageContainerModule { }
