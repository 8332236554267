import { Event, ExtraOptions, Router, RouterModule, Routes, Scroll } from '@angular/router';
import { NgModule } from '@angular/core';
import { filter, pairwise } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';

import { DesktopLayoutModule } from './layouts/desktop/desktop-layout.module';

const routes: Routes = [
  { path: '', loadChildren: () => DesktopLayoutModule }
];

const config: ExtraOptions = {
  initialNavigation: 'enabled',
  anchorScrolling: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router, private viewportScroller: ViewportScroller) {
    this.handleScrollOnNavigation();
  }

  /**
   * See https://gist.github.com/iffa/9c820072135d25a6372d58075fe264dd
   */
  private handleScrollOnNavigation(): void {
    this.router.events.pipe(
      filter((e: Event): e is Scroll => e instanceof Scroll),
      pairwise()
    ).subscribe(([previous, current]) => {
      if (current.position) {
        this.viewportScroller.scrollToPosition(current.position);
      } else if (current.anchor) {
        this.viewportScroller.scrollToAnchor(current.anchor);
      } else if (this.getBaseRoute(previous.routerEvent.urlAfterRedirects) !== this.getBaseRoute(current.routerEvent.urlAfterRedirects)) {
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }

  private getBaseRoute(url: string): string {
    return url.split('?')[0];
  }
}
