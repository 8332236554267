<input (blur)="onTouched()"
       type="text"
       [id]="inputId"
       name="phone"
       [class]="inputClass"
       [(ngModel)]="phone"
       [placeholder]="('Common.Phone' | translate) + '*'"
       [showMaskTyped]="true"
       prefix="+38("
       mask="000)-000-00-00">
