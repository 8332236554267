import { NgModule } from '@angular/core';
import { StateTransferInitializerModule } from '@nguniversal/common';
import { BrowserTransferStateModule, TransferState } from '@angular/platform-browser';
import { MissingTranslationHandler, TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { AppMissingTranslationHandler } from '@classes/app-missing-translation-handler';
import { TranslateBrowserLoader } from '@services/translations/translate-browser-loader';

export function translateLoaderFactory(transferState: TransferState, http: HttpClient): TranslateLoader {
  return new TranslateBrowserLoader('./assets/i18n/', '.json', transferState, http);
}

@NgModule({
  bootstrap: [AppComponent],
  imports: [
    AppModule,
    StateTransferInitializerModule,
    BrowserTransferStateModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: translateLoaderFactory, deps: [TransferState, HttpClient] },
      compiler: { provide: TranslateCompiler, useClass: TranslateMessageFormatCompiler },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: AppMissingTranslationHandler }
    })
  ]
})
export class AppBrowserModule { }
