import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-rating-mark',
  templateUrl: './rating-mark.component.html',
  styleUrls: ['./rating-mark.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingMarkComponent {
  @Input() value = 0;
  @Input() category = '';
  @Input() clarification = '';
  @Input() bordered = false;
}
