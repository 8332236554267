<a [routerLink]="block.offer.hotel.hotelUrl | currentLangLink"
   [queryParams]=" block.offer | blockOfferQuery : block.searchQuery"
   [title]="block.offer.hotel.name">
  <app-overlaid-image [resizesLinks]="block | blockImageLinks" [imgAlt]="block.offer.hotel.name">
    <ng-template appOverlayTop>
      <app-icons-set
        [adultsCount]="block.offer.tourists.adultsCount"
        [childrenCount]="block.offer.tourists.childrenCount"
        [stars]="block.offer.hotel.stars"
        [accommodation]="block.offer.accommodation"
        [transport]="block.offer.transport"
        [food]="block.offer.food">
      </app-icons-set>
    </ng-template>
    <ng-template appOverlayBottom>
      <app-hotel-info [data]="block.offer" theme="white"></app-hotel-info>
    </ng-template>
  </app-overlaid-image>
</a>
