import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-star-icons',
  templateUrl: './star-icons.component.html',
  styleUrls: ['./star-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StarIconsComponent {
  public icons: null[] = [];

  @Input() set stars(stars: string | number) {
    const parsedStars = typeof stars === 'string' ? parseInt(stars, 10) : stars;
    this.icons = parsedStars > 0 ? Array(parsedStars).fill(null) : [];
  }
}
