<ng-container [formGroup]="formGroup">
  <app-star-rating
    formControlName="common"
    [category]="'Reviews.TripRating' | translate">
  </app-star-rating>
  <app-star-rating
    formControlName="cleaning"
    [category]="VoteCode.Cleaning | ratingTranslateMapper | translate">
  </app-star-rating>
  <app-star-rating
    formControlName="food"
    [category]="VoteCode.Food | ratingTranslateMapper | translate">
  </app-star-rating>
  <app-star-rating
    formControlName="rooms"
    [category]="VoteCode.Rooms | ratingTranslateMapper | translate">
  </app-star-rating>
  <app-star-rating
    formControlName="service"
    [category]="VoteCode.Service | ratingTranslateMapper | translate">
  </app-star-rating>
  <app-star-rating
    formControlName="infrastructure"
    [category]="VoteCode.Infrastructure | ratingTranslateMapper | translate">
  </app-star-rating>
</ng-container>
