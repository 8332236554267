import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { createCustomElement } from '@angular/elements';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@modules/tours/shared/shared.module';
import { HotelPopupComponent } from './components/hotel-popup/hotel-popup.component';
import { OfferMeTourCardComponent } from './components/offer-me-tour-card/offer-me-tour-card.component';
import { TourOffersPageComponent } from './tour-offers-page.component';
import { PlatformService } from '@services/platform.service';
import { HotToursPageComponent } from './hot-tours-page.component';
import { SeoModule } from '@modules/tours/seo/seo.module';
import { TourOffersContentComponent } from './components/tour-offers-content/tour-offers-content.component';

@NgModule({
  declarations: [
    TourOffersPageComponent,
    HotToursPageComponent,
    TourOffersContentComponent,
    OfferMeTourCardComponent,
    HotelPopupComponent
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    CarouselModule,
    SharedModule,
    RouterModule,
    TranslateModule,
    SeoModule
  ]
})
export class TourOffersPageModule {
  constructor(injector: Injector, platform: PlatformService) {
    platform.runOnlyInBrowser(() => this.defineHotelPopupCustomElement(injector));
  }

  private defineHotelPopupCustomElement(injector: Injector) {
    if (!customElements.get('app-hotel-popup')) {
      const customElement = createCustomElement(HotelPopupComponent, { injector });
      customElements.define('app-hotel-popup', customElement);
    }
  }
}
