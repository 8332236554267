import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { LanguageManagerService } from './language-manager.service';
import { InformationStore } from './store/information.store';
import { SeoService } from './seo.service';
import { ZoneDebugService } from './zone-debug.service';
import { PlatformService } from './platform.service';
import { SocketIOService } from './socket-io.service';
import { TourOperatorsStore } from './store/tour-operators.store';
import { SearchFormStore } from './store/search-form.store';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class InitializationService {
  constructor(
    private langService: LanguageManagerService,
    private informationStore: InformationStore,
    private tourOperatorsStore: TourOperatorsStore,
    private searchForm: SearchFormStore,
    private seo: SeoService,
    private zoneDebug: ZoneDebugService,
    private platform: PlatformService,
    private socketIO: SocketIOService,
    private router: Router,
    private modal: NgbModal
  ) { }

  init(): void {
    this.langService.init();
    this.searchForm.init();
    this.tourOperatorsStore.init();
    this.informationStore.init();
    this.seo.init();
    // this.zoneDebug.init(); // TODO: remove after SSR debug

    this.platform.runOnlyInBrowser(() => {
      this.socketIO.init();
    });
    this.dismissOpenedModalsOnHistoryNavigation();
  }

  dismissOpenedModalsOnHistoryNavigation() {
    this.router.events.pipe(
      filter((e): e is NavigationStart => e instanceof NavigationStart && e.navigationTrigger === 'popstate'),
      untilDestroyed(this)
    ).subscribe(() => this.modal.dismissAll());
  }
}
