<div class="app">
  <div
    class="app__image-overlay app__block app__block_color_blue container-fluid"
    [style.padding-bottom.px]="(specialLayout$ | async) === 'Article' ? 40 : null"
    [appBackgroundImage]="backgroundImageLink$ | async">
    <app-header
      *ngIf="activeMenuItem$ | async as activeMenuItem"
      [items]="siteMenu"
      [activeMenuItem]="activeMenuItem">
    </app-header>
    <ng-container>
      <app-search *ngIf="showSearchForm$ | async"></app-search>
    </ng-container>
  </div>
  <div class="app__block_color_white" *ngIf="showThematicSectionsMenu$ | async">
    <div class="container" *ngIf="thematicSectionsMenu$ | async as menu">
      <div class="row">
        <app-thematic-sections-menu [items]="menu"></app-thematic-sections-menu>
      </div>
    </div>
  </div>
  <div class="app__block app__breadcrumbs" *ngIf="showBreadcrumbs$ | async">
    <ng-container *ngIf="breadcrumbs$ | async as breadcrumbs">
      <app-breadcrumbs [items]="breadcrumbs" class="container"></app-breadcrumbs>
    </ng-container>
  </div>
  <div class="app__content">
    <router-outlet></router-outlet>
  </div>
  <div class="app__footer container-fluid">
    <app-footer></app-footer>
  </div>
</div>
