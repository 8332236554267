<ng-container *ngIf="titles$ | async as titles">
  <ng-container *ngIf="hotels$ | async as hotels">
    <div class="app__block" *ngIf="display.hotels && hotels.length > 0">
      <app-seo-linking-section [title]="titles.hotels" [list]="hotels"></app-seo-linking-section>
    </div>
  </ng-container>
  <ng-container *ngIf="resorts$ | async as resorts">
    <div class="app__block" *ngIf="display.resorts && resorts.length > 0">
      <app-seo-linking-section [showPrice]="false" [title]="titles.resorts" [list]="resorts"></app-seo-linking-section>
    </div>
  </ng-container>
  <ng-container *ngIf="popularCountries$ | async as popular">
    <div class="app__block" *ngIf="display.popular && popular.length > 0">
      <app-seo-linking-section [title]="titles.popular" [list]="popular"></app-seo-linking-section>
    </div>
  </ng-container>
</ng-container>
