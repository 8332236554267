<div class="hotel-popup">
  <div class="hotel-popup__image" *ngIf="imageLink as link">
    <img [src]="link" [alt]="hotelName">
  </div>
  <div class="hotel-popup__main">
    <div class="hotel-popup__title">{{ hotelName }}</div>
    <a
      class="btn btn-accent hotel-popup__link"
      *ngIf="priceUAH"
      [routerLink]="hotelUrl | currentLangLink">
      {{ priceUAH | currency:'UAH':'symbol-narrow':'1.0-0' }}
    </a>
  </div>
</div>
