import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { ToursCatalog } from 'package-types';

import { TourOperatorsStore } from '@services/store/tour-operators.store';

@Pipe({
  name: 'tourOperator'
})
export class TourOperatorPipe implements PipeTransform {
  constructor(
    private readonly tourOperatorsStore: TourOperatorsStore
  ) { }

  transform(operatorId: number): Observable<ToursCatalog.SiteTourOperator | null> {
    return this.tourOperatorsStore.onTourOperator(operatorId);
  }
}
