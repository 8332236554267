import { Pipe, PipeTransform } from '@angular/core';
import Decimal from 'decimal.js';

import { findCheapestEntity, WithPrice } from '@helpers/price.helper';

@Pipe({
  name: 'cheapestEntity'
})
export class CheapestEntityPipe implements PipeTransform {
  transform<T extends { priceUAH: Decimal }>(offers: T[]): WithPrice<T> | undefined {
    return findCheapestEntity(offers);
  }
}
