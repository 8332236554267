import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Lang2, ToursCatalog } from 'package-types';

import { ToursCatalogEndpoint } from './api/tours-catalog.endpoint';
import { SeoService } from './seo.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class StaticPageInfoLoaderService {
  constructor(
    private readonly toursCatalog: ToursCatalogEndpoint,
    private readonly translate: TranslateService,
    private readonly seoService: SeoService
  ) { }

  loadTagsForStaticPage(type: ToursCatalog.StaticPageType): void {
    this.toursCatalog.getSeoInfo(type)
      .pipe(untilDestroyed(this))
      .subscribe(({ seo }) => this.seoService.patchPageTags({ seo: seo[this.translate.currentLang as Lang2] }));
  }

  resetLoadedTags(): void {
    this.seoService.reset();
  }
}
