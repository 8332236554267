import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export interface LinkDefinition {
  charset?: string;
  crossOrigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
}

@Injectable({
  providedIn: 'root'
})
export class LinkService {
  private readonly selectByAttributes = ['hreflang', 'rel'] as const;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document
  ) { }

  updateTags(tags: LinkDefinition[]): void {
    tags.forEach(t => this.updateTag(t));
  }

  updateTag(tag: LinkDefinition): void {
    const selector = this.parseSelector(tag);
    const linkElement = this.document.head.querySelector<any>(selector)
      || this.document.head.appendChild(this.document.createElement('link'));

    if (linkElement) {
      Object.keys(tag).forEach(prop => linkElement[prop] = (tag as any)[prop]);
    }
  }

  removeTags(tags: LinkDefinition[]): void {
    tags.forEach(t => this.removeTag(t));
  }

  removeTag(tag: LinkDefinition): void {
    const selector = this.parseSelector(tag);
    const linkElement = this.document.head.querySelector<HTMLLinkElement>(selector);

    if (linkElement) {
      this.document.head.removeChild(linkElement);
    }
  }

  getTag(tag: LinkDefinition): HTMLLinkElement | null {
    const selector = this.parseSelector(tag);

    return this.document.head.querySelector(selector);
  }

  getTags(): NodeListOf<HTMLLinkElement> {
    return this.document.head.querySelectorAll('link');
  }

  private parseSelector(tag: LinkDefinition): string {
    const attrSelector = this.selectByAttributes
      .map(attr => tag[attr] ? `[${attr}="${tag[attr]}"]` : '')
      .join('');

    return `link${attrSelector}`;
  }
}
