import { TableCell } from './table-cell';

export class TableBody<T> {
  constructor(private body: TableCell<T>[][]) {
    this.checkBody(body);
  }

  getCell(x: number, y: number): TableCell<T> | null {
    if (this.isValidX(x) && this.isValidY(y)) {
      return this.body[y][x];
    }

    return null;
  }

  getRow(idx: number): TableCell<T>[] | null {
    if (this.isValidY(idx)) {
      return this.body[idx];
    }

    return null;
  }

  getRows(): TableCell<T>[][] {
    return this.body;
  }

  getCol(idx: number): TableCell<T>[] | null {
    if (this.isValidX(idx)) {
      return this.body.map((c: TableCell<T>[]) => c[idx]);
    }

    return null;
  }

  getCols(): TableCell<T>[][] {
    const cols: TableCell<T>[][] = [];

    for (let i: number = 0; i < this.body[0].length; i += 1) {
      const col = this.getCol(i);
      if (!col) {
        throw new Error('Null column');
      }
      cols.push(col);
    }

    return cols;
  }

  private isValidX(val: number): boolean {
    return this.isValidIndex(val) && this.isInsideWidthRange(val);
  }

  private isValidY(val: number): boolean {
    return this.isValidIndex(val) && this.isInsideHeightRange(val);
  }

  private isInsideWidthRange(val: number): boolean {
    return val >= 0 && val < this.body[0]?.length;
  }

  private isInsideHeightRange(val: number): boolean {
    return val >= 0 && val < this.body.length;
  }

  private isValidIndex(val: number): boolean {
    return val != null && !Number.isNaN(val);
  }

  private checkBody(body: TableCell<T>[][]): void {
    if (body.length === 0) {
      throw new Error('Table body is empty');
    }

    const rowsLengths: number[] = body.map(row => row.length);
    const lengthsSum: number = rowsLengths.reduce((sum, curr) => sum + curr);

    if (lengthsSum % rowsLengths.length !== 0) {
      throw new Error(`Table body rows lengths are not equal. Lengths: ${rowsLengths}`);
    }
  }
}
