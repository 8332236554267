import { Store } from 'rxjs-observable-store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';

import { PostDestinationReviewState } from './post-destination-review.state';

import { ActionStatus } from '@interfaces/action-status';

@UntilDestroy()
@Injectable()
export class PostDestinationReviewStore extends Store<PostDestinationReviewState> {
  constructor() {
    super(new PostDestinationReviewState());
  }

  setPostingStatus(status: ActionStatus) {
    this.patchState(status, 'loadingStatus', 'postReview');
  }

  setImageUploadStatus(idx: number, status: ActionStatus) {
    const copy = [...this.state.loadingStatus.uploadImages];
    copy[idx] = status;
    this.patchState(copy, 'loadingStatus', 'uploadImages');
  }

  spliceImageUploadStatus(idx: number) {
    const copy = [...this.state.loadingStatus.uploadImages];
    copy.splice(idx, 1);
    this.patchState(copy, 'loadingStatus', 'uploadImages');
  }

  discardImageUploadStatuses() {
    this.patchState([], 'loadingStatus', 'uploadImages');
  }
}
