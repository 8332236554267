import { merge, Observable, OperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ToursCatalog } from 'package-types';

import { CountryPageInfo, PageInfo, ResortPageInfo } from '@interfaces/services/api/tours-catalog.endpoint';

export function pickCountryInfoOrResortInfo(): OperatorFunction<PageInfo | undefined, CountryPageInfo | ResortPageInfo> {
  return input$ => merge(
    filterCountryPageInfo(input$),
    filterResortPageInfo(input$)
  );
}

function filterCountryPageInfo(source$: Observable<PageInfo | undefined>): Observable<CountryPageInfo> {
  return source$.pipe(
    filter((info): info is CountryPageInfo => info?.type === ToursCatalog.PageType.Country)
  );
}

function filterResortPageInfo(source$: Observable<PageInfo | undefined>): Observable<ResortPageInfo> {
  return source$.pipe(
    filter((info): info is ResortPageInfo => info?.type === ToursCatalog.PageType.Resort)
  );
}
