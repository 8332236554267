import { isSameDay } from 'date-fns';
import uniqBy from 'lodash/uniqBy';

import { ToursSearch } from 'package-types';

import { TableDrivenFilter } from '../interfaces/table-driven-filter';

export function buildFoodLabels(offers: ToursSearch.Offer[]): ToursSearch.Food[] {
  return uniqBy(offers, 'food').map(offer => offer.food);
}

export function buildRoomLabels(offers: ToursSearch.Offer[]): string[] {
  return uniqBy(offers, 'room').map(offer => offer.room);
}

export function filterOffersByNightDate(offers: ToursSearch.Offer[], options: TableDrivenFilter): ToursSearch.Offer[] {
  return offers.filter((offer: ToursSearch.Offer) => filterOfferByNightDate(offer, options));
}

export function filterOfferByNightDate(offer: ToursSearch.Offer | null, options: TableDrivenFilter): boolean {
  if (!offer) return false;

  const isSameFromDate: boolean = isSameDay(offer.dateRange.from, options.fromDate as Date);
  const isSameNights: boolean = offer.nights === options.nights;

  return isSameFromDate && isSameNights;
}
