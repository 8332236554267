import { filter, map } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ToursCatalog } from 'package-types';

import { InformationStore } from '@services/store/information.store';
import { LocationInfoPageComponent } from '../location-info-page/location-info-page.component';
import { HotelsPageComponent } from '../hotels-page/hotels-page.component';
import { HotToursPageComponent } from '../tour-offers-page/hot-tours-page.component';
import { TourOffersPageComponent } from '../tour-offers-page/tour-offers-page.component';
import { HotelOverviewPageComponent } from '../hotel-overview-page/hotel-overview-page.component';

import { PageInfo } from '@interfaces/services/api/tours-catalog.endpoint';
import { DynamicPageClass } from '@interfaces/dynamic-pages';

@UntilDestroy()
@Component({
  selector: 'app-default-page',
  templateUrl: './dynamic-page-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicPageContainerComponent implements OnInit {
  private readonly pagePostfixMapper = {
    [ToursCatalog.PagePostfix.Info]: LocationInfoPageComponent,
    [ToursCatalog.PagePostfix.Hotels]: HotelsPageComponent,
    [ToursCatalog.PagePostfix.HotTours]: HotToursPageComponent,
    default: TourOffersPageComponent
  } as const;

  @ViewChild('outlet', { read: ViewContainerRef, static: true }) outletRef!: ViewContainerRef;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly informationStore: InformationStore,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.route.data.pipe(
      filter(data => data.page !== null),
      map(data => (data.page as PageInfo)),
      map(pageInfo => ({ pageInfo, component: this.pickPageComponent(pageInfo) })),
      untilDestroyed(this)
    ).subscribe(({ component, pageInfo }) => {
      this.informationStore.setPageInfo(pageInfo);
      this.render(component);
    });
  }

  private render(cmp: any): void {
    const factory = this.componentFactoryResolver.resolveComponentFactory(cmp);
    this.outletRef.clear();
    this.outletRef.createComponent(factory);
    this.cd.markForCheck();
  }


  private pickPageComponent(info: PageInfo): DynamicPageClass {
    switch (info.type) {
      case ToursCatalog.PageType.Country:
      case ToursCatalog.PageType.Resort:
        return this.pagePostfixMapper[info.postfix ?? 'default'];
      case ToursCatalog.PageType.Hotel:
        return HotelOverviewPageComponent;
      default:
        throw new Error(`Unable to select component to display page '${(info as any).type}'`);
    }
  }
}
