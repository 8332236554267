import { Images, Lang2, ToursCatalog } from 'package-types';

import { HotelFacility, HotelRating } from '../services/hotel-page-info.state';

import { DisplayableHotelReview, HotelReview, ReviewImageWithLinks, SiteReviewImage } from '@interfaces/hotel-review';
import { Hotel } from '@interfaces/services/api/tours-catalog.endpoint';

const descriptionTypeToHotelServiceMapper: { [type in ToursCatalog.DescriptionType]: ToursCatalog.HotelService | undefined } = {
  [ToursCatalog.DescriptionType.HotelServices]: ToursCatalog.HotelService.Hotel,
  [ToursCatalog.DescriptionType.RoomServices]: ToursCatalog.HotelService.Room,
  [ToursCatalog.DescriptionType.Children]: ToursCatalog.HotelService.Children,
  [ToursCatalog.DescriptionType.Beach]: ToursCatalog.HotelService.Beach,
  [ToursCatalog.DescriptionType.Entertainment]: ToursCatalog.HotelService.Entertainment,
  [ToursCatalog.DescriptionType.Location]: undefined,
  [ToursCatalog.DescriptionType.Common]: undefined,
  [ToursCatalog.DescriptionType.Rooms]: undefined
};

const availableVoteCodes: ToursCatalog.VoteCode[] = [
  ToursCatalog.VoteCode.Cleaning,
  ToursCatalog.VoteCode.Food,
  ToursCatalog.VoteCode.Rooms,
  ToursCatalog.VoteCode.Service
];

const availableFacilities: ToursCatalog.DescriptionType[] = [
  ToursCatalog.DescriptionType.Beach,
  ToursCatalog.DescriptionType.Children,
  ToursCatalog.DescriptionType.Location,
  ToursCatalog.DescriptionType.HotelServices,
  ToursCatalog.DescriptionType.RoomServices,
  ToursCatalog.DescriptionType.Entertainment
];

export function buildServices(services: ToursCatalog.HotelServices, type: ToursCatalog.DescriptionType): ToursCatalog.Service[] {
  const service = descriptionTypeToHotelServiceMapper[type];
  if (!service) {
    return [];
  }

  return services[service] ?? [];
}

export function buildRating(hotelInfo: Hotel): HotelRating {
  const { votes, count, rating } = hotelInfo.reviews;
  const filteredVotes = votes.filter(vote => availableVoteCodes.includes(vote.code));

  return { count, rating, votes: filteredVotes };
}

export function buildFacilities(hotelInfo: Hotel, lang: Lang2): HotelFacility[] {
  return availableFacilities
    .map(title => ({ title, text: hotelInfo.descriptions[lang]?.[title], services: buildServices(hotelInfo.services, title) }))
    .filter((facility): facility is HotelFacility => typeof facility.text === 'string');
}

export function mapReviewImage({ imageId, title }: SiteReviewImage, links: Images.ImageResizesLinks): ReviewImageWithLinks | undefined {
  if (!imageId) {
    return undefined;
  }

  return { imageId, title, links: links[imageId] ?? { } };
}

export function pickImageLinks(images: SiteReviewImage[], links: Images.ImageResizesLinks): ReviewImageWithLinks[] {
  return images
    .map(image => mapReviewImage(image, links))
    .filter((review): review is ReviewImageWithLinks => review !== undefined);
}

export function enrichReviewsByImageLinks(reviews: HotelReview[], links: Images.ImageResizesLinks): DisplayableHotelReview[] {
  return reviews
    .map(({ images, ...rest }) => ({ ...rest, images: pickImageLinks(images, links) }));
}
