<a class="offer-tile"
   [routerLink]="block.offer.hotel.hotelUrl | currentLangLink"
   [queryParams]=" block.offer | blockOfferQuery : block.searchQuery"
   [title]="block.offer.hotel.name">
  <app-overlaid-image *ngIf="block | blockImageLinks as links"
                      [resizesLinks]="links"
                      [imgAlt]="block.offer.hotel.name"
                      class="offer-tile__background-image">
    <ng-template appOverlayTop>
      <app-icons-set
        [adultsCount]="block.offer.tourists.adultsCount"
        [childrenCount]="block.offer.tourists.childrenCount"
        [stars]="block.offer.hotel.stars"
        [accommodation]="block.offer.accommodation"
        [transport]="block.offer.transport"
        [food]="block.offer.food">
      </app-icons-set>
    </ng-template>
  </app-overlaid-image>
  <app-hotel-info class="offer-tile__content" [data]="block.offer"></app-hotel-info>
</a>
