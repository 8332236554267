import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ToursCatalog } from 'package-types';

import { PostDestinationReviewStore } from './post-destination-review.store';
import { ToursCatalogEndpoint } from '@services/api/tours-catalog.endpoint';

import { ReviewFormValue, ReviewImage } from '../interfaces/post-hotel-review';

import Destination = ToursCatalog.Destination;

@UntilDestroy()
@Injectable()
export class PostDestinationReviewService {
  constructor(
    private readonly toursCatalog: ToursCatalogEndpoint,
    private readonly store: PostDestinationReviewStore
  ) { }

  postReview(destination: Destination, value: ReviewFormValue) {
    this.store.setPostingStatus('inProgress');
    this.store.discardImageUploadStatuses();

    of<ReviewFormValue>(value).pipe(
      map(formValue => this.mapFormValueToHotelReviewParams(destination, formValue)),
      switchMap(hotelReviewParams => this.toursCatalog.postDestinationReview(hotelReviewParams)),
      switchMap(({ id }) => value.images.length ? forkJoin(this.imageLoaders(id, value.images)) : of([])),
      untilDestroyed(this)
    ).subscribe({
      complete: () => this.store.setPostingStatus('success'),
      error: () => this.store.setPostingStatus('error')
    });
  }

  private imageLoaders(reviewId: string, images: ReviewImage[]) {
    return images.map(({ file, title }, idx) => {
      this.store.setImageUploadStatus(idx, 'inProgress');

      return this.toursCatalog.uploadDestinationReviewImage(reviewId, file, title)
        .pipe(
          tap(() => this.store.setImageUploadStatus(idx, 'success')),
          catchError((err) => {
            console.error('Failed to upload review image.', err);
            this.store.setImageUploadStatus(idx, 'error');

            return of({ success: false });
          })
        );
    });
  }

  private mapFormValueToHotelReviewParams(destination: Destination, formValue: ReviewFormValue): ToursCatalog.SiteDestinationReviewParams {
    const { message, dates: { checkIn, checkOut }, votes } = formValue;
    const { common, ...concrete } = votes;
    const votesList: ToursCatalog.Vote[] = Object.entries(concrete)
      .map(([key, vote]) => ({ vote, code: key as ToursCatalog.VoteCode }));

    return {
      destination,
      message,
      checkIn: new Date(checkIn),
      checkOut: new Date(checkOut),
      rating: common,
      votes: votesList
    };
  }
}
