import { ToursSearch } from 'package-types';

import { TableDrivenFilter } from '../interfaces/table-driven-filter';

export function filterOfferByFoodRoom(offer: ToursSearch.Offer | null, options: TableDrivenFilter): boolean {
  if (!offer) return false;

  const isSameFood: boolean = offer.food === options.food;
  const isSameRoom: boolean = offer.room === options.room;

  return isSameFood && isSameRoom;
}


