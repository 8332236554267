import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { NgbAlertModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';

import { PostReviewFormComponent } from './components/post-review-form/post-review-form.component';
import { SharedModule } from '../shared/shared.module';
import { SearchModule } from '../search/search.module';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { ReviewImageComponent } from './components/review-image/review-image.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { MessageGroupComponent } from './components/message-group/message-group.component';
import { VotesGroupComponent } from './components/votes-group/votes-group.component';
import { CompanyReviewFormComponent } from './components/company-review-form/company-review-form.component';


@NgModule({
  declarations: [
    PostReviewFormComponent,
    ImageUploaderComponent,
    ImagePreviewComponent,
    StarRatingComponent,
    ReviewImageComponent,
    MessageGroupComponent,
    VotesGroupComponent,
    CompanyReviewFormComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule,
    SearchModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxMaskModule,
    NgbAlertModule,
    NgbRatingModule
  ],
  exports: [
    PostReviewFormComponent,
    CompanyReviewFormComponent
  ]
})
export class ReviewModule { }
