import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { convertQueryParams } from '@helpers/converter.helper';
import { normalizeParams } from '@helpers/url-params.helper';

import { ParsedParams } from '@interfaces/parsed-params';

@Injectable({
  providedIn: 'root'
})
export class QueryParamsService {
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly location: Location
  ) { }

  getParsedParamsSnapshot(): ParsedParams {
    const urlTree = this.router.parseUrl(this.location.path());

    return convertQueryParams(urlTree.queryParams);
  }

  patchParams(parsedParams: ParsedParams): void {
    const urlTree = this.router.parseUrl(this.location.path());
    urlTree.queryParams = { ...urlTree.queryParams, ...normalizeParams(parsedParams) };

    this.location.replaceState(urlTree.toString(), undefined, this.location.getState());
  }

  navigateWithNewParams(parsedParams: ParsedParams): Promise<boolean> {
    return this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: normalizeParams(parsedParams),
      relativeTo: this.activatedRoute,
      replaceUrl: true
    });
  }
}
