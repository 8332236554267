import { ɵComponentDef as ComponentDef, ɵNG_COMP_DEF } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ToursCatalog } from 'package-types';

import { SiteMenuSection } from '@constants/site-menu-section';
import { GlobalLayoutStore } from '@services/store/global-layout.store';
import { SeoService } from '@services/seo.service';
import { InformationStore } from '@services/store/information.store';
import { StaticPageInfoLoaderService } from '@services/static-page-info-loader.service';
import { ClientSectionLinks } from '@services/store/global-layout.state';
import { SpecialLayout } from '../../layouts/desktop/special-layout';
import { SearchFormStore } from '@services/store/search-form.store';
import { HtmlHeadService } from '@services/html-head.service';

export const NG_COMP_DEF = ɵNG_COMP_DEF as 'ɵcmp';

export interface LayoutElementsVisibility {
  searchForm?: boolean;
  thematicSectionsMenu?: boolean;
  breadcrumbs?: boolean;
}

export interface StaticPageOptions {
  seoInfoUploadToken?: ToursCatalog.StaticPageType;
  specialLayout?: SpecialLayout;
  layoutElementsVisibility?: LayoutElementsVisibility;
  activeSiteMenuSection?: SiteMenuSection;
  headingImageLink?: string;
  breadcrumbs?: ClientSectionLinks;
  thematicSectionsMenu?: ClientSectionLinks;
  skipIndexing?: boolean;
}

export interface DynamicPageOptions {
  pageName: ToursCatalog.PageName;
  setDefaultSearchParams?: boolean;
  disableH1SmallUpdates?: boolean;
}

export interface PageComponent {
  new(...args: any[]): any;

  [NG_COMP_DEF]: ComponentDef<any>;
}

export interface ClassDecorator {
  (constructor: { new(...args: any[]): any }): void;
}

export interface PageDecorator<T extends object> {
  (opts: T): ClassDecorator;
}

export interface StaticPageDecorator extends PageDecorator<StaticPageOptions> { }

export interface DynamicPageDecorator extends PageDecorator<DynamicPageOptions> { }

export interface InjectedServices {
  globalLayout: GlobalLayoutStore;
  searchForm: SearchFormStore;
  seoService: SeoService;
  translate: TranslateService;
  info: InformationStore;
  staticPageInfoLoader: StaticPageInfoLoaderService;
  htmlHead: HtmlHeadService;
}

type OnInitMixin = (services: InjectedServices) => void;
type OnDestroyMixin = (services: InjectedServices) => void;
type OnLangChange = (services: InjectedServices) => void;

export interface LifecycleMixin {
  onInitMixin: OnInitMixin;
  onDestroyMixin: OnDestroyMixin;
  onLangChange: OnLangChange;
}
