import { Inject, Injectable, InjectionToken, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { MonoTypeOperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';

export type Pretender = 'Server' | 'Browser';

export const PLATFORM_PRETENDER = new InjectionToken<Pretender>('platform-pretender');

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(PLATFORM_PRETENDER) private pretendAs: Pretender | null
  ) { }

  accessForServer<T>(): MonoTypeOperatorFunction<T> {
    return source$ => source$.pipe(
      filter(() => this.isServer())
    );
  }

  accessForBrowser<T>(): MonoTypeOperatorFunction<T> {
    return source$ => source$.pipe(
      filter(() => this.isBrowser())
    );
  }

  runOnlyInBrowser(fn: () => void): void {
    if (this.isBrowser()) {
      fn();
    }
  }

  runOnlyOnServer(fn: () => void): void {
    if (this.isServer()) {
      fn();
    }
  }

  isBrowser(): boolean {
    return this.pretendAs ? this.isPretenderBrowser() : isPlatformBrowser(this.platformId);
  }

  isServer(): boolean {
    return this.pretendAs ? this.isPretenderServer() : isPlatformServer(this.platformId);
  }

  private isPretenderServer() {
    return this.pretendAs === 'Server';
  }

  private isPretenderBrowser() {
    return this.pretendAs === 'Browser';
  }
}
