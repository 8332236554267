export enum ShortUrlSearchParams {
  DestinationId = 'did',
  DestinationType = 'dt',
  Hotels = 'h',
  Resorts = 'rs',
  DepartureCity = 'dc',
  DatesCheckIn = 'cIn',
  DatesCheckOut = 'cOut',
  DurationFrom = 'drf',
  DurationTo = 'drt',
  TouristsAdults = 'ta',
  TouristsChildren = 'tc',
  Foods = 'f',
  Transports = 't',
  Services = 'sv',
  Stars = 'st',
  RatingMin = 'rmin',
  RatingMax = 'rmax',
  PriceMin = 'pmin',
  PriceMax = 'pmax'
}
