import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';
import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PlatformService } from './platform.service';
import { convertDatesInParsedJson } from '@helpers/converter.helper';

type GetOptions = Parameters<HttpClient['get']>[1];
type PostOptions = Parameters<HttpClient['post']>[2];
type PutOptions = Parameters<HttpClient['put']>[2];
type DeleteOptions = Parameters<HttpClient['delete']>[1];
type OptionsOptions = Parameters<HttpClient['options']>[1];
type PatchOptions = Parameters<HttpClient['patch']>[2];
type HeadOptions = Parameters<HttpClient['head']>[1];

type HttpMethod = 'get' | 'post' | 'put' | 'delete' | 'options' | 'patch' | 'head';

@Injectable({
  providedIn: 'root'
})
export class TransferHttpService {
  constructor(
    private readonly transferState: TransferState,
    private readonly httpClient: HttpClient,
    private readonly platform: PlatformService
  ) { }

  get<T>(url: string, options?: GetOptions): Observable<T> {
    return this.tryCache<T>('get', url, options, undefined, this.httpClient.get<T>(url, options));
  }

  post<T>(url: string, body: any, options?: PostOptions): Observable<T> {
    return this.tryCache<T>('post', url, options, body, this.httpClient.post<T>(url, body, options));
  }

  put<T>(url: string, body: any, options?: PutOptions): Observable<T> {
    return this.tryCache<T>('put', url, options, body, this.httpClient.put<T>(url, body, options));
  }

  delete<T>(url: string, options?: DeleteOptions): Observable<T> {
    return this.tryCache<T>('delete', url, options, undefined, this.httpClient.delete<T>(url, options));
  }

  patch<T>(url: string, body: any, options?: PatchOptions): Observable<T> {
    return this.tryCache<T>('patch', url, options, body, this.httpClient.patch<T>(url, body, options));
  }

  head<T>(url: string, options?: HeadOptions): Observable<T> {
    return this.tryCache<T>('head', url, options, undefined, this.httpClient.head<T>(url, options));
  }

  options<T>(url: string, options?: OptionsOptions): Observable<T> {
    return this.tryCache<T>('options', url, options, undefined, this.httpClient.options<T>(url, options));
  }

  private tryCache<T>(method: HttpMethod, url: string, options: any, body: any, remoteRequest: Observable<T>): Observable<T> {
    const key = this.makeCacheKey(method, url, options, body);
    const stateKey = makeStateKey<T>(key);
    const cachedResponse = this.consumeCachedResponse<T>(stateKey);
    if (cachedResponse) {
      /**
       * Cached response must be wrapped in Promise (pushed in a microtask queue)
       */
      return from(Promise.resolve(cachedResponse));
    }

    return remoteRequest.pipe(
      tap(data => this.platform.runOnlyOnServer(() => this.transferState.set<T>(stateKey, data)))
    );
  }

  private makeCacheKey(method: HttpMethod, url: string, options: any = { }, body: any = { }): string {
    return `${method}:${url}:${JSON.stringify(options)}:${JSON.stringify(body)}`;
  }

  private consumeCachedResponse<T>(key: StateKey<T>): T | null {
    const data = this.transferState.get<T | null>(key, null);
    if (data) {
      convertDatesInParsedJson(data);
      this.platform.runOnlyInBrowser(() => this.transferState.remove(key));
    }

    return data;
  }
}
