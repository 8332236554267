import { Component } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Tourists } from 'package-angular';

import { valueAccessor } from '@helpers/angular.helper';

@UntilDestroy()
@Component({
  selector: 'app-tourists',
  templateUrl: './tourists.component.html',
  styleUrls: ['./tourists.component.scss'],
  providers: [valueAccessor(TouristsComponent)]
})
export class TouristsComponent implements ControlValueAccessor {
  tourists = new FormControl(null);

  private onTouched = () => { };

  registerOnChange(fn: (val: any) => void): void {
    this.tourists.valueChanges.pipe(untilDestroyed(this)).subscribe(fn);
  }

  registerOnTouched(fn: () => { }): void {
    this.onTouched = fn;
  }

  writeValue(touristsValue?: Tourists): void {
    touristsValue && this.tourists.setValue(cloneDeep(touristsValue));
  }
}
