import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { LocalStorageService } from './local-storage/local-storage.service';
import { AuthEndpoint } from './api/auth.endpoint';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private readonly clientId$$ = new BehaviorSubject<string | null>(null);
  private isRefresh = false;
  private isUpdatedFromLocalStorage = false;

  constructor(
    private readonly localStorage: LocalStorageService,
    private readonly auth: AuthEndpoint
  ) { }

  getClientId(): Observable<string | null> {
    return of(this.clientId$$.value)
      .pipe(
        switchMap((id) => {
          if (id === null && !this.isUpdatedFromLocalStorage) {
            return this.localStorage.getClientId().pipe(
              finalize(() => this.isUpdatedFromLocalStorage = true)
            );
          }

          return of(id);
        }),
        map(id => id ?? null),
        tap(id => this.clientId$$.next(id))
      );
  }

  refreshSession(): Observable<string> {
    if (this.isRefresh) {
      return this.clientId$$
        .pipe(
          filter((id): id is string => id !== null),
          take(1),
          distinctUntilChanged()
        );
    }
    this.isRefresh = true;
    this.clientId$$.next(null);

    return this.auth.startSession()
      .pipe(
        map(({ clientId }) => clientId),
        switchMap(id => this.localStorage.setClientId(id)
          .pipe(map(() => id))
        ),
        tap(id => this.clientId$$.next(id)),
        finalize(() => this.isRefresh = false)
      );
  }
}
