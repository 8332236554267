<div class="row row-zero-margin additional-options" [formGroup]="additionalOptionsForm">
  <div class="additional-options__block col-zero-padding col-12 col-sm-6 col-md-5 col-xl-2">
    <app-search-input [label]="searchComponentDictionary.FoodsLabel | translate">
      <app-search-selector class="additional-options__select"
                           formControlName="foods"
                           [translationBase]="searchSelectorsTranslations.foods"
                           [items]="foods"
                           [placeholder]="searchComponentDictionary.FoodsPlaceHolder">
      </app-search-selector>
    </app-search-input>
  </div>
  <div class="additional-options__block col-zero-padding col-12 col-sm-6 col-md-5 col-xl-2">
    <app-search-input [label]="searchComponentDictionary.TransportLabel | translate">
      <app-search-selector class="additional-options__select"
                           formControlName="transports"
                           [translationBase]="searchSelectorsTranslations.transport"
                           [items]="transport"
                           [placeholder]="searchComponentDictionary.TransportPlaceHolder">
      </app-search-selector>
    </app-search-input>
  </div>
  <div class="additional-options__block col-zero-padding col-12 col-sm-6 col-md-2 col-xl-2">
    <app-search-input [label]="searchComponentDictionary.HotelStarsLabel | translate">
      <app-hotel-stars class="additional-options__select"
                       formControlName="stars"
                       [placeholder]="searchComponentDictionary.HotelStarsPlaceHolder">
      </app-hotel-stars>
    </app-search-input>
  </div>
  <div class="additional-options__block col-zero-padding col-12 col-sm-6 col-md-5 col-xl-2">
    <app-search-input [label]="searchComponentDictionary.ServicesLabel | translate">
      <app-search-selector class="additional-options__select"
                           formControlName="services"
                           [translationBase]="searchSelectorsTranslations.services"
                           [items]="services"
                           [placeholder]="searchComponentDictionary.ServicesPlaceHolder">
      </app-search-selector>
    </app-search-input>
  </div>
  <div class="additional-options__block col-zero-padding col-12 col-md-5 col-xl-2">
    <app-search-input [label]="searchComponentDictionary.HotelRatingLabel | translate" [backgroundTransparent]="true">
      <app-hotel-rating class="additional-options__select"
                        formControlName="ratingMin"
                        [isTickWhite]="true">
      </app-hotel-rating>
    </app-search-input>
  </div>
  <div class="additional-options__block col-zero-padding col-12 col-md-2 col-xl-2">
    <app-search-input [label]="searchComponentDictionary.CostRangeLabel | translate">
      <app-cost-range [parentForm]="additionalOptionsForm"
                      class="additional-options__select">
      </app-cost-range>
    </app-search-input>
  </div>
</div>
