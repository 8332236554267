import { Range } from '@interfaces/range';

export abstract class RangeImpl<T> implements Range<T> {
  constructor(public from: T, public to: T) { }

  abstract eachValue(): T[];

  abstract merge(range: RangeImpl<T>): RangeImpl<T>;

  abstract isInside(val: T): boolean;

  toArray(): T[] {
    return [this.from, this.to];
  }
}
