import { animate, state, style, transition, trigger } from '@angular/animations';

export const toggleAdditionalOptions = trigger('toggleAdditionalOptions', [
  state('open', style({
    height: '*'
  })),
  state('closed', style({
    height: '0'
  })),
  transition('open => closed', [
    animate('0.2s')
  ]),
  transition('closed => open', [
    animate('0.2s')
  ]),
]);
