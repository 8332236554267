import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { searchComponentDictionary } from '@constants/translation-dictionaries';

import { Dictionary } from '@interfaces/modules/tours/search';

@UntilDestroy()
@Component({
  selector: 'app-cost-range',
  templateUrl: './cost-range.component.html',
  styleUrls: ['./cost-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CostRangeComponent implements OnInit {
  @Input() parentForm!: FormGroup;

  public searchComponentDictionary: Dictionary = searchComponentDictionary;

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
    const { maxPrice, minPrice, price } = this.grabControls();
    price.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        minPrice.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        maxPrice.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        this.cd.markForCheck();
      });
  }

  private grabControls() {
    const price = this.parentForm.get('price');
    const minPrice = this.parentForm.get('price.min');
    const maxPrice = this.parentForm.get('price.max');

    if (!price || !minPrice || !maxPrice) {
      throw new Error('Necessary FormControls don\'t exists');
    }

    return { price, minPrice, maxPrice };
  }
}
