<ng-container [formGroup]="parentForm">
  <ng-container formGroupName="price">
    <input svThreshold
           class="cost-range__input"
           [style.width.%]="50"
           formControlName="min"
           [style.color]="parentForm.get('price.min')?.invalid ? 'red' : null"
           [placeholder]="searchComponentDictionary.CostRangePlaceHolderMin | translate"
           type="number">
    <input svThreshold
           class="cost-range__input"
           [style.width.%]="50"
           formControlName="max"
           [style.color]="parentForm.get('price.max')?.invalid ? 'red' : null"
           [placeholder]="searchComponentDictionary.CostRangePlaceHolderMax | translate"
           type="number">
  </ng-container>
</ng-container>
