import { ErrorMessage } from '@constants/error-message.enum';
import { HttpError } from '@constants/http-error.enum';
import { InfoMessage } from './info-message.enum';
import { SuccessMessage } from './success-message.enum';

export const searchComponentDictionary = {
  SearchButton: 'SearchComponent.SearchButton',
  OfferMeTourButton: 'SearchComponent.OfferMeTourButton',
  OpenAdditionalSearchOptionsButton: 'SearchComponent.OpenAdditionalSearchOptionsButton',
  LocationLabel: 'SearchComponent.Location.Label',
  LocationPlaceHolder: 'SearchComponent.Location.PlaceHolder',
  HotelsPickerLabel: 'SearchComponent.HotelsPicker.Label',
  HotelsPickerPlaceHolder: 'SearchComponent.HotelsPicker.PlaceHolder',
  ResortsPickerLabel: 'SearchComponent.ResortsPicker.Label',
  ResortsPickerPlaceHolder: 'SearchComponent.ResortsPicker.PlaceHolder',
  TourOperatorsLabel: 'SearchComponent.TourOperators.Label',
  DepartureCityLabel: 'SearchComponent.DepartureCity.Label',
  DateRangeLabel: 'SearchComponent.DateRange.Label',
  TourDurationLabel: 'SearchComponent.TourDuration.Label',
  TouristsLabel: 'SearchComponent.Tourists.Label',
  FoodsLabel: 'SearchComponent.Foods.Label',
  FoodsPlaceHolder: 'SearchComponent.Foods.PlaceHolder',
  TransportLabel: 'SearchComponent.Transport.Label',
  TransportPlaceHolder: 'SearchComponent.Transport.PlaceHolder',
  HotelStarsLabel: 'SearchComponent.HotelStars.Label',
  HotelStarsPlaceHolder: 'SearchComponent.HotelStars.PlaceHolder',
  ServicesLabel: 'SearchComponent.Services.Label',
  ServicesPlaceHolder: 'SearchComponent.Services.PlaceHolder',
  HotelRatingLabel: 'SearchComponent.HotelRating.Label',
  CostRangeLabel: 'SearchComponent.CostRange.Label',
  CostRangePlaceHolderMin: 'SearchComponent.CostRange.PlaceHolder.Min',
  CostRangePlaceHolderMax: 'SearchComponent.CostRange.PlaceHolder.Max'
};

export const searchSelectorsTranslations = {
  foods: 'Common.FoodType',
  transport: 'Common.TransportType',
  services: 'Services'
};

export const errorMessagesDictionary: Record<ErrorMessage, string> & { [key: string]: string | undefined } = {
  [ErrorMessage.ImageLoadingFailure]: 'ErrorMessages.ImageLoadingFailure',
  [ErrorMessage.OfficesLoadingFailure]: 'ErrorMessages.OfficesLoadingFailure',
  [ErrorMessage.DestinationRequired]: 'ErrorMessages.DestinationRequired',
  [ErrorMessage.InvalidPriceRange]: 'ErrorMessages.InvalidPriceRange',
  [ErrorMessage.SendingReviewFailure]: 'ErrorMessages.SendingReviewFailure',
  [ErrorMessage.TourOrderCreationFailed]: 'ErrorMessages.TourOrderCreationFailed',
  [ErrorMessage.TourSelectionClaimCreationFailed]: 'ErrorMessages.TourSelectionClaimCreationFailed',
  [ErrorMessage.SiteFeedbackPostingFailed]: 'ErrorMessages.SiteFeedbackPostingFailed',
  [ErrorMessage.Default]: 'ErrorMessages.Default'
};

export const infoMessagesDictionary: { [message in InfoMessage]: string } = {
  [InfoMessage.FilterCanceling]: 'InfoMessages.FilterCanceling'
};

export const successMessageDictionary: { [message in SuccessMessage]: string} = {
  [SuccessMessage.ReviewPosted]: 'SuccessMessages.ReviewPosted',
  [SuccessMessage.TourOrderCreated]: 'SuccessMessages.TourOrderCreated',
  [SuccessMessage.TourSelectionClaimCreated]: 'SuccessMessages.TourSelectionClaimCreated',
  [SuccessMessage.SiteFeedbackPosted]: 'SuccessMessages.SiteFeedbackPosted'
};

export const httpErrorMessagesDictionary: { [message in HttpError]: string } = {
  [HttpError.MissedPermission]: 'ErrorMessages.Http.MissedPermission',
  [HttpError.NotFound]: 'ErrorMessages.Http.NotFound',
  [HttpError.RequestTimeout]: 'ErrorMessages.Http.RequestTimeout',
  [HttpError.Unauthorized]: 'ErrorMessages.Http.Unauthorized',
  [HttpError.UnknownError]: 'ErrorMessages.Http.UnknownError',
  [HttpError.UnprocessableEntity]: 'ErrorMessages.Http.UnprocessableEntity'
};
