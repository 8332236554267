import { Injectable, NgZone } from '@angular/core';

declare const Zone: any;

// TODO: remove after SSR debug
@Injectable({
  providedIn: 'root'
})
export class ZoneDebugService {
  constructor(private zone: NgZone) { }

  init() {
    this.zone.onUnstable.subscribe(() => {
      const taskTrackingZone = Zone.current.get('TaskTrackingZone');
      if (!taskTrackingZone) {
        throw new Error('TaskTrackingZone is not initialized');
      }
      const macroTasks: any[] = taskTrackingZone.getTasksFor('macroTask');
      const microTasks: any[] = taskTrackingZone.getTasksFor('microTask');
      console.log(`Macro Tasks - ${macroTasks.length} items`);
      console.log(macroTasks.map(({ data }) => data));
      console.log(`Micro Tasks - ${microTasks.length} items`);
      console.log(microTasks.map(({ data }) => data));
      const { hasPendingMacrotasks, hasPendingMicrotasks, isStable } = this.zone;
      console.log(`
${new Date().toISOString()} NgZone state:
  pending microtasks - ${hasPendingMicrotasks},
  pending macrotasks - ${hasPendingMacrotasks},
  isStable - ${isStable}`);
    });
  }
}
