<app-table-skeleton
  [title]="'TourOffer.Options.Food' | translate"
  [emptyTableMessage]="'HotelOverviewPage.PriceTable.EmptyTableMessage' | translate"
  (cellClick)="cellClicked($event)"
  *ngIf="(table$ | async) as table"
  [table]="table">
  <ng-template appTableColumnLabelTemplate let-food="item">
    {{ food | foodTranslationPath | translate }}
  </ng-template>
  <ng-template appTableRowLabelTemplate let-room="item">
    {{ room }}
  </ng-template>
  <ng-template appTableCellTemplate let-cell="cell">
    <app-room-food-cell [cell]="cell"></app-room-food-cell>
  </ng-template>
</app-table-skeleton>
