import merge from 'lodash/merge';

import { ToursCatalog } from 'package-types';

import { defaultSearch } from '@constants/default-search-params';
import { convertPageTypeToDestinationType } from './converter.helper';

import { SearchParams } from '@interfaces/modules/tours/search';
import { PageInfo } from '@interfaces/services/api/tours-catalog.endpoint';

export function getDestinationId(pageInfo: PageInfo) {
  switch (pageInfo.type) {
    case ToursCatalog.PageType.Country:
      return pageInfo.country.otpuskId;
    case ToursCatalog.PageType.Resort:
      return pageInfo.resort.otpuskId;
    case ToursCatalog.PageType.Hotel:
      return pageInfo.hotel.hotelId;
    default:
      throw new Error(`Unknown page type - ${(pageInfo as PageInfo).type}`);
  }
}

export function createDefaultSearchWithDestination(pageInfo?: PageInfo): SearchParams {
  if (!pageInfo) {
    throw new Error('PageInfo should be loaded before SearchParams building');
  }

  const id = getDestinationId(pageInfo);
  if (!id) {
    throw new Error('Incorrect data! PageInfo should have id');
  }

  return {
    ...defaultSearch(),
    destination: {
      id,
      type: convertPageTypeToDestinationType(pageInfo.type)
    }
  };
}

export function sanitizeSearch(params: SearchParams): SearchParams {
  return merge(defaultSearch(), params);
}
