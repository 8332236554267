import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { config } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faCalendarAlt as fasCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faMap } from '@fortawesome/free-solid-svg-icons/faMap';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faMapPin } from '@fortawesome/free-solid-svg-icons/faMapPin';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faCalendarAlt as farCalendarAlt } from '@fortawesome/free-regular-svg-icons/faCalendarAlt';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons/faPlusSquare';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faBroom } from '@fortawesome/free-solid-svg-icons/faBroom';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faFrown } from '@fortawesome/free-regular-svg-icons/faFrown';

@NgModule({
  imports: [FontAwesomeModule]
})
export class IconsModule {
  constructor(library: FaIconLibrary) {
    config.autoAddCss = false;
    library.addIcons(
      faAngleDown,
      faAngleLeft,
      faAngleRight,
      faArrowUp,
      faArrowDown,
      faArrowLeft,
      faArrowRight,
      fasCalendarAlt,
      farCalendarAlt,
      faStar,
      faMap,
      faCaretUp,
      faCaretDown,
      faCheck,
      faSpinner,
      faPlusSquare,
      faTimes,
      faFacebookF,
      faInstagram,
      faMapMarkerAlt,
      faCamera,
      faChevronLeft,
      faChevronRight,
      faMapPin,
      faSave,
      faPen,
      faBroom,
      faExclamationTriangle,
      faExclamationCircle,
      faFrown,
    );
  }
}
