import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-search-picker-option',
  templateUrl: './search-picker-option.component.html',
  styleUrls: ['./search-picker-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchPickerOptionComponent {
  @Input() name: string = '';
  @Input() additionalInfo: string = '';
}
