<div class="offer-management__container">
  <app-filter-tab>
    <app-filter-tab-header
      [title]="'TourOffer.Options.Dates' | translate">
      <div class="offer-management__header-info">
        <app-table-scroll
          (left)="scroll('left')"
          (right)="scroll('right')">
          <app-offer-option
            *ngIf="datesRange$ | async as dates"
            [roundCorners]="false"
            [text]="
              dates.from | dateFormat:format | uppercase | join
              :' - '
              :(dates.to | dateFormat:format | uppercase)">
          </app-offer-option>
        </app-table-scroll>
        <app-table-scroll
          (left)="scroll('up')"
          (right)="scroll('down')">
          <app-offer-option
            *ngIf="nightsRange$ | async as nights"
            [roundCorners]="false"
            [text]="'HotelOverviewPage.NightsRange' | translate: { from: nights.from, to: nights.to }">
          </app-offer-option>
        </app-table-scroll>
      </div>
    </app-filter-tab-header>
    <app-night-date-table appFilterTabContent></app-night-date-table>
  </app-filter-tab>
</div>
<div class="offer-management__container">
  <app-filter-tab>
    <app-filter-tab-header
      [title]="'HotelOverviewPage.PriceTable.RoomAndFood' | translate">
      <div class="offer-management__header-info offer-management__header-info_xs_hidden">
        <app-offer-option
          *ngIf="(selectedFilter$ | async)?.room as room"
          [text]="room">
        </app-offer-option>
        <app-offer-option
          *ngIf="(selectedFilter$ | async)?.food as food"
          [text]="food | foodTranslationPath | translate">
        </app-offer-option>
      </div>
    </app-filter-tab-header>
    <app-room-food-table appFilterTabContent></app-room-food-table>
  </app-filter-tab>
</div>
<div class="offer-management__container">
  <app-filter-tab>
    <app-filter-tab-header
      [title]="'TourOffer.Options.Transport' | translate">
      <div class="offer-management__header-info offer-management__header-info_xs_hidden">
        <app-offer-option
          *ngIf="(selectedOffer$ | async)?.transport as transport"
          [text]="transport | transportTranslationPath | translate">
        </app-offer-option>
        <app-offer-option
          *ngIf="(selectedFlights$ | async)?.from?.timeRange?.from as flightFrom"
          [text]="'TourOffer.Options.There' | translate | join:': ':(flightFrom | dateFormat:'dd.LL')">
        </app-offer-option>
        <app-offer-option
          *ngIf="(selectedFlights$ | async)?.to?.timeRange?.from as flightTo"
          [text]="'TourOffer.Options.Back' | translate | join:': ':(flightTo | dateFormat:'dd.LL')">
        </app-offer-option>
      </div>
    </app-filter-tab-header>
    <app-transport-menu appFilterTabContent></app-transport-menu>
  </app-filter-tab>
</div>
