import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Images, ToursCatalog } from 'package-types';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleComponent {
  @Input() page?: ToursCatalog.BlocksPage;
  @Input() images: Images.ImageResizesLinks | null = null;

  PageBlockType = ToursCatalog.PageBlockType;

  buildSectionHtml(headText = '', html = ''): string {
    return `<h2>${headText}</h2>${html}`;
  }
}
