import { Injectable, TemplateRef } from '@angular/core';
import { Store } from 'rxjs-observable-store';
import { UntilDestroy } from '@ngneat/until-destroy';

import { createDefaultElementsVisibility, GlobalLayoutState, SectionLinks } from './global-layout.state';

import { LayoutElementsVisibility } from '@classes/decorators/interfaces';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class GlobalLayoutStore extends Store<GlobalLayoutState> {
  constructor() {
    super(new GlobalLayoutState());
  }

  setElementsVisibility(settings: LayoutElementsVisibility): void {
    this.patchState({ ...createDefaultElementsVisibility(), ...settings }, 'elementsVisibility');
  }

  resetElementsVisibility(): void {
    this.patchState({ thematicSectionsMenu: false, breadcrumbs: false, searchForm: false }, 'elementsVisibility');
  }

  setTitle(title: string): void {
    this.patchState(title, 'header', 'title');
  }

  removeTitle(): void {
    this.patchState(undefined, 'header', 'title');
  }

  setSubtitle(templateRef: TemplateRef<any> | string): void {
    this.patchState(templateRef, 'header', 'subtitle');
  }

  removeSubtitle(): void {
    this.patchState(undefined, 'header', 'subtitle');
  }

  setWidget(templateRef: TemplateRef<any>): void {
    this.patchState(templateRef, 'header', 'widget');
  }

  removeWidget(): void {
    this.patchState(undefined, 'header', 'widget');
  }

  changeBackgroundImage(imageLink?: string): void {
    this.patchState(imageLink, 'header', 'backgroundImageLink');
  }

  setBreadcrumbs(breadcrumbs: SectionLinks): void {
    this.patchState(breadcrumbs, 'breadcrumbs');
  }

  setThematicSectionsMenu(menu: SectionLinks): void {
    this.patchState(menu, 'thematicSectionsMenu');
  }
}
