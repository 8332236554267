import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DOCUMENT } from '@angular/common';

import { LinkDefinition, LinkService } from './link.service';

interface HtmlHeadTags {
  links?: LinkDefinition[];
  meta?: MetaDefinition[];
  title?: string;
  lang?: string;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class HtmlHeadService {
  constructor(
    private readonly title: Title,
    private readonly meta: Meta,
    private readonly link: LinkService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) { }

  update({ links, meta, title, lang }: HtmlHeadTags): void {
    links && this.link.updateTags(links);
    meta && this.updateMeta(meta);
    lang && this.setHtmlLang(lang);
    title && this.title.setTitle(title);
  }

  remove({ meta, links }: HtmlHeadTags): void {
    meta && this.removeMeta(meta);
    links && this.link.removeTags(links);
  }

  updateMeta(tags: MetaDefinition[]): void {
    tags.forEach(t => this.meta.updateTag(t));
  }

  private removeMeta(tags: MetaDefinition[]): void {
    tags.forEach((t) => {
      const selector = this.createSelector(t);
      if (selector) {
        this.meta.removeTag(selector);
      }
    });
  }

  private createSelector({ property, name }: MetaDefinition): string {
    if (property) {
      return `property="${property}"`;
    }
    if (name) {
      return `name="${name}"`;
    }

    return '';
  }

  private setHtmlLang(lang: string) {
    const html = this.document.querySelector('html');
    if (html) {
      html.lang = lang;
    }
  }
}
