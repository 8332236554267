import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { ToursSearch } from 'package-types';

import { valueAccessor } from '@helpers/angular.helper';

@Component({
  selector: 'app-hotel-stars',
  templateUrl: './hotel-stars.component.html',
  providers: [valueAccessor(HotelStarsComponent)]
})
export class HotelStarsComponent implements ControlValueAccessor {
  @Input() placeholder!: string;

  public stars: ToursSearch.Stars[] = [
    ToursSearch.Stars.One,
    ToursSearch.Stars.Two,
    ToursSearch.Stars.Three,
    ToursSearch.Stars.Four,
    ToursSearch.Stars.Five
  ];

  private selectedStarsValue?: ToursSearch.Stars[];
  private onTouched: Function = () => { };
  private onChange: Function = () => { };

  get selectedStars() {
    return this.selectedStarsValue;
  }

  set selectedStars(selectedStars) {
    this.selectedStarsValue = selectedStars;
    this.onTouched();
    this.onChange(selectedStars);
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  writeValue(items: ToursSearch.Stars[]): void {
    this.selectedStarsValue = items;
  }
}
