import { RawSeoLinkingLink } from '@interfaces/services/api/tours-catalog.endpoint';

export class SeoLinkingState {
  popularCountries: RawSeoLinkingLink[] = [];
  resorts: RawSeoLinkingLink[] = [];
  hotels: RawSeoLinkingLink[] = [];
  titles = {
    popular: '',
    resorts: '',
    hotels: ''
  };
}
