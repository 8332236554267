import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { PlatformService } from '@services/platform.service';

@Directive({
  selector: '[appRenderOnlyInBrowser]'
})
export class RenderOnlyInBrowserDirective implements OnInit {
  constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly platform: PlatformService
  ) { }

  ngOnInit() {
    if (this.platform.isBrowser()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
