import { Pipe, PipeTransform } from '@angular/core';

import { ToursSearch } from 'package-types';

import { normalizeParams } from '@helpers/url-params.helper';
import { formatToISODate } from '@helpers/date.helper';

import { NormalizedParams } from '@interfaces/modules/tours/search';
import { RawBlockOffer } from '@interfaces/services/api/tours-catalog.endpoint';

import DestinationType = ToursSearch.DestinationType;

@Pipe({
  name: 'blockOfferQuery'
})
export class BlockOfferQueryPipe implements PipeTransform {
  transform(offer: RawBlockOffer, query?: ToursSearch.Query): Partial<NormalizedParams> {
    return normalizeParams({
      offerId: offer.id,
      search: {
        destination: { id: offer.hotel.hotelId, type: DestinationType.Hotel },
        departureCity: query?.departureCityId,
        tourists: query?.tourists,
        duration: query?.duration,
        dates: query ? { checkIn: formatToISODate(query.dates.checkIn), checkOut: formatToISODate(query.dates.checkOut) } : undefined,
        transports: query?.transports,
        price: query?.price,
        foods: query?.foods
      }
    });
  }
}
