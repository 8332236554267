import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ToursSearch } from 'package-types';

import { ChosenFlights } from '@pages/tours/hotel-overview-page/modules/hotel-availability/interfaces/offer-management';

@Component({
  selector: 'app-tour-options',
  templateUrl: './tour-options.component.html',
  styleUrls: ['./tour-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TourOptionsComponent {
  @Input() offer: ToursSearch.Offer | null = null;
  @Input() flights: ChosenFlights | null = null;
}
