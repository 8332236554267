import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Currency, Lang2, TourOrders, ToursSearch } from 'package-types';
import { cleanDeep } from 'package-utils';

import { SelectionStore } from '../../services/store/selection.store';
import { NotificationService } from '@services/notification.service';
import { ErrorMessage } from '@constants/error-message.enum';
import { SuccessMessage } from '@constants/success-message.enum';
import { TourOrderCreationService } from '../../services/tour-order-creation.service';
import { transformLinkWithCurrentLang } from '@helpers/language.helper';
import { OfferRelevanceModalComponent } from '../offer-relevance-modal/offer-relevance-modal.component';
import { GtagService } from '@modules/gtag/gtag.service';
import { HotelPageInfoStore } from '../../../../services/hotel-page-info.store';

import { ChosenFlights } from '../../interfaces/offer-management';
import { ContactInfo } from '@interfaces/modules/tours/shared/contact-form';
import { Hotel } from '@interfaces/services/api/tours-catalog.endpoint';

@UntilDestroy()
@Component({
  selector: 'app-book-offer-modal',
  templateUrl: './book-offer-modal.component.html',
  styleUrls: ['./book-offer-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TourOrderCreationService]
})
export class BookOfferModalComponent implements OnInit {
  public offer$: Observable<ToursSearch.Offer | undefined> = this.selection.onChanges('selectedOffer');
  public flights$: Observable<ChosenFlights> = this.selection.onChanges('selectedFlights');
  public creationLoading$ = this.tourOrderCreation.creationLoading$;

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly selection: SelectionStore,
    private readonly notification: NotificationService,
    private readonly tourOrderCreation: TourOrderCreationService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly modalService: NgbModal,
    private readonly gtag: GtagService,
    private readonly hotelPageInfo: HotelPageInfoStore,
  ) { }

  ngOnInit(): void {
    this.tourOrderCreation.creationResult$
      .pipe(untilDestroyed(this))
      .subscribe(({ error, result, context, params }) => {
        if (error) {
          this.notification.error(ErrorMessage.TourOrderCreationFailed);
        } else {
          this.notification.success(SuccessMessage.TourOrderCreated);
          this.closeModal();
          if (result && context.payment === TourOrders.PaymentType.Online) {
            const langLink = transformLinkWithCurrentLang(`/checkout/${result.tourOrder._id}`, this.translate.currentLang as Lang2);
            this.router.navigateByUrl(langLink);
          }

          const hotel = this.hotelPageInfo.state.hotelInfo;
          if (hotel && result) {
            this.sendEvent(result.tourOrder._id as any as string, hotel, params.offer);
          }
        }
      });
  }

  orderTour(contact: ContactInfo): void {
    const ref = this.modalService.open(OfferRelevanceModalComponent, { size: 'lg', centered: true });
    ref.componentInstance.offer = this.selection.state.selectedOffer;
    ref.componentInstance.flights = this.selection.state.selectedFlights;
    ref.result.then(
      ({ offer, flights }) => this.tourOrderCreation.createTourOrder(contact, offer, flights),
      () => this.closeModal(),
    );
  }

  closeModal(): void {
    this.modal.close();
  }

  private sendEvent(tourOrderId: string, hotel: Hotel, offer: ToursSearch.Offer): void {
    const item = this.buildEventData(hotel, offer);

    this.gtag.event('purchase', {
      transaction_id: tourOrderId,
      value: item.price,
      tax: 0,
      shipping: 0,
      currency: Currency.UAH.toUpperCase(),
      items: [item],
    });
  }

  private buildEventData({ country, resort, names }: Hotel, offer: ToursSearch.Offer): Record<string, string | number | undefined> {
    return cleanDeep({
      item_id: offer.offerId,
      item_name: `Замовлення туру - ${country.names.uk.name}, ${resort.names.uk.name}, ${names.name}`,
      price: parseFloat(offer.priceUAH.toString()),
      item_brand: 'Замовлення туру',
      item_category: 'Замовлення туру',
      quantity: 1,
    });
  }
}
