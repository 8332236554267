import { of, OperatorFunction } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { RawSeoLinkingLink, SeoLinkingResult } from '@interfaces/services/api/tours-catalog.endpoint';

export function handleSeoLinking<T extends SeoLinkingResult>(): OperatorFunction<T, RawSeoLinkingLink[]> {
  return source$ => source$.pipe(
    map(({ list }) => list),
    catchError((err) => {
      console.error(err);

      return of([]);
    })
  );
}
