import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { extractLangFromUrl, transformLinkWithCurrentLang } from '@helpers/language.helper';

@Injectable({
  providedIn: 'root'
})
export class NotFoundServiceService {
  constructor(
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly location: Location
  ) { }

  navigateToNotFoundPage(url: string): void {
    this.router.navigateByUrl(transformLinkWithCurrentLang('/page-not-found', extractLangFromUrl(url)), { skipLocationChange: true })
      .then(() => this.location.replaceState(url));
  }
}
