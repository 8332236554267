import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

export const openClose: AnimationTriggerMetadata = trigger('openClose', [
  state('open', style({
    height: '*'
  })),
  state('closed', style({
    height: '0',
    padding: '0',
    'margin-top': '0'
  })),
  transition('open <=> closed', [
    animate('0.3s')
  ]),
]);
