import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { SearchResultExpiredModalComponent } from './components/search-result-expired-modal/search-result-expired-modal.component';
import { SearchTimeoutExpiredModalComponent } from './components/search-timeout-expired-modal/search-timeout-expired-modal.component';
import { SearchFailedModalComponent } from './components/search-failed-modal/search-failed-modal.component';

@NgModule({
  declarations: [
    SearchResultExpiredModalComponent,
    SearchTimeoutExpiredModalComponent,
    SearchFailedModalComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgbModalModule
  ]
})
export class SearchModalsModule { }
