import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { delay, filter, map, share, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual } from 'date-fns';

import { Otpusk, TourOrders, ToursSearch } from 'package-types';

import { TourOrdersEndpoint } from '@services/api/tour-orders.endpoint';
import { ApiLoader, ApiRequestResult } from '@classes/api-loader';

import { ChosenFlights } from '../../interfaces/offer-management';

import RelevanceStatus = Otpusk.RelevanceStatus;

@UntilDestroy()
@Component({
  selector: 'app-offer-relevance-modal',
  templateUrl: './offer-relevance-modal.component.html',
  styleUrls: ['./offer-relevance-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferRelevanceModalComponent implements OnInit {
  @Input() offer!: ToursSearch.Offer;
  @Input() flights!: ChosenFlights;

  readonly RelevanceStatus = Otpusk.RelevanceStatus;

  relevanceLoading$: Observable<boolean>;
  relevance$: Observable<ApiRequestResult<TourOrders.Actions.SiteCheckOfferRelevanceResult, { offerId: string }, { }>>;

  private readonly loader = new ApiLoader<{ offerId: string }>();

  private readonly autoCloseStatuses = [
    RelevanceStatus.TourOperatorConnectionError,
    RelevanceStatus.ActualPrice,
    RelevanceStatus.InvalidInputData,
    RelevanceStatus.Unknown
  ];

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly tourOrders: TourOrdersEndpoint,
  ) {
    this.relevanceLoading$ = this.loader.status$.pipe(map(s => s === 'inProgress'));
    this.relevance$ = this.loader.data$
      .pipe(
        switchMap(
          data => this.tourOrders.checkOfferRelevance(data.params.offerId)
            .pipe(this.loader.completion(data))
        ),
        share(),
      );
    this.relevance$
      .pipe(
        filter(relevance => relevance.error !== null || this.autoCloseStatuses.includes(relevance.result.status)),
        delay(3000),
        untilDestroyed(this)
      )
      .subscribe(() => this.modal.close({ offer: this.offer, flights: this.flights }));
  }

  ngOnInit(): void {
    this.loader.fetch({ offerId: this.offer.offerId }, { });
  }

  applyNewOffer(offer: ToursSearch.Offer): void {
    this.modal.close({ offer, flights: this.tryToPreserveSelectedFlights(offer.flights) });
  }

  cancelOrder(): void {
    this.modal.dismiss();
  }

  private tryToPreserveSelectedFlights(newOfferFlights: ToursSearch.Flights): ChosenFlights {
    return {
      from: newOfferFlights.from.find(f => this.sameFlight(f, this.flights.from)),
      to: newOfferFlights.to.find(f => this.sameFlight(f, this.flights.to)),
    };
  }

  private sameFlight(a?: ToursSearch.Flight, b?: ToursSearch.Flight): boolean {
    if (!a && !b) {
      return true;
    }
    if (!(a && b)) {
      return false;
    }

    return Boolean(
      a.airport.from === b.airport.from
      && a.airport.to === b.airport.to
      && a.code === b.code
      && a.line === b.line
      && a.craft === b.craft
      && this.equalOptionalDates(a.timeRange.from, b.timeRange.from)
      && this.equalOptionalDates(a.timeRange.to, b.timeRange.to)
    );
  }

  private equalOptionalDates(date1?: Date, date2?: Date): boolean {
    if (!date1 && !date2) {
      return true;
    }
    if (date1 && date2) {
      return isEqual(date1, date2);
    }

    return false;
  }
}
