import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';

import { NumbersRangeModule } from 'package-angular';

import { HeaderComponent } from './components/header/header.component';
import { DynamicPageContainerModule } from '@pages/tours/dynamic-page-container/dynamic-page-container.module';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ThematicSectionsMenuComponent } from './components/thematic-sections-menu/thematic-sections-menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderNavSmComponent } from './components/header/header-nav-sm/header-nav-sm.component';
import { HeaderNavComponent } from './components/header/header-nav/header-nav.component';
import { SearchModule } from '@modules/tours/search/search.module';
import { LangSelectorComponent } from './components/header/lang-selector/lang-selector.component';
import { DesktopLayoutComponent } from './desktop-layout.component';
import { SearchComponent } from './components/search/search.component';
import { SearchAdditionalOptionsComponent } from './components/search/search-additional-options/search-additional-options.component';
import { SharedModule } from '@modules/tours/shared/shared.module';
import { MainPageModule } from '@pages/tours/main-page/main-page.module';
import { CoreModule } from '@modules/tours/core/core.module';
import { BackgroundImageDirective } from './directives/background-image.directive';
import { DesktopLayoutRoutingModule } from './desktop-layout-routing.module';
import { BreadcrumbListJsonLdPipe } from './pipes/breadcrumb-list-json-ld.pipe';
import { SendSiteFeedbackModalComponent } from './components/send-site-feedback-modal/send-site-feedback-modal.component';

@NgModule({
  declarations: [
    BreadcrumbsComponent,
    DesktopLayoutComponent,
    ThematicSectionsMenuComponent,
    FooterComponent,
    HeaderComponent,
    HeaderNavSmComponent,
    HeaderNavComponent,
    LangSelectorComponent,
    SearchAdditionalOptionsComponent,
    SearchComponent,
    BackgroundImageDirective,
    BreadcrumbListJsonLdPipe,
    SendSiteFeedbackModalComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    DynamicPageContainerModule,
    FormsModule,
    MainPageModule,
    NgbDropdownModule,
    DesktopLayoutRoutingModule,
    ReactiveFormsModule,
    SearchModule,
    SharedModule,
    TranslateModule,
    FontAwesomeModule,
    NumbersRangeModule,
    NgxJsonLdModule
  ]
})
export class DesktopLayoutModule { }
