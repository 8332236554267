import { Injectable } from '@angular/core';
import { Store } from 'rxjs-observable-store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, startWith } from 'rxjs/operators';

import { EmptyTableBuilder } from '@modules/tours/table-toolkit/classes/empty-table-builder';
import { Table } from '@modules/tours/table-toolkit/classes/table';
import { NightDateHeaderFactory } from '../../classes/night-date-table/night-date-header-factory';
import { DateRange } from '@classes/range/date-range';
import { NumberRange } from '@classes/range/number-range';
import { NightDateArea } from '../../classes/night-date-table/night-date-area';
import { LoadingMatrixState } from './loading-matrix.state';
import { convertStringRangeToDateRange } from '@helpers/date.helper';
import { SearchResultStore } from '../../../../../dynamic-page-container/services/store/search-result.store';
import { HotelSearchService } from '../../../hotel-availability/services/hotel-search.service';

import { LoadingStatus } from '@modules/tours/table-toolkit/interfaces/loading-status';
import { NDAreaItem } from '../../interfaces/night-date-area-item';
import { SearchParams } from '@interfaces/modules/tours/search';
import { SearchEventType, SearchResultEvent } from '../../../hotel-availability/interfaces/hotel-search';

@UntilDestroy()
@Injectable()
export class LoadingMatrixStore extends Store<LoadingMatrixState> {
  constructor(
    private searchService: HotelSearchService,
    private searchResultStore: SearchResultStore
  ) {
    super(new LoadingMatrixState());
    this.init();
  }

  init(): void {
    this.searchService.search$
      .pipe(
        startWith({ params: this.searchResultStore.state.actualSearch.search, type: SearchEventType.Result }),
        filter((event): event is SearchResultEvent => event.type === SearchEventType.Result),
        untilDestroyed(this)
      )
      .subscribe(({ params }) => this.updateLoadedTableAreasByLastSearchData(params));
  }

  updateMatrix(area: NightDateArea, status: LoadingStatus): void {
    const mergedArea = this.mergeMatrixAreaInto(area);
    this.setState({
      ...this.state,
      matrix: this.fillTable(this.buildTable(mergedArea), area, status),
      area: mergedArea
    });
  }

  getStatus(date: Date, nights: number): LoadingStatus | null {
    const cell = this.state.matrix?.findCell(date, nights);

    return cell?.data ?? null;
  }

  isLoaded(date: Date, nights: number): boolean {
    const cell = this.state.matrix?.findCell(date, nights);

    return cell == null ? false : cell.data === LoadingStatus.Loaded;
  }

  cutLoadedAreas(area: NightDateArea): NightDateArea {
    const filtered: NDAreaItem[] = area.eachValue().filter((val: NDAreaItem) => !this.state.matrix?.hasData(val.date, val.nights));

    return NightDateArea.fromArrays(
      filtered.map(({ date }) => date),
      filtered.map(({ nights }) => nights)
    );
  }

  isEmptyArea(area: NightDateArea): boolean {
    if (!this.state.matrix) {
      return true;
    }

    for (const { date, nights } of area.eachValue()) {
      if (this.state.matrix.findCell(date, nights)?.data != null) {
        return false;
      }
    }

    return true;
  }

  private buildTable(area: NightDateArea): Table<any, Date, number> {
    return new EmptyTableBuilder<any, Date, number>(
      area.nights.eachValue(),
      area.dates.eachValue(),
      new NightDateHeaderFactory()
    ).build();
  }

  private fillTable(table: Table<any, Date, number>, area: NightDateArea, status: LoadingStatus): Table<LoadingStatus, Date, number> {
    for (const cell of table) {
      if (!cell) {
        throw new Error('Cell should be not null');
      }
      const date: Date = table.colHeader.getSegment(cell.position.x);
      const nights: number = table.rowHeader.getSegment(cell.position.y);

      if (area.isValuesInside(date, nights)) {
        cell.data = status;
      } else if (this.state.matrix && this.state.matrix.hasData(date, nights)) {
        cell.data = this.state.matrix.findCell(date, nights)?.data;
      }
    }

    return table;
  }

  private mergeMatrixAreaInto(area: NightDateArea): NightDateArea {
    const matrixArea = this.toArea();
    if (matrixArea == null) {
      return area;
    }

    return area.merge(matrixArea);
  }

  private toArea(): NightDateArea | null {
    if (this.state.matrix == null) {
      return null;
    }

    return new NightDateArea(
      DateRange.fromArray(this.state.matrix.colHeader.getSegments()),
      NumberRange.fromArray(this.state.matrix.rowHeader.getSegments())
    );
  }

  private updateLoadedTableAreasByLastSearchData(params?: SearchParams): void {
    const nights = params?.duration;
    const dates = params?.dates;

    if (!nights?.from || !nights?.to || !dates?.checkIn || !dates.checkOut) {
      return;
    }

    const { from, to } = convertStringRangeToDateRange({
      from: dates.checkIn,
      to: dates.checkOut
    });

    if (!from || !to) {
      return;
    }

    const area = NightDateArea.fromArrays([from, to], [nights.from, nights.to]);
    this.updateMatrix(area, LoadingStatus.Loaded);
  }
}
