import { Inject, Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { WINDOW } from './window.service';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface Config {
  breakPoints: { [key in Breakpoint]: { min: number, max: number } };
  debounceTime: number;
}

const config: Config = {
  breakPoints: {
    xs: { min: 0, max: 539 },
    sm: { min: 540, max: 719 },
    md: { min: 720, max: 959 },
    lg: { min: 960, max: 1279 },
    xl: { min: 1280, max: Infinity }
  },
  debounceTime: 100
};

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {
  windowSize$ = fromEvent(this.window, 'resize')
    .pipe(
      startWith({ }), // induce to defining initial breakpoint value
      debounceTime(config.debounceTime),
      map(() => this.defineBreakpoint(this.window.innerWidth)),
      distinctUntilChanged()
    );

  constructor(@Inject(WINDOW) private window: Window) { }

  private defineBreakpoint(screenWidth: number): Breakpoint {
    const [breakpoint] = Object
      .entries(config.breakPoints)
      .find(([_, { min, max }]) => screenWidth >= min && screenWidth <= max) ?? [];
    this.assertBreakpoint(breakpoint);

    return breakpoint;
  }

  private assertBreakpoint(value: unknown): asserts value is Breakpoint {
    if (Object.keys(config.breakPoints).includes(value as Breakpoint)) {
      return;
    }

    throw new Error(`${value} is not correct breakpoint value`);
  }
}
