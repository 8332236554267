<div class="icons-set">
  <div class="icons-set__item icons-set__item_left">
    <app-star-icons *ngIf="stars" [stars]="stars"></app-star-icons>
  </div>
  <div class="icons-set__item icons-set__item_right">
    <app-accommodation-icon
      *ngIf="accommodation"
      [value]="accommodation"
      [title]="', ' | safeJoin
        : ('Common.AdultsNumeral' | translate:{ count: adultsCount })
        : ('Common.ChildrenNumeral' | translate: { count: childrenCount } | lowercase)
    ">
    </app-accommodation-icon>
    <app-transport-icon
      *ngIf="transport"
      [value]="transport"
      [title]="('Common.TransportType.' + transport) | translate">
    </app-transport-icon>
    <app-food-icon
      *ngIf="food"
      [value]="food"
      [title]="('Common.FoodType.' + food) | translate">
    </app-food-icon>
  </div>
</div>
