import { ChangeDetectionStrategy, Component } from '@angular/core';

import { PriceDisplayOptionsComponent } from '../price-display-options/price-display-options.component';

@Component({
  selector: 'app-price-display-options-select',
  templateUrl: 'price-display-options-select.component.html',
  styleUrls: ['../price-display-options/price-display-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceDisplayOptionsSelectComponent extends PriceDisplayOptionsComponent { }
