import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { SessionService } from '../session.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  constructor(private readonly session: SessionService) { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.session.getClientId()
      .pipe(
        switchMap(id => next.handle(id ? this.setClientId(id, req) : req)),
        catchError((err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            return this.session.refreshSession().pipe(
              switchMap(id => next.handle(this.setClientId(id, req)))
            );
          }

          return throwError(err);
        })
      );
  }

  private setClientId(id: string, req: HttpRequest<unknown>): HttpRequest<unknown> {
    return req.clone({
      headers: req.headers.set('Client-Id', id)
    });
  }
}
