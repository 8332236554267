<button
  class="btn scroll-btn d-none d-sm-inline-block"
  type="button"
  (click)="left.emit()">
  <fa-icon [icon]="['fas', 'angle-left']"></fa-icon>
</button>
<ng-content></ng-content>
<button
  class="btn scroll-btn d-none d-sm-inline-block"
  type="button"
  (click)="right.emit()">
  <fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
</button>
