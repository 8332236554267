import { DateRange } from '@classes/range/date-range';
import { NumberRange } from '@classes/range/number-range';

import { NDAreaItem } from '../../interfaces/night-date-area-item';

export class NightDateArea {
  constructor(protected datesValue: DateRange, protected nightsValue: NumberRange) { }

  get dates(): DateRange {
    return this.datesValue;
  }

  get nights(): NumberRange {
    return this.nightsValue;
  }

  static fromArrays(dates: Date[], nights: number[]): NightDateArea {
    return new NightDateArea(
      DateRange.fromArray(dates),
      NumberRange.fromArray(nights)
    );
  }

  eachValue(): NDAreaItem[] {
    const nights: number[] = this.nights.eachValue();

    return this.dates.eachValue()
      .map(
        d => nights.map(n => ({ date: new Date(d), nights: n }))
      ).reduce(
        (acc, curr) => [...acc, ...curr],
        []
      );
  }

  merge(area: NightDateArea): NightDateArea {
    return new NightDateArea(this.dates.merge(area.dates), this.nights.merge(area.nights));
  }

  isValuesInside(date: Date, nights: number): boolean {
    return this.dates.isInside(date) && this.nights.isInside(nights);
  }
}
