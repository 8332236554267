<ul class="tours-list" *ngIf="tours">
  <li class="tours-list__item hot-tour" *ngFor="let tour of tours">
    <a
      class="hot-tour__name hot-tour__link"
      [title]="(tour.countryName | fieldTranslation)"
      [routerLink]="tour.offer.hotel.hotelUrl | currentLangLink"
      [queryParams]="tour.offer | blockOfferQuery : tour.searchQuery">
      {{ (tour.countryName | fieldTranslation) }}
    </a>
    <span class="hot-tour__price">
      {{ 'HotToursBlock.Item.Price' | translate:
      {
        price: (tour.offer.priceUAH | currency:'UAH':'грн':'1.0-0')
      }
      }}
    </span>
  </li>
</ul>
