<div class="btn-group btn-group-toggle checkbox-filter">
  <label class="checkbox-filter__item" *ngFor="let checkbox of checkboxes">
    <input class="invisible" type="checkbox" [checked]="checkbox.checked" (change)="toggle(checkbox)">
    <span class="checkbox">
      <svg viewBox="0 0 15 15" width="15" height="15">
        <path d="M 1.667 0 L 13.333 0 L 13.333 0 C 14.254 0 15 0.745 15 1.667 L 15 13.333 L 15 13.333 C 15 14.254 14.254 15 13.333 15 L 1.667 15 L 1.667 15 C 0.746 15 0 14.254 0 13.333 L 0 1.667 L 0 1.667 C 0 0.745 0.746 0 1.667 0 Z"></path>
        <polyline points="2 8 5.667 12 13 3"></polyline>
      </svg>
    </span>
    <span class="label" *ngIf="checkbox.value">
      <ng-template #defaultLabelTemplate>{{ checkbox.value }}</ng-template>
      <ng-container
        [ngTemplateOutlet]="labelTemplate || defaultLabelTemplate"
        [ngTemplateOutletContext]="{ label: checkbox.value }">
      </ng-container>
    </span>
  </label>
</div>
