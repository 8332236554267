<div class="transport-menu">
  <div class="flight-group" *ngFor="let offer of (offers$ | async)" (click)="selectOffer(offer)">
    <div style="height: 1.3em">
      <ng-container *ngIf="offer.operatorId | tourOperator | async as tourOperator">
        {{ 'Common.TourOperator' | translate }}: {{ tourOperator.name }}
        <img style="height: 100%"
             [src]="tourOperator.logo"
             [alt]="tourOperator.name + ' logo'">
      </ng-container>
      <span style="color: red" *ngIf="offer && calculatePriceDiff(offer) > 0">
        {{
        'HotelOverviewPage.RelativePrice' | translate: {
          price: calculatePriceDiff(offer) | currency:'UAH':'symbol-narrow':'1.0-0'
        }
        }}
      </span>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-6">
          <div class="flight-group__from">
            <div class="flight">
              <app-flights-list
                *ngIf="offer?.flights?.from as flights"
                [(selectedFlight)]="flightFrom"
                [flights]="flights">
              </app-flights-list>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="flight-group__to">
            <div class="flight">
              <app-flights-list
                *ngIf="offer?.flights?.to as flights"
                [(selectedFlight)]="flightTo"
                [flights]="flights">
              </app-flights-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
