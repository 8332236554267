<ngx-json-ld *ngIf="items" [json]="items | breadcrumbListJsonLd"></ngx-json-ld>
<nav *ngIf="items">
  <ol class="breadcrumbs">
    <li *ngFor="let item of items; let i = index;">
      <ng-container *ngIf="(items.length - 1) === i; then currentPage else link;"></ng-container>
      <ng-template #link>
        <a [routerLink]="item.link | currentLangLink">{{ item.title }}</a>
      </ng-template>
      <ng-template #currentPage>
        <span>{{ item.title }}</span>
      </ng-template>
    </li>
  </ol>
</nav>
