import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { FilterTabHeaderComponent } from '../filter-tab-header/filter-tab-header.component';
import { openClose } from '../../animations/open-close';

@UntilDestroy()
@Component({
  selector: 'app-filter-tab',
  templateUrl: './filter-tab.component.html',
  styleUrls: ['./filter-tab.component.scss'],
  animations: [openClose],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterTabComponent implements OnInit {
  @ContentChild(FilterTabHeaderComponent, { static: true }) header!: FilterTabHeaderComponent;

  private isOpenValue = false;

  constructor(private cd: ChangeDetectorRef) { }

  @Input()
  set isOpen(val: boolean) {
    this.isOpenValue = val;
    this.header.isOpen = val;
  }

  get isOpen() {
    return this.isOpenValue;
  }

  ngOnInit(): void {
    this.header.toggleTab
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isOpen = !this.isOpen;
        this.cd.markForCheck();
      });
  }
}
