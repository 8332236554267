import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'descriptionTranslateMapper'
})
export class DescriptionTranslateMapperPipe implements PipeTransform {
  private readonly translatePath: string = 'HotelOverviewPage.Descriptions';

  transform(code: string): string {
    return `${this.translatePath}.${code}`;
  }
}
