import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {
  transform(base: string, ...pieces: string[]): string {
    return [base].concat(pieces).join('');
  }
}
