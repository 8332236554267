import { Ng5SliderModule } from 'ng5-slider';
import { NgxMaskModule } from 'ngx-mask';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { NumbersRangeModule, TouristPickerModule } from 'package-angular';

import { SearchHotelsPickerComponent } from './components/search-hotels-picker/search-hotels-picker.component';
import { SearchResortsPickerComponent } from './components/search-resorts-picker/search-resorts-picker.component';
import { DateRangePipe } from './components/date-range/date-range.pipe';
import { DepartureCityComponent } from './components/departure-city/departure-city.component';
import { HotelRatingComponent } from './components/hotel-rating/hotel-rating.component';
import { HotelStarsComponent } from './components/hotel-stars/hotel-stars.component';
import { DestinationGroupPipe } from './components/destination/destination-group.pipe';
import { DestinationComponent } from './components/destination/destination.component';
import { CostRangeComponent } from './components/cost-range/cost-range.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { TourDurationComponent } from './components/tour-duration/tour-duration.component';
import { TouristsComponent } from './components/tourists/tourists.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SearchSelectorComponent } from './components/search-selector/search-selector.component';
import { SearchBaseComponent } from './components/search-base/search-base.component';
import { SharedModule } from '@modules/tours/shared/shared.module';
import { ChildLabelComponent } from './components/tourists/child-label/child-label.component';
import { SearchPickerOptionComponent } from './components/search-picker-option/search-picker-option.component';
import { HotelSelectorDataPipe } from './components/search-hotels-picker/hotel-selector-data.pipe';
import { SearchModalsModule } from './search-modals/search-modals.module';

const pipes = [
  DateRangePipe,
  DestinationGroupPipe,
  HotelSelectorDataPipe
];

const components = [
  DestinationComponent,
  DateRangeComponent,
  TouristsComponent,
  DepartureCityComponent,
  TourDurationComponent,
  SearchSelectorComponent,
  HotelRatingComponent,
  SearchHotelsPickerComponent,
  HotelStarsComponent,
  SearchResortsPickerComponent,
  CostRangeComponent,
  SearchInputComponent,
  SearchBaseComponent,
  ChildLabelComponent,
  SearchPickerOptionComponent
];

@NgModule({
  declarations: [
    ...components,
    ...pipes
  ],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    NgbDatepickerModule,
    NgSelectModule,
    Ng5SliderModule,
    NgbDropdownModule,
    NgxMaskModule.forRoot(undefined),
    FormsModule,
    SharedModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    NumbersRangeModule,
    TouristPickerModule,
    SearchModalsModule
  ],
  exports: [
    DestinationComponent,
    DateRangeComponent,
    TouristsComponent,
    DepartureCityComponent,
    TourDurationComponent,
    SearchSelectorComponent,
    HotelRatingComponent,
    SearchHotelsPickerComponent,
    HotelStarsComponent,
    SearchResortsPickerComponent,
    CostRangeComponent,
    SearchInputComponent
  ]
})
export class SearchModule { }
