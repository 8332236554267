<ng-select [clearable]="false"
           [searchable]="false"
           appendTo="body"
           [(ngModel)]="selectedCity"
           [items]="(departureCities$ | async)!"
           [placeholder]="'Common.NotSelected' | translate"
           [notFoundText]="'Common.NotFound' | translate"
           bindValue="id">
  <ng-template ng-label-tmp ng-option-tmp let-item="item">
    <!-- Item can be default ng-select { label, id } object. This check prevent incorrect object usage.-->
    <ng-container *ngIf="item?.nameFrom && item?.id">
      {{ item | departureCityLabel }}
    </ng-container>
  </ng-template>
</ng-select>
