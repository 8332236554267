import { Injector, NgModule, Type } from '@angular/core';

@NgModule()
export class StaticInjectorModule {
  private static injector?: Injector;

  constructor(injector: Injector) {
    StaticInjectorModule.injector = injector;
  }

  public static get<T>(token: Type<T>): T {
    if (!StaticInjectorModule.injector) {
      throw new Error('Static injector should be initialized');
    }

    return StaticInjectorModule.injector.get<T>(token);
  }
}
