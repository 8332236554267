import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { ErrorModalStore } from '../../services/store/error-modal.store';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorModalComponent {
  messageTranslation$: Observable<string[]> = this.errorModalStore.onChanges('messageTranslation');

  constructor(public modal: NgbActiveModal, private errorModalStore: ErrorModalStore) { }
}
