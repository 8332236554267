import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filter-tab-header',
  templateUrl: './filter-tab-header.component.html',
  styleUrls: ['./filter-tab-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterTabHeaderComponent {
  @Input() title = '';
  @Input() isOpen = false;
  @Output() toggleTab = new EventEmitter<void>();
}
