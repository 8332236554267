import { SearchParams } from '@interfaces/modules/tours/search';
import { ToursSearchResult } from '@interfaces/tours-search-result';

export enum SearchEventType {
  Start = 'Start',
  Result = 'Result'
}

export interface StartSearchEvent {
  type: SearchEventType.Start;
  params: SearchParams;
}

export interface SearchResultEvent extends ToursSearchResult {
  type: SearchEventType.Result;
}

export type HotelSearchEvent = StartSearchEvent | SearchResultEvent;
