import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Gateway } from 'package-types';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class AuthEndpoint {
  private readonly basePath = environment.apiUrl;

  constructor(private readonly http: HttpClient) { }

  startSession(): Observable<Gateway.Actions.StartSessionResult> {
    return this.http.post<Gateway.Actions.StartSessionResult>(`${this.basePath}/session/start`, null);
  }
}
