import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-offer-option',
  templateUrl: './offer-option.component.html',
  styleUrls: ['./offer-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferOptionComponent {
  @Input() text = '';
  @Input() roundCorners = true;

  @HostBinding('style.border-radius.px')
  get borderRadius() {
    return this.roundCorners ? 5 : 0;
  }
}
