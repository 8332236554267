import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { subDays } from 'date-fns';

import { Required } from 'package-utils';
import { ToursCatalog, ToursSearch } from 'package-types';

import { AvailableTourDates } from '../../services/post-destination-review.state';
import { AppConfig, APP_CONFIG } from '../../../../../app-config.module';
import { PostDestinationReviewStore } from '../../services/post-destination-review.store';
import { PostDestinationReviewService } from '../../services/post-destination-review.service';
import { NotificationService } from '@services/notification.service';
import { SuccessMessage } from '@constants/success-message.enum';
import { formatToISODate } from '@helpers/date.helper';
import { strictTourRange } from '@helpers/validators.helper';
import { ErrorMessage } from '@constants/error-message.enum';

import { ActionStatus } from '@interfaces/action-status';

@UntilDestroy()
@Component({
  selector: 'app-post-review-form',
  templateUrl: './post-review-form.component.html',
  styleUrls: ['./post-review-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PostDestinationReviewService, PostDestinationReviewStore]
})
export class PostReviewFormComponent implements OnInit {
  @Required @Input() destinationId!: number;
  @Required @Input() destinationType!: ToursSearch.DestinationType;
  @Input() postButtonTheme: 'accent' | 'primary' = 'primary';

  @Output() posted = new EventEmitter<void>();

  reviewForm = this.createFormGroup();
  availableTourDates$: Observable<AvailableTourDates> = this.postReviewStore.onChanges('availableToursDates');
  reviewPostingStatus$: Observable<ActionStatus> = this.postReviewStore.onChanges('loadingStatus', 'postReview');
  imagesUploadingStatus$: Observable<ActionStatus[]> = this.postReviewStore.onChanges('loadingStatus', 'uploadImages');

  constructor(
    @Inject(APP_CONFIG) public readonly config: AppConfig,
    private readonly fb: FormBuilder,
    private readonly cd: ChangeDetectorRef,
    private readonly postReviewStore: PostDestinationReviewStore,
    private readonly reviewService: PostDestinationReviewService,
    private readonly notification: NotificationService
  ) { }

  get imagesForm() {
    return this.reviewForm.get('images') as FormArray;
  }

  ngOnInit(): void {
    this.reviewPostingStatus$
      .pipe(untilDestroyed(this))
      .subscribe((status) => {
        this.showNotification(status);
        if (status === 'success') {
          this.posted.emit();
          this.reviewForm.reset();
        }
      });
  }

  removeImage(idx: number) {
    this.imagesForm.removeAt(idx);
    this.postReviewStore.spliceImageUploadStatus(idx);
  }

  send(): void {
    const destination: ToursCatalog.Destination = {
      id: this.destinationId,
      type: this.destinationType
    };
    this.reviewService.postReview(destination, this.reviewForm.value);
  }

  addImages(files: File[]) {
    const imagesForm = this.reviewForm.get('images') as FormArray;
    files.forEach(file => imagesForm.push(
      this.fb.control({ file, title: null })
    ));
    this.cd.markForCheck();
  }

  private showNotification(status: ActionStatus): void {
    switch (status) {
      case 'success':
        return this.notification.success(SuccessMessage.ReviewPosted);
      case 'error':
        return this.notification.error(ErrorMessage.SendingReviewFailure);
    }
  }

  private createFormGroup() {
    return this.fb.group({
      dates: [
        {
          checkIn: formatToISODate(subDays(new Date(), 20)),
          checkOut: formatToISODate(subDays(new Date(), 15))
        },
        Validators.compose([Validators.required, strictTourRange])
      ],
      message: [null, Validators.required],
      votes: [null, Validators.required],
      images: this.fb.array([])
    });
  }
}
