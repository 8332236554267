import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map } from 'rxjs/operators';

import { ToursCatalogEndpoint } from '@services/api/tours-catalog.endpoint';

@Component({
  selector: 'app-hot-tours-card',
  templateUrl: './hot-tours-card.component.html',
  styleUrls: ['./hot-tours-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotToursCardComponent {
  public hotTours = this.toursCatalog
    .getHotToursBlock()
    .pipe(map(res => res.hotToursBlock));

  constructor(private toursCatalog: ToursCatalogEndpoint) { }
}
