import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { GlobalSearchService } from '@services/tours-search/global-search.service';

import { SearchParams } from '@interfaces/modules/tours/search';

@UntilDestroy()
@Component({
  selector: 'app-search-failed-modal',
  templateUrl: './search-failed-modal.component.html',
  styleUrls: ['../modal-common-style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchFailedModalComponent {
  @Input() searchParams!: SearchParams;

  constructor(
    private modal: NgbActiveModal,
    private globalSearch: GlobalSearchService
  ) { }

  search(): void {
    this.globalSearch
      .navigateToSearchPage(this.searchParams)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.modal.close());
  }

  close(): void {
    this.modal.close();
  }
}
