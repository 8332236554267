import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseFloat'
})
export class ParseFloatPipe implements PipeTransform {
  transform(value?: string): number | undefined {
    // for using optional values in template
    if (!value) {
      return undefined;
    }

    const float = Number.parseFloat(value);

    return Number.isNaN(float) ? undefined : float;
  }
}
