import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Images } from 'package-types';

import { ImagesEndpoint } from './api/images.endpoint';
import { replaceError } from '@helpers/rx.helper';

@Injectable()
export class OverviewImagesLoaderService {
  constructor(private readonly images: ImagesEndpoint) { }

  enrichOverviewByImageResizes<T extends { headImageId?: string }>(
    overviews: T[],
    resizesMap: Images.ImageResizesLinks
  ): (T & { imageResizes: Images.ResizesLinks })[] {
    return overviews.map(item => ({ ...item, imageResizes: resizesMap[item.headImageId as string] ?? { } }));
  }

  loadHeadImages(entities: { _id: string, headImageId?: string }[]): Observable<Images.ImageResizesLinks> {
    return of(entities).pipe(
      map(entities => entities
        .map(({ headImageId, _id }) => ({ headImageId, entityId: _id }))
        .filter((item): item is { entityId: string, headImageId: string } => item.headImageId !== undefined)
      ),
      switchMap(ids => this.images.getCustomImagesLinks(ids.map(item => item.entityId))
        .pipe(
          map(res => res.imageResizesLinks),
          replaceError('Images links are not loaded', { })
        )
      )
    );
  }
}
