<app-table-skeleton
  class="nd-table"
  [title]="'TourOffer.Options.Dates' | translate"
  [emptyTableMessage]="'HotelOverviewPage.PriceTable.EmptyTableMessage' | translate"
  (cellClick)="cellClicked($event)"
  *ngIf="table$ | async as table"
  [table]="table">
  <ng-template appTableColumnLabelTemplate let-date="item">
    {{ date | dateFormat:'EEEEEE dd.LL' | uppercase }}
  </ng-template>
  <ng-template appTableRowLabelTemplate let-nights="item">
    {{ 'HotelOverviewPage.Nights' | translate: { nights: nights } }}
  </ng-template>
  <ng-template appTableCellTemplate let-cell="cell">
    <app-night-date-cell class="nd-table__cell" [cell]="cell"></app-night-date-cell>
  </ng-template>
</app-table-skeleton>
