import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Images, ServiceName } from 'package-types';

import { TransferHttpService } from '@services/transfer-http.service';
import { environment } from '@environment';

import GetCustomImagesLinksRes = Images.Actions.SiteGetCustomImagesLinksResult;
import GetDestinationReviewImagesRes = Images.Actions.SiteGetDestinationReviewsImagesLinksResult;
import GetHotelImagesLinksRes = Images.Actions.SiteGetHotelImagesLinksResult;

@Injectable({
  providedIn: 'root'
})
export class ImagesEndpoint {
  private readonly basePath = `${environment.apiUrl}/${ServiceName.Images}`;

  constructor(private http: TransferHttpService) { }

  getCustomImagesLinks(entityIds: string[]): Observable<GetCustomImagesLinksRes> {
    if (!entityIds.length) {
      return of({ imageResizesLinks: { } });
    }

    return this.http.post<GetCustomImagesLinksRes>(`${this.basePath}/site/entity/links`, { entityIds });
  }

  getDestinationReviewImages(imagesIds: string[]): Observable<GetDestinationReviewImagesRes> {
    return this.http.post<GetDestinationReviewImagesRes>(`${this.basePath}/site/destinations/reviews/links`, { imagesIds });
  }

  getHotelImagesLinks(hotelIds: number[]): Observable<GetHotelImagesLinksRes> {
    return this.http.post<GetHotelImagesLinksRes>(`${this.basePath}/site/hotel/links`, { hotelIds });
  }
}
