import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { ToursSearch } from 'package-types';

import { SearchModalsService } from '@modules/tours/search/search-modals/search-modals.service';
import { IrrelevantSearchError } from './errors/irrelevant-search.error';
import { TooLongSearchError } from './errors/too-long-search.error';

import { SearchParams } from '@interfaces/modules/tours/search';

@Injectable({
  providedIn: 'root'
})
export class SearchErrorsService {
  constructor(
    private readonly searchModalsService: SearchModalsService
  ) { }

  handleSearchErrors(err: unknown, search: SearchParams) {
    if (this.isSearchQueryExpiredHttpError(err) || err instanceof IrrelevantSearchError) {
      this.searchModalsService.showSearchResultExpiredModal(search);

      return;
    }
    if (err instanceof TooLongSearchError) {
      this.searchModalsService.showSearchTimeoutExpiredModal(search);

      return;
    }
    this.searchModalsService.showSearchFailedModal(search);
  }

  private isSearchQueryExpiredHttpError(err: unknown): err is HttpErrorResponse {
    return err instanceof HttpErrorResponse && err.error.data?.reason?.code === ToursSearch.ReasonCode.SearchQueryExpired;
  }
}
