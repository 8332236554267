import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ControlValueAccessor } from '@angular/forms';
import { map } from 'rxjs/operators';

import { BackOffice } from 'package-types';

import { BackOfficeEndpoint } from '@services/api/back-office.endpoint';
import { NotificationService } from '@services/notification.service';
import { valueAccessor } from '@helpers/angular.helper';
import { OfficesStore } from '@services/store/offices.store';

@Component({
  selector: 'app-office-select',
  templateUrl: './office-select.component.html',
  styleUrls: ['./office-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [valueAccessor(OfficeSelectComponent), OfficesStore]
})
export class OfficeSelectComponent implements ControlValueAccessor, OnInit {
  offices$: Observable<BackOffice.SiteOffice[]> = this.offices.onChanges('list')
    .pipe(map(offices => offices.filter(o => !o.isTemporarilyClosed)));

  private selectedOfficeValue: BackOffice.SiteOffice | null = null;
  private onTouched = () => { };
  private onChange = (_: any) => { };

  constructor(
    private readonly backOffice: BackOfficeEndpoint,
    private readonly notification: NotificationService,
    private readonly translate: TranslateService,
    private readonly offices: OfficesStore
  ) { }

  set selectedOffice(office: BackOffice.SiteOffice | null) {
    this.selectedOfficeValue = office;
    this.onChange(office);
  }

  get selectedOffice(): BackOffice.SiteOffice | null {
    return this.selectedOfficeValue;
  }

  writeValue(obj: any): void {
    this.selectedOfficeValue = obj;
  }

  registerOnChange(fn: (_: any) => { }): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => { }): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    this.offices.init();
  }
}
