<app-cell-skeleton [type]="type$ | async" [mainInfo]="offer | offerPrice">
  <ng-container [ngSwitch]="layout$ | async">
    <app-additional-offer-info *ngSwitchCase="CellLayout.AdditionalInfo">
      <ng-container *ngIf="cell.data as data">
        <span>{{ 'HotelOverviewPage.Nights' | translate: { nights: data.nights } }}</span>
        <span>{{ data.dateRange.from | dateFormat:'dd MMMM' }}</span>
      </ng-container>
    </app-additional-offer-info>
    <span *ngSwitchCase="CellLayout.EmptyOffer">-</span>
  </ng-container>
  </app-cell-skeleton>
