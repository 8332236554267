import { Store } from 'rxjs-observable-store';
import { Injectable } from '@angular/core';

import { TableDrivenFilterState } from '@pages/tours/hotel-overview-page/modules/available-offers/services/store/table-driven-filter.state';

import { TableDrivenFilter } from '@pages/tours/hotel-overview-page/modules/available-offers/interfaces/table-driven-filter';

@Injectable()
export class TableDrivenFilterStore extends Store<TableDrivenFilterState> {
  constructor() {
    super(new TableDrivenFilterState());
  }

  updateFilter(val: TableDrivenFilter): void {
    this.setState({ ...this.state, ...val });
  }
}
