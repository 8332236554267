import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { ToursCatalog, ToursSearch } from 'package-types';

import { InformationStore } from '@services/store/information.store';
import { HotelsPageStore, PageParams } from './hotels-page.store';
import { DynamicPage } from '@classes/decorators/dynamic-page.decorator';

@DynamicPage({
  pageName: ToursCatalog.PageName.Hotels,
  setDefaultSearchParams: true,
  disableH1SmallUpdates: true
})
@Component({
  selector: 'app-hotels-page',
  templateUrl: './hotels-page.component.html',
  styleUrls: ['./hotels-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HotelsPageStore]
})
export class HotelsPageComponent implements OnInit {
  hotels$: Observable<ToursSearch.HotelProposal[]> = this.hotelsPage.onChanges('hotels');
  isLoading$: Observable<boolean | undefined> = this.hotelsPage.onChanges('requests', ToursCatalog.Actions.Name.GetHotelsForPage, 'inProgress');
  hasAvailableHotels$: Observable<boolean> = this.hotelsPage.onChanges('hasAvailableHotels');
  resortPageInfo$ = this.information.resortPageInfo$;

  constructor(
    private readonly hotelsPage: HotelsPageStore,
    private readonly information: InformationStore
  ) { }

  ngOnInit(): void {
    this.hotelsPage.init(this.getInitSearchParams());
  }

  loadNextPage(): void {
    this.hotelsPage.loadNextPage();
  }

  private getInitSearchParams(): PageParams {
    const { pageInfo } = this.information.state;

    switch (pageInfo?.type) {
      case ToursCatalog.PageType.Country:
        return { countryId:  pageInfo.country.otpuskId };
      case ToursCatalog.PageType.Resort:
        return { resortId: pageInfo.resort.otpuskId };
      default:
        return { };
    }
  }
}
