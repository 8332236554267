import difference from 'lodash/difference';

import { BackOffice, Weekday } from 'package-types';

export enum NaturalWeekday {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

export interface WorkHours {
  start: string;
  end: string;
}

export interface WeekdaysGroup {
  weekdays: NaturalWeekday[];
  workHours?: WorkHours;
}

export class WorkHoursNormalizer {
  private readonly allWeekdays = this.getWeekdayEnumValues();

  private groups: WeekdaysGroup[] = [];
  private consumedWeekdays: Weekday[] = [];

  constructor(private readonly schedule: BackOffice.OfficeWorkHours[]) { }

  groupWeekdaysByWorkHours(): WeekdaysGroup[] {
    for (const item of this.schedule) {
      this.addWeekdayToCorrespondingGroup(item);
      this.consumedWeekdays.push(item.weekday);
    }

    this.addDaysOffGroupIfNeeded();

    return this.groups;
  }

  private addWeekdayToCorrespondingGroup({ workHours, weekday }: BackOffice.OfficeWorkHours): void {
    const naturalWeekday = this.toNaturalWeekday(weekday);
    const targetGroup = this.ensureSuitableGroup(workHours);
    targetGroup.weekdays.push(naturalWeekday);
  }

  private ensureSuitableGroup(workHours: WorkHours) {
    const foundGroup = this.groups.find(group => group.workHours !== undefined && this.isSameWorkHours(group.workHours, workHours));

    return foundGroup ?? this.declareNewGroup(workHours);
  }

  private declareNewGroup({ start, end }: WorkHours) {
    const newGroup = { workHours: { start, end }, weekdays: [] };
    this.groups.push(newGroup);

    return newGroup;
  }

  private getWeekdayEnumValues(): Weekday[] {
    return Object.values(Weekday)
      .filter((item): item is Weekday => typeof item === 'number');
  }

  private addDaysOffGroupIfNeeded(): void {
    const daysOff = this.collectDaysOff();
    if (daysOff.length) {
      this.groups.push({ weekdays: daysOff });
    }
  }

  private collectDaysOff(): NaturalWeekday[] {
    return difference(this.allWeekdays, this.consumedWeekdays).map(val => this.toNaturalWeekday(val));
  }

  private isSameWorkHours(a: WorkHours, b: WorkHours): boolean {
    return a.start === b.start && a.end === b.end;
  }

  private toNaturalWeekday(weekday: Weekday): NaturalWeekday {
    return weekday === Weekday.Sunday ? NaturalWeekday.Sunday : weekday - 1;
  }
}
