<div class="book-offer">
  <app-modal-header (closeModal)="closeModal()">
    <div class="book-offer__title">
      <h4 class="book-offer__text">{{ 'BookOfferModal.Title' | translate }}</h4>
      <span class="book-offer__offer-id" *ngIf="offer$ | async as offer">
        {{ offer.offerId }}
      </span>
    </div>
    <div class="book-offer__price" *ngIf="offer$ | async as offer">
      {{ offer.priceUAH.toNumber() | currency:'UAH':'symbol-narrow':'1.0-0' }}
    </div>
  </app-modal-header>
  <div class="book-offer__body">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="book-offer__block">
            <div class="book-offer__title">{{ 'BookOfferModal.Body.Contacts' | translate }}</div>
            <div class="book-offer__content">
              <app-contact-form
                [isLoading]="creationLoading$ | async"
                (submitContact)="orderTour($event)">
              </app-contact-form>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="book-offer__block">
            <div class="book-offer__title">{{ 'BookOfferModal.Body.TourOptions' | translate }}</div>
            <div class="book-offer__content">
              <app-tour-options
                *ngIf="offer$ | async as offer"
                [offer]="offer"
                [flights]="(flights$ | async)!">
              </app-tour-options>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
