<div class="reviews">
  <div class="reviews__title">{{ review.message.title }}</div>
  <div class="reviews__dates">{{ review | hotelReviewTourRange }}</div>
  <div class="reviews__rating" *ngIf="review.rating">
    <app-rating-mark
      [value]="review.rating"
      [category]="'HotelOverviewPage.Rating.Rating' | translate"
      [clarification]="'HotelOverviewPage.Rating.ToMaxValue' | translate: { value: 10 }">
    </app-rating-mark>
    <div class="reviews__votes" *ngIf="review.votes as votes">
      <app-rating-mark
        *ngFor="let item of votes"
        class="reviews__vote"
        [value]="item.vote"
        [category]="item.code | ratingTranslateMapper | translate"
        [clarification]="'HotelOverviewPage.Rating.ToMaxValue' | translate: { value: 10 }">
      </app-rating-mark>
    </div>
  </div>
  <div class="reviews__text" *ngIf="review.message.text">
    <app-read-more [text]="review.message.text"></app-read-more>
  </div>
  <div class="reviews__gallery" *ngIf="galleryImages.length">
    <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
  </div>
</div>
