import { TranslateLoader } from '@ngx-translate/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export class TranslateBrowserLoader implements TranslateLoader {
  constructor(
    private readonly prefix: string,
    private readonly suffix: string,
    private readonly transferState: TransferState,
    private readonly http: HttpClient
  ) { }

  public getTranslation(lang: string): Observable<any> {
    const key = makeStateKey<number>(`transfer-translate-${lang}`);
    const data = this.transferState.get<any>(key, null);
    if (data) {
      return of(data);
    }

    return new TranslateHttpLoader(this.http, this.prefix, this.suffix).getTranslation(lang);
  }
}
