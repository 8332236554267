import { IconOptions } from 'leaflet';

export function getMarkerIconOptions(): IconOptions {
  return {
    iconSize: [25, 41],
    iconAnchor: [13, 41],
    iconUrl: 'assets/images/marker-icon.png',
    iconRetinaUrl: 'assets/images/marker-icon-2x.png',
    shadowUrl: 'assets/images/marker-shadow.png'
  };
}
