<div class="contacts">
  <div class="contacts__info" *ngIf="hotel?.address as hotelAddress">
    <h3 class="contacts__title">{{ 'HotelOverviewPage.Contacts' | translate }}</h3>
    <ul class="contacts__list">
      <li *ngIf="hotelAddress.address as address" class="contacts__item contacts__item_address">
        <span>{{ address }}</span>
      </li>
      <li *ngIf="hotelAddress.phones.length > 0" class="contacts__item contacts__item_phone">
        <span>{{ hotelAddress.phones.join(', ') }}</span>
      </li>
      <li *ngIf="hotelAddress.email as email" class="contacts__item contacts__item_email">
        <a [href]="email | mailto">{{ email }}</a>
      </li>
      <li *ngIf="hotelAddress.url as url" class="contacts__item contacts__item_site">
        <a [href]="url">{{ url }}</a>
      </li>
    </ul>
  </div>
  <div class="contacts__map" *ngIf="hotel?.location as location">
    <app-map-card [location]="location"></app-map-card>
  </div>
</div>
