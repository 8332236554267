<ng-select [clearable]="false"
           [searchable]="false"
           appendTo="body"
           [compareWith]="comparator"
           [(ngModel)]="selectedDuration"
           [items]="(durationsList$ | async)!">
  <ng-template ng-label-tmp ng-option-tmp let-item="item">
    {{ 'SearchComponent.DateRange.ParamLabel' | translate: { from: item?.from, to: item?.to } }}
  </ng-template>
</ng-select>
