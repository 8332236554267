<div class="container" container="body" ngbDropdown placement="bottom-right" #dropdown="ngbDropdown">
  <a (click)="renderDatepickerView()" class="link field_menu_toggler" id="dateRangeDropdown" ngbDropdownToggle>
    {{ dateRange | tourDateRange }}
  </a>
  <div class="dropdown-menu container-fluid" ngbDropdownMenu aria-labelledby="dateRangeDropdown">
      <div class="row">
        <ng-container #vc></ng-container>
      </div>
  </div>
</div>
<ng-template #datepicker>
  <ngb-datepicker  (select)="onDateSelection($event)"
                   [minDate]="currentMinDate"
                   [maxDate]="currentMaxDate"
                   [displayMonths]="displayMonths"
                   [dayTemplate]="t"
                   [startDate]="$any(dateRange).from"
                   outsideDays="hidden"
                   navigation="arrows">
  </ngb-datepicker>
  <ng-template #t let-date let-focused="focused">
          <span class="custom-day"
                [class.focused]="focused"
                [class.start-range]="isStartRange(date)"
                [class.end-range]="isEndRange(date)"
                [class.same-date]="isStartRange(date) && isEndRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = undefined">
              {{ date.day }}
          </span>
  </ng-template>
</ng-template>
