import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Options } from 'ng5-slider';

import { valueAccessor } from '@helpers/angular.helper';

const defaultRating = 4;

@Component({
  selector: 'app-hotel-rating',
  templateUrl: './hotel-rating.component.html',
  styleUrls: ['./hotel-rating.component.scss'],
  providers: [valueAccessor(HotelRatingComponent)]
})
export class HotelRatingComponent implements ControlValueAccessor {
  get range() {
    return this.rangeValue;
  }

  set range(range) {
    this.onTouched();
    this.onChange(range);
    this.rangeValue = range;
  }

  @Input() isTickWhite: boolean = false;

  private rangeValue: number = defaultRating;

  sliderOptions: Options = {
    floor: 4,
    ceil: 10,
    step: 1,
    showSelectionBarEnd: true,
    showTicks: true,
    showTicksValues: true
  };

  private onTouched: Function = () => { };
  private onChange: Function = () => { };

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  writeValue(range: number): void {
    this.rangeValue = range;
  }
}
