<div class="ov-rating" *ngIf="rating">
  <div class="ov-rating__header">
    <h2 class="ov-rating__title">{{ 'HotelOverviewPage.Rating.HotelRating' | translate }}</h2>
    <div class="ov-rating__value">
      <app-rating-mark
        [value]="rating.rating"
        [clarification]="'HotelOverviewPage.Rating.ToMaxValue' | translate: { value: 10 }"
        [bordered]="true">
      </app-rating-mark>
    </div>
  </div>
  <div class="ov-rating__categories">
    <div class="ov-rating__item" *ngFor="let vote of rating.votes">
      <app-rating-mark
        [value]="vote.vote"
        [category]="vote.code | ratingTranslateMapper | translate"
        [clarification]="'HotelOverviewPage.Rating.ToMaxValue' | translate: { value: 10 }">
      </app-rating-mark>
    </div>
    <div class="ov-rating__item">
      <app-rating-mark
        [value]="rating.count"
        [category]="'HotelOverviewPage.Rating.ReviewsCount' | translate">
      </app-rating-mark>
    </div>
  </div>
</div>
