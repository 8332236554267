import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ToursSearch } from 'package-types';

import { valueAccessor } from '@helpers/angular.helper';
import { SearchFormStore } from '@services/store/search-form.store';

@Component({
  selector: 'app-departure-city',
  templateUrl: './departure-city.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [valueAccessor(DepartureCityComponent)]
})
export class DepartureCityComponent implements ControlValueAccessor {
  departureCities$: Observable<ToursSearch.DepartureCity[]> = this.localSearch.onChanges('departureCities')
    .pipe(tap(cities => this.cleanSelectedCityIfNotDepartureCity(cities)));

  private selectedCityIdValue?: number;
  private onTouched: Function = () => { };
  private onChange: Function = () => { };

  constructor(
    private readonly localSearch: SearchFormStore,
    private readonly cd: ChangeDetectorRef
  ) { }

  get selectedCity() {
    return this.selectedCityIdValue;
  }

  set selectedCity(selectedCityId) {
    this.selectedCityIdValue = selectedCityId;
    this.onTouched();
    this.onChange(selectedCityId);
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  writeValue(city: number): void {
    this.selectedCityIdValue = city;
    this.cd.markForCheck();
  }

  private cleanSelectedCityIfNotDepartureCity(cities: ToursSearch.DepartureCity[]): void {
    if (cities.find(c => c.id === this.selectedCityIdValue)) {
      return;
    }
    this.selectedCity = undefined;
  }
}
