import { interval, Subscription } from 'rxjs';

export class Interval {
  private sub?: Subscription;

  constructor(
    private readonly fn: () => any,
    private readonly period = 5000
  ) { }

  start(): void {
    if (this.sub) return;

    this.sub = interval(this.period).subscribe(() => this.fn());
  }

  stop(): void {
    if (!this.sub) return;

    this.sub.unsubscribe();
    this.sub = undefined;
  }
}
