import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

import { Util } from 'package-types';

import { SiteMenuSection } from '@constants/site-menu-section';
import { SiteMenuItem } from '@constants/site-menu';
import { AppConfig, APP_CONFIG } from '../../../../app-config.module';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  @Input() activeMenuItem = SiteMenuSection.Tours;
  @Input() items: Readonly<SiteMenuItem<string | Util.Translation>[]> = [];

  public isOpen: boolean = false;
  public readonly phoneNumber: string;

  constructor(@Inject(APP_CONFIG) config: AppConfig) {
    this.phoneNumber = config.contactInfo.phoneNumbers.main;
  }

  toggleMenu(): void {
    this.isOpen = !this.isOpen;
  }
}
