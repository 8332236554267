import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';

import { MapCardComponent } from './components/map-card/map-card.component';
import { StarIconsComponent } from './components/star-icons/star-icons.component';
import { OfficeInfoComponent } from './components/office-info/office-info.component';
import { OfficesCardComponent } from './components/offices-card/offices-card.component';
import { ReadMoreComponent } from './components/read-more/read-more.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { LocationsGridComponent } from './components/locations-grid/locations-grid.component';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { CurrentLangLinkPipe } from './pipes/current-lang-link.pipe';
import { HeaderTitleComponent } from './components/header-title/header-title.component';
import { HeaderSubtitleDirective } from './directives/header-subtitle.directive';
import { BusPreloaderComponent } from './components/bus-preloader/bus-preloader.component';
import { DepartureCityLabelPipe } from '@modules/tours/shared/pipes/departure-city-label.pipe';
import { SpriteIconComponent } from './components/sprite-icon/sprite-icon.component';
import { HamburgerComponent } from './components/hamburger/hamburger.component';
import { CollapsablePanelComponent } from './components/collapsable-panel/collapsable-panel.component';
import { FieldTranslationPipe } from './pipes/field-translation.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { ActiveUrlPathDirective } from './directives/active-url-path.directive';
import { HotelCardComponent } from './components/hotel-card/hotel-card.component';
import { LoadMoreButtonComponent } from './components/load-more-button/load-more-button.component';
import { HotelsMapComponent } from './components/hotels-map/hotels-map.component';
import { OffersGridComponent } from './components/offers-grid/offers-grid.component';
import { RenderOnlyOnServerDirective } from './directives/render-only-on-server.directive';
import { RenderOnlyInBrowserDirective } from './directives/render-only-in-browser.directive';
import { TypeofPipe } from './pipes/typeof.pipe';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { FormInputDirective } from './components/form-field/form-input.directive';
import { ConvertBytesPipe } from './pipes/convert-bytes.pipe';
import { MimeTypesExtensionsPipe } from './pipes/mime-types-extensions.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { ReviewComponent } from './components/review/review.component';
import { RatingMarkComponent } from './components/rating-mark/rating-mark.component';
import { HotelReviewTourRangePipe } from './pipes/hotel-review-tour-range.pipe';
import { RatingTranslateMapperPipe } from './pipes/rating-translate-mapper.pipe';
import { SpinnerLoaderDirective } from './directives/spinner-loader.directive';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PrevTemplateDirective } from './components/pagination/directives/prev-template.directive';
import { PageTemplateDirective } from './components/pagination/directives/page-template.directive';
import { EllipsisTemplateDirective } from './components/pagination/directives/ellipsis-template.directive';
import { NextTemplateDirective } from './components/pagination/directives/next-template.directive';
import { SrcSetPipe } from './pipes/src-set.pipe';
import { WorkSchedulePipe } from './pipes/work-schedule/work-schedule.pipe';
import { LocationCardComponent } from './components/location-card/location-card.component';
import { GridContentTemplateDirective } from './components/locations-grid/grid-content-template.directive';
import { ParseFloatPipe } from './pipes/parse-float.pipe';
import { NgxGalleryImagesPipe } from './pipes/ngx-gallery-images.pipe';
import { LinksPaginationComponent } from './components/links-pagination/links-pagination.component';
import { MailtoPipe } from './pipes/mailto.pipe';
import { HiddenLinkDirective } from './directives/hidden-link.directive';
import { OfficeSelectComponent } from './components/office-select/office-select.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { InputTransformDirective } from './directives/input-transform.directive';
import { ShowForLangDirective } from './directives/show-for-lang.directive';
import { BlockOfferQueryPipe } from '@modules/tours/shared/pipes/block-offer-query.pipe';
import { SafeJoinPipe } from './pipes/safe-join.pipe';
import { CheapestEntityPipe } from './pipes/cheapest-offer.pipe';
import { SubtitleLinkComponent } from './components/subtitle-link/subtitle-link.component';
import { PrivacyPolicyPopupComponent } from './components/privacy-policy-popup/privacy-policy-popup.component';
import { TourOptionsComponent } from './components/tour-options/tour-options.component';
import { FlightDatePipe } from './pipes/flight-date.pipe';
import { TourOperatorPipe } from './pipes/tour-operator.pipe';
import { DepartureCitySelectorPipe } from './pipes/departure-city-selector.pipe';
import { TransportTranslationPathPipe } from './pipes/transport-translation-path.pipe';
import { FoodTranslationPathPipe } from './pipes/food-translation-path.pipe';


@NgModule({
  declarations: [
    ReadMoreComponent,
    SubscriptionComponent,
    MapCardComponent,
    OfficesCardComponent,
    LocationsGridComponent,
    OfficeInfoComponent,
    FormFieldComponent,
    StarIconsComponent,
    HeaderTitleComponent,
    BusPreloaderComponent,
    HeaderSubtitleDirective,
    CurrentLangLinkPipe,
    DepartureCityLabelPipe,
    SpriteIconComponent,
    HamburgerComponent,
    CollapsablePanelComponent,
    FieldTranslationPipe,
    LoaderComponent,
    ActiveUrlPathDirective,
    HotelCardComponent,
    LoadMoreButtonComponent,
    HotelsMapComponent,
    OffersGridComponent,
    ReviewComponent,
    RatingMarkComponent,
    RenderOnlyOnServerDirective,
    RenderOnlyInBrowserDirective,
    TypeofPipe,
    ModalHeaderComponent,
    FormInputDirective,
    ConvertBytesPipe,
    MimeTypesExtensionsPipe,
    DateFormatPipe,
    JoinPipe,
    HotelReviewTourRangePipe,
    RatingTranslateMapperPipe,
    SpinnerLoaderDirective,
    PaginationComponent,
    PrevTemplateDirective,
    PageTemplateDirective,
    EllipsisTemplateDirective,
    NextTemplateDirective,
    SrcSetPipe,
    WorkSchedulePipe,
    LocationCardComponent,
    GridContentTemplateDirective,
    ParseFloatPipe,
    NgxGalleryImagesPipe,
    LinksPaginationComponent,
    MailtoPipe,
    HiddenLinkDirective,
    OfficeSelectComponent,
    PhoneInputComponent,
    InputTransformDirective,
    ShowForLangDirective,
    BlockOfferQueryPipe,
    SafeJoinPipe,
    CheapestEntityPipe,
    SubtitleLinkComponent,
    PrivacyPolicyPopupComponent,
    TourOptionsComponent,
    FlightDatePipe,
    TourOperatorPipe,
    DepartureCitySelectorPipe,
    TransportTranslationPathPipe,
    FoodTranslationPathPipe,
  ],
  imports: [
    CommonModule,
    LeafletModule,
    NgbDropdownModule,
    NgxMaskModule.forRoot(undefined),
    CarouselModule,
    TranslateModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RouterModule,
    LazyLoadImageModule,
    NgxGalleryModule,
    FormsModule
  ],
  exports: [
    ReadMoreComponent,
    SubscriptionComponent,
    MapCardComponent,
    OfficesCardComponent,
    LocationsGridComponent,
    OfficeInfoComponent,
    FormFieldComponent,
    StarIconsComponent,
    HeaderTitleComponent,
    BusPreloaderComponent,
    HeaderSubtitleDirective,
    CurrentLangLinkPipe,
    DepartureCityLabelPipe,
    TypeofPipe,
    SpriteIconComponent,
    HamburgerComponent,
    CollapsablePanelComponent,
    FieldTranslationPipe,
    LoaderComponent,
    ActiveUrlPathDirective,
    HotelCardComponent,
    LoadMoreButtonComponent,
    HotelsMapComponent,
    OffersGridComponent,
    ReviewComponent,
    RatingMarkComponent,
    RenderOnlyInBrowserDirective,
    RenderOnlyOnServerDirective,
    ModalHeaderComponent,
    FormInputDirective,
    ConvertBytesPipe,
    MimeTypesExtensionsPipe,
    DateFormatPipe,
    JoinPipe,
    HotelReviewTourRangePipe,
    RatingTranslateMapperPipe,
    SpinnerLoaderDirective,
    PaginationComponent,
    PrevTemplateDirective,
    PageTemplateDirective,
    EllipsisTemplateDirective,
    NextTemplateDirective,
    SrcSetPipe,
    WorkSchedulePipe,
    LocationCardComponent,
    GridContentTemplateDirective,
    ParseFloatPipe,
    NgxGalleryImagesPipe,
    LinksPaginationComponent,
    MailtoPipe,
    HiddenLinkDirective,
    OfficeSelectComponent,
    PhoneInputComponent,
    InputTransformDirective,
    ShowForLangDirective,
    BlockOfferQueryPipe,
    SafeJoinPipe,
    SubtitleLinkComponent,
    PrivacyPolicyPopupComponent,
    TourOptionsComponent,
    FlightDatePipe,
    TourOperatorPipe,
    DepartureCitySelectorPipe,
    TransportTranslationPathPipe,
    FoodTranslationPathPipe,
  ]
})
export class SharedModule { }
