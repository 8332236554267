import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { convertFileToBase64 } from 'package-angular';

import { valueAccessor } from '@helpers/angular.helper';
import { replaceError } from '@helpers/rx.helper';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [valueAccessor(ImagePreviewComponent)]
})
export class ImagePreviewComponent implements ControlValueAccessor {
  preview$: Observable<string>;

  @Input() alt = '';

  private preview$$ = new BehaviorSubject<File | undefined>(undefined);
  private onChange = (_: File) => { };
  private onTouched = () => { };

  constructor() {
    this.preview$ = this.preview$$.asObservable().pipe(
      switchMap(file => file ? convertFileToBase64(file) : of('')),
      replaceError('', 'Conversion File -> Base64 string error'),
    );
  }

  @Input()
  set file(file: File) {
    this.preview$$.next(file);
  }

  writeValue(file?: File): void {
    this.preview$$.next(file);
  }

  registerOnChange(fn: (file: File) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
