import { TableHeader } from './table-header';
import { TableBody } from './table-body';
import { TableCell } from './table-cell';

export class Table<T, C, R> implements Iterable<TableCell<T> | null> {
  [Symbol.iterator] = (): TableIterator<T> => new TableIterator(this);

  constructor(
    public body: TableBody<T>,
    public colHeader: TableHeader<C>,
    public rowHeader: TableHeader<R>
  ) { }

  get width(): number {
    return this.colHeader.length();
  }

  get height(): number {
    return this.rowHeader.length();
  }

  hasCell(x: C, y: R): boolean {
    return Boolean(this.findCell(x, y));
  }

  hasData(x: C, y: R): boolean {
    return this.findCell(x, y)?.data != null;
  }

  findCell(x: C, y: R): TableCell<T> | null {
    const colIdx = this.colHeader.findIndex(x);
    const rowIdx = this.rowHeader.findIndex(y);

    if (rowIdx >= 0 && colIdx >= 0) {
      return this.body.getCell(colIdx, rowIdx);
    }

    return null;
  }
}

class TableIterator<T> implements Iterator<TableCell<T> | null> {
  private counter: number = 0;

  constructor(private table: Table<T, any, any>) { }

  next(): IteratorResult<TableCell<T> | null> {
    const cell = this.table.body.getCell(this.getX(), this.getY());

    this.counter += 1;

    return { value: cell, done: this.hasNext() };
  }

  private hasNext(): boolean {
    return this.counter > this.table.width * this.table.height;
  }

  private getX(): number {
    return this.counter % this.table.width;
  }

  private getY(): number {
    return Math.floor(this.counter / this.table.width);
  }
}
