<div class="lang-selector">
  <div container="body" ngbDropdown>
    <button ngbDropdownToggle class="btn btn-secondary lang-selector__btn" id="langMenu" >
      <span>{{ (currentLangCode$ | async)! | translate | uppercase }}</span>
    </button>
    <div ngbDropdownMenu aria-labelledby="langMenu">
      <button ngbDropdownItem *ngFor="let lang of availableLanguages" (click)="selectLanguage(lang)">
        {{ langCodeTranslations[lang] | translate | uppercase }}
      </button>
    </div>
  </div>
</div>
