import { Store } from 'rxjs-observable-store';

import { RequestState, StoreRequestStateUpdater } from '@interfaces/helpers/store-request-state-updater';

export function getStoreRequestStateUpdater(store: Store<any>): StoreRequestStateUpdater {
  return (requestName: string, requestState: RequestState): void => {
    store.setState({
      ...store.state,
      requests: {
        ...store.state.requests,
        [requestName]: { ...store.state.requests[requestName], ...requestState },
      },
    });
  };
}

export function generateRequestState(): RequestState {
  return {
    inProgress: false,
    success: false,
    error: false
  };
}
