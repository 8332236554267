import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-offer-option',
  templateUrl: './offer-option.component.html',
  styleUrls: ['./offer-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferOptionComponent {
  @Input() iconSrc?: string;
  @Input() iconAlt = '';
  @Input() mainText = '';
  @Input() minorText = '';
}
