import { addDays, format, isBefore, isValid } from 'date-fns';
import { ru, uk } from 'date-fns/locale';

import { Lang2 } from 'package-types';

import { SearchDates } from '@interfaces/modules/tours/search';

export function initSearchDates(): SearchDates {
  const today = new Date();

  return {
    checkIn: formatToISODate(addDays(today, 1)),
    checkOut: formatToISODate(addDays(today, 5))
  };
}

export function formatToISODate(date: Date): string {
  return format(date, 'y-MM-dd');
}

export function generateMinEnabledDate() {
  return addDays(new Date(), 1);
}

export function generateMaxEnabledDate() {
  return addDays(new Date, 365);
}

export function sanitizePastSearchDates(dates: SearchDates): SearchDates {
  const today = new Date();
  if (dates.checkIn && isBefore(new Date(dates.checkIn), today)) {
    return initSearchDates();
  }

  return dates;
}

export function pickDateLocale(lang: Lang2) {
  switch (lang) {
    case Lang2.Ru:
      return ru;
    case Lang2.Uk:
      return uk;
  }

  return null;
}

export function sanitizeStringDate(value: string) {
  return isValid(new Date(value)) ? value : null;
}

export function parseDate(value: string) {
  const date: Date = new Date(value);

  return isValid(date) ? date : null;
}

export function convertStringRangeToDateRange(range: { from: string, to: string }) {
  return {
    from: parseDate(range.from),
    to: parseDate(range.to)
  };
}
