import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@environment';
import { AppBrowserModule } from './app/app.browser.module';

if (environment.production) {
  enableProdMode();
}

declare const Zone: any;

// TODO: remove after SSR debug
// increase stack trace length (optional)
// Error.stackTraceLimit = 100;
const longStackTraceZoneSpec = Zone.longStackTraceZoneSpec;
// increase stack trace limit (optional)
// longStackTraceZoneSpec.longStackTraceLimit = 1000;
Zone.current
  .fork(new Zone.TaskTrackingZoneSpec())
  // .fork(longStackTraceZoneSpec)
  .run(() => {
    // NgZone forks currentZone, which is here longStackTraceZone
    const ngZone = new NgZone({
      // enableLongStackTrace: true
    });
    platformBrowserDynamic()
      .bootstrapModule(AppBrowserModule, { ngZone });
  });
