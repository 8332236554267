import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ToursSearch } from 'package-types';

import { SelectionStore } from '../../../hotel-availability/services/store/selection.store';
import { NightDateStore } from '../../services/store/night-date.store';
import { TableDrivenFilterStore } from '../../services/store/table-driven-filter.store';
import { extractDisplayFilterFromOffer, extractFlightsFromOffer } from '../../../hotel-availability/helpers/offer-options.helper';

import { NightDateCell, NightDateTable } from '../../interfaces/night-date-table';

@Component({
  selector: 'app-night-date-table',
  templateUrl: './night-date-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NightDateTableComponent {
  table$: Observable<NightDateTable> = this.availableDatesStore.onChanges('table')
    .pipe(filter(table => table != null));

  constructor(
    private selectionStore: SelectionStore,
    private filterStore: TableDrivenFilterStore,
    private availableDatesStore: NightDateStore,
  ) { }

  cellClicked(cell: NightDateCell): void {
    if (cell.data !== null) {
      this.selectOffer(cell.data);
    } else {
      this.availableDatesStore.checkAndUpload(cell);
    }
  }

  private selectOffer(o: ToursSearch.Offer): void {
    this.filterStore.updateFilter(extractDisplayFilterFromOffer(o));
    this.selectionStore.setSelectedOfferWithFlights(o, extractFlightsFromOffer(o));
  }
}
