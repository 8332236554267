<div class="app__content container-fluid">
  <div class="row">
    <div class="container">
      <app-offers-grid *ngIf="hotels$ | async as hotels" [hotels]="hotels">
        <ng-container *ngIf="hasAvailableHotels$ | async; then loadMoreBtn else noTours;"></ng-container>
      </app-offers-grid>
    </div>
  </div>
</div>
<ng-template #loadMoreBtn>
  <app-load-more-button
    [loadingMsg]="('HotelsPage.LoadMore.Loading' | translate)!"
    [loadMoreMsg]="('HotelsPage.LoadMore.Static' | translate)!"
    [isLoading]="(isLoading$ | async)!"
    (click)="loadNextPage()">
  </app-load-more-button>
</ng-template>
<ng-template #noTours>{{ 'HotelsPage.LoadMore.NoData' | translate }}</ng-template>
<ng-template appHeaderSubtitle>
  <app-subtitle-link
    *ngIf="resortPageInfo$ | async as info"
    [names]="info.resort.countryNames"
    [url]="info.resort.countryUrl">
  </app-subtitle-link>
</ng-template>
