import { Component, OnInit } from '@angular/core';

import { InitializationService } from '@services/initialization.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <ng-container *appRenderOnlyInBrowser>
      <app-privacy-policy-popup></app-privacy-policy-popup>
      <notifier-container></notifier-container>
    </ng-container>
  `
})

export class AppComponent implements OnInit {
  constructor(private initializationService: InitializationService) { }

  ngOnInit(): void {
    this.initializationService.init();
  }
}
