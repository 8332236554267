<header class="header container">
  <div class="row no-gutters header__main">
    <div class="header__logo col-3 col-md-3 col-lg-1">
      <a class="logo" [routerLink]="'/' | currentLangLink" [title]="'ToolbarComponent.LogoTitle' | translate">
        <img class="logo__image" src="/assets/images/logo_silpo.png" [alt]="'ToolbarComponent.LogoTitle' | translate">
      </a>
    </div>
    <div class="header__page-title col-6 d-md-none">
      {{ 'MainMenu.Tours' | translate }}
    </div>
    <div class="header__toggle-btn d-md-none col-3">
      <button [ngClass]="{ 'is-active': isOpen }" class="hamburger hamburger--collapse toggle-menu-btn"
              (click)="toggleMenu()">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
      </span>
      </button>
    </div>
    <div class="header__nav col-12 d-md-none">
      <app-header-nav-sm
        [activeItem]="activeMenuItem"
        [items]="items"
        [isOpen]="isOpen">
      </app-header-nav-sm>
    </div>
    <div
      class="header__nav d-none d-md-flex col-md-12 col-lg-8 order-md-2 order-lg-1 justify-content-md-center justify-content-lg-start">
      <app-header-nav
        [items]="items"
        [activeItem]="activeMenuItem">
      </app-header-nav>
    </div>
    <div class="header__right d-none d-md-flex col-md-5 offset-md-4 col-lg-3 offset-lg-0 order-md-1 order-lg-2">
      <div class="phone">
        <div class="icon icon-phone"></div>
        <a [href]="'tel:' + phoneNumber">{{ phoneNumber }}</a>
      </div>
      <app-lang-selector></app-lang-selector>
    </div>
  </div>

  <div class="row justify-content-center">
    <app-header-title class="header__title col-12"></app-header-title>
  </div>
</header>
