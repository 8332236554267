import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ToursSearch } from 'package-types';
import { Required } from 'package-utils';

@Component({
  selector: 'app-flights-list',
  templateUrl: './flights-list.component.html',
  styleUrls: ['./flights-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightsListComponent {
  @Input() @Required flights!: ToursSearch.Flight[];
  @Output() selectedFlightChange = new EventEmitter<ToursSearch.Flight>();

  private selectedFlightValue?: ToursSearch.Flight;

  @Input() set selectedFlight(flight: ToursSearch.Flight | undefined) {
    this.selectedFlightValue = flight;
    this.selectedFlightChange.emit(flight);
  }

  get selectedFlight(): ToursSearch.Flight | undefined {
    return this.selectedFlightValue;
  }
}
