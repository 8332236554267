import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BackOffice } from 'package-types';

@Component({
  selector: 'app-office-info',
  templateUrl: './office-info.component.html',
  styleUrls: ['./office-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfficeInfoComponent {
  @Input() office?: BackOffice.SiteOffice;
  @Input() showCity: boolean = false;
}
