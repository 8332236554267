import { Store } from 'rxjs-observable-store';
import { Injectable } from '@angular/core';

import { ToursSearch } from 'package-types';

import { AvailableOffersState } from './available-offers.state';

@Injectable()
export class AvailableOffersStore extends Store<AvailableOffersState> {
  constructor() {
    super(new AvailableOffersState());
  }

  update(unfiltered: ToursSearch.Offer[], filtered: ToursSearch.Offer[]): void {
    this.setState({
      ...this.state,
      unfilteredOffers: unfiltered,
      offers: filtered
    });
  }

  setFiltered(offers: ToursSearch.Offer[]): void {
    this.patchState(offers, 'offers');
  }

  cleanFiltered(): void {
    this.setFiltered([...this.state.unfilteredOffers]);
  }

  cleanOffers(): void {
    this.setState({ ...this.state, offers: [], unfilteredOffers: [] });
  }
}
