import { Pipe, PipeTransform } from '@angular/core';

import { Images } from 'package-types';

import { GalleryLinks, pickGalleryImage } from '@helpers/images.helper';

@Pipe({
  name: 'ngxGalleryImages'
})
export class NgxGalleryImagesPipe implements PipeTransform {
  transform(links: Images.ResizesLinks[]): GalleryLinks[] {
    return links.map(links => pickGalleryImage(links));
  }
}
