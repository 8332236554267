<div class="tours-grid">
  <div class="container">
    <div class="row">
      <div
        class="tours-grid__card"
        [class]="gridClassList"
        *ngFor="let card of cards">
        <ng-container [ngTemplateOutlet]="card"></ng-container>
      </div>
    </div>
  </div>
</div>
