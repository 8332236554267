<div class="container" ngbDropdown>
  <a class="link field_menu_toggler" id="touristPickerDropdown" ngbDropdownToggle>
    <ng-container *ngIf="tourists.value">
      <span class="field__adult-count">
        {{ 'TouristsComponent.AdultsCount' | translate: { count: tourists.value.adults } }}
      </span>
      <span>,&nbsp;</span>
      <span class="field__children-count" *ngIf="tourists.value.children">
        {{ 'TouristsComponent.ChildrenCount' | translate: { count: tourists.value.children.length } }}
      </span>
    </ng-container>
  </a>
  <div ngbDropdownMenu aria-labelledby="touristPickerDropdown">
    <sv-tourist-picker
      [ageLabel]="ageLabel"
      [selectedAgeLabel]="selectedAgeLabel"
      [btnClass]="['btn', 'btn-accent']"
      [counterBtnClass]="['btn', 'btn-accent']"
      [adultsLabelMainText]="'TouristsComponent.LabelMain' | translate"
      [adultsLabelMinorText]="'TouristsComponent.LabelMinor' | translate"
      [addBtnText]="'TouristsComponent.AddChildButton' | translate"
      [formControl]="tourists">
    </sv-tourist-picker>
  </div>
</div>
<ng-template #ageLabel let-child>
  <app-child-label [childAge]="child"></app-child-label>
</ng-template>
<ng-template #selectedAgeLabel let-child>
  <app-child-label [childAge]="child" [showCloseIcon]="true"></app-child-label>
</ng-template>
