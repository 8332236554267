import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { TableCellTemplateDirective } from './directives/table-cell-template.directive';
import { TableColumnLabelTemplateDirective } from './directives/table-column-label-template.directive';
import { TableRowLabelTemplateDirective } from './directives/table-row-label-template.directive';
import { CellSkeletonComponent } from './components/cell-skeleton/cell-skeleton.component';
import { TableSkeletonComponent } from './components/table-skeleton/table-skeleton.component';

@NgModule({
  declarations: [
    CellSkeletonComponent,
    TableSkeletonComponent,
    TableCellTemplateDirective,
    TableColumnLabelTemplateDirective,
    TableRowLabelTemplateDirective
  ],
  exports: [
    CellSkeletonComponent,
    TableSkeletonComponent,
    TableCellTemplateDirective,
    TableColumnLabelTemplateDirective,
    TableRowLabelTemplateDirective
  ],
  imports: [
    CommonModule,
    FontAwesomeModule
  ]
})
export class TableToolkitModule { }
