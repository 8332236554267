import { AfterViewInit, Component, HostListener, Input, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateNativeAdapter, NgbDatepickerI18n, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ControlValueAccessor } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { format, parseISO } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';

import { CustomNgbDatepickerI18n } from './CustomNgbDatepickerI18n';
import { valueAccessor } from '@helpers/angular.helper';

import { NgbDateRange, SearchDates } from '@interfaces/modules/tours/search';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['date-range.component.scss'],
  providers: [
    valueAccessor(DateRangeComponent),
    { provide: NgbDatepickerI18n, useClass: CustomNgbDatepickerI18n }
  ]
})
export class DateRangeComponent implements ControlValueAccessor, AfterViewInit {
  public dateRange?: NgbDateRange;
  public dates?: SearchDates;
  public hoveredDate?: NgbDate;
  public displayMonths = 1;
  public currentMinDate?: NgbDate;
  public currentMaxDate?: NgbDate;

  private minDateValue?: NgbDate;
  private maxDateValue?: NgbDate;
  private adapter = new NgbDateNativeAdapter();
  private currentLang?: string;

  @Input() maxRangeLength = 14;
  @Input()
  set minDate(date: Date) {
    this.minDateValue = NgbDate.from(this.adapter.fromModel(date)) ?? undefined;
    this.currentMinDate = this.minDateValue;
  }

  @Input()
  set maxDate(date: Date) {
    this.maxDateValue = NgbDate.from(this.adapter.fromModel(date)) ?? undefined;
    this.currentMaxDate = this.maxDateValue;
  }

  @ViewChild('vc', { read: ViewContainerRef }) vc!: ViewContainerRef;
  @ViewChild('datepicker', { read: TemplateRef }) tpl!: TemplateRef<any>;
  @ViewChild('dropdown', { read: NgbDropdown }) dropdown!: NgbDropdown;

  private onTouched = () => { };
  private onChange = (_: any) => { };

  constructor(
    private translate: TranslateService,
    private calendar: NgbCalendar
  ) { }

  renderDatepickerView(): void {
    if (this.currentLang !== this.translate.currentLang) {
      this.currentLang = this.translate.currentLang;
      this.vc.clear();
      this.vc.insert(this.tpl.createEmbeddedView(null));
    }
  }

  registerOnChange(fn: (_: any) => { }): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => { }): void {
    this.onTouched = fn;
  }

  @HostListener('window:resize')
  private onResize(): void {
    this.displayMonths = window.innerWidth > 660 ? 2 : 1;
  }

  ngAfterViewInit(): void {
    this.onResize();
  }

  writeValue(dates: SearchDates): void {
    this.dates = cloneDeep(dates);
    this.convertDates();
  }

  onDateSelection(date: NgbDate): void {
    this.onTouched();
    const { from, to } = this.dateRange ?? { };
    if (from && !to) {
      this.setToRange(date);
    } else {
      this.setFromRange(date);
    }

    this.convertDates();
    this.onChange(cloneDeep(this.dates));
  }

  isHovered(date: NgbDate): boolean {
    return Boolean(
      this.dateRange?.from
      && !this.dateRange?.to
      && this.hoveredDate
      && date.after(this.dateRange?.from)
      && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate): boolean {
    return date.after(this.dateRange?.from) && date.before(this.dateRange?.to);
  }

  isStartRange(date: NgbDate): boolean {
    return date.equals(this.dateRange?.from);
  }

  isEndRange(date: NgbDate): boolean {
    return date.equals(this.dateRange?.to);
  }

  private convertDates(): void {
    if (!this.dates) return;

    const from = this.dates.checkIn && this.adapter.fromModel(parseISO(this.dates.checkIn));
    const to = this.dates.checkOut && this.adapter.fromModel(parseISO(this.dates.checkOut));
    this.dateRange = {
      from: from || undefined,
      to: to || undefined
    };
  }

  private setFromRange(date: NgbDate): void {
    this.currentMinDate = date;
    const possibleMaxDate = this.calendar.getNext(date, 'd', this.maxRangeLength - 1);
    this.currentMaxDate = possibleMaxDate.after(this.maxDateValue) ? this.maxDateValue : possibleMaxDate;

    const checkIn = this.adapter.toModel(date) ?? undefined;
    this.dates = {
      checkIn: checkIn && format(checkIn, 'y-MM-dd'),
      checkOut: undefined
    };
  }

  private setToRange(date: NgbDate): void {
    this.currentMinDate = this.minDateValue;
    this.currentMaxDate = this.maxDateValue;

    const checkOut = this.adapter.toModel(date);
    if (this.dates && checkOut) {
      this.dates.checkOut = format(checkOut, 'y-MM-dd');
    }
    this.dropdown.close();
  }
}
