<div class="app__block container-fluid">
  <app-tour-offers-content class="row"></app-tour-offers-content>
  <div class="row">
    <app-offer-me-tour-card class="col-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6"></app-offer-me-tour-card>
  </div>
</div>
<app-seo-linking [display]="{ popular: true, resorts: true, hotels: false }"></app-seo-linking>
<div class="app__block" *ngIf="page$ | async as page">
  <div class="container" *ngIf="(page | fieldTranslation)?.html as html">
    <app-seo-text [seoText]="html"></app-seo-text>
  </div>
</div>
<ng-template appHeaderSubtitle>
  <app-subtitle-link
    *ngIf="resortPageInfo$ | async as info"
    [names]="info.resort.countryNames"
    [url]="info.resort.countryUrl">
  </app-subtitle-link>
</ng-template>
