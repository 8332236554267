import { InjectionToken, NgModule } from '@angular/core';

import { Lang2, MimeType } from 'package-types';

export interface LanguagesConfig {
  primary: Lang2;
  secondary: Lang2;
}

export interface AppConfig {
  languages: LanguagesConfig;
  contactInfo: {
    email: string,
    phoneNumbers: {
      main: string,
      additional: string[]
    }
  };
  imageUpload: {
    maxSizeInBytes: number,
    imageTypes: MimeType[]
  };
  pages: {
    reviews: {
      itemsPerPage: number,
      pageUrlSegmentMatcher: RegExp
    },
    hotelsCatalog: {
      itemsPerPage: number,
      starsUrlSegmentMatcher: RegExp,
      pageUrlSegmentMatcher: RegExp
    },
    hotelPageInfo: {
      reviewsPerPage: number
    }
  };
}

export const APP_CONFIG = new InjectionToken<AppConfig>('app-config');

export const config: AppConfig = {
  languages: {
    primary: Lang2.Uk,
    secondary: Lang2.Ru
  },
  contactInfo: {
    email: 'voyage@fozzy.ua',
    phoneNumbers: {
      main: '0 800 300 606',
      additional: ['044 392 06 06']
    }
  },
  imageUpload: {
    maxSizeInBytes: 10 * 1024 * 1024,
    imageTypes: [MimeType.PNG, MimeType.JPG, MimeType.SVG]
  },
  pages: {
    reviews: {
      itemsPerPage: 20,
      pageUrlSegmentMatcher: /^page-([2-9]|[1-9][0-9]*)$/
    },
    hotelsCatalog: {
      itemsPerPage: 20,
      starsUrlSegmentMatcher: /^([1-5])-star$/,
      pageUrlSegmentMatcher: /^page([2-9]|[1-9][0-9]+)$/
    },
    hotelPageInfo: {
      reviewsPerPage: 20
    }
  }
};

@NgModule({
  providers: [{
    provide: APP_CONFIG,
    useValue: config
  }]
})
export class AppConfigModule { }
