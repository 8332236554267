import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgxGalleryImage, NgxGalleryOptions } from '@kolkov/ngx-gallery';

import { Required } from 'package-utils';

import { pickGalleryImage } from '@helpers/images.helper';

import { DisplayableHotelReview } from '@interfaces/hotel-review';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];

  @Required private reviewValue!: DisplayableHotelReview;

  @Input()
  set review(review: DisplayableHotelReview) {
    this.reviewValue = review;
    this.galleryImages = this.reviewValue.images
      .map(({ links, title }) => {
        const base = pickGalleryImage(links);

        return { ...base, description: title };
      });
  }

  get review(): DisplayableHotelReview {
    return this.reviewValue;
  }

  ngOnInit(): void {
    this.galleryOptions = [
      {
        width: '100%',
        height: '150px',
        image: false,
        thumbnailsColumns: 3,
        thumbnailsPercent: 100,
        thumbnailsMargin: 5,
        thumbnailMargin: 5,
        thumbnailsArrowsAutoHide: true,
        previewArrowsAutoHide: true,
        thumbnailsSwipe: true,
        previewCloseOnEsc: true,
        previewKeyboardNavigation: true
      }
    ];
  }
}
