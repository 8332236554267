import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { valueAccessor } from '@helpers/angular.helper';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [valueAccessor(StarRatingComponent)]
})
export class StarRatingComponent implements ControlValueAccessor {
  @Input() category = '';

  private rateValue = 0;
  private onTouched = () => { };
  private onChange = (_: number) => { };

  constructor(private readonly cd: ChangeDetectorRef) { }

  set rate(val: number) {
    this.rateValue = val;
    this.onChange(val);
    this.onTouched();
  }

  get rate() {
    return this.rateValue;
  }

  registerOnChange(fn: (_: number) => { }): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => { }): void {
    this.onTouched = fn;
  }

  writeValue(val: number): void {
    if (typeof val !== 'number') {
      throw new Error('Rating value should be a number');
    }

    this.rateValue = val;
    this.cd.markForCheck();
  }
}
