import { subDays } from 'date-fns';

import { ActionStatus } from '@interfaces/action-status';

export interface LoadingStatus {
  uploadImages: ActionStatus[];
  postReview: ActionStatus;
}

export interface AvailableTourDates {
  min: Date;
  max: Date;
}

export class PostDestinationReviewState {
  availableToursDates: AvailableTourDates = {
    min: subDays(new Date, 365),
    max: subDays(new Date, 1)
  };
  loadingStatus: LoadingStatus = {
    uploadImages: [],
    postReview: 'pristine'
  };
}
