import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { Lang2, TourOrders, ToursSearch } from 'package-types';

import { ApiLoader, ApiRequestResult } from '@classes/api-loader';
import { convertSearchParamsToSearchQuery } from '@helpers/converter.helper';
import { SelectionStore } from './store/selection.store';
import { TourOrdersEndpoint } from '@services/api/tour-orders.endpoint';
import { HotelPageInfoStore } from '../../../services/hotel-page-info.store';
import { SearchResultStore } from '../../../../dynamic-page-container/services/store/search-result.store';

import { ContactInfo } from '@interfaces/modules/tours/shared/contact-form';
import { ChosenFlights } from '../interfaces/offer-management';

type OrderParams = TourOrders.Actions.SiteCreateTourOrderArguments['params'];

interface OrderContext {
  payment: TourOrders.PaymentType;
}

@Injectable()
export class TourOrderCreationService {
  public creationLoading$: Observable<boolean>;
  public creationResult$: Observable<ApiRequestResult<TourOrders.Actions.SiteCreateTourOrderResult, OrderParams, OrderContext>>;
  public configLoading$: Observable<boolean>;
  public configResult$: Observable<ApiRequestResult<TourOrders.TourOrdersConfig, { }, { }>>;

  private readonly orderTourLoader = new ApiLoader<OrderParams, OrderContext>();
  private readonly configLoader = new ApiLoader();

  constructor(
    private readonly selection: SelectionStore,
    private readonly tourOrders: TourOrdersEndpoint,
    private readonly translate: TranslateService,
    private readonly hotelPageInfo: HotelPageInfoStore,
    private readonly searchResult: SearchResultStore
  ) {
    this.creationLoading$ = this.orderTourLoader.status$.pipe(map(s => s === 'inProgress'));
    this.creationResult$ = this.orderTourLoader.data$
      .pipe(
        switchMap(data => this.tourOrders.createTourOrder(data.params)
          .pipe(this.orderTourLoader.completion(data)))
      );

    this.configLoading$ = this.configLoader.status$.pipe(map(s => s === 'inProgress'));
    this.configResult$ = this.configLoader.data$.pipe(
      switchMap(data => this.tourOrders.getTourOrdersConfig().pipe(this.configLoader.completion(data)))
    );
  }

  loadConfig() {
    this.configLoader.fetch({ }, { });
  }

  createTourOrder(contact: ContactInfo, offer: ToursSearch.Offer, flights: ChosenFlights) {
    const params = this.createTourOrderParams(contact, offer, flights);
    this.orderTourLoader.fetch(params, { payment: contact.payment });
  }

  private createTourOrderParams(contact: ContactInfo, offer: ToursSearch.Offer, flights: ChosenFlights) {
    const { hotelInfo } = this.hotelPageInfo.state;
    const search = this.searchResult.state.actualSearch.search;
    const { office, comment, email, phone, name, payment } = contact;
    if (!hotelInfo) {
      throw new Error('Not enough information to order the tour');
    }

    return {
      offer,
      flights,
      lang: this.translate.currentLang as Lang2,
      contacts: { email, phone, fullName: name },
      comment: comment ?? undefined,
      selectedOfficeId: office?._id ?? undefined,
      hotelId: hotelInfo.hotelId,
      toursSearchQuery: convertSearchParamsToSearchQuery(search),
      paymentType: payment
    };
  }
}
