import { Lang2 } from 'package-types';

import { NaturalWeekday, WeekdaysGroup } from './work-hours-normalizer';
import dateTranslations from '@constants/date-translations';

class WeekdaysAccumulator {
  private acc: NaturalWeekday[] = [];

  add(weekday: NaturalWeekday) {
    this.acc.push(weekday);
  }

  isEmpty(): boolean {
    return this.acc.length === 0;
  }

  isInRow(weekday: NaturalWeekday): boolean {
    const last = this.last();
    if (last === undefined) {
      return false;
    }

    return (last + 1) === weekday;
  }

  flush(): NaturalWeekday[] {
    const result = this.acc;
    this.clean();

    return result;
  }

  clean() {
    this.acc = [];
  }

  private last(): NaturalWeekday | undefined {
    return this.acc[this.acc.length - 1];
  }
}

class WeekdaysGroupRenderer {
  workHours(start: string, end: string): string {
    return `${start} - ${end}`;
  }

  group(weekdaysInfo: string, workHours: string): string {
    return `${weekdaysInfo}: ${workHours}`;
  }

  weekdaysRange(from: string, to: string): string {
    return `${from}-${to}`;
  }

  dividedWeekdays(...weekdays: string[]): string {
    return weekdays.join(', ');
  }
}

export type ScheduleView = string[];

export class ScheduleDemonstrator {
  // Inline translation for synchronous usage
  private readonly dayOffTranslation = { [Lang2.Ru]: 'выходной', [Lang2.Uk]: 'вихідний' };
  private readonly renderer = new WeekdaysGroupRenderer();
  private readonly schedule: WeekdaysGroup[];

  constructor(private lang: Lang2, schedule: WeekdaysGroup[]) {
    this.schedule = this.sortWeekdaysInGroups(schedule);
  }

  scheduleToString(): ScheduleView {
    return this.schedule.map(({ weekdays, workHours }) => {
      const workHoursInfo = workHours ? this.renderer.workHours(workHours.start, workHours.end) : this.dayOffTranslation[this.lang];
      const weekdaysInfo = this.joinWeekdays(weekdays);

      return this.renderer.group(weekdaysInfo, workHoursInfo);
    });
  }

  private joinWeekdays(weekdays: NaturalWeekday[]): string {
    const result: string[] = [];
    const acc = new WeekdaysAccumulator();

    for (const weekday of weekdays) {
      if (!acc.isEmpty() && !acc.isInRow(weekday)) {
        result.push(this.weekdaysToString(acc.flush()));
      }
      acc.add(weekday);
    }

    if (!acc.isEmpty()) {
      result.push(this.weekdaysToString(acc.flush()));
    }

    return this.renderer.dividedWeekdays(...result);
  }

  private weekdaysToString(weekdays: NaturalWeekday[]): string {
    if (weekdays.length === 1) {
      return this.getWeekdayTranslation(weekdays.pop() as NaturalWeekday);
    }
    const first = this.getWeekdayTranslation(weekdays.shift() as NaturalWeekday);
    const last = this.getWeekdayTranslation(weekdays.pop() as NaturalWeekday);

    return weekdays.length ? this.renderer.weekdaysRange(first, last) : this.renderer.dividedWeekdays(first, last);
  }

  private getWeekdayTranslation(weekday: NaturalWeekday): string {
    return dateTranslations[this.lang].weekday[weekday];
  }

  private sortWeekdaysInGroups(schedule: WeekdaysGroup[]): WeekdaysGroup[] {
    return schedule.map(({ weekdays, workHours }) => {
      const sortedWeekdays = [...weekdays].sort((a, b) => a - b);

      return { workHours, weekdays: sortedWeekdays };
    });
  }
}
