import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { ToursSearch } from 'package-types';

import { HotelPageInfoStore } from '../../services/hotel-page-info.store';

@UntilDestroy()
@Component({
  selector: 'app-post-review-modal',
  templateUrl: './post-review-modal.component.html',
  styleUrls: ['./post-review-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostReviewModalComponent {
  readonly hotelDestination = ToursSearch.DestinationType.Hotel;
  hotelId$: Observable<number> = this.hotelPage.onChanges('hotelInfo').pipe(
    map(info => info?.hotelId),
    filter((id): id is number => Boolean(id)),
    take(1)
  );

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly hotelPage: HotelPageInfoStore
  ) { }
}
