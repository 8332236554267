<ng-select [items]="$any(destinations$ | async)"
           appendTo="body"
           [loading]="isLoading"
           (clear)="autocomplete$.next('')"
           [groupBy]="groupByDestinationType"
           [(ngModel)]="destination"
           [typeahead]="autocomplete$"
           [compareWith]="comparator"
           [loadingText]="'Common.Loading' | translate"
           [notFoundText]="'Common.NotFound' | translate"
           [clearAllText]="'Common.Clear' | translate"
           [typeToSearchText]="typeToSearchText"
           [placeholder]="placeholder">
  <ng-template ng-optgroup-tmp let-destinationType="item">
    {{ destinationType?.label | destinationGroup | translate }}
  </ng-template>
  <ng-template ng-label-tmp let-destination="item">
    {{ destination?.fullName }}
  </ng-template>
  <ng-template ng-option-tmp let-destination="item">
    <div class="tour-item">
      <div class="tour-item__left">
        <span class="tour-item__main">{{ destination?.name }}</span>
        <span class="tour-item__minor">{{ destination?.countryName }}</span>
      </div>
      <div class="tour-item__right tour-item__minor">
        <ng-container *ngIf="destination?.price; then withPrice else noTours"></ng-container>
        <ng-template #withPrice>
          {{
          'SearchComponent.Location.Option.Price' | translate:{
            price: destination?.price | currency:'UAH':'грн':'1.0-0'
          }
          }}
        </ng-template>
        <ng-template #noTours>{{ 'SearchComponent.Location.Option.NoTours' | translate }}</ng-template>
      </div>
    </div>
  </ng-template>
</ng-select>
