import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

import { searchComponentDictionary, searchSelectorsTranslations } from '@constants/translation-dictionaries';
import { generateMaxEnabledDate, generateMinEnabledDate } from '@helpers/date.helper';
import { SearchFormStore } from '@services/store/search-form.store';
import { SearchFormState } from '@services/store/search-form.state';

@UntilDestroy()
@Component({
  selector: 'app-search-base',
  template: ''
})
export class SearchBaseComponent {
  public searchComponentDictionary = searchComponentDictionary;
  public searchSelectorsTranslations = searchSelectorsTranslations;

  public resorts$: Observable<SearchFormState['destinationSelectors']['resorts']> = this.searchForm
    .onChanges('destinationSelectors', 'resorts');
  public hotels$: Observable<SearchFormState['destinationSelectors']['hotels']> = this.searchForm
    .onChanges('destinationSelectors', 'hotels');

  public foods$: Observable<SearchFormState['selectors']['food']> = this.searchForm.onChanges('selectors', 'food');
  public transport$: Observable<SearchFormState['selectors']['transport']> = this.searchForm.onChanges('selectors', 'transport');
  public services$: Observable<SearchFormState['selectors']['services']> = this.searchForm.onChanges('selectors', 'services');

  public minAvailableSearchDate = generateMinEnabledDate();
  public maxAvailableSearchDate = generateMaxEnabledDate();
  public availableDaysRange = 14;

  constructor(protected readonly searchForm: SearchFormStore) { }

  get searchFormGroup(): FormGroup {
    return this.searchForm.formGroup;
  }
}
