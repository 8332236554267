import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  errorMessagesDictionary,
  httpErrorMessagesDictionary,
  infoMessagesDictionary,
  successMessageDictionary
} from '@constants/translation-dictionaries';
import { ErrorMessage } from '@constants/error-message.enum';
import { InfoMessage } from '@constants/info-message.enum';
import { HttpError } from '@constants/http-error.enum';
import { SuccessMessage } from '@constants/success-message.enum';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private readonly notifier: NotifierService,
    private readonly translate: TranslateService
  ) { }

  httpError(message: HttpError, interpolateParams?: object): void {
    this.translate
      .get(httpErrorMessagesDictionary[message], interpolateParams)
      .pipe(untilDestroyed(this))
      .subscribe(message => this.notifier.notify('error', message));
  }

  error(message: ErrorMessage | string, interpolateParams?: object): void {
    this.translate
      .get(errorMessagesDictionary[message] ?? message, interpolateParams)
      .pipe(untilDestroyed(this))
      .subscribe(message => this.notifier.notify('error', message));
  }

  info(message: InfoMessage, interpolateParams?: object): void {
    this.translate
      .get(infoMessagesDictionary[message], interpolateParams)
      .pipe(untilDestroyed(this))
      .subscribe(message => this.notifier.notify('info', message));
  }

  success(message: SuccessMessage, interpolateParams?: object): void {
    this.translate
      .get(successMessageDictionary[message], interpolateParams)
      .pipe(untilDestroyed(this))
      .subscribe(message => this.notifier.notify('success', message));
  }
}
