<div class="container">
  <div class="offers-list">
    <h2 class="offers-list__header">{{ title }}</h2>
    <ul class="offers-list__body">
      <li class="item-link" *ngFor="let item of list">
        <a [routerLink]="item.url | currentLangLink" [title]="item.text | seoLinkingText">
          <span class="offers-list__info">{{ item.text | seoLinkingText }}</span>
          <span
            class="offers-list__price"
            *ngIf="showPrice && item.price">
            {{ 'Common.FromUah' | translate:{ price: item.price } }}
          </span>
        </a>
      </li>
    </ul>
  </div>
</div>
