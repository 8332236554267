import { Pipe, PipeTransform } from '@angular/core';

import { ToursCatalog } from 'package-types';

import { UrlService } from '@services/url.service';

@Pipe({
  name: 'breadcrumbListJsonLd'
})
export class BreadcrumbListJsonLdPipe implements PipeTransform {
  constructor(
    private readonly url: UrlService
  ) { }

  transform(links: ToursCatalog.SiteSectionLink[]): Record<string, any> {
    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: links.map((l, idx) => ({
        '@type': 'ListItem',
        position: idx + 1,
        name: l.title,
        item: idx + 1 !== links.length ? { '@type': 'Thing', '@id': this.url.getOrigin() + l.link } : undefined
      }))
    };
  }
}
