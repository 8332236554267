import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Required } from 'package-utils';

import { MainPageBlockWithResizes } from '../../interfaces/main-page-block';

@Component({
  selector: 'app-offer-tile-small',
  templateUrl: './offer-tile-small.component.html',
  styleUrls: ['./offer-tile-small.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferTileSmallComponent {
  @Input() @Required block!: MainPageBlockWithResizes;
}
