<table class="table" *ngIf="hasData">
  <thead>
  <tr>
    <th class="table__title cell">
      {{ title }}
    </th>
    <th
      class="table__head cell"
      *ngFor="let col of table?.colHeader?.getSegments()">
      <ng-container
        [ngTemplateOutlet]="columnLabelTemplate"
        [ngTemplateOutletContext]="{ item: col }">
      </ng-container>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of table?.rowHeader?.getSegments(); let rowIndex = index">
    <th class="table__side cell">
      <ng-container
        [ngTemplateOutlet]="rowLabelTemplate"
        [ngTemplateOutletContext]="{ item: row }">
      </ng-container>
    </th>
    <td *ngFor="let cell of table?.body?.getRow(rowIndex)">
      <div *ngIf="cell" class="table__cell cell" (click)="cellClicked($any(cell))">
        <ng-container
          [ngTemplateOutlet]="cellTemplate"
          [ngTemplateOutletContext]="{ cell: cell }">
        </ng-container>
      </div>
    </td>
  </tr>
  </tbody>
</table>
<div *ngIf="!hasData">{{ emptyTableMessage }}</div>
