import { Inject, Injectable } from '@angular/core';

import { GTAG } from './gtag.token';
import { EventParams, Gtag } from './gtag.interface';
import { GTAG_CONFIG } from './gtag-config.token';
import { GtagConfig } from './gtag-config.interface';

@Injectable()
export class GtagService {
  constructor(
    @Inject(GTAG) private readonly gtag: Gtag,
    @Inject(GTAG_CONFIG) private readonly config: GtagConfig,
  ) { }

  event<T extends EventParams>(eventName: string, params: T | undefined, config: { waitForDispatch: true }): Promise<void>;
  event<T extends EventParams>(eventName: string, params: T | undefined, config?: { waitForDispatch?: boolean }): void;
  event<T extends EventParams>(eventName: string, params: T | undefined, config: { waitForDispatch?: boolean } = { }): any {
    if (!config.waitForDispatch) {
      this.gtag('event', eventName, params ?? { });

      return;
    }

    return new Promise<void>((resolve, reject) => {
      const timeout = setTimeout(() => reject(new Error('Gtag event timeout exceeded')), this.config.eventTimeout);

      this.gtag(
        'event',
        eventName,
        {
          ...params,
          event_timeout: this.config.eventTimeout,
          event_callback(status) {
            clearTimeout(timeout);
            if (status === 'timeout') {
              reject(new Error('Gtag event timeout exceeded'));
            } else {
              resolve();
            }
          },
        },
      );
    });
  }
}
