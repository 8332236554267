import { ChangeDetectionStrategy, Component } from '@angular/core';
import { distinctUntilChanged, distinctUntilKeyChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Images, ToursCatalog } from 'package-types';

import { InformationStore } from '@services/store/information.store';
import { ImagesEndpoint } from '@services/api/images.endpoint';
import { DynamicPage } from '@classes/decorators/dynamic-page.decorator';
import { ToursCatalogEndpoint } from '@services/api/tours-catalog.endpoint';
import { OverviewImagesLoaderService } from '@services/overview-images-loader.service';
import { pickCountryInfoOrResortInfo } from '@helpers/dynamic-page.helper';

import { Country, CountryPageInfo, Resort, ResortOverview } from '@interfaces/services/api/tours-catalog.endpoint';

interface ResortOverviewWithImageResizes extends ResortOverview {
  imageResizes: Images.ResizesLinks;
}

@DynamicPage({
  pageName: ToursCatalog.PageName.Attractions,
  setDefaultSearchParams: true,
  disableH1SmallUpdates: true
})
@Component({
  selector: 'app-location-info-page',
  templateUrl: './location-info-page.component.html',
  styles: [':host { display: block; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OverviewImagesLoaderService]
})
export class LocationInfoPageComponent {
  resortPageInfo$ = this.informationStore.resortPageInfo$;
  locationInfo$: Observable<Country | Resort> = this.informationStore.onChanges('pageInfo').pipe(
    pickCountryInfoOrResortInfo(),
    map(info => info.type === ToursCatalog.PageType.Country ? info.country : info.resort),
    distinctUntilKeyChanged('_id'),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
  images$ = this.locationInfo$.pipe(
    switchMap(({ _id }) => this.images.getCustomImagesLinks([_id])),
    map(res => res.imageResizesLinks)
  );
  resortsOverview$ = this.informationStore.onChanges('pageInfo')
    .pipe(
      filter((info): info is CountryPageInfo => info?.type === ToursCatalog.PageType.Country),
      distinctUntilChanged((prev, curr) => prev.country.otpuskId === curr.country.otpuskId),
      switchMap(page => this.toursCatalog.getResortsOverview(page.country.otpuskId)),
      switchMap(({ resorts }) => this.mergeOverviewWithImageResizes(resorts))
    );

  constructor(
    private readonly informationStore: InformationStore,
    private readonly images: ImagesEndpoint,
    private readonly toursCatalog: ToursCatalogEndpoint,
    private readonly overviewImagesLoader: OverviewImagesLoaderService
  ) { }

  private mergeOverviewWithImageResizes(resorts: ResortOverview[]): Observable<ResortOverviewWithImageResizes[]> {
    return this.overviewImagesLoader.loadHeadImages(resorts)
      .pipe(
        map(resizes => this.overviewImagesLoader.enrichOverviewByImageResizes(resorts, resizes))
      );
  }
}
