import { Injectable } from '@angular/core';

import { ToursSearch } from 'package-types';

import { NightDateTableFiller } from '../classes/night-date-table/night-date-table-filler';
import { sortOffersInAscendingOrderOfPrice } from '../helpers/offers.helper';
import { filterOfferByFoodRoom } from '../helpers/night-date.helper';
import { fillEmptyCellsByUnfilteredOffers } from '@modules/tours/table-toolkit/helpers/table.helper';
import { EmptyTableBuilder } from '@modules/tours/table-toolkit/classes/empty-table-builder';
import { TableDrivenFilterStore } from './store/table-driven-filter.store';
import { NightDateHeaderFactory } from '../classes/night-date-table/night-date-header-factory';

import { NightDateTable } from '../interfaces/night-date-table';
import { TableDrivenFilter } from '../interfaces/table-driven-filter';

@Injectable()
export class NightDateTableFactoryService {
  constructor(
    private filterStore: TableDrivenFilterStore
  ) { }

  standard(offers: ToursSearch.Offer[], rowLabels: number[], colLabels: Date[]): NightDateTable {
    const sorted = sortOffersInAscendingOrderOfPrice(offers);

    return this.buildAndFill(sorted, rowLabels, colLabels);
  }

  filtered(offers: ToursSearch.Offer[], rowLabels: number[], colLabels: Date[]): NightDateTable {
    const sorted = sortOffersInAscendingOrderOfPrice(offers);
    const relevant = this.chooseRelevantOffers(sorted, this.getFilter());

    return this.buildAndFill(relevant, rowLabels, colLabels);
  }

  combined(offers: ToursSearch.Offer[], rowLabels: number[], colLabels: Date[]): NightDateTable {
    return fillEmptyCellsByUnfilteredOffers(
      this.standard(offers, rowLabels, colLabels),
      this.filtered(offers, rowLabels, colLabels)
    );
  }

  private buildAndFill(offers: ToursSearch.Offer[], rowLabels: number[], colLabels: Date[]): NightDateTable {
    const empty = this.buildEmptyTable(rowLabels, colLabels);

    return new NightDateTableFiller(empty).fill(offers);
  }

  private buildEmptyTable(rowLabels: number[], colLabels: Date[]): NightDateTable {
    return new EmptyTableBuilder<ToursSearch.Offer, Date, number>(
      rowLabels,
      colLabels,
      new NightDateHeaderFactory()
    ).build();
  }

  private getFilter(): TableDrivenFilter {
    return this.filterStore.state;
  }

  private chooseRelevantOffers(offers: ToursSearch.Offer[], filter: TableDrivenFilter): ToursSearch.Offer[] {
    return filter ? this.filterOffers(offers, filter) : offers;
  }

  private filterOffers(offers: ToursSearch.Offer[], options: TableDrivenFilter): ToursSearch.Offer[] {
    return offers.filter(offer => filterOfferByFoodRoom(offer, options));
  }
}
