import { TranslateService } from '@ngx-translate/core';
import { ɵComponentDef as ComponentDef } from '@angular/core';
import { Subscription } from 'rxjs';

import { Lang2, Location, ToursCatalog } from 'package-types';

import { StaticInjectorModule } from './static-injector.module';
import { GlobalLayoutStore } from '@services/store/global-layout.store';
import { SeoService } from '@services/seo.service';
import { InformationStore } from '@services/store/information.store';
import { StaticPageInfoLoaderService } from '@services/static-page-info-loader.service';
import { getImageLinksInDescOrder } from '@helpers/images.helper';
import { SearchFormStore } from '@services/store/search-form.store';
import { HtmlHeadService } from '@services/html-head.service';

import { ClassDecorator, InjectedServices, LifecycleMixin, NG_COMP_DEF, PageComponent } from './interfaces';
import { PageInfo } from '@interfaces/services/api/tours-catalog.endpoint';
import { HeadTagsArgs } from '@interfaces/services/seo.service';
import { Writable } from '@interfaces/util';

export function buildPageDecorator({ onInitMixin, onDestroyMixin, onLangChange }: LifecycleMixin): ClassDecorator {
  return (constructor) => {
    const component = getComponentDef(constructor as PageComponent);
    if (!component) {
      throw new Error('Page decorator can be used only for angular components');
    }
    const { onDestroy, onInit } = component;
    let services: InjectedServices;
    let onLangChangeSubscription: Subscription;

    Object.assign(component, {
      onInit() {
        services = getServices();
        onInitMixin.call(this, services);
        onLangChangeSubscription = services.translate.onLangChange
          .subscribe(() => onLangChange(services));
        onInit?.call(this);
      },
      onDestroy() {
        onDestroy?.call(this);
        if (!services) {
          throw new Error('Services in page decorator are not injected');
        }
        onDestroyMixin.call(this, services);
        onLangChangeSubscription?.unsubscribe();
      }
    });
  };
}

export function extractHeadTags(pageName: ToursCatalog.PageName, lang: Lang2, info: PageInfo): HeadTagsArgs {
  const [src] = info.headImage?.links ? getImageLinksInDescOrder(info.headImage.links) : [];

  return {
    seo: info.seo[lang],
    img: { src, alt: info.headImage?.alt },
    coordinates: getLocation(info)?.coordinates
  };
}

function getLocation(info: PageInfo): Location | undefined {
  switch (info.type) {
    case ToursCatalog.PageType.Country:
      return info.country.location;
    case ToursCatalog.PageType.Resort:
      return info.resort.location;
    default:
      return undefined;
  }
}

function getServices(): InjectedServices {
  return {
    globalLayout: StaticInjectorModule.get(GlobalLayoutStore),
    searchForm: StaticInjectorModule.get(SearchFormStore),
    seoService: StaticInjectorModule.get(SeoService),
    translate: StaticInjectorModule.get(TranslateService),
    info: StaticInjectorModule.get(InformationStore),
    staticPageInfoLoader: StaticInjectorModule.get(StaticPageInfoLoaderService),
    htmlHead: StaticInjectorModule.get(HtmlHeadService),
  };
}

function getComponentDef(constructor: PageComponent): Writable<ComponentDef<any>> {
  return constructor[NG_COMP_DEF];
}
