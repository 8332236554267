import { WebAppError } from '../../../errors/web-app.error';

import { SearchParams } from '@interfaces/modules/tours/search';

export class TooLongSearchError extends WebAppError {
  constructor(
    readonly searchId: string,
    readonly pageNumber: number,
    readonly params: SearchParams
  ) {
    super('Search timed out');
  }
}
