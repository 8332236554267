import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { InlineSVGModule } from 'ng-inline-svg';

import { OverlayBottomDirective } from './components/overlaid-image/overlay-bottom.directive';
import { OfferTileComponent } from './components/offer-tile/offer-tile.component';
import { TilesGridComponent } from './components/tiles-grid/tiles-grid.component';
import { SharedModule } from '@modules/tours/shared/shared.module';
import { OverlaidImageComponent } from './components/overlaid-image/overlaid-image.component';
import { HotelInfoComponent } from './components/hotel-info/hotel-info.component';
import { OfferTileSmallComponent } from './components/offer-tile-small/offer-tile-small.component';
import { OverlayTopDirective } from './components/overlaid-image/overlay-top.directive';
import { MainPageComponent } from './main-page.component';
import { IconsSetComponent } from './components/icons-set/icons-set.component';
import { HtmlBlockComponent } from './components/html-block/html-block.component';
import { MainPageBlockHtmlPipe } from './components/html-block/main-page-block-html.pipe';
import { BlockImageLinksPipePipe } from './pipe/block-image-links.pipe';
import { VlasnyiRakhunokBlockComponent } from './components/vlasnyi-rakhunok-block/vlasnyi-rakhunok-block.component';
import { BlocksSortingPipe } from './pipe/blocks-sorting.pipe';
import { HotToursCardModule } from '@modules/tours/hot-tours-card/hot-tours-card.module';
import { TransportIconComponent } from './components/transport-icon/transport-icon.component';
import { AccommodationIconComponent } from './components/accommodation-icon/accommodation-icon.component';
import { FoodIconComponent } from './components/food-icon/food-icon.component';

@NgModule({
  declarations: [
    MainPageComponent,
    OfferTileSmallComponent,
    TilesGridComponent,
    OverlaidImageComponent,
    HotelInfoComponent,
    OfferTileComponent,
    OverlayTopDirective,
    OverlayBottomDirective,
    IconsSetComponent,
    HtmlBlockComponent,
    MainPageBlockHtmlPipe,
    BlockImageLinksPipePipe,
    VlasnyiRakhunokBlockComponent,
    BlocksSortingPipe,
    TransportIconComponent,
    AccommodationIconComponent,
    FoodIconComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    RouterModule,
    LazyLoadImageModule,
    HotToursCardModule,
    InlineSVGModule
  ],
  exports: [MainPageComponent]
})
export class MainPageModule { }
