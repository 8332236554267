<div class="overlaid-image">
  <div class="overlaid-image__container">
    <img class="overlaid-image__background"
         *ngIf="resizesLinks | srcSet as srcSet"
         [style.object-fit]="imageFit"
         [src]="resizesLinks!['634x370'] || resizesLinks!['954x510'] || resizesLinks!['1268x740']"
         [lazyLoad]="srcSet"
         defaultImage="assets/images/placeholder.png"
         [useSrcset]="true"
         [alt]="imgAlt">
  </div>
  <div *ngIf="topTemplate" class="overlaid-image__overlay overlaid-image__overlay_top">
    <ng-container [ngTemplateOutlet]="topTemplate"></ng-container>
  </div>
  <div *ngIf="bottomTemplate" class="overlaid-image__overlay overlaid-image__overlay_bottom">
    <ng-container [ngTemplateOutlet]="bottomTemplate"></ng-container>
  </div>
</div>
