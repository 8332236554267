<div class="container hotel-card__container">
  <div class="row hotel-card">
    <div class="hotel-card__carousel col-12 col-lg-4">
      <ng-container *ngIf="galleryImages.length">
        <ngx-gallery [images]="galleryImages" [options]="options"></ngx-gallery>
        <div class="hotel-card__slides-count">
          {{ 'HotelCardComponent.Photos' | translate:{ count: galleryImages.length } }}
        </div>
      </ng-container>
    </div>
    <div class="hotel-card__main col-12 col-lg-8">
      <div class="hotel-card__header">
        <div class="hotel-card__title">
          <a class="hotel-card__link" [routerLink]="hotelUrl | currentLangLink"
             [queryParams]="queryParams">{{ hotelName }}</a>
        </div>
        <div class="hotel-card__stars">
          <app-star-icons [stars]="stars"></app-star-icons>
        </div>
        <div class="hotel-card__ratio ratio" *ngIf="rating > 0">
          <span>{{ 'HotelCardComponent.Ratio' | translate }}:</span>
          <span class="ratio__value">{{ rating }}</span>
          <span>/10</span>
        </div>
      </div>
      <div class="hotel-card__body">
        <div class="location">
          <span class="location__name" (click)="openMap(map)">{{ countryName }}, {{ resortName }}</span>
        </div>
        <ng-template #map>
          <app-hotels-map
            [imageLink]="galleryImages[0]?.small"
            [hotelName]="hotelName"
            [priceUAH]="priceUAH"
            [hotelUrl]="hotelUrl"
            [location]="location">
          </app-hotels-map>
        </ng-template>
        <div class="hotel-card__information information" *ngIf="food || accommodation || transport || room || offer">
          <span
            *ngIf="(food || offer?.food) as food"
            class="information__icon icon icon_foods">
            {{ food }}
          </span>
          <app-sprite-icon
            *ngIf="(accommodation || offer?.tourists?.accommodation) as accommodation"
            class="information__icon"
            type="tourists"
            [value]="accommodation">
          </app-sprite-icon>
          <app-sprite-icon
            *ngIf="(transport || offer?.transport) as transport"
            class="information__icon"
            type="transport"
            [value]="transport">
          </app-sprite-icon>
          <span class="information__icon information__room room" *ngIf="(room || offer?.room) as room">
              <span class="room__label">{{ 'HotelCardComponent.Room' | translate }}</span>
              <span class="room__type">{{ room }}</span>
          </span>
        </div>
      </div>
      <div class="hotel-card__footer" *ngIf="(priceUAH || offer?.priceUAH?.toString()) as price">
        <a class="hotel-card__btn_link btn btn-accent" [routerLink]="hotelUrl | currentLangLink"
           [queryParams]="queryParams">
          {{ price | currency:'UAH':'symbol-narrow':'1.0-0' }}
        </a>
      </div>
    </div>
  </div>
</div>
