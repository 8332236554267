import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';

import { SearchResultExpiredModalComponent } from './components/search-result-expired-modal/search-result-expired-modal.component';
import { SearchTimeoutExpiredModalComponent } from './components/search-timeout-expired-modal/search-timeout-expired-modal.component';
import { SearchFailedModalComponent } from './components/search-failed-modal/search-failed-modal.component';

import { SearchParams } from '@interfaces/modules/tours/search';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class SearchModalsService {
  constructor(
    private readonly modalService: NgbModal
  ) { }

  showSearchResultExpiredModal(searchParams: SearchParams) {
    const ref = this.modalService.open(SearchResultExpiredModalComponent, { size: 'lg', centered: true });
    ref.componentInstance.searchParams = searchParams;
  }

  showSearchTimeoutExpiredModal(searchParams: SearchParams) {
    const ref = this.modalService.open(SearchTimeoutExpiredModalComponent, { size: 'lg', centered: true });
    ref.componentInstance.searchParams = searchParams;
  }

  showSearchFailedModal(searchParams: SearchParams) {
    const ref = this.modalService.open(SearchFailedModalComponent, { size: 'lg', centered: true });
    ref.componentInstance.searchParams = searchParams;
  }
}
