import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';

import { cleanDeep } from 'package-utils';

import { searchParamsSchema } from './search-params.schema';
import { replaceError } from '@helpers/rx.helper';

import { SearchParams } from '@interfaces/modules/tours/search';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(private storage: StorageMap) { }

  setSearchParams(params: SearchParams): Observable<undefined> {
    return this.storage.set('search-params', cleanDeep(params, { emptyArrays: false }));
  }

  getSearchParams(): Observable<SearchParams | undefined> {
    return this.storage.get<SearchParams>('search-params', searchParamsSchema)
      .pipe(replaceError('Broken params are stored in local storage', undefined));
  }

  setClientId(id: string): Observable<undefined> {
    return this.storage.set('client-id', id);
  }

  getClientId(): Observable<string | undefined> {
    return this.storage.get('client-id', { type: 'string' });
  }
}
