<article class="article">
  <div class="article__header">
    <ng-content></ng-content>
  </div>
  <div class="article__body" *ngIf="page?.blocks as blocks">
    <ng-container *ngFor="let block of $any(blocks)">
      <ng-container [ngSwitch]="block.type">
        <ng-container *ngSwitchCase="PageBlockType.HTML">
          <section [innerHTML]="buildSectionHtml(block.title, block.html)"></section>
        </ng-container>
        <ng-container *ngSwitchCase="PageBlockType.Image">
          <div
            class="article__image"
            *ngIf="images && (block.imageId | fromImageIdToLinks:images) as resizesLinks">
            <img *ngIf="resizesLinks | srcSet as srcSet" [lazyLoad]="srcSet" [useSrcset]="true">
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</article>
