import isNumber from 'lodash/isNumber';

export function toArrayOfNumbers(value?: string, separator?: string) {
  if (!value || !separator) {
    return null;
  }

  return value.split(separator)
    .map(item => parseFloat(item))
    .filter(item => !Number.isNaN(item));
}

export function toFiniteNumber(val: unknown) {
  const number: number = Number(val);

  return Boolean(val) && Number.isFinite(number) ? number : null;
}

export function toFiniteNumberString(val: unknown) {
  return isNumber(val) ? val.toString() : null;
}

export function createIntegerGroupParser(regExp: RegExp): (token: string) => number | null {
  return (token) => {
    const [_, integerGroup] = token.match(regExp) ?? [];
    if (integerGroup) {
      const integer = parseInt(integerGroup, 10);

      return Number.isNaN(integer) ? null : integer;
    }

    return null;
  };
}
