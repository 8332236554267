import { GtagConfig } from './gtag-config.interface';
import { Gtag } from './gtag.interface';
import { GtagWindow } from './gtag-window.interface';
import { PlatformService } from '@services/platform.service';

export function gtagFactory(config: GtagConfig, window: GtagWindow, document: Document, platform: PlatformService): Gtag {
  if (window.gtag) {
    return window.gtag;
  }
  if (platform.isServer()) {
    return window.gtag = function fakeGtag() { /* do nothing */ };
  }

  const script = document.createElement('script');

  script.src = `https://www.googletagmanager.com/gtag/js?id=${config.trackingId}`;
  script.type = 'text/javascript';
  script.async = true;

  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];

  function gtag(...args: any[]) { window.dataLayer?.push(arguments); }

  gtag('js', new Date());
  gtag('config', config.trackingId);

  return window.gtag = gtag;
}
