import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-sprite-icon',
  templateUrl: './sprite-icon.component.html',
  styleUrls: ['./sprite-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpriteIconComponent {
  @Input() type!: 'transport' | 'tourists';
  @Input() value!: string;
}
