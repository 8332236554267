import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Hotel } from '@interfaces/services/api/tours-catalog.endpoint';

@Component({
  selector: 'app-hotel-contacts',
  templateUrl: './hotel-contacts.component.html',
  styleUrls: ['./hotel-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotelContactsComponent {
  @Input() hotel?: Hotel | null;
}
