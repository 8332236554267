<form [formGroup]="reviewForm" class="container review-form">
  <app-votes-group
    class="review-form__votes"
    formControlName="votes">
  </app-votes-group>
  <div class="row review-form__message">
    <div class="col-12">
      <app-form-field>
        <app-date-range
          *ngIf="availableTourDates$ | async as tourDates"
          formControlName="dates"
          [minDate]="tourDates.min"
          [maxDate]="tourDates.max"
          [maxRangeLength]="30">
        </app-date-range>
      </app-form-field>
    </div>
    <div class="col-12">
      <app-message-group formControlName="message"></app-message-group>
    </div>
    <app-image-uploader
      class="col-4"
      (files)="addImages($event)"
      [contentTypes]="config.imageUpload.imageTypes">
      <fa-icon [icon]="['fas', 'camera']"></fa-icon>
      {{ 'Reviews.AddPhoto' | translate }}
    </app-image-uploader>
    <button
      type="button"
      class="btn offset-4 col-4"
      [class.btn-primary]="postButtonTheme === 'primary'"
      [class.btn-accent]="postButtonTheme === 'accent'"
      (click)="send()"
      [disabled]="reviewForm.invalid || (reviewPostingStatus$ | async) === 'inProgress'">
      <ng-template [appSpinnerLoader]="(reviewPostingStatus$ | async) === 'inProgress'">
        {{ 'Reviews.PostReview' | translate }}
      </ng-template>
    </button>
    <ng-container formArrayName="images" *ngIf="imagesUploadingStatus$ | async as uploadStatus">
      <app-review-image
        class="col-12"
        [uploadStatus]="uploadStatus[i] || 'pristine'"
        *ngFor="let image of imagesForm.controls; let i = index;"
        [formControlName]="i"
        (remove)="removeImage(i)">
      </app-review-image>
    </ng-container>
  </div>
</form>
