<form class="contact-form row" [formGroup]="contactForm">
  <div class="form-group col-12">
    <app-form-field label="Common.Name">
      <input appFormInput
             name="name"
             type="text"
             formControlName="name"
             [placeholder]="('Common.Name' | translate) + '*'">
    </app-form-field>
    <ng-container *ngIf="contactForm.get('name') as name">
      <div class="form-group__error" *ngIf="name.touched && name.errors?.required">
        {{ 'ValidationErrors.EnterName' | translate }}
      </div>
    </ng-container>
  </div>
  <div class="form-group col-12">
    <app-form-field label="Common.Phone">
      <app-phone-input formControlName="phone" inputClass="sv-input"></app-phone-input>
    </app-form-field>
    <ng-container *ngIf="contactForm.get('phone') as phone">
      <div class="form-group__error" *ngIf="phone.touched && (phone.errors?.required || phone.errors?.pattern)">
        {{ 'ValidationErrors.EnterPhone' | translate:{ format: '+38(0##)-###-##-##' } }}
      </div>
    </ng-container>
  </div>
  <div class="form-group col-12">
    <app-form-field label='E-mail:'>
      <input appFormInput type="text" name="email" formControlName="email" placeholder="E-mail*">
    </app-form-field>
    <ng-container *ngIf="contactForm.get('email') as email">
      <div class="form-group__error"
           *ngIf="email.touched && (email.errors?.email || email.errors?.required)">
        {{ 'ValidationErrors.WrongEMail' | translate }}
      </div>
    </ng-container>
  </div>
  <div class="form-group col-12">
    <app-form-field label="Common.Comment">
      <textarea appFormInput
                type="text"
                name="comment"
                formControlName="comment"
                [placeholder]="'Common.Comment' | translate">
      </textarea>
    </app-form-field>
  </div>
  <div class="form-group col-12">
    <ng-container *ngIf="configResult$ | async as config; then selector else preloader"></ng-container>
  </div>
</form>
<ng-template #preloader>
  <app-bus-preloader></app-bus-preloader>
</ng-template>
<ng-template #selector let-config>
  <div class="form-group__buy-block">
    <button
      type="button"
      class="btn btn-accent btn-block btn-sm"
      (click)="config.allowOnlinePayment && this.toggleOfficeSelect()">
      {{ 'ContactForm.BookButton' | translate }}
      <fa-icon [icon]="['fas', isOfficeSelectOpen ? 'caret-up' : 'caret-down']"></fa-icon>
    </button>
    <ng-container *ngIf="isOfficeSelectOpen || !config.allowOnlinePayment">
      <app-form-field class="w-100">
        <app-office-select [formControl]="$any(contactForm).get('office')"></app-office-select>
      </app-form-field>
      <button
        [disabled]="isLoading || contactForm.invalid"
        (click)="contactFormSubmit(PaymentType.InOffice)"
        class="btn btn-accent btn-block btn-sm"
        type="button">
        <ng-template [appSpinnerLoader]="isLoading === true">
          {{ 'ContactForm.SendButton' | translate }}
        </ng-template>
      </button>
    </ng-container>
    <ng-container *ngIf="!isOfficeSelectOpen && config.allowOnlinePayment">
      <span>{{ 'ContactForm.Or' | translate }}</span>
      <button
        [disabled]="isLoading || contactForm.invalid"
        (click)="contactFormSubmit(PaymentType.Online)"
        class="btn btn-accent btn-block form-group__buy-btn"
        type="button">
        <ng-template [appSpinnerLoader]="isLoading === true">
          <span>{{ 'ContactForm.BuyOnlineButton' | translate }}</span>
          <img src="/assets/images/mastercard.png" alt="MasterCard">
          <img src="/assets/images/visa.png" alt="Visa">
        </ng-template>
      </button>
    </ng-container>
  </div>
</ng-template>
