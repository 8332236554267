<a class="tour-card" *ngIf="locationLink" [routerLink]="locationLink | currentLangLink">
  <div class="tour-card__image">
    <img
      *ngIf="imageSrcSet"
      [lazyLoad]="imageSrcSet"
      [useSrcset]="true"
      defaultImage="assets/images/placeholder.png"
      [alt]="imageAlt || ''">
  </div>
  <div class="tour-card__body">
    <span class="tour-card__title" *ngIf="title">{{ title }}</span>
    <div class="tour-card__info-list" *ngIf="infoList">
      <span *ngFor="let item of infoList">{{ item }}</span>
    </div>
    <div class="tour-card__price" *ngIf="price">
      {{ 'Common.FromUah' | translate:{ price: price } }}
    </div>
  </div>
</a>
