import { Injectable } from '@angular/core';
import { Store } from 'rxjs-observable-store';
import { Observable, throwError } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, filter, tap } from 'rxjs/operators';
import merge from 'lodash/merge';
import differenceBy from 'lodash/differenceBy';
import { NavigationEnd, Router } from '@angular/router';

import { ToursSearch } from 'package-types';

import { SearchResultState } from './search-result.state';
import { ToursSearchService } from '@services/tours-search/tours-search.service';
import { InformationStore } from '@services/store/information.store';
import { QueryParamsService } from '@services/query-params.service';
import { createDefaultSearchWithDestination } from '@helpers/search.helper';
import { getStoreRequestStateUpdater } from '@helpers/endpoint.helper';
import { PlatformService } from '@services/platform.service';
import { SearchErrorsService } from '@services/tours-search/search-errors.service';
import { SearchFormStore } from '@services/store/search-form.store';

import { ParsedParams } from '@interfaces/parsed-params';
import { SearchParams } from '@interfaces/modules/tours/search';
import { ToursSearchResult } from '@interfaces/tours-search-result';
import { StoreRequestStateUpdater } from '@interfaces/helpers/store-request-state-updater';

import ResultStatus = ToursSearch.ResultStatus;

@UntilDestroy()
@Injectable()
export class SearchResultStore extends Store<SearchResultState> {
  private readonly storeRequestStateUpdater: StoreRequestStateUpdater;

  constructor(
    private readonly searchService: ToursSearchService,
    private readonly informationStore: InformationStore,
    private readonly queryParamsService: QueryParamsService,
    private readonly searchForm: SearchFormStore,
    private readonly platform: PlatformService,
    private readonly searchErrorsService: SearchErrorsService,
    private readonly router: Router
  ) {
    super(new SearchResultState());
    this.storeRequestStateUpdater = getStoreRequestStateUpdater(this);
  }

  init(): void {
    this.reloadSearchDueToSearchParams();
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe(() => this.reloadSearchDueToSearchParams());
  }

  loadHotels(page = 1): void {
    const { search, searchId } = this.state.actualSearch;
    if (page === 1) {
      this.setState({ ...this.state, page, status: undefined, hotels: [], hasNext: true });
    } else {
      this.setState({ ...this.state, page, status: undefined });
    }
    this.receiveResult(search, searchId, page)
      .pipe(
        filter(v => v.res.status === ResultStatus.Ready || v.res.status === ResultStatus.Failed),
        untilDestroyed(this)
      )
      .subscribe({
        next: ({ res: { status, result: { hotels } } }) => {
          const newHotels = differenceBy(hotels, this.state.hotels, 'hotelId');
          this.setState({ ...this.state, status, hotels: [...this.state.hotels, ...newHotels], hasNext: newHotels.length > 0 });
        },
        error: err => this.searchErrorsService.handleSearchErrors(err, search)
      });
  }

  loadNextPage(): void {
    this.loadHotels(this.state.page + 1);
  }

  private reloadSearchDueToSearchParams() {
    const { search, searchId } = this.buildActualSearchParams(this.queryParamsService.getParsedParamsSnapshot());
    this.searchForm.setSearchParams(search);
    this.reloadHotels(search, searchId);
  }

  private reloadHotels(search: SearchParams, searchId?: string): void {
    this.patchState({ searchId, search }, 'actualSearch');
    this.searchService.finishActiveSearches();
    this.loadHotels();
  }

  private receiveResult(search: SearchParams, searchId?: string, page?: number): Observable<ToursSearchResult> {
    const updater = this.storeRequestStateUpdater.bind(null, 'search');
    updater({ inProgress: true });

    return this.searchService.search(search, searchId, page)
      .pipe(
        tap(() => updater({ inProgress: false, success: true })),
        catchError((err) => {
          updater({ inProgress: false, error: true });

          return throwError(err);
        })
      );
  }

  private buildActualSearchParams({ searchId, search = { } }: ParsedParams): { search: SearchParams, searchId?: string } {
    const { destination, ...searchWithoutDestination } = search;
    const newSearch = merge(createDefaultSearchWithDestination(this.informationStore.state.pageInfo), searchWithoutDestination);

    return { searchId, search: newSearch };
  }
}
