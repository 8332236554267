import { ChangeDetectionStrategy, Component, Inject, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { footerMenu, mainMenu } from '@constants/site-menu';
import { AppConfig, APP_CONFIG } from '../../../../app-config.module';
import { SendSiteFeedbackModalComponent } from '../send-site-feedback-modal/send-site-feedback-modal.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  public readonly mainMenu = mainMenu;
  public readonly footerMenu = footerMenu;
  public readonly phoneNumber: string;

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private readonly modalService: NgbModal,
    private readonly injector: Injector
  ) {
    this.phoneNumber = config.contactInfo.phoneNumbers.main;
  }

  openSendSiteFeedbackModal() {
    this.modalService.open(SendSiteFeedbackModalComponent, { injector: this.injector, size: 'lg', centered: true });
  }
}
