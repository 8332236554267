import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import { FiltersStore } from '../../services/store/filters.store';
import { AvailableOffersStore } from '../../services/store/available-offers.store';
import { filterOffers } from '../../helpers/offer-options.helper';
import { NotificationService } from '@services/notification.service';
import { InfoMessage } from '@constants/info-message.enum';

import { GlobalFilter } from '../../interfaces/offer-management';

@Component({
  selector: 'app-filters-bar',
  templateUrl: './filters-bar.component.html',
  styleUrls: ['./filters-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersBarComponent {
  availableValues$: Observable<GlobalFilter> = this.filtersStore.onChanges('availableValues');
  selectedValues$: Observable<GlobalFilter> = this.filtersStore.onChanges('selectedValues');

  constructor(
    private filtersStore: FiltersStore,
    private availableOffersStore: AvailableOffersStore,
    private notificationService: NotificationService
  ) { }

  filter(filter: Partial<GlobalFilter>): void {
    const newFilter = { ...this.filtersStore.state.selectedValues, ...filter };
    const filtered = filterOffers(this.availableOffersStore.state.unfilteredOffers, newFilter);

    if (filtered.length === 0) {
      this.notificationService.info(InfoMessage.FilterCanceling);
      this.filtersStore.reloadFilter();
    } else {
      this.filtersStore.patchSelectedValues(filter);
      this.availableOffersStore.setFiltered(filtered);
    }
  }

  cleanFilter(): void {
    this.filtersStore.clean();
    this.availableOffersStore.cleanFiltered();
  }
}
