<div class="rating-value" *ngIf="value">
  <div class="rating-value__mark"
       [class.rating-value__mark_bordered]="bordered">
    {{ value }}
  </div>
  <div class="rating-value__title">
    <span class="rating-value__label">{{ category }}</span>
    <span class="rating-value__range">{{ clarification }}</span>
  </div>
</div>
