import { Decimal } from 'decimal.js';

export type WithPrice<T extends object> = T & { priceUAH: Decimal };

export function findCheapestEntity<T extends { priceUAH: Decimal }>(offers: T[]): WithPrice<T> | undefined {
  let cheapest: WithPrice<T> | undefined;

  for (const offer of offers) {
    if (offer.priceUAH) {
      if (!cheapest || (cheapest.priceUAH && offer.priceUAH.lessThan(cheapest.priceUAH))) {
        cheapest = offer as WithPrice<T>;
      }
    }
  }

  return cheapest;
}

