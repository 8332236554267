import { TemplateRef } from '@angular/core';

import { ToursCatalog, Util } from 'package-types';

import { SiteMenuSection } from '@constants/site-menu-section';
import { SpecialLayout } from '../../layouts/desktop/special-layout';

import { LayoutElementsVisibility } from '@classes/decorators/interfaces';

export interface ClientSectionLinkPathTranslation {
  path: string;
  params?: { [type: string]: any };
}

export interface ClientSectionLinkBase {
  link: string;
  urlMatcher?: RegExp;
}

export interface ClientSectionLinkForTranslation extends ClientSectionLinkBase{
  translation: ClientSectionLinkPathTranslation;
}

export interface TranslatedClientSectionLink extends ClientSectionLinkBase {
  title: string;
}

export type ClientSectionLink = ClientSectionLinkForTranslation | TranslatedClientSectionLink;

export interface ThematicSectionLink extends ToursCatalog.SiteSectionLink {
  urlMatcher?: RegExp;
}

export type ServerSectionLinks = Util.Translation<ToursCatalog.SiteSectionLink[]>;
export type ClientSectionLinks = ClientSectionLink[];
export type SectionLinks = ClientSectionLinks | ServerSectionLinks;

export function createDefaultElementsVisibility(): Required<LayoutElementsVisibility> {
  return {
    thematicSectionsMenu: false,
    searchForm: false,
    breadcrumbs: false
  };
}

export class GlobalLayoutState {
  /**
   * It's replace for routerLinkActive. We use a lot of dynamically
   * generated pages for hotels, resorts, countries
   * with language prefixes and without them for SEO.
   * It's not possible to use routerLinkActive for highlighting menu item.
   */
  activeSiteMenuSection: SiteMenuSection = SiteMenuSection.Tours;
  specialLayout?: SpecialLayout;
  elementsVisibility: Required<LayoutElementsVisibility> = createDefaultElementsVisibility();
  breadcrumbs: SectionLinks = [];
  thematicSectionsMenu: SectionLinks = [];
  header: {
    backgroundImageLink?: string;
    title?: string,
    subtitle?: TemplateRef<any> | string,
    widget?: TemplateRef<any>
  } = { };
}
