import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { NotificationService } from './notification.service';
import { HttpError } from '@constants/http-error.enum';
import { ErrorMessage } from '@constants/error-message.enum';

@UntilDestroy()
@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  private readonly showDefaultError$$ = new Subject();

  constructor(private notificationService: NotificationService) {
    this.showDefaultError$$
      .pipe(
        throttleTime(2 * 60 * 1000, undefined, { leading: true, trailing: false }),
        untilDestroyed(this)
      ).subscribe(() => this.notificationService.error(ErrorMessage.Default));
  }

  handleError(error: unknown) {
    console.error(error);
    if (error instanceof HttpErrorResponse) {
      this.notificationService.httpError(this.pickHttpError(error.status));
    } else {
      this.showDefaultError$$.next();
    }
  }

  private pickHttpError(status: number): HttpError {
    if (status >= 400 && status < 500) {
      switch (status) {
        case 400:
          return HttpError.UnknownError;
        case 401:
          return HttpError.Unauthorized;
        case 404:
          return HttpError.NotFound;
        case 408:
          return HttpError.RequestTimeout;
        case 422:
          return HttpError.UnprocessableEntity;
      }
    } else if (status >= 500) {
      return HttpError.UnknownError;
    }

    return HttpError.UnknownError;
  }
}
