import { BehaviorSubject, Observable } from 'rxjs';

import { ToursSearch } from 'package-types';

import { SearchParams } from '@interfaces/modules/tours/search';
import { ToursSearchResult } from '@interfaces/tours-search-result';

export interface SearchData {
  id: string;
  params: SearchParams;
  page: number;
}

export interface SearchCreateParams {
  data: SearchData;
  resultSource: BehaviorSubject<ToursSearchResult>;
}

export class Search {
  private readonly id: string;
  private readonly params: SearchParams;
  private readonly page: number;
  private readonly result$$: BehaviorSubject<ToursSearchResult>;

  static from(resultState: ToursSearchResult) {
    const { id, page, params } = resultState;
    const resultSource = new BehaviorSubject(resultState);

    return new Search({ resultSource, data: { id, page, params } });
  }

  constructor({ data, resultSource }: SearchCreateParams) {
    this.id = data.id;
    this.params = data.params;
    this.page = data.page;
    this.result$$ = resultSource;
  }

  getSearchId() {
    return this.id;
  }

  getPageNumber() {
    return this.page;
  }

  getParams() {
    return this.params;
  }

  asObservable(): Observable<ToursSearchResult> {
    return this.result$$.asObservable();
  }

  next(response: ToursSearch.Actions.GetResultResult) {
    this.result$$.next({ id: this.id, params: this.params, page: this.page, res: response });
  }

  finish(response: ToursSearch.Actions.GetResultResult) {
    this.result$$.next({ id: this.id, params: this.params, page: this.page, res: response });
    this.result$$.complete();
  }

  error(err: Error) {
    this.result$$.error(err);
  }

  destroy() {
    this.result$$.complete();
  }
}
