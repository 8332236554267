import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

import { SharedModule } from '@modules/tours/shared/shared.module';
import { BookOfferModalComponent } from './components/book-offer-modal/book-offer-modal.component';
import { PriceDisplayOptionsComponent } from './components/price-display-options/price-display-options.component';
import { HotelAvailabilityComponent } from './hotel-availability.component';
import { CheckboxFilterComponent } from './components/checkbox-filter/checkbox-filter.component';
import { SelectedOfferPanelComponent } from './components/selected-offer-panel/selected-offer-panel.component';
import { FiltersBarComponent } from './components/filters-bar/filters-bar.component';
import { CheckboxLabelTemplateDirective } from './directives/checkbox-label-template.directive';
import { AvailableOffersModule } from '../available-offers/available-offers.module';
import { HotelAvailabilityCoreModule } from '../hotel-availability-core/hotel-availability-core.module';
import { OfferOptionComponent } from './components/offer-option/offer-option.component';
import { DisplayOptionGroupComponent } from './components/display-option-group/display-option-group.component';
import { DisplayOptionSelectComponent } from './components/display-option-select/display-option-select.component';
import { OptionLabelDirective } from './directives/option-label.directive';
import { OptionValueDirective } from './directives/option-value.directive';
import { PriceDisplayOptionsGroupComponent } from './components/price-display-options-group/price-display-options-group.component';
import { PriceDisplayOptionsSelectComponent } from './components/price-display-options-select/price-display-options-select.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { OfferRelevanceModalComponent } from './components/offer-relevance-modal/offer-relevance-modal.component';

@NgModule({
  declarations: [
    HotelAvailabilityComponent,
    PriceDisplayOptionsComponent,
    BookOfferModalComponent,
    CheckboxFilterComponent,
    SelectedOfferPanelComponent,
    FiltersBarComponent,
    CheckboxLabelTemplateDirective,
    OfferOptionComponent,
    DisplayOptionGroupComponent,
    DisplayOptionSelectComponent,
    OptionLabelDirective,
    OptionValueDirective,
    PriceDisplayOptionsGroupComponent,
    PriceDisplayOptionsSelectComponent,
    ContactFormComponent,
    OfferRelevanceModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgbModalModule,
    TranslateModule,
    AvailableOffersModule,
    HotelAvailabilityCoreModule,
    FontAwesomeModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    NgxMaskModule
  ],
  exports: [HotelAvailabilityComponent],
  providers: [CurrencyPipe]
})
export class HotelAvailabilityModule { }
