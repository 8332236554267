import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { SeoLinkingComponent } from './components/seo-linking/seo-linking.component';
import { SeoLinkingSectionComponent } from './components/seo-linking-section/seo-linking-section.component';
import { SeoLinkingTextPipe } from './pipes/seo-linking-text.pipe';
import { SharedModule } from '../shared/shared.module';
import { SeoTextComponent } from './components/seo-text/seo-text.component';


@NgModule({
  declarations: [
    SeoLinkingComponent,
    SeoLinkingSectionComponent,
    SeoLinkingTextPipe,
    SeoTextComponent
  ],
  exports: [
    SeoLinkingComponent,
    SeoTextComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    RouterModule
  ]
})
export class SeoModule { }
