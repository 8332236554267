import { Lang2, Util } from 'package-types';

import { SiteMenuSection } from './site-menu-section';

export interface SiteMenuItem<T = string> {
  readonly translatePath: string;
  readonly link: T;
  readonly name?: SiteMenuSection;
}

export const mainMenu: Readonly<SiteMenuItem<string | Util.Translation>[]> = [
  {
    translatePath: 'MainMenu.Tours',
    link: '/',
    name: SiteMenuSection.Tours
  },
  {
    translatePath: 'MainMenu.AirTickets',
    link: {
      [Lang2.Ru]: 'https://avia.silpovoyage.ua/ru',
      [Lang2.Uk]: 'https://avia.silpovoyage.ua'
    }
  },
  {
    translatePath: 'MainMenu.Booking',
    link: {
      [Lang2.Ru]: 'https://hotels.silpovoyage.ua/ru',
      [Lang2.Uk]: 'https://hotels.silpovoyage.ua'
    }
  },
  {
    translatePath: 'MainMenu.Offices',
    link: '/offices',
    name: SiteMenuSection.Offices
  },
  {
    translatePath: 'MainMenu.Business',
    link: '/business',
    name: SiteMenuSection.CorporateServices
  }
];

export const footerMenu: Readonly<SiteMenuItem[]> = [
  { link: '/about', translatePath: 'FooterComponent.About' },
  { link: '/vakansii', translatePath: 'FooterComponent.OpenPositions' },
  { link: '/countries', translatePath: 'FooterComponent.Countries' },
  { link: '/hotels', translatePath: 'FooterComponent.Hotels' },
  { link: '/smi', translatePath: 'FooterComponent.Media' },
  { link: '/slovar-turista', translatePath: 'FooterComponent.TouristVocabulary' },
  { link: '/reviews', translatePath: 'FooterComponent.Reviews' },
  { link: '/legal-info', translatePath: 'FooterComponent.LegalInfo' }
];
