import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { isSameDay } from 'date-fns';
import { combineLatest, Observable } from 'rxjs';

import { ToursCatalog, ToursSearch } from 'package-types';

import { TableDrivenFilterStore } from '../../services/store/table-driven-filter.store';
import { SelectionStore } from '../../../hotel-availability/services/store/selection.store';
import { AvailableOffersStore } from '../../../hotel-availability/services/store/available-offers.store';

import { TableDrivenFilter } from '../../interfaces/table-driven-filter';
import { ChosenFlights } from '../../../hotel-availability/interfaces/offer-management';

@Component({
  selector: 'app-transport-menu',
  templateUrl: './transport-menu.component.html',
  styleUrls: ['./transport-menu.component.scss']
})
export class TransportMenuComponent implements OnInit {
  offers$!: Observable<ToursSearch.Offer[]>;
  cheapestOffer?: ToursSearch.Offer;

  @Input() hotelPageInfo!: ToursCatalog.HotelPageInfo;

  constructor(
    private selectionStore: SelectionStore,
    private availableOffersStore: AvailableOffersStore,
    private filterStore: TableDrivenFilterStore
  ) { }

  ngOnInit(): void {
    this.offers$ = combineLatest([
      this.filterStore.state$,
      this.availableOffersStore.onChanges('offers') as Observable<ToursSearch.Offer[]>]
    ).pipe(
      map(([filter, offers]) => {
        const actualOffers = offers && filter
          ? offers.filter(offer => this.offerByOptionsFilter(offer, filter))
          : [];

        this.cheapestOffer = actualOffers[0];

        return actualOffers;
      })
    );
  }

  @Input() set flightFrom(flight: ToursSearch.Flight | undefined) {
    this.setSelectedFlightsRange({ from: flight });
  }

  get flightFrom(): ToursSearch.Flight | undefined {
    return this.selectionStore.state.selectedFlights.from;
  }

  @Input() set flightTo(flight: ToursSearch.Flight | undefined) {
    this.setSelectedFlightsRange({ to: flight });
  }

  get flightTo(): ToursSearch.Flight | undefined {
    return this.selectionStore.state.selectedFlights.to;
  }

  selectOffer(offer: ToursSearch.Offer): void {
    if (this.selectionStore.state.selectedOffer !== offer) {
      const { from: selectedFlightFrom, to: selectedFlightTo }: ChosenFlights = this.selectionStore.state.selectedFlights;
      this.selectionStore.setSelectedOfferWithFlights(
        offer,
        {
          from: this.resetFlightOrUseCurrent(offer.flights?.from ?? [], selectedFlightFrom),
          to: this.resetFlightOrUseCurrent(offer.flights?.to ?? [], selectedFlightTo)
        }
      );
    }
  }


  calculatePriceDiff(offer: ToursSearch.Offer): number {
    const priceUAH = this.cheapestOffer?.priceUAH;

    return Math.round(priceUAH ? offer.priceUAH.minus(priceUAH).toNumber() : 0);
  }

  private setSelectedFlightsRange(range: Partial<ChosenFlights>): void {
    const storedFlights = this.selectionStore.state.selectedFlights;
    this.selectionStore.setSelectedFlights({ ...storedFlights, ...range });
  }

  private resetFlightOrUseCurrent(flights: ToursSearch.Flight[] = [], current?: ToursSearch.Flight): ToursSearch.Flight {
    const [newFlight] = flights;

    if (!current) {
      return newFlight;
    }

    return flights.includes(current) ? current : newFlight;
  }

  private offerByOptionsFilter(offer: ToursSearch.Offer, options: TableDrivenFilter): boolean {
    const isSameFromDate = Boolean(options.fromDate && isSameDay(offer.dateRange.from, options.fromDate));

    return offer.food === options.food &&
      offer.room === options.room &&
      isSameFromDate &&
      offer.nights === options.nights;
  }
}
