import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ErrorModalComponent } from '../components/error-modal/error-modal.component';
import { ErrorModalStore } from './store/error-modal.store';
import { ErrorMessage } from '@constants/error-message.enum';

@Injectable({
  providedIn: 'root'
})
export class ErrorModalService {
  constructor(
    private readonly modalService: NgbModal,
    private readonly errorModalStore: ErrorModalStore
  ) { }

  open(...messages: ErrorMessage[]): void {
    this.errorModalStore.setMessageTranslationOrUseDefault(...messages);
    if (!this.modalService.hasOpenModals()) {
      this.modalService.open(ErrorModalComponent, { size: 'lg', centered: true });
    }
  }
}
