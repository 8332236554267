<div class="app__block">
  <div class="container">
    <div class="row">
      <div class="col-12 col-xl-9">
        <app-article
          *ngIf="locationInfo$ | async as locationInfo"
          [images]="images$ | async"
          [page]="locationInfo.pages.attractions | fieldTranslation">
          <app-map-card [location]="locationInfo.location"></app-map-card>
        </app-article>
      </div>
      <aside class="col-12 col-xl-3">
        <div class="container">
          <div class="row aside-cards">
<!--            <div class="col-12 col-sm-6 col-xl-12">-->
<!--              <app-subscription></app-subscription>-->
<!--            </div>-->
            <div class="col-12 col-sm-6 col-xl-12">
              <app-offices-card></app-offices-card>
            </div>
          </div>
        </div>
      </aside>
    </div>
    <div class="row" *ngIf="resortsOverview$ | async as resorts">
      <h3 class="col-12">{{ 'Common.Resorts' | translate }}</h3>
      <app-locations-grid class="col-12" [grid]="{ base: 12, sm: 6, md: 4, lg: 3 }">
        <ng-template *ngFor="let resort of resorts" appGridContentTemplate>
          <app-location-card
            [title]="(resort.names | fieldTranslation)?.name"
            [price]="resort.priceUAH | parseFloat"
            [imageSrcSet]="resort.imageResizes | srcSet"
            [imageAlt]="(resort.names | fieldTranslation)?.name"
            [locationLink]="resort.resortUrl"
            [infoList]="[
              (resort.countryNames | fieldTranslation)?.name || '',
              'Common.HotelsAmount' | translate:{ amount: resort.hotelsAmount }
            ]">
          </app-location-card>
        </ng-template>
      </app-locations-grid>
    </div>
  </div>
</div>
<ng-template appHeaderSubtitle>
  <app-subtitle-link
    *ngIf="resortPageInfo$ | async as info"
    [names]="info.resort.countryNames"
    [url]="info.resort.countryUrl">
  </app-subtitle-link>
</ng-template>
