<div class="btn-group" *ngIf="options.length">
  <button
    *ngFor="let option of options"
    class="btn btn-option"
    [style.width]="labelWidth"
    [class.btn-primary]="option.value === selection"
    [class.btn-muted]="option.value !== selection"
    (click)="selectionChange.emit(option.value)">
    <ng-container
      [ngTemplateOutlet]="label"
      [ngTemplateOutletContext]="{ $implicit: option }">
    </ng-container>
  </button>
</div>
